import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import type { IBattingPerformanceModel, IMatchPlayerModel } from '@clsplus/cls-plus-data-models'
import { closeSpellForPreviousBallForBowler } from '@clsplus/cls-plus-data-models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { includes, sortBy } from 'lodash'
import { observer } from 'mobx-react-lite'

import Bowling from '../../assets/bowling.svg'
import * as Reference from '../../data/reference'
import { useMst } from '../../data/stores/rootStore'
import CommsHelpers from '../../helpers/commsHelpers'
import type { BallDescriptionProps } from '../../types/props'
import { BowlerApproach } from './BowlerApproach'
import { FreeHit } from './FreeHit'

export const BallDescription = observer(
  ({
    mode,
    ball,
    game,
    lastBall,
    ballStore,
    editBall = false,
    isNewestBall = true,
    isNewestOver = true,
    preBall = true,
    timelineEvents,
    isDisabled,
  }: BallDescriptionProps) => {
    const { appSettings } = useMst()
    let battingPlayers: (IMatchPlayerModel | undefined)[] = []
    const bowlingPlayers: IMatchPlayerModel[] | undefined = game.getBowlingPlayersInBattingOrder(
      true,
      false,
      ball.getInningsId
    )

    if (ball.batterMp) {
      battingPlayers.push(ball.batterMp)
    }
    if (ball.batterNonStrikeMp) {
      battingPlayers.push(ball.batterNonStrikeMp)
    }

    const handleBowlerChange = (player: IMatchPlayerModel) => {
      const previousBowler = ball.bowlerMp?.id
      ball.setBowler(player)
      const inning = game.getActiveInning()

      // check if the player selected is missing any required properties. if so, set to default values
      if (player?.player.battingHandedId === null) player.setBattingHandedId(1) // RIGHT
      if (player?.player.bowlingHandedId === null) player.setBowlingHandedId(1) // RIGHT
      if (player?.player.primaryThrowingArmId === null) player.setPrimaryThrowingArmId(1) // RIGHT
      if (player?.player.bowlingTypeId === null) player.setBowlingTypeId(2) // SLOW

      ball.setBowlerHand(
        player?.player.bowlingHandedId !== null && player?.player.bowlingHandedId !== undefined
          ? Reference.HandedTypeOptions[player?.player.bowlingHandedId]
          : null
      )
      ball.setBowlerType(
        player?.player.bowlingTypeId !== null && player?.player.bowlingTypeId !== undefined
          ? Reference.BowlerTypeOptions[player?.player.bowlingTypeId]
          : null
      )
      if (!editBall && inning) {
        timelineEvents?.addNewBowler(
          player,
          inning.inningsMatchOrder,
          appSettings.timeMachine.baseline,
          appSettings.timeMachine.activated
        )
        inning?.newBowler(player, isNewestOver, isNewestBall)
        if (previousBowler) {
          closeSpellForPreviousBallForBowler(
            previousBowler,
            ball.overNumber,
            ball.ballNumber,
            ballStore.getBallsForBowler(inning.id, previousBowler),
            inning
          )
        }
      } else if (editBall && mode === 'advanced') {
        // change of bowler should trigger auto-commentary refresh
        ball.setTextDescription(CommsHelpers.generateBallCommentary(game, ball, lastBall))
      }
    }
    const handleBatterChange = (player: IMatchPlayerModel) => {
      ball.setStrikeBatter(player)
      const activeInning = game.getActiveInning()
      const newestBallInInningsIncludingUnconfirmed = activeInning
        ? ballStore.getNewestBall(activeInning.id, true)
        : null
      if (!editBall) {
        activeInning?.switchStrike(
          undefined,
          ball.overNumber === 0 && ball.ballNumber === 1,
          !editBall &&
            newestBallInInningsIncludingUnconfirmed?.overNumber === 0 &&
            newestBallInInningsIncludingUnconfirmed?.ballNumber === 1
        )
      } else if (editBall && ball.overNumber === 0 && ball.ballNumber === 1) {
        // if editing strike batter on first ball, just set their perf order. do not attempt to change strike
        const onStrike = activeInning?.battingPerformances.find(
          (perf: IBattingPerformanceModel) => perf.playerMp.id === ball.batterMp?.id
        )
        const nonStrike = activeInning?.battingPerformances.find(
          (perf: IBattingPerformanceModel) => perf.playerMp.id === ball.batterNonStrikeMp?.id
        )
        if (onStrike && nonStrike) {
          onStrike?.updateOrder(1)
          nonStrike?.updateOrder(2)
        }
      }
      if (
        ball.batterMp &&
        ball.batterNonStrikeMp &&
        ball.dismissal &&
        ball.dismissal.dismissalTypeId !== null &&
        ball.dismissal.dismissalTypeId !== undefined &&
        includes(Reference.DismissalMethodsForStrikeBatter, Reference.DismissalMethods[ball.dismissal.dismissalTypeId])
      ) {
        // if ball already has a dismissal, and it's a dismissal only possible for the strike batter
        // ...then change the "batter out" on the ball to match the new strike batter
        ball.setBatterOut([ball.batterMp, ball.batterNonStrikeMp].find(pl => pl.id === player.id))
      }
      if (editBall && mode === 'advanced') {
        // change of batter should trigger auto-commentary refresh
        ball.setTextDescription(CommsHelpers.generateBallCommentary(game, ball, lastBall))
      }
    }

    battingPlayers = sortBy(battingPlayers, ['batting_order'])

    return (
      <>
        <Flex direction="row">
          <Box marginBottom={mode === 'advanced' ? '7px' : '0px'}>
            <Menu placement={mode === 'advanced' ? 'right-start' : 'bottom-start'}>
              <MenuButton
                as={Button}
                boxShadow="cls.light"
                color="cls.black"
                marginRight="7px"
                size="sm"
                fontSize="md"
                disabled={isDisabled}
                data-testid="ballDescriptionBowler"
              >
                {`${ball.bowlerMp?.getDisplayName()}${ball.bowlerMp?.getShirtNumber(true)}`}
                <Box display="inline-block" height="12px" marginLeft="7px">
                  <img src={Bowling} style={{ height: '14px', width: '14px' }} alt="Bowling" />
                </Box>
              </MenuButton>
              <MenuList zIndex={20} borderColor="gray.200">
                {bowlingPlayers?.map(player => {
                  if (player.id !== ball.bowlerMp?.id) {
                    return (
                      <MenuItem key={player.id} onClick={() => handleBowlerChange(player)}>
                        {`${player.getDisplayName()}${player.getShirtNumber(true)}`}
                      </MenuItem>
                    )
                  }
                  return null
                })}
              </MenuList>
            </Menu>
            to
            <Menu placement={mode === 'advanced' ? 'right-start' : 'bottom-end'}>
              <MenuButton
                as={Button}
                boxShadow="cls.light"
                color="cls.black"
                marginLeft="7px"
                size="sm"
                fontSize="md"
                disabled={isDisabled}
                data-testid="ball-description-batter"
              >
                {`${ball.batterMp?.getDisplayName()}${ball.batterMp?.getShirtNumber(true)}`}
                <FontAwesomeIcon icon={['fal', 'cricket']} size="sm" style={{ marginLeft: '7px' }} />
              </MenuButton>
              <MenuList zIndex={20} borderColor="gray.200">
                {battingPlayers.map(player => {
                  if (player && player.id !== ball.batterMp?.id) {
                    return (
                      <MenuItem key={player?.id} onClick={() => handleBatterChange(player)}>
                        {`${player?.getDisplayName()}${player?.getShirtNumber(true)}`}
                      </MenuItem>
                    )
                  }
                  return null
                })}
              </MenuList>
            </Menu>
          </Box>
          {mode === 'advanced' && game.matchConfigs.maxOvers !== null && (
            <Flex flex={1} direction="row" marginLeft="14px" paddingX="7px">
              <FreeHit mode={mode} game={game} ball={ball} isDisabled={isDisabled} />
            </Flex>
          )}
        </Flex>
        <Flex direction="row" marginLeft={mode === 'core' ? '14px' : '0px'}>
          {mode === 'advanced' && (
            <Flex direction="row">
              {ball.bowlingAnalysis?.bowlerHandId !== null && ball.bowlingAnalysis?.bowlerHandId !== undefined && (
                <Menu placement="right-start">
                  <MenuButton
                    as={Button}
                    boxShadow="cls.light"
                    color="cls.black"
                    isDisabled={!preBall}
                    textTransform="capitalize"
                    marginRight="7px"
                    size="sm"
                    fontSize="md"
                    disabled={isDisabled}
                  >
                    {ball.bowlingAnalysis?.bowlerHandId !== null &&
                      ball.bowlingAnalysis?.bowlerHandId !== undefined &&
                      `${Reference.HandedTypeOptions[ball.bowlingAnalysis.bowlerHandId]?.toLowerCase()} arm`}
                  </MenuButton>
                  <MenuList zIndex={20} borderColor="gray.200">
                    {Reference.HandedTypeOptions.map(type => {
                      const typeName = type.replace('_', ' ').toLowerCase()
                      return (
                        <MenuItem
                          key={typeName}
                          textTransform="capitalize"
                          onClick={() => ball.setBowlerHand(type)}
                          isDisabled={!preBall}
                        >
                          {typeName}
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </Menu>
              )}
              {ball.bowlingAnalysis?.bowlerTypeId !== null && ball.bowlingAnalysis?.bowlerTypeId !== undefined && (
                <Menu placement="right-start">
                  <MenuButton
                    as={Button}
                    boxShadow="cls.light"
                    color="cls.black"
                    textTransform="capitalize"
                    marginRight="7px"
                    size="sm"
                    fontSize="md"
                    disabled={isDisabled}
                  >
                    {ball.bowlingAnalysis?.bowlerTypeId !== null &&
                      ball.bowlingAnalysis?.bowlerTypeId !== undefined &&
                      Reference.BowlerTypeOptions[ball.bowlingAnalysis?.bowlerTypeId]?.replace('_', ' ').toLowerCase()}
                  </MenuButton>
                  <MenuList zIndex={20} borderColor="gray.200">
                    {Reference.BowlerTypeOptions.map(type => {
                      const typeName = type.replace('_', ' ').toLowerCase()
                      return (
                        <MenuItem key={typeName} textTransform="capitalize" onClick={() => ball.setBowlerType(type)}>
                          {typeName}
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </Menu>
              )}
            </Flex>
          )}
          <BowlerApproach mode={mode} ball={ball} isDisabled={isDisabled} />
          {mode === 'advanced' && (
            <Menu placement="right-start">
              <MenuButton
                as={Button}
                boxShadow="cls.light"
                color="cls.black"
                textTransform="capitalize"
                marginRight="7px"
                size="sm"
                fontSize="md"
                disabled={isDisabled}
              >
                Keeper {ball.fieldingAnalysis?.wicketKeeperPosition}
              </MenuButton>
              <MenuList zIndex={20} borderColor="gray.200">
                {Reference.WicketKeeperPositionOptions.map(type => {
                  const typeName = type.replace('_', ' ').toLowerCase()
                  return (
                    <MenuItem
                      key={typeName}
                      textTransform="capitalize"
                      onClick={() => ball.setWicketKeeperPosition(type)}
                    >
                      {typeName}
                    </MenuItem>
                  )
                })}
              </MenuList>
            </Menu>
          )}
        </Flex>
      </>
    )
  }
)
