import { Box, Flex } from '@chakra-ui/react'
import type { IBallModel } from '@clsplus/cls-plus-data-models'
import { observer } from 'mobx-react-lite'

import { FieldingPlacements } from '../../components/Field/FieldingPlacements'
import { useMst } from '../../data/stores/rootStore'
import type { IClspMatchModel, ISettingsModel } from '../../types/models'

const PreBallAdvanced = observer(({ game, ball }: { game: IClspMatchModel; ball: IBallModel }) => {
  const { appSettings }: { appSettings: ISettingsModel } = useMst()
  const matchSettings = appSettings.getMatchSettings(game.id)

  return (
    <Box paddingY="14px" w="100%">
      {matchSettings.fielderPlacement && (
        <Flex marginBottom="7px" paddingTop="21px" borderTop="solid 1px" borderTopColor="cls.backgroundGray">
          <FieldingPlacements ball={ball} />
        </Flex>
      )}
    </Box>
  )
})

export default PreBallAdvanced
