import { Box, Flex, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { indexOf, isNil } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import AdvancedScoring from '../../components/AdvancedScoring/AdvancedScoring'
import BasicScoring from '../../components/BasicScoring/BasicScoring'
import { Button } from '../../components/Buttons/Button'
import { Checkbox } from '../../components/Checkbox/Checkbox'
import {
  DismissalMethods,
  DismissalMethodsNoOrigDecisionPlayer,
  DismissalMethodsNoOrigDecisionUmpire,
} from '../../data/reference'
import type { InBallComponentProps } from '../../types/props'
import { borderStyle, gradientStyle } from './styles'

const InBallAdvanced = observer(
  ({
    game,
    ball,
    completeBall,
    resyncBall,
    inBall,
    editBall,
    ballRunsVal,
    setBallRunsVal,
    ballExtrasVal,
    setBallExtrasVal,
    isPrimaryEditCheck,
    insertingBall,
  }: InBallComponentProps) => {
    const allowedToBeEndOfOver: boolean =
      (!ball.getExtraType || ball.getExtraType === 'LEG_BYE' || ball.getExtraType === 'BYE') &&
      (!insertingBall || (insertingBall && !ball.isCurrentOver))
    const [endOver, setEndOver] = useState(
      ball.ballDisplayNumber !== null &&
        ball.ballDisplayNumber > (game.matchConfigs.ballsPerOver || 6) - 1 &&
        allowedToBeEndOfOver
    )

    const wicketOrReviewAreIncomplete: boolean =
      (ball.pendingDismissal &&
        (!ball.dismissal || ball.dismissal.dismissalTypeId === null || isNil(ball.dismissal?.batterMp))) ||
      (ball.pendingReview &&
        (!ball.review ||
          ball.review.dismissalTypeId === null ||
          (ball.review.originalDecision === null &&
            indexOf(
              ball.review.reviewSourceTypeId === 0
                ? DismissalMethodsNoOrigDecisionUmpire
                : DismissalMethodsNoOrigDecisionPlayer,
              DismissalMethods[ball.review.dismissalTypeId]
            ) === -1) ||
          (ball.review.finalDecision === null && ball.review.drsUmpiresCall === null)))

    useEffect(() => {
      setEndOver(
        ball.ballDisplayNumber !== null &&
          ball.ballDisplayNumber > (game.matchConfigs.ballsPerOver || 6) - 1 &&
          allowedToBeEndOfOver
      )
    }, [ball.ballDisplayNumber, allowedToBeEndOfOver, game.matchConfigs.ballsPerOver])

    return (
      <Box w="100%" position="relative">
        <Flex w="100%" direction="row" marginTop="14px">
          <Flex {...borderStyle} flex={4.5}>
            <BasicScoring
              ball={ball}
              game={game}
              mode="advanced"
              editBall={editBall}
              insertBall={insertingBall}
              ballRunsVal={ballRunsVal}
              setBallRunsVal={setBallRunsVal}
              ballExtrasVal={ballExtrasVal}
              setBallExtrasVal={setBallExtrasVal}
              isPrimaryEditCheck={isPrimaryEditCheck}
            />
          </Flex>
          <Flex flex={1} flexDirection="column" h="100%" align="flex-end" justify="flex-end" padding="14px 7px 7px 0">
            <Flex flexDirection="row" w="90%">
              <Flex flex={1}>
                <Button
                  w="100%"
                  h="44px"
                  colorScheme="cls.blue"
                  onClick={() => completeBall(editBall ? ball.endOfOver : endOver)}
                  isDisabled={wicketOrReviewAreIncomplete}
                  data-testid="doneConfirmAllButton"
                >
                  {editBall ? 'Done' : 'Confirm All'}
                </Button>
              </Flex>
              {editBall && resyncBall && (
                <Flex flex={0.25} marginLeft="7px">
                  <Button
                    w="100%"
                    h="44px"
                    colorScheme="teal"
                    onClick={() => resyncBall()}
                    data-testid="resyncBallDataButton"
                  >
                    <FontAwesomeIcon icon={['far', 'cloud-upload']} size="sm" style={{ fontSize: '16px' }} />
                  </Button>
                </Flex>
              )}
            </Flex>
            <Flex flexDirection="row" w="90%" marginTop="7px">
              <Flex flex={1.25}>
                <Button
                  w="100%"
                  h="44px"
                  colorScheme="cls.gray"
                  onClick={() => completeBall(editBall ? ball.endOfOver : endOver, true)}
                  isDisabled={wicketOrReviewAreIncomplete}
                  data-testid="doneConfirmAllIncompleteButton"
                >
                  {editBall && (
                    <Text>
                      Done
                      <br />
                      (Incomplete)
                    </Text>
                  )}
                  {!editBall && (
                    <Text>
                      Confirm All
                      <br />
                      (Incomplete)
                    </Text>
                  )}
                </Button>
              </Flex>
              {ball &&
                ball.ballDisplayNumber &&
                ball.ballDisplayNumber > (game.matchConfigs.ballsPerOver || 6) - 1 &&
                !editBall && (
                  <Flex flex={0.75} justify="flex-end" marginLeft="7px">
                    <Checkbox
                      colorScheme="cls.yellow"
                      isChecked={endOver ? true : false}
                      isDisabled={!allowedToBeEndOfOver}
                      onChange={() => setEndOver(!endOver)}
                      lineHeight="12px"
                      letterSpacing="-0.5px"
                      data-testid="endOverCheckbox"
                    >
                      End Over
                    </Checkbox>
                  </Flex>
                )}
            </Flex>
          </Flex>
        </Flex>
        <AdvancedScoring game={game} ball={ball} editBall={editBall} isPrimaryEditCheck={isPrimaryEditCheck} />
        {!inBall && !editBall && (
          <Box
            position="absolute"
            top="0px"
            right="0px"
            left="0px"
            bottom="0px"
            backgroundColor="rgba(227, 227, 227, 0.8)"
            backgroundImage={gradientStyle}
            borderTopRightRadius="7px"
          />
        )}
      </Box>
    )
  }
)

export default InBallAdvanced
