import { each, flatten, map } from 'lodash'
import { v4 as uuid } from 'uuid'

import type {
  ExtraAbbreviations,
  FieldingPositionInterface,
  HeadingType,
  PowerPlayInterface,
  ScoringButtonType,
} from '../../types'

export const HandedTypeOptions: string[] = ['LEFT', 'RIGHT']
export const BowlerTypeOptions: string[] = ['WRIST_SPIN', 'FINGER_SPIN', 'SLOW', 'MEDIUM', 'MEDIUM_FAST', 'FAST']
export const BowlerApproachOptions: string[] = ['OVER', 'AROUND']
export const BallLengthOptions: string[] = ['UNKNOWN', 'BOUNCER', 'YORKER']
export const BowlerBallTypeOptionsBracketed: Array<string>[] = [
  ['STOCK', 'SLOWER'],
  ['VARIATION', 'WRONG_UN'],
]
export const BowlerBallTypeOptions: string[] = flatten(BowlerBallTypeOptionsBracketed)
BowlerBallTypeOptions.splice(0, 0, 'UNKNOWN')
export const ShotTypeOptionsBracketed: Array<string>[] = [
  ['DEFENSIVE', 'LEAVE', 'PADDED'],
  ['CUT', 'LATE_CUT', 'SQUARE_CUT', 'PULL', 'HOOK'],
  ['DRIVE', 'GLANCE', 'LEG_GLANCE', 'RAMP'],
  ['SWEEP', 'REVERSE_SWEEP', 'SWITCH_HIT', 'PADDLE', 'FLICK', 'SCOOP'],
]
export const ShotTypeOptions: string[] = flatten(ShotTypeOptionsBracketed)
ShotTypeOptions.splice(0, 0, 'UNKNOWN')
export const ShotContactOptionsBracketed: Array<string>[] = [
  ['GOOD', 'POOR', 'PLAY_MISS'],
  ['INSIDE_EDGE', 'OUTSIDE_EDGE', 'HIT_GLOVES'],
]
export const ShotContactOptions: string[] = flatten(ShotContactOptionsBracketed)
ShotContactOptions.splice(0, 0, 'UNKNOWN')
export const BodyContactOptionsBracketed: Array<string>[] = [['HIT_PAD'], ['HIT_BODY', 'HIT_HEAD']]
export const BodyContactOptions: string[] = flatten(BodyContactOptionsBracketed)
export const FootworkOptions: string[] = ['DUCKED', 'BACK_FOOT', 'BACKED_AWAY', 'FRONT_FOOT', 'BATTER_MOVEMENT']
export const ExtrasTypeOptions: string[] = ['NO_BALL', 'WIDE', 'BYE', 'LEG_BYE', 'NO_BALL_BYE', 'NO_BALL_LEG_BYE']
export const ShotZoneOptions: string[] = [
  'UNKNOWN',
  'SQUARE_LEG',
  'FINE_LEG',
  'THIRD_MAN',
  'POINT',
  'COVER',
  'MID_OFF',
  'MID_ON',
  'MID_WICKET',
  'LONG_ON',
  'LONG_OFF',
  'DEEP_COVER',
  'DEEP_POINT',
  'SLIPS',
  'GULLY',
  'DEEP_SQUARE_LEG',
  'DEEP_MID_WICKET',
]

export const ExtrasTypeAbbreviations: ExtraAbbreviations[] = [
  {
    key: 'BYE',
    value: 'B',
  },
  {
    key: 'NO_BALL',
    value: 'NB',
  },
  {
    key: 'WIDE',
    value: 'WD',
  },
  {
    key: 'LEG_BYE',
    value: 'LB',
  },
  {
    key: 'NO_BALL_LEG_BYE',
    value: 'NB + #LB',
  },
  {
    key: 'NO_BALL_BYE',
    value: 'NB + #B',
  },
]
export const WicketKeeperPositionOptions: string[] = ['UP', 'BACK']
export const OfficialTypeOptions: string[] = ['UMPIRE', 'THIRD_UMPIRE', 'FOURTH_UMPIRE', 'MATCH_REFEREE']
export const BoundaryOptions: string[] = ['IN', 'NORMAL']
export const OutfieldSpeedOptions: string[] = ['SLOW', 'NORMAL', 'FAST']
export const PitchMoistureOptions: string[] = ['VERY_DRY', 'DRY', 'NORMAL', 'MOIST']
export const PitchGrassOptions: string[] = ['BARREN', 'NORMAL', 'GREEN']
export const PitchQualityOptions: string[] = ['NORMAL', 'DETERIORATING', 'SLOW', 'LOW']
export const WeatherTemperatureOptions: string[] = ['VERY_HOT', 'HOT', 'WARM', 'MILD', 'COLD', 'VERY_COLD']
export const WeatherAtmosOptions: string[] = ['CLEAR', 'PARTLY_CLOUDY', 'CLOUDY']
export const WeatherWindOptions: string[] = ['NO_WIND', 'BREEZE', 'WINDY', 'GALE']
export const WeatherRainOptions: string[] = ['NO_RAIN', 'SHOWERS', 'RAIN', 'HEAVY_RAIN']
export const TossDecisionOptions: string[] = ['BAT', 'BOWL']
export const DismissalMethods: string[] = [
  'CAUGHT',
  'LBW',
  'BOWLED',
  'STUMPED',
  'RUN_OUT',
  'HIT_WICKET',
  'RETIRED_HURT',
  'HIT_TWICE',
  'TIMED_OUT',
  'HANDLED_BALL',
  'OBSTRUCTING_FIELD',
  'RETIRED',
  'RETIRED_NO',
  'ABSENT',
]
export const DismissalMethodsForBall: string[] = [
  'CAUGHT',
  'LBW',
  'BOWLED',
  'STUMPED',
  'RUN_OUT',
  'HIT_WICKET',
  'HIT_TWICE',
  'TIMED_OUT',
  'HANDLED_BALL',
  'OBSTRUCTING_FIELD',
]
export const DismissalMethodsForBowler: string[] = ['CAUGHT', 'LBW', 'BOWLED', 'STUMPED', 'HIT_WICKET']
export const DismissalMethodsForStrikeBatter: string[] = [
  'CAUGHT',
  'LBW',
  'BOWLED',
  'STUMPED',
  'HIT_WICKET',
  'HIT_TWICE',
]
export const DismissalMethodsForNonStrikeBatter: string[] = ['RUN_OUT', 'OBSTRUCTING_FIELD']
export const DismissalMethodsReviewable: string[] = [
  'CAUGHT',
  'LBW',
  'BOWLED',
  'STUMPED',
  'RUN_OUT',
  'HIT_WICKET',
  'HIT_TWICE',
  'HANDLED_BALL',
  'OBSTRUCTING_FIELD',
]
export const DismissalMethodsFielder1: string[] = ['CAUGHT', 'STUMPED', 'RUN_OUT', 'OBSTRUCTING_FIELD']
export const DismissalMethodsFielder2: string[] = ['RUN_OUT', 'OBSTRUCTING_FIELD']
export const ReviewSourceOptions: string[] = ['UMPIRE', 'PLAYER']
export const DismissalMethodsNoOrigDecisionUmpire: string[] = ['CAUGHT', 'RUN_OUT', 'STUMPED']
export const DismissalMethodsNoOrigDecisionPlayer: string[] = ['RUN_OUT', 'STUMPED']
export const BreakTypes: string[] = [
  'UNKNOWN',
  'DRINKS',
  'LUNCH',
  'TEA',
  'STUMPS',
  'RAIN_DELAY',
  'BAD_LIGHT',
  'INJURY',
  'ON_FIELD_DELAY',
  'OTHER',
  'TIMEOUT',
  'INNINGS_BREAK',
]
export const MatchStatus: string[] = [
  'NOT_YET_STARTED',
  'IN_PROGRESS_PLAYING',
  'IN_PROGRESS_IN_BREAK',
  'COMPLETED',
  'CANCELLED',
  'POSTPONED',
]
export const MatchResultType: string[] = ['NO_RESULT', 'RESULT', 'DRAWN', 'POSTPONED', 'ABANDONED', 'TIED', 'FORFEIT']
export const InningEndOptions: string[] = ['DECLARED', 'TARGET_REACHED', 'ALL_OUT', 'COMPULSORY_CLOSE', 'FORFEIT']

const PowerPlayTypes: string[] = ['POWER_PLAY_1', 'POWER_PLAY_2', 'POWER_PLAY_3']

export const PowerPlayTriggers10: PowerPlayInterface[] | null = [
  { startOver: '0', endOver: '3', powerPlay: PowerPlayTypes[0] },
  { powerPlay: PowerPlayTypes[1] },
]
export const PowerPlayTriggers20: PowerPlayInterface[] | null = [
  { startOver: '0', powerPlay: PowerPlayTypes[0] },
  { startOver: '6', powerPlay: PowerPlayTypes[1] },
]
export const PowerPlayTriggers20BigBash: PowerPlayInterface[] | null = [
  { startOver: '0', endOver: '4', powerPlay: PowerPlayTypes[0] },
]
export const PowerPlayTriggersHundred: PowerPlayInterface[] | null = [
  { startOver: '0', powerPlay: PowerPlayTypes[0] },
  { startOver: '5', powerPlay: PowerPlayTypes[1] },
]
export const PowerPlayTriggers50: PowerPlayInterface[] | null = [
  { startOver: '0', powerPlay: PowerPlayTypes[0] },
  { startOver: '10', powerPlay: PowerPlayTypes[1] },
  { startOver: '40', powerPlay: PowerPlayTypes[2] },
]
export const PartnershipStatusTypes: string[] = ['NOT_OUT', 'OUT', 'RETIRED']
export const NewBallTypes: string[] = ['NEW_BALL', 'REPLACED_BALL']
export const PlayerActiveReasonTypes: string[] = [
  'UNKNOWN',
  'STARTING_TEAM',
  'SUB_UNUSED',
  'CONCUSSION_OFF',
  'CONCUSSION_ON',
  'SUPER_SUB_OFF',
  'SUPER_SUB_ON',
]
export const TimelineTypes: string[] = [
  'OTHER',
  'BALL',
  'COMMENTARY_UPDATE',
  'NEW_BATTER',
  'NEW_BOWLER',
  'MATCH_BREAK_START',
  'CONDITIONS_UPDATE',
  'TOSS',
  'MATCH_START',
  'MATCH_END',
  'START_OF_PLAY',
  'END_OF_PLAY',
  'INNING_START',
  'INNING_END',
  'DEAD_BALL',
  'DRS',
  'DLS',
  'MATCH_BREAK_END',
  'POWER_PLAY_START',
  'POWER_PLAY_END',
  'EDIT_START',
  'EDIT_CANCEL',
  'EDIT_COMPLETE',
  'PENALTY_RUNS',
  'NON_BALL_DISMISSAL',
]

// Fielding Positions
// angle quadrants: 60, 40, 40, 40, 40, 40, 40, 60
// quadrants w/overlap: 0-70, 50-110, 90-150, 130-190, 170-230, 210-270, 250-310, 290-360
export const FieldingPositionsBrackets: FieldingPositionInterface[] = [
  {
    angle: {
      start: 0,
      end: 70,
    },
    positions: [
      {
        id: 0,
        name: 'SHORT_LEG',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 1,
        name: 'LEG_SLIP',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 2,
        name: 'LEG_GULLY',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 3,
        name: 'BACKWARD_SHORT_LEG',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 4,
        name: 'SHORT_FINE_LEG',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 5,
        name: 'STRAIGHT_FINE_LEG',
        short: false,
        mid: true,
        deep: true,
      },
      {
        id: 6,
        name: 'FINE_LEG',
        short: false,
        mid: true,
        deep: true,
      },
      {
        id: 7,
        name: 'DEEP_FINE_LEG',
        short: false,
        mid: false,
        deep: true,
      },
      {
        id: 8,
        name: 'SQUARE_FINE_LEG',
        short: false,
        mid: false,
        deep: true,
      },
    ],
  },
  {
    angle: {
      start: 50,
      end: 110,
    },
    positions: [
      {
        id: 9,
        name: 'SQUARE_LEG',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 10,
        name: 'FORWARD_SQUARE_LEG',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 11,
        name: 'BACKWARD_SQUARE_LEG',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 12,
        name: 'DEEP_BACKWARD_SQUARE_LEG',
        short: false,
        mid: false,
        deep: true,
      },
      {
        id: 13,
        name: 'DEEP_SQUARE_LEG',
        short: false,
        mid: false,
        deep: true,
      },
      {
        id: 14,
        name: 'DEEP_FORWARD_SQUARE_LEG',
        short: false,
        mid: false,
        deep: true,
      },
    ],
  },
  {
    angle: {
      start: 90,
      end: 150,
    },
    positions: [
      {
        id: 15,
        name: 'SILLY_MID_ON',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 16,
        name: 'SHORT_MID_WICKET',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 17,
        name: 'MID_WICKET',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 18,
        name: 'DEEP_MID_WICKET',
        short: false,
        mid: true,
        deep: true,
      },
    ],
  },
  {
    angle: {
      start: 130,
      end: 180,
    },
    positions: [
      {
        id: 19,
        name: 'SHORT_MID_ON',
        short: true,
        mid: true,
        deep: false,
      },
      {
        id: 20,
        name: 'MID_ON',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 21,
        name: 'LONG_ON',
        short: false,
        mid: false,
        deep: true,
      },
      {
        id: 22,
        name: 'STRAIGHT_LONG_ON',
        short: false,
        mid: false,
        deep: true,
      },
      {
        id: 23,
        name: 'WIDE_LONG_ON',
        short: false,
        mid: false,
        deep: true,
      },
    ],
  },
  {
    angle: {
      start: 180,
      end: 230,
    },
    positions: [
      {
        id: 24,
        name: 'BOWLER',
        short: true,
        mid: true,
        deep: false,
      },
      {
        id: 25,
        name: 'SILLY_MID_OFF',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 26,
        name: 'SHORT_MID_OFF',
        short: true,
        mid: true,
        deep: false,
      },
      {
        id: 27,
        name: 'MID_OFF',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 28,
        name: 'LONG_OFF',
        short: false,
        mid: false,
        deep: true,
      },
      {
        id: 29,
        name: 'STRAIGHT_LONG_OFF',
        short: false,
        mid: false,
        deep: true,
      },
      {
        id: 30,
        name: 'WIDE_LONG_OFF',
        short: false,
        mid: false,
        deep: true,
      },
    ],
  },
  {
    angle: {
      start: 210,
      end: 270,
    },
    positions: [
      {
        id: 31,
        name: 'SHORT_COVER',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 32,
        name: 'COVER',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 33,
        name: 'EXTRA_COVER',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 34,
        name: 'DEEP_COVER',
        short: false,
        mid: false,
        deep: true,
      },
    ],
  },
  {
    angle: {
      start: 250,
      end: 310,
    },
    positions: [
      {
        id: 35,
        name: 'BACKWARD_POINT',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 36,
        name: 'POINT',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 37,
        name: 'FORWARD_POINT',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 38,
        name: 'COVER_POINT',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 39,
        name: 'DEEP_BACKWARD_POINT',
        short: false,
        mid: false,
        deep: true,
      },
      {
        id: 40,
        name: 'DEEP_POINT',
        short: false,
        mid: false,
        deep: true,
      },
      {
        id: 41,
        name: 'DEEP_COVER_POINT',
        short: false,
        mid: false,
        deep: true,
      },
    ],
  },
  {
    angle: {
      start: 290,
      end: 360,
    },
    positions: [
      {
        id: 42,
        name: 'SILLY_POINT',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 43,
        name: 'WICKET_KEEPER',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 44,
        name: 'FIRST_SLIP',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 45,
        name: 'SECOND_SLIP',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 46,
        name: 'THIRD_SLIP',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 47,
        name: 'FOURTH_SLIP',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 48,
        name: 'FIFTH_SLIP',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 49,
        name: 'FLY_SLIP',
        short: true,
        mid: true,
        deep: false,
      },
      {
        id: 50,
        name: 'GULLY',
        short: true,
        mid: false,
        deep: false,
      },
      {
        id: 51,
        name: 'SHORT_THIRD_MAN',
        short: false,
        mid: true,
        deep: false,
      },
      {
        id: 52,
        name: 'THIRD_MAN',
        short: false,
        mid: true,
        deep: true,
      },
      {
        id: 53,
        name: 'DEEP_THIRD_MAN',
        short: false,
        mid: false,
        deep: true,
      },
      {
        id: 54,
        name: 'FINE_THIRD_MAN',
        short: false,
        mid: false,
        deep: true,
      },
    ],
  },
]
export const FieldingPositions: string[] = []
each(FieldingPositionsBrackets, bracket => {
  return map(bracket.positions, position => {
    FieldingPositions[position.id] = position.name
  })
})

// Layouts
export const ScoringButtons: ScoringButtonType[] = [
  {
    value: 0,
    optionsName: 'dot',
    first: true,
    middle: false,
    last: false,
    label: '0',
  },
  {
    value: 1,
    optionsName: 'runs',
    first: false,
    middle: true,
    last: false,
    label: '1',
  },
  {
    value: 2,
    optionsName: 'runs',
    first: false,
    middle: true,
    last: false,
    label: '2',
  },
  {
    value: 3,
    optionsName: 'runs',
    first: false,
    middle: true,
    last: false,
    label: '3',
  },
  {
    value: 4,
    optionsName: 'runs',
    first: false,
    middle: true,
    last: false,
    label: '4',
  },
  {
    value: 6,
    optionsName: 'runs',
    first: false,
    middle: true,
    last: false,
    label: '6',
  },
]

export const WicketKeeperPositionCoords = [
  { id: 'UP', x: 50.0, y: 40.5 },
  { id: 'BACK', x: 50.0, y: 33.5 },
]

export const DefaultFieldingPlacements = [
  {
    id: uuid(),
    placement: { id: uuid(), x: WicketKeeperPositionCoords[1].x, y: WicketKeeperPositionCoords[1].y },
    fieldingPositionId: 43,
  },
  { id: uuid(), placement: { id: uuid(), x: 50.0, y: 66 }, fieldingPositionId: 24 },
  { id: uuid(), placement: { id: uuid(), x: 40, y: 75 } },
  { id: uuid(), placement: { id: uuid(), x: 40, y: 80 } },
  { id: uuid(), placement: { id: uuid(), x: 40, y: 85 } },
  { id: uuid(), placement: { id: uuid(), x: 50, y: 75 } },
  { id: uuid(), placement: { id: uuid(), x: 50, y: 80 } },
  { id: uuid(), placement: { id: uuid(), x: 50, y: 85 } },
  { id: uuid(), placement: { id: uuid(), x: 60, y: 75 } },
  { id: uuid(), placement: { id: uuid(), x: 60, y: 80 } },
  { id: uuid(), placement: { id: uuid(), x: 60, y: 85 } },
]

export const DefaultFieldingPlacementsRHB = [
  { id: uuid(), placement: { id: uuid(), x: 68.28, y: 12.94 }, fieldingPositionId: 6 }, // Fine leg
  { id: uuid(), placement: { id: uuid(), x: 41.88, y: 25.55 }, fieldingPositionId: 44 }, // 1st slip
  { id: uuid(), placement: { id: uuid(), x: 36.41, y: 27.92 }, fieldingPositionId: 45 }, // 2nd slip
  { id: uuid(), placement: { id: uuid(), x: 50.0, y: 27.92 }, fieldingPositionId: 43 }, // Wicketkeeper
  { id: uuid(), placement: { id: uuid(), x: 32.34, y: 30.97 }, fieldingPositionId: 46 }, // 3rd slip
  { id: uuid(), placement: { id: uuid(), x: 24.22, y: 36.81 }, fieldingPositionId: 36 }, // Point
  { id: uuid(), placement: { id: uuid(), x: 71.25, y: 47.78 }, fieldingPositionId: 17 }, // Mid Wicket
  { id: uuid(), placement: { id: uuid(), x: 24.22, y: 50.69 }, fieldingPositionId: 32 }, // Cover
  { id: uuid(), placement: { id: uuid(), x: 57.81, y: 63.06 }, fieldingPositionId: 20 }, // Mid On
  { id: uuid(), placement: { id: uuid(), x: 35.78, y: 63.06 }, fieldingPositionId: 27 }, // Mid Off
  { id: uuid(), placement: { id: uuid(), x: 50.0, y: 68.5 }, fieldingPositionId: 24 }, // Bowler
]

export const DefaultFieldingPlacementsLHB = [
  { id: uuid(), placement: { id: uuid(), x: 31.72, y: 12.94 }, fieldingPositionId: 6 }, // Fine leg
  { id: uuid(), placement: { id: uuid(), x: 58.12, y: 25.55 }, fieldingPositionId: 44 }, // 1st slip
  { id: uuid(), placement: { id: uuid(), x: 63.59, y: 27.92 }, fieldingPositionId: 45 }, // 2nd slip
  { id: uuid(), placement: { id: uuid(), x: 50.0, y: 27.92 }, fieldingPositionId: 43 }, // Wicketkeeper
  { id: uuid(), placement: { id: uuid(), x: 67.66, y: 30.97 }, fieldingPositionId: 46 }, // 3rd slip
  { id: uuid(), placement: { id: uuid(), x: 75.78, y: 36.81 }, fieldingPositionId: 36 }, // Point
  { id: uuid(), placement: { id: uuid(), x: 28.75, y: 47.78 }, fieldingPositionId: 17 }, // Mid Wicket
  { id: uuid(), placement: { id: uuid(), x: 75.78, y: 50.69 }, fieldingPositionId: 32 }, // Cover
  { id: uuid(), placement: { id: uuid(), x: 42.19, y: 63.06 }, fieldingPositionId: 20 }, // Mid On
  { id: uuid(), placement: { id: uuid(), x: 64.22, y: 63.06 }, fieldingPositionId: 27 }, // Mid Off
  { id: uuid(), placement: { id: uuid(), x: 50.0, y: 68.5 }, fieldingPositionId: 24 }, // Bowler
]

export const battingHeaders: Array<HeadingType> = [
  {
    label: 'R',
    key: 'allRuns',
    flexValue: 1,
    justifyValue: 'center',
  },
  {
    label: 'B',
    key: 'allBalls',
    flexValue: 1,
    justifyValue: 'center',
  },
  {
    label: '\u2022',
    key: 'allDotBalls',
    flexValue: 1,
    justifyValue: 'center',
  },
  {
    label: '4',
    key: 'allFours',
    flexValue: 1,
    justifyValue: 'center',
  },
  {
    label: '6',
    key: 'allSixes',
    flexValue: 1,
    justifyValue: 'center',
  },
  {
    label: 'SR',
    key: 'allStrikeRate',
    flexValue: 1.5,
    justifyValue: 'flex-end',
  },
]

export const coreBattingHeaders: Array<HeadingType> = [
  {
    label: 'R',
    key: 'allRuns',
    flexValue: 0.3,
    justifyValue: 'flex-end',
  },
  {
    label: 'B',
    key: 'allBalls',
    flexValue: 0.3,
    justifyValue: 'flex-end',
  },
]

export const bowlingHeaders: Array<HeadingType> = [
  {
    label: 'O',
    key: 'allOvers',
    flexValue: 1,
    justifyValue: 'center',
  },
  {
    label: 'M',
    key: 'allMaidens',
    flexValue: 1,
    justifyValue: 'center',
  },
  {
    label: 'R',
    key: 'allRuns',
    flexValue: 1,
    justifyValue: 'center',
  },
  {
    label: 'W',
    key: 'allWickets',
    flexValue: 1,
    justifyValue: 'center',
  },
  {
    label: '\u2022',
    key: 'allDotBalls',
    flexValue: 1,
    justifyValue: 'center',
  },
  {
    label: 'ECON',
    key: 'allEconomyRate',
    flexValue: 1.5,
    justifyValue: 'flex-end',
  },
]

export const coreBowlingHeaders: Array<HeadingType> = [
  {
    label: 'R/W',
    key: 'allRuns_allWickets',
    flexValue: 0.3,
    justifyValue: 'flex-end',
  },
  {
    label: 'O',
    key: 'allOvers',
    flexValue: 0.3,
    justifyValue: 'flex-end',
  },
]

export const UserEventTypes: string[] = ['PRIMARY_REQUEST', 'APPROVE_PRIMARY_REQUEST', 'DENY_PRIMARY_REQUEST']

export const MatchTeamRoleOptions: string[] = ['HEAD_COACH', 'ASSISTANT_COACH', 'TEAM_MANAGER']

/* App Settings */
export const ScoreFormats: string[] = ['RUNS-WICKETS', 'WICKETS-RUNS']
