import { Box, Flex, Text } from '@chakra-ui/react'
import type { IMatchPlayerModel } from '@clsplus/cls-plus-data-models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { concat } from 'lodash'
import { observer } from 'mobx-react-lite'

import theme from '../../theme/theme'
import type { DropdownCallbackArgs } from '../../types'
import type { ActionButtonsProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import Dropdown from '../Dropdown/Dropdown'

export const ActionButtons = observer(({ mode, type, inning, ball, game, editBall }: ActionButtonsProps) => {
  const batterOptions = game.getEligibleBattingPlayersInBattingOrder()?.map((player: IMatchPlayerModel) => {
    return {
      value: player.id,
      label: `${player.getDisplayName()}${player.getShirtNumber(true)}`,
    }
  })
  const bowlerOptions = game
    .getBowlingPlayersInBattingOrder(true)
    ?.filter(player => !concat(ball?.getOverBowlers(), ball?.bowlerMp)?.find(b => b?.id === player.id))
    .map((player: IMatchPlayerModel) => {
      return {
        value: player.id,
        label: `${player.getDisplayName()}${player.getShirtNumber(true)}`,
      }
    })
  const handleSwitchStrike = () => {
    ball?.setStrikeBatter(inning.getCurrentBatterPerformances?.find(perf => !perf.onStrike)?.getPlayerProfile)
    const firstBallOfInnings = ball?.overNumber === 0 && ball?.ballNumber === 1
    inning.switchStrike(undefined, firstBallOfInnings, firstBallOfInnings)
  }
  const handleLikelyChange = ({ value, target }: DropdownCallbackArgs) => {
    if (target === 'likelyNextBatter') {
      inning.setLikelyNextBatter(game.getEligibleBattingPlayersInBattingOrder()?.find(player => player.id === value))
    }
    if (target === 'likelyNextBowler') {
      inning.setLikelyNextBowler(game.getBowlingPlayersInBattingOrder(true)?.find(player => player.id === value))
    }
  }

  if (type === 'batting') {
    return (
      <Flex flex={1.5} align="center" justify="flex-start" paddingTop="7px">
        <Button
          onClick={() => handleSwitchStrike()}
          isDisabled={editBall}
          colorScheme="cls.white"
          w={mode !== 'advanced' ? '35px' : undefined}
          data-testid="switchStrikeBatterButton"
        >
          <FontAwesomeIcon icon={['far', 'repeat-alt']} rotation={90} color={theme.colors.cls.black} />
        </Button>
        <Box marginLeft={['4px', '4px', '7px', '7px']}>
          <Dropdown
            target="likelyNextBatter"
            options={batterOptions || []}
            height="40px"
            value={
              inning.likelyNextBatter ? (
                <Text fontSize="14px" color={theme.colors.cls.black}>
                  <em>Next</em>
                  <br />
                  {inning.likelyNextBatter?.getDisplayName()}
                </Text>
              ) : (
                <Text fontSize="14px" color={theme.colors.cls.black}>
                  Likely Next
                  <br />
                  Batter?
                </Text>
              )
            }
            origValue={
              <Text fontSize="14px" color={theme.colors.cls.black}>
                Likely Next
                <br />
                Batter?
              </Text>
            }
            onChange={handleLikelyChange}
            hideCheck
            isClearable
            isWhite
            preserveCase
            data-testid="likelyNextBatterDropdown"
          />
        </Box>
      </Flex>
    )
  }
  if (type === 'bowling') {
    return (
      <Flex flex={mode !== 'advanced' ? 1.5 : 1} align="center" justify="flex-start" paddingTop="16px">
        <Box marginLeft={mode === 'advanced' ? '7px' : 0}>
          <Dropdown
            target="likelyNextBowler"
            options={bowlerOptions || []}
            height="40px"
            value={
              inning.likelyNextBowler ? (
                <Text fontSize="14px" color={theme.colors.cls.black}>
                  <em>Next</em>
                  <br />
                  {inning.likelyNextBowler?.getDisplayName()}
                </Text>
              ) : (
                <Text fontSize="14px" color={theme.colors.cls.black}>
                  Likely Next
                  <br />
                  Bowler?
                </Text>
              )
            }
            origValue={
              <Text fontSize="14px" color={theme.colors.cls.black}>
                Likely Next
                <br />
                Bowler?
              </Text>
            }
            onChange={handleLikelyChange}
            isClearable
            isWhite
            hideCheck
            preserveCase
          />
        </Box>
      </Flex>
    )
  }
  return null
})
