import type { IBallModel, IFieldingPlayerModel } from '@clsplus/cls-plus-data-models'
import { capitalize, find, flatten, indexOf, isNil, sample, startsWith, upperFirst } from 'lodash'

import * as Reference from '../data/reference'
import type { IClspMatchModel } from '../types/models'
import { getAngleAndLength } from './fieldingHelpers'

const CommsHelpers = (function () {
  const extrasText = (ball: IBallModel, noBallValue: number = 1) => {
    if (ball.getExtraType !== null && ball.runsExtra !== null) {
      let extrasType = ball.getExtraType?.replace(/_/g, ' ').toLowerCase()
      if (ball.getExtraType !== 'NO_BALL_LEG_BYE' && ball.getExtraType !== 'NO_BALL_BYE') {
        if (
          (ball.runsExtra === 1 && ball.getExtraType !== 'NO_BALL') ||
          (ball.getExtraType === 'NO_BALL' && ball.runsExtra === noBallValue)
        ) {
          return `${extrasType}`
        }
        return `${ball.runsExtra} ${extrasType}s`
      } else {
        extrasType = extrasType?.replace('no ball', '')
        if (ball.runsExtra <= noBallValue + 1) return `no ball + ${extrasType}`
        return `no ball + ${ball.runsExtra - noBallValue}${extrasType}s`
      }
    }
    return ''
  }

  const generateBallSummary = (ball: IBallModel, noBallValue: number = 1) => {
    let currentBallText = ''
    if (ball.runsExtra !== null && ball.runsExtra > 0) {
      if (currentBallText !== '') currentBallText += ', '
      if (ball.getExtraType) currentBallText += extrasText(ball, noBallValue)
    }
    if (ball.runsBat !== null && ball.runsBat > 0) {
      if (currentBallText !== '') currentBallText += ' + '
      currentBallText += ball.runsBat === 1 ? ball.runsBat + ' run' : ball.runsBat + ' runs'
    }
    if (ball.appeal) {
      if (currentBallText !== '') currentBallText += ', '
      currentBallText += 'appeal'
    }
    if (ball.review) {
      if (currentBallText !== '') currentBallText += ', '
      currentBallText += 'review'
      if (ball.review.originalDecision || ball.review.finalDecision) {
        currentBallText += ' ('
        if (ball.review.originalDecision)
          currentBallText += `${ball.review.originalDecision === true ? 'out' : 'not out'} => `
        if (ball.review.finalDecision) {
          currentBallText += ball.review.finalDecision === true ? 'out' : 'not out'
        } else if (ball.review.drsUmpiresCall) {
          currentBallText += "Umpire's Call"
        } else {
          currentBallText += '?'
        }
        currentBallText += ')'
      }
    }
    if (ball.dismissal) {
      if (currentBallText !== '') currentBallText += ', '
      currentBallText += 'wicket'
      if (ball.dismissal.dismissalTypeId !== null || ball.dismissal.batterMp) {
        if (ball.dismissal.dismissalTypeId !== null)
          currentBallText += ` (${Reference.DismissalMethods[ball.dismissal.dismissalTypeId]
            .replace(/_/g, ' ')
            .toLowerCase()}`
        if (ball.dismissal.batterMp) currentBallText += ` - ${ball.dismissal.batterMp.getShortName}`
        currentBallText += ')'
      }
    }
    const desc = currentBallText === '' ? '0 runs' : currentBallText
    return desc
  }

  const generateSimpleBallCommentary = (ball: IBallModel) => {
    return `${ball.bowlerMp?.getShortName} to ${ball.batterMp?.getShortName}, ${generateBallSummary(ball)}`
  }

  const generateBallCommentary = (game: IClspMatchModel, ball: IBallModel, lastBall?: IBallModel) => {
    let desc = ''
    let basicsDesc = ''
    const descPointers = {
      newBall: false,
      wkPosition: false,
      bowlerApproach: false,
      ballType: false,
      shot: false,
      footwork: false,
      appeal: false,
      hitPadOrBody: false,
      wicketCredited: false,
      wicketFielded: false,
      runsSaved: false,
      noBall:
        ball.getExtraType === 'NO_BALL' ||
        ball.getExtraType === 'NO_BALL_LEG_BYE' ||
        ball.getExtraType === 'NO_BALL_BYE',
      wide: ball.getExtraType === 'WIDE',
    }
    const positiveSuperlatives = ['great', 'quality', 'fantastic', 'impressive', 'terrific']
    const averageSuperlatives = ['good', 'tidy']
    const superlatives = flatten([positiveSuperlatives, averageSuperlatives])
    const negativeSuperlatives = ['poor', 'bad', 'wild', 'shaky', 'mediocre', 'sloppy']
    const negativeAdverbs = ['poorly', 'shakily', 'averagely', 'sloppily']
    const mistake = ['a mistake', 'a blunder', 'an error']
    const signalled = ['signalled', 'indicated']
    const conjunction = ['but', 'however']

    const runsAsWords = [
      ['no', 'zero', '0'],
      ['one', 'a single', 'a', '1'],
      ['two', 'a couple of', 'a pair of', '2'],
      ['three', '3'],
      ['four', '4'],
      ['five', '5'],
      ['six', '6', 'a half dozen'],
    ]

    const bowlerType =
      ball.bowlingAnalysis?.bowlerTypeId !== null && ball.bowlingAnalysis?.bowlerTypeId !== undefined
        ? Reference.BowlerTypeOptions[ball.bowlingAnalysis.bowlerTypeId]
        : null
    const bowlerBallType =
      ball.bowlingAnalysis?.bowlerBallTypeId !== null && ball.bowlingAnalysis?.bowlerBallTypeId !== undefined
        ? Reference.BowlerTypeOptions[ball.bowlingAnalysis.bowlerBallTypeId]
        : null
    const footworkType =
      ball.battingAnalysis?.footworkTypeId !== null && ball.battingAnalysis?.footworkTypeId !== undefined
        ? Reference.FootworkOptions[ball.battingAnalysis.footworkTypeId]
        : null
    const shotType =
      ball.battingAnalysis?.shots.shotTypeId !== null && ball.battingAnalysis?.shots.shotTypeId !== undefined
        ? Reference.ShotTypeOptions[ball.battingAnalysis.shots.shotTypeId]
        : null
    const shotContact =
      ball.battingAnalysis?.shots.shotContactId !== null && ball.battingAnalysis?.shots.shotContactId !== undefined
        ? Reference.ShotContactOptions[ball.battingAnalysis?.shots.shotContactId]
        : null
    const bodyContact =
      ball.battingAnalysis?.shots.bodyContactId !== null && ball.battingAnalysis?.shots.bodyContactId !== undefined
        ? Reference.BodyContactOptions[ball.battingAnalysis?.shots.bodyContactId]
        : null

    const playerPronoun =
      ball.batterMp?.player.person?.gender === 'M'
        ? 'his'
        : ball.batterMp?.player.person?.gender === 'F'
        ? 'her'
        : 'their'

    // Free hit
    if (ball.freeHit) {
      if (!descPointers.noBall && !descPointers.wide) {
        basicsDesc += 'Free hit'
      }
      if ((ball.runsBat || 0) >= 4) {
        basicsDesc += sample([
          '',
          `, and ${ball.batterMp?.getShortName} takes advantage`,
          `, and ${ball.batterMp?.getShortName} takes advantage of it`,
          `, and ${ball.batterMp?.getShortName} makes the most of it`,
        ])
      } else if ((isNil(ball.runsBat) || ball.runsBat === 0) && (isNil(ball.runsExtra) || ball.runsExtra === 0)) {
        basicsDesc += sample([
          '',
          `, but ${ball.batterMp?.getShortName} can't take advantage`,
          `, but ${ball.batterMp?.getShortName} doesn't take advantage of it`,
        ])
      }
      basicsDesc += '. '
    }

    // New ball
    if (ball.newBallTakenId === 0 && ball.overNumber !== 0) {
      descPointers.newBall = true
      basicsDesc += sample([
        `new ball is taken by ${game.getBowlingTeam()?.name}`,
        `${game.getBowlingTeam()?.name} decide to take the new ball`,
        `the umpire indicates that ${game.getBowlingTeam()?.name} will take the new ball`,
      ])
    }

    let pitchDesc = ''

    // Change in wicketkeeper position / bowler approach
    if (lastBall) {
      const asWith = sample(['as', 'with'])
      if (descPointers.newBall) basicsDesc += `, ${asWith}`

      // wicketkeeper position
      if (
        ball.ballNumber > 1 &&
        ball.fieldingAnalysis?.wicketKeeperPositionId !== null &&
        ball.fieldingAnalysis?.wicketKeeperPositionId !== undefined &&
        lastBall.fieldingAnalysis?.wicketKeeperPositionId !== ball.fieldingAnalysis?.wicketKeeperPositionId &&
        lastBall.overNumber === ball.overNumber
      ) {
        descPointers.wkPosition = true
        const wkPosition = ball.fieldingAnalysis.wicketKeeperPositionId
        const wkTerm = sample(['wicketkeeper', 'keeper'])
        basicsDesc += `${descPointers.newBall ? 'the' : ''} ${descPointers.newBall ? wkTerm : capitalize(wkTerm)} ${
          asWith === 'as' || !descPointers.newBall ? 'moves' : 'moving'
        } ${Reference.WicketKeeperPositionOptions[wkPosition].replace(/_/g, ' ').toLowerCase()} ${
          wkPosition === indexOf(Reference.WicketKeeperPositionOptions, 'BACK') ? 'from' : 'to'
        } the stumps`
      }

      // bowler approach (over/around)
      if (
        ball.ballNumber > 1 &&
        ball.bowlingAnalysis?.bowlerBallApproachId !== null &&
        ball.bowlingAnalysis?.bowlerBallApproachId !== undefined &&
        lastBall.bowlingAnalysis?.bowlerBallApproachId !== ball.bowlingAnalysis?.bowlerBallApproachId &&
        lastBall.overNumber === ball.overNumber
      ) {
        descPointers.bowlerApproach = true
        const asWith = sample(['as', 'with'])
        let comesTerm
        if (!ball.dismissal) {
          if (descPointers.newBall || descPointers.wkPosition) {
            basicsDesc += `, ${asWith} `
            comesTerm = asWith === 'as' ? 'comes' : 'now coming'
          } else {
            comesTerm = sample(['comes', 'now coming'])
          }
        }
        const bowlerApproach = sample([
          `${Reference.BowlerApproachOptions[ball.bowlingAnalysis.bowlerBallApproachId]
            .replace(/_/g, ' ')
            .toLowerCase()} the wicket`,
          `${Reference.BowlerApproachOptions[ball.bowlingAnalysis.bowlerBallApproachId]
            .replace(/_/g, ' ')
            .toLowerCase()} the wicket to ${ball.batterMp?.getShortName}`,
        ])
        if (ball.dismissal) {
          pitchDesc += bowlerApproach
        } else {
          basicsDesc += `${ball.bowlerMp?.getShortName} ${comesTerm} ${bowlerApproach}`
        }
      }
    }

    // Ball speed (If fast bowler && speed >= 145 -OR- if slow/spin bowler && speed >= 95)
    let ballSpeedDesc = ''

    if (
      ball.bowlingAnalysis?.ballSpeed &&
      (((bowlerType === 'FAST' || bowlerType === 'MEDIUM_FAST' || bowlerType === 'MEDIUM') &&
        ball.bowlingAnalysis?.ballSpeed >= 145) ||
        ((bowlerType === 'WRIST_SPIN' || bowlerType === 'FINGER_SPIN' || bowlerType === 'SLOW') &&
          ball.bowlingAnalysis?.ballSpeed >= 95))
    ) {
      ballSpeedDesc += `${ball.bowlingAnalysis?.ballSpeed}kph!`
    }

    // Delivery type (If slower (fast bowler) or wrong un (spin bowler))
    if (
      ball.bowlingAnalysis?.bowlerBallTypeId !== null &&
      ball.bowlingAnalysis?.bowlerBallTypeId !== undefined &&
      (bowlerBallType === 'SLOWER' || bowlerBallType === 'WRONG_UN')
    ) {
      descPointers.ballType = true
      if (bowlerBallType === 'SLOWER') {
        pitchDesc += `${sample(['slower', 'slow'])} ${sample(['ball', 'delivery'])}`
      } else if (bowlerBallType === 'WRONG_UN') {
        pitchDesc += 'wrong un'
      }
    }

    // Pitch & arrival
    if (ball.bowlingAnalysis?.pitchMap) {
      let arrivalAtStumpsOrOffSide = null
      if (ball.battingAnalysis?.arrival) {
        const arrivalPoint =
          ball.batterMp?.player.battingHandedId === indexOf(Reference.HandedTypeOptions, 'LEFT')
            ? 100 - ball.battingAnalysis?.arrival.x
            : ball.battingAnalysis?.arrival.x
        arrivalAtStumpsOrOffSide = arrivalPoint < 62
      }

      // LENGTH
      const pitchMapX =
        ball.batterMp?.player.battingHandedId === indexOf(Reference.HandedTypeOptions, 'LEFT')
          ? 100 - ball.bowlingAnalysis?.pitchMap.x
          : ball.bowlingAnalysis?.pitchMap.x
      if (pitchDesc !== '') pitchDesc += ', '
      if (ball.bowlingAnalysis?.pitchMap.y <= 8.5) {
        // full toss
        pitchDesc += 'full toss'
      } else if (ball.bowlingAnalysis?.pitchMap.y <= 23.5) {
        // yorker
        pitchDesc += 'yorker'
      } else if (ball.bowlingAnalysis?.pitchMap.y <= 39) {
        // full
        pitchDesc += sample(['full', 'pitched up', 'full ball', `${ball.bowlerMp?.getShortName} pitches one up`])
      } else if (
        Reference.ExtrasTypeOptions[ball.extrasTypeId || 0] !== 'WIDE' &&
        (pitchMapX <= 46.5 || pitchMapX >= 51) &&
        ball.bowlingAnalysis?.pitchMap.y <= 54
      ) {
        // good length (and a "non good" line)
        pitchDesc += sample([
          'good length',
          'on a good length',
          'length ball',
          `good length from ${ball.bowlerMp?.getShortName}`,
        ])
      } else if (ball.bowlingAnalysis?.pitchMap.y > 54 && ball.bowlingAnalysis?.pitchMap.y <= 68.5) {
        // short of a length
        pitchDesc += sample([
          'back of a length',
          'short of a length',
          `back of a length from ${ball.bowlerMp?.getShortName}`,
        ])
      } else if (ball.bowlingAnalysis?.pitchMap.y > 68.5 && ball.bowlingAnalysis?.pitchMap.y <= 83.5) {
        // short
        pitchDesc += sample([
          'short',
          'short ball',
          `${ball.bowlerMp?.getShortName} drops one in short`,
          `dropped in short by ${ball.bowlerMp?.getShortName}`,
        ])
      } else if (ball.bowlingAnalysis?.pitchMap.y > 83.5) {
        // very short / bouncer
        pitchDesc += sample(['very short', 'very short ball', 'bouncer', 'half-tracker'])
      }

      // LINE
      const anglingAngled = sample(['angling', 'angled'])
      const anglingAngledAdverbs =
        Reference.ExtrasTypeOptions[ball.extrasTypeId || 0] === 'WIDE'
          ? sample([' far', ' sharply', ' wildly', ' loosely'])
          : ''
      const repetition = ['', ' again', ' once again', ' once more']
      let useLineRepitition = false
      if (pitchDesc !== '') pitchDesc += ', '
      if (pitchMapX < 51) {
        if (pitchMapX <= 46.5) {
          // outside off
          if (Reference.ExtrasTypeOptions[ball.extrasTypeId || 0] === 'WIDE' && arrivalAtStumpsOrOffSide) {
            pitchDesc += sample(['too wide outside off', 'pitching far outside off', 'pitching well outside off stump'])
          } else {
            pitchDesc += sample([
              'outside off stump',
              'outside off',
              'pitching outside off',
              'pitching outside off stump',
            ])
            if (lastBall?.bowlingAnalysis?.pitchMap && lastBall.bowlingAnalysis.pitchMap.x <= 46.5)
              useLineRepitition = true
          }
        } else if (pitchMapX > 46.5 && pitchMapX < 51) {
          // on line / at the stumps
          if (
            Reference.ExtrasTypeOptions[ball.extrasTypeId || 0] !== 'WIDE' &&
            ball.bowlingAnalysis?.pitchMap.y > 39 &&
            ball.bowlingAnalysis?.pitchMap.y <= 54
          ) {
            // both good line & good length
            pitchDesc += sample([
              'good line and length',
              `good line and length from ${ball.bowlerMp?.getShortName}`,
              'on a good line and length',
              `on a good line and length from ${ball.bowlerMp?.getShortName}`,
              'pitching on a good line and length',
            ])
          } else {
            // just good line
            pitchDesc += sample(['on a good line', 'pitching on a good line', 'on line'])
          }
          if (
            (lastBall?.bowlingAnalysis?.pitchMap?.x || 0) > 46.5 &&
            (lastBall?.bowlingAnalysis?.pitchMap?.x || 0) < 51
          )
            useLineRepitition = true
        }
        if (!isNil(arrivalAtStumpsOrOffSide) && !arrivalAtStumpsOrOffSide) {
          // pitched on off side, angling to leg side
          pitchDesc += sample([
            ` but ${anglingAngled} across`,
            ` but ${anglingAngled}${anglingAngledAdverbs} across the batter`,
            ` but ${anglingAngled}${anglingAngledAdverbs} across ${ball.batterMp?.getShortName}`,
          ])
          if (Reference.ExtrasTypeOptions[ball.extrasTypeId || 0] === 'WIDE') {
            pitchDesc += sample(['', ' and down the leg side'])
          }
        } else if (useLineRepitition) {
          pitchDesc += sample(repetition)
        }
      } else if (pitchMapX >= 51) {
        if (pitchMapX >= 51 && pitchMapX <= 53) {
          // on leg
          pitchDesc += sample(['on leg stump', 'pitching on leg', 'pitching near leg stump'])
          if ((lastBall?.bowlingAnalysis?.pitchMap?.x || 0) >= 51 && (lastBall?.bowlingAnalysis?.pitchMap?.x || 0) < 53)
            useLineRepitition = true
        } else if (pitchMapX > 53) {
          // outside leg
          if (Reference.ExtrasTypeOptions[ball.extrasTypeId || 0] === 'WIDE' && !arrivalAtStumpsOrOffSide) {
            pitchDesc += sample(['too wide outside leg', 'pitching far outside leg', 'pitching well down the leg side'])
          } else {
            pitchDesc += sample(['outside leg', 'pitching outside leg', 'pitching outside leg stump'])
            if ((lastBall?.bowlingAnalysis?.pitchMap?.x || 0) > 53) useLineRepitition = true
          }
        }
        if (arrivalAtStumpsOrOffSide) {
          // pitched on leg side, angling to off side
          pitchDesc += sample([
            ` and ${anglingAngled} across`,
            ` and ${anglingAngled}${anglingAngledAdverbs} across the batter`,
            ` and ${anglingAngled}${anglingAngledAdverbs} across ${ball.batterMp?.getShortName}`,
          ])
        } else if (useLineRepitition) {
          pitchDesc += sample(repetition)
        }
      }
    }
    let arrivalShotDesc = ''
    if (footworkType && (shotType !== 'LEAVE' || Reference.ExtrasTypeOptions[ball.extrasTypeId || 0] !== 'WIDE')) {
      descPointers.footwork = true
      arrivalShotDesc +=
        ball.batterMp && pitchDesc.search(ball.batterMp.getShortName) > -1
          ? ball.batterMp.player.person?.gender === 'M'
            ? 'He '
            : ball.batterMp.player.person?.gender === 'F'
            ? 'She '
            : 'They '
          : `${ball.batterMp?.getShortName} `
      if (footworkType === 'FRONT_FOOT') {
        arrivalShotDesc += sample([
          'gets on the front foot',
          'moves onto the front foot',
          'pushes forward',
          'gets forward',
        ])
      } else if (footworkType === 'BACK_FOOT') {
        arrivalShotDesc += sample(['gets on the back foot', 'moves onto the back foot', 'rocks back', 'goes back'])
      } else if (footworkType === 'BACKED_AWAY') {
        arrivalShotDesc += sample(['backs away', 'creates room', 'creates space', 'steps back', 'steps away'])
      } else if (footworkType === 'DUCKED') {
        arrivalShotDesc += sample(['ducks', 'ducks under it', 'ducks out of the way'])
      } else if (footworkType === 'BATTER_MOVEMENT') {
        arrivalShotDesc += sample([
          'moves down the pitch',
          'shuffles down the pitch',
          'advances',
          'advances down the pitch',
        ])
      }
    }
    const isMultipleWides =
      Reference.ExtrasTypeOptions[ball.extrasTypeId || 0] === 'WIDE' && ball.runsExtra && ball.runsExtra >= 2
    if (ball.battingAnalysis?.shots) {
      descPointers.shot = true
      if (
        !isNil(ball.battingAnalysis?.footworkTypeId) &&
        (Reference.FootworkOptions[ball.battingAnalysis?.footworkTypeId] !== 'DUCKED' ||
          (Reference.FootworkOptions[ball.battingAnalysis?.footworkTypeId] === 'DUCKED' &&
            shotType !== 'LEAVE' &&
            shotType !== null)) &&
        (ball.battingAnalysis?.shots.bodyContactId === null ||
          (ball.battingAnalysis?.shots.bodyContactId !== null && shotContact !== 'PLAY_MISS')) &&
        (shotType !== 'LEAVE' || Reference.ExtrasTypeOptions[ball.extrasTypeId || 0] !== 'WIDE') &&
        !(shotType === 'LEAVE' && ball.dismissal && !bodyContact)
      ) {
        // if footwork was used, they didn't duck or they ducked while playing a shot, and they weren't hit on the body, and they weren't just leaving a wide
        // then we need to add a conjunction
        arrivalShotDesc +=
          ((shotType !== 'LEAVE' || ball.battingAnalysis?.shots.bodyContactId !== null) &&
            shotContact !== 'PLAY_MISS') ||
          isMultipleWides
            ? ' and '
            : ' but '
      } else if (
        arrivalShotDesc === '' &&
        (shotType !== 'LEAVE' || Reference.ExtrasTypeOptions[ball.extrasTypeId || 0] !== 'WIDE')
      ) {
        arrivalShotDesc += `${ball.batterMp?.getShortName} `
      }
      if ((shotType !== 'LEAVE' || ball.battingAnalysis?.shots.bodyContactId !== null) && shotType !== null) {
        // Shot Played
        let shotVerb
        if (
          shotType !== 'PADDED' &&
          shotType !== 'LEAVE' &&
          (!ball.battingAnalysis?.shots.attacking || shotType === 'DEFENSIVE') &&
          (!shotContact || shotContact === 'GOOD' || shotContact === 'POOR')
        ) {
          if (shotContact === 'POOR') {
            arrivalShotDesc += sample([
              `plays a ${sample(negativeSuperlatives)} defensive stroke`,
              `defends ${sample(negativeAdverbs)}`,
            ])
          } else {
            arrivalShotDesc += sample(['plays a defensive stroke', 'defends'])
          }
        } else if (
          shotType === 'PADDED' ||
          (!shotContact && ball.battingAnalysis?.shots.bodyContactId !== null) ||
          (shotContact && shotContact !== 'GOOD' && shotContact !== 'POOR')
        ) {
          if (shotType === 'PADDED') {
            arrivalShotDesc += sample(['pads up', 'pads it away', 'pads the ball away'])
          } else if (shotContact === 'INSIDE_EDGE') {
            arrivalShotDesc += sample(['edges', 'inside edges'])
          } else if (shotContact === 'OUTSIDE_EDGE') {
            arrivalShotDesc += sample(['edges', 'outside edges'])
          } else if (shotContact === 'PLAY_MISS' && !bodyContact) {
            arrivalShotDesc += sample([
              'misses',
              ball.battingAnalysis?.shots.attacking ? 'swings and misses' : 'plays and misses',
              'makes no contact',
            ])
          } else if (shotContact === 'HIT_GLOVES' && !bodyContact) {
            arrivalShotDesc += sample(['is hit on the gloves', 'is struck on the gloves'])
          }
          if (ball.battingAnalysis?.shots.bodyContactId !== null && shotType !== 'PADDED') {
            if (
              footworkType &&
              (shotContact ||
                (!shotContact &&
                  footworkType === 'DUCKED' &&
                  (bodyContact === 'HIT_HEAD' || bodyContact === 'HIT_PAD' || bodyContact === 'HIT_BODY'))) &&
              shotContact !== 'INSIDE_EDGE' &&
              shotContact !== 'OUTSIDE_EDGE' &&
              shotContact !== 'HIT_GLOVES'
            ) {
              arrivalShotDesc += ', and '
            }
            if (bodyContact === 'HIT_HEAD') {
              arrivalShotDesc +=
                shotContact === 'INSIDE_EDGE' || shotContact === 'OUTSIDE_EDGE'
                  ? sample([' onto their helmet', ' into their helmet'])
                  : sample(['is hit on the helmet', 'is struck on the helmet'])
            } else if (bodyContact === 'HIT_PAD') {
              arrivalShotDesc +=
                shotContact === 'INSIDE_EDGE' || shotContact === 'OUTSIDE_EDGE'
                  ? sample([' into their pads', ' onto the pads'])
                  : sample(['is hit on the pad', 'is hit on the pads'])
            } else if (bodyContact === 'HIT_BODY') {
              arrivalShotDesc +=
                shotContact === 'INSIDE_EDGE' || shotContact === 'OUTSIDE_EDGE'
                  ? sample([' onto their body', ' onto the body'])
                  : sample(['is hit on the body', 'is struck on the body'])
            }
          }
          if (
            (shotContact === 'PLAY_MISS' && !bodyContact) ||
            (shotType !== 'PADDED' &&
              (bodyContact === 'HIT_PAD' ||
                bodyContact === 'HIT_BODY' ||
                bodyContact === 'HIT_HEAD' ||
                shotContact === 'HIT_GLOVES'))
          ) {
            descPointers.hitPadOrBody = true
            if (shotType === 'LEAVE') {
              arrivalShotDesc += sample([' while trying to leave', ' while trying to leave the ball'])
            } else {
              const attemptingShot = sample([
                'while attempting',
                'while trying',
                'while attempting to play',
                'while trying to play',
              ])
              arrivalShotDesc += ` ${attemptingShot} ${
                shotType === 'DEFENSIVE'
                  ? attemptingShot === 'while attempting' || attemptingShot === 'while trying'
                    ? 'to defend'
                    : sample(['a defensive shot', 'a defensive stroke'])
                  : `a ${shotType?.replace(/_/g, ' ').toLowerCase()}`
              }`
            }
          }
        } else if (
          ball.battingAnalysis?.shots.attacking &&
          (!shotContact || shotContact === 'GOOD' || shotContact === 'POOR')
        ) {
          // Shot type (leave, drive, glance etc)
          if (shotType) {
            const isCutShot = shotType === 'SQUARE_CUT' || shotType === 'LATE_CUT' || shotType === 'CUT'
            const isGlanceShot = shotType === 'GLANCE' || shotType === 'LEG_GLANCE'
            const nounFirst = sample([true, false])
            if (nounFirst) {
              // Noun first (e.g. "square cuts...")
              if (isCutShot) {
                const shotTypeA = shotType.toLowerCase().split('_')
                if (shotTypeA.length > 1) {
                  const adjectiveFirst = sample([true, false])
                  if (adjectiveFirst) {
                    arrivalShotDesc += shotTypeA[0]
                  }
                  arrivalShotDesc += `${adjectiveFirst ? ' ' : ''}${shotTypeA[1]}s`
                  const adjectiveOnly = sample([true, false])
                  if (!adjectiveFirst && adjectiveOnly) {
                    arrivalShotDesc += ` ${shotTypeA[0]}`
                  }
                } else {
                  arrivalShotDesc += `${shotTypeA[0]}s`
                }
              } else {
                arrivalShotDesc += `${shotType.replace(/_/g, ' ').toLowerCase()}s`
              }
              if (shotContact === 'POOR') {
                arrivalShotDesc += ` ${sample(negativeAdverbs)}`
              }
            } else {
              // Verb first (e.g. "plays a square cut...")
              if (ball.battingAnalysis?.shots.inTheAir) {
                shotVerb = sample(['lofts', 'lifts', 'skies', isCutShot ? 'slices' : 'plays'])
              } else {
                shotVerb = sample(['plays'])
                if (shotType === 'DRIVE') {
                  shotVerb = sample(['punches', 'eases'])
                } else if (isCutShot) {
                  shotVerb = sample(['slices', 'guides', 'plays'])
                } else if (isGlanceShot) {
                  shotVerb = sample(['tucks', 'flicks', 'guides', 'finesses'])
                }
              }
              const poorDescriptor = shotContact === 'POOR' ? `${sample(negativeSuperlatives)} ` : ''
              arrivalShotDesc += `${shotVerb} a ${poorDescriptor}${shotType.replace(/_/g, ' ').toLowerCase()}`
            }
          }
        }
      } else if (
        bodyContact !== 'HIT_PAD' &&
        bodyContact !== 'HIT_BODY' &&
        bodyContact !== 'HIT_HEAD' &&
        !ball.dismissal &&
        Reference.ExtrasTypeOptions[ball.extrasTypeId || 0] !== 'WIDE' &&
        (isNil(ball.battingAnalysis?.footworkTypeId) ||
          (!isNil(ball.battingAnalysis?.footworkTypeId) &&
            Reference.FootworkOptions[ball.battingAnalysis?.footworkTypeId] !== 'DUCKED'))
      ) {
        // Leave
        const allowsChoice = sample([
          'decides to let',
          'decides to just let',
          'decides to allow',
          'opts to let',
          'lets',
          'allows',
          'watches',
        ])
        const subjectChoice = sample(['the ball', 'that one', 'it'])
        const travelPrefixChoice = allowsChoice && allowsChoice.indexOf('allow') > -1 ? 'to ' : ''
        const travelChoice = sample(['through', 'go through', 'travel through', 'pass through'])
        let travelSuffix = ''
        let wkChoice: string | undefined = ''
        if (!isMultipleWides) {
          travelSuffix = ' to '
          wkChoice = sample(['the keeper', 'the wicketkeeper', game.getBowlingTeam()?.getWicketkeeper?.getShortName])
        }
        const suffixChoice = sample([
          '',
          '',
          ' unchallenged',
          ' untouched',
          ' without playing a shot',
          ' without offering a shot',
        ])
        arrivalShotDesc += `${allowsChoice} ${subjectChoice} ${travelPrefixChoice}${travelChoice}${travelSuffix}${wkChoice}${suffixChoice}` // eslint-disable-line max-len
      }
    }

    let wagonWheelDesc = ''
    const wagonWheelFirst = ball.shortRun ? true : sample([true, false])
    // Shot wagon wheel
    if (ball.battingAnalysis?.shots.wagonWheel && shotType) {
      if (
        !isMultipleWides &&
        shotType !== 'LEAVE' &&
        ball.getExtraType !== 'BYE' &&
        ball.getExtraType !== 'NO_BALL_BYE'
      ) {
        const wagonWheelX =
          ball.batterMp?.player.battingHandedId === indexOf(Reference.HandedTypeOptions, 'LEFT')
            ? 100 - ball.battingAnalysis.shots.wagonWheel.x
            : ball.battingAnalysis.shots.wagonWheel.x
        const nonSingleSideShot =
          shotType === 'DEFENSIVE' ||
          shotType === 'PADDED' ||
          shotType === 'DRIVE' ||
          shotType === 'RAMP' ||
          shotType === 'GLANCE' ||
          shotType === 'SWITCH_HIT'
        const isCaughtDismissal =
          ball.dismissal && ball.dismissal.dismissalTypeId === indexOf(Reference.DismissalMethods, 'CAUGHT')
        const shotAngleAndLength = getAngleAndLength(
          wagonWheelX,
          ball.battingAnalysis.shots.wagonWheel.y,
          50,
          43.5,
          92,
          100
        )
        const isShotTowardsSlips =
          ((shotAngleAndLength.angle > 270 && shotAngleAndLength.angle <= 360) ||
            (shotAngleAndLength.angle > 0 && shotAngleAndLength.angle <= 10)) &&
          shotAngleAndLength.length < 20
        const throughOver = ball.battingAnalysis.shots.inTheAir ? 'over' : 'through'
        if (ball.battingAnalysis.shots.wagonWheel.y < 43.5 && !isShotTowardsSlips) {
          // through/behind square
          const pointOrSquare = wagonWheelX < 50 ? 'point' : 'square'
          const throughOrBehind =
            wagonWheelX < 50 && ball.battingAnalysis.shots.wagonWheel.y > 38 ? 'through' : 'behind'
          wagonWheelDesc += sample([
            ` ${throughOrBehind} ${pointOrSquare}`,
            ` back ${throughOrBehind} ${pointOrSquare}`,
            nonSingleSideShot
              ? ` ${throughOrBehind} ${pointOrSquare} on the ${wagonWheelX < 50 ? 'off' : sample(['on', 'leg'])} side`
              : sample([` ${throughOrBehind} ${pointOrSquare}`, ` back ${throughOrBehind} ${pointOrSquare}`]),
          ])
        } else if (nonSingleSideShot && wagonWheelX < 35) {
          // off side
          wagonWheelDesc += isCaughtDismissal
            ? ' on the off side'
            : sample([' on the off side', ` ${throughOver} the off side`, ` ${throughOver} the off side field`, ''])
        } else if (wagonWheelX >= 35 && wagonWheelX <= 65 && ball.battingAnalysis.shots.wagonWheel.y >= 60) {
          // down the ground
          wagonWheelDesc += isCaughtDismissal
            ? ' down the ground'
            : sample([
                ' down the ground',
                `${
                  wagonWheelX >= 45 && wagonWheelX <= 55
                    ? sample([' straight down the ground', ' past the bowler'])
                    : ''
                }`,
              ])
        } else if (nonSingleSideShot && wagonWheelX > 65) {
          // on/leg side
          wagonWheelDesc += isCaughtDismissal
            ? sample([' on the on side', ' on the leg side'])
            : sample([
                ' on the on side',
                ' on the leg side',
                ` ${throughOver} the on side field`,
                ` ${throughOver} the leg side field`,
                '',
              ])
        }
      } else if (
        Reference.ExtrasTypeOptions[ball.extrasTypeId || 0] === 'WIDE' &&
        ball.runsExtra &&
        ball.runsExtra >= 2
      ) {
        // Special case: Ball ran away for multiple wides (2 or more)
        const extrasMinusOverthrows = ball.runsExtra - (ball.fieldingAnalysis?.overThrows || 0)
        const conjChoice =
          !isNil(ball.battingAnalysis?.footworkTypeId) &&
          Reference.FootworkOptions[ball.battingAnalysis?.footworkTypeId] === 'DUCKED'
            ? 'and'
            : sample(conjunction)
        const subjectChoice = sample(['the ball', 'it'])
        const wkChoice = sample([
          'the keeper',
          'the wicketkeeper',
          game.getBowlingTeam()?.getWicketkeeper?.getShortName,
        ])
        const isShotLeave = Reference.ShotTypeOptions[ball.battingAnalysis?.shots.shotTypeId || 0] === 'LEAVE'
        const actionChoice =
          extrasMinusOverthrows === 5
            ? sample(['runs', 'runs away', 'flies', 'flies away'])
            : sample(['runs away', extrasMinusOverthrows <= 3 ? 'trickles away' : 'flies away'])
        const boundaryChoice = extrasMinusOverthrows === 5 ? sample([' to the rope', ' to the boundary']) : ''
        const numberWordChoice =
          extrasMinusOverthrows > 6 ? extrasMinusOverthrows : sample(runsAsWords[extrasMinusOverthrows])
        wagonWheelDesc += `${
          isShotLeave ? `${upperFirst(subjectChoice)}` : `, ${conjChoice} ${subjectChoice}`
        } beats ${wkChoice} and ${actionChoice}${boundaryChoice} for ${numberWordChoice} wides`
      }
    }

    let runsDesc = ''
    if (wagonWheelFirst && wagonWheelDesc !== '') runsDesc += wagonWheelDesc
    if (
      ball.battingAnalysis?.shots.shotTypeId !== null &&
      shotType !== 'LEAVE' &&
      (shotContact !== 'PLAY_MISS' ||
        (shotContact === 'PLAY_MISS' && (ball.getExtraType === 'LEG_BYE' || ball.getExtraType === 'NO_BALL_LEG_BYE')))
    ) {
      let runsPrefix = descPointers.shot || descPointers.footwork ? ' for ' : ''
      if (ball.runsBat !== null) {
        // Runs
        let runsTotal = (ball.runsBat || 0) + (ball.shortRun || 0)
        if (ball.fieldingAnalysis?.overThrows) runsTotal -= ball.fieldingAnalysis?.overThrows
        if (runsTotal > 0) {
          runsDesc += `${runsPrefix}${runsTotal <= 6 ? sample(runsAsWords[runsTotal]) : runsTotal} ${
            runsTotal !== 1 ? 'runs' : 'run'
          }`
        }
      } else if (ball.runsExtra !== null && ball.getExtraType !== 'NO_BALL') {
        // Extras
        let runsExtra = ball.runsExtra
        let extrasType = ball.getExtraType || ''
        if ((ball.getExtraType === 'LEG_BYE' || ball.getExtraType === 'NO_BALL_LEG_BYE') && descPointers.hitPadOrBody)
          runsPrefix = ', resulting in '
        if (ball.getExtraType === 'NO_BALL_LEG_BYE' || ball.getExtraType === 'NO_BALL_BYE') {
          runsExtra = runsExtra - (game.matchConfigs.noBallRuns || 1)
          extrasType = extrasType?.replace('NO_BALL_', '')
        }
        if (ball.runsExtra > 1) runsExtra += ball.shortRun || 0
        if (extrasType !== 'WIDE' || (extrasType === 'WIDE' && runsExtra > 1)) {
          runsDesc += `${runsPrefix}${runsExtra <= 6 ? sample(runsAsWords[runsExtra]) : runsExtra} ${extrasType
            .replace(/_/g, ' ')
            .toLowerCase()}${runsExtra !== 1 ? 's' : ''}`
        }
      }
    } else {
      // no shot, or the ball was left. check if the ball went for any Byes
      if (ball.runsExtra !== null && (ball.getExtraType === 'BYE' || ball.getExtraType === 'NO_BALL_BYE')) {
        runsDesc += ', and '
        const runsExtraByes =
          ball.getExtraType === 'NO_BALL_BYE' ? ball.runsExtra - (game.matchConfigs.noBallRuns || 1) : ball.runsExtra
        const byeVerb = sample(['runs', 'trickles', 'rolls', 'flies'])
        let byeBlame = ''
        if (ball.getByeBlame === 'WICKET_KEEPER') {
          const wkName = game.getBowlingTeam()?.getWicketkeeper?.getShortName
          byeBlame += sample([' from the wicketkeeper', wkName ? ` from ${wkName}` : ' from the keeper'])
        }
        runsDesc += `the ball ${byeVerb} away${byeBlame} for ${sample(runsAsWords[runsExtraByes])} bye${
          runsExtraByes !== 1 ? 's' : ''
        }`
        if (ball.getByeBlame === 'BOWLER') {
          runsDesc += sample([
            `, as a result of the ${sample(negativeSuperlatives)} delivery from ${ball.bowlerMp?.getShortName}`,
            `, due to the ${sample(negativeSuperlatives)} delivery by ${ball.bowlerMp?.getShortName}`,
          ])
        }
      }
    }
    if (ball.shortRun) {
      // Short Run
      const shortRunDesc = sample([
        'by the batters',
        'by the two batters',
        `by ${ball.batterMp?.getShortName} and ${ball.batterNonStrikeMp?.getShortName}`,
        'running between the wickets',
      ])
      runsDesc += `. ${capitalize(sample(mistake))} ${shortRunDesc} results in ${
        ball.shortRun === 1 ? 'a' : ball.shortRun
      } short run${ball.shortRun !== 1 ? 's' : ''} being ${sample(signalled)} by the umpire`
    }
    if (!wagonWheelFirst && wagonWheelDesc !== '') runsDesc += wagonWheelDesc

    // Appeal w/review params
    let appealWicketDesc = ''
    let appealWicketFieldedDesc = ''
    let appealForMissedEvent = false
    let reviewDesc = ''
    if (ball.appeal || ball.review) {
      descPointers.appeal = true
      let umpireName = ball.fieldingAnalysis?.stumpingMissed
        ? ball.umpireNonControl
          ? sample([
              `umpire ${ball.umpireNonControl.getShortName}`,
              `umpire ${ball.umpireNonControl.getDisplayName}`,
              'the umpire',
            ])
          : 'the umpire'
        : ball.umpireControl
        ? sample([
            `umpire ${ball.umpireControl.getShortName}`,
            `umpire ${ball.umpireControl.getDisplayName}`,
            'the umpire',
          ])
        : 'the umpire'
      if (ball.fieldingAnalysis?.stumpingMissed) umpireName += ' at square leg'
      const notOutText = ball.fieldingAnalysis?.runOutMissed
        ? sample([
            `the batter is given not out. `,
            `the umpire says the batter is not out. `,
            `the umpire deems the batter has made their ground. `,
          ])
        : sample([
            `${ball.batterMp?.getShortName} is given not out. `,
            `${umpireName} gives ${ball.batterMp?.getShortName} not out. `,
            `${umpireName} says not out. `,
            `${umpireName} is unmoved. `,
          ])
      if (ball.review && ball.review.dismissalTypeId !== null) {
        const appealDismissal =
          ball.review.dismissalTypeId === indexOf(Reference.DismissalMethods, 'CAUGHT')
            ? 'a catch'
            : ball.review.dismissalTypeId === indexOf(Reference.DismissalMethods, 'RUN_OUT')
            ? 'a run out'
            : ball.review.dismissalTypeId === indexOf(Reference.DismissalMethods, 'LBW')
            ? 'LBW'
            : Reference.DismissalMethods[ball.review.dismissalTypeId].replace(/_/g, ' ').toLowerCase()
        const isRunOutOrStumped =
          ball.review?.dismissalTypeId === indexOf(Reference.DismissalMethods, 'RUN_OUT') ||
          ball.review?.dismissalTypeId === indexOf(Reference.DismissalMethods, 'STUMPED')
        const isBowledOrHitWkt =
          ball.review?.dismissalTypeId === indexOf(Reference.DismissalMethods, 'BOWLED') ||
          ball.review?.dismissalTypeId === indexOf(Reference.DismissalMethods, 'HIT_WICKET')
        if (
          (!ball.review.originalDecision && isRunOutOrStumped) ||
          ball.review.originalDecision === false ||
          !ball.review.originalDecision
        ) {
          if (isRunOutOrStumped) {
            const extraRun = sample([' ', ' to come back for ', ' to return for '])
            if (ball.runsBat) reviewDesc += `The batters attempt${extraRun}an extra run, but `
            if (ball.fieldingAnalysis?.fieldingPlayers && ball.fieldingAnalysis?.fieldingPlayers.length > 0)
              reviewDesc += `the ${
                ball.review?.dismissalTypeId === indexOf(Reference.DismissalMethods, 'STUMPED') ? 'glovework' : 'throw'
              } by ${ball.fieldingAnalysis?.fieldingPlayers[0].playerMp.getShortName} is ${sample(superlatives)}. `
            reviewDesc += `${game.getBowlingTeam()?.name} appeal${
              ball.review?.dismissalTypeId !== null ? ` for ${appealDismissal}` : ''
            }, and it's referred to the third umpire for review. `
          } else {
            const isUmpireReview =
              !isNil(ball.review?.reviewSourceTypeId) &&
              Reference.ReviewSourceOptions[ball.review.reviewSourceTypeId] === 'UMPIRE'
            const isUmpireReviewNoOrigDecision =
              isUmpireReview &&
              indexOf(
                Reference.DismissalMethodsNoOrigDecisionUmpire,
                Reference.DismissalMethods[ball.review.dismissalTypeId]
              ) > -1
            const reviewSource = isUmpireReview ? `${sample(['The', 'Both'])} umpires` : game.getBowlingTeam()?.name
            const meetingChoice = isUmpireReview
              ? sample([' meet and decide to', ' converse before deciding to', ' get together and decide to'])
              : ''
            reviewDesc += `${game.getBowlingTeam()?.name} appeal${
              ball.review?.dismissalTypeId !== null ? ` for ${appealDismissal}` : ''
            }${isUmpireReviewNoOrigDecision ? '' : `, ${sample(conjunction)} `}${
              isUmpireReviewNoOrigDecision ? '. ' : notOutText
            }${reviewSource}${meetingChoice} call for a${isUmpireReview ? 'n umpire' : ''} review. `
          }
        }
        if (ball.review.originalDecision === true) {
          reviewDesc += `${umpireName} gives ${ball.batterMp?.getShortName} out${
            ball.review.dismissalTypeId
              ? ball.review.dismissalTypeId === indexOf(Reference.DismissalMethods, 'LBW')
                ? ' LBW'
                : ` ${Reference.DismissalMethods[ball.review.dismissalTypeId].replace(/_/g, ' ').toLowerCase()}`
              : ''
          }, ${sample(conjunction)} `
          if (isBowledOrHitWkt) {
            // if B or HW, review is umpire initiated
            reviewDesc += `it's sent up to the third umpire for a review. `
          } else {
            // otherwise, check who initiated the review (player or umpire)
            reviewDesc +=
              ball.review.getReviewSource === 'PLAYER'
                ? `${ball.batterMp?.getShortName} signals for a review. `
                : `the umpires then ${sample([
                    'signal for a review',
                    'send it upstairs for review',
                    'ask the batter to remain while a review is undertaken',
                    `ask ${ball.batterMp?.getShortName} to remain while a review is undertaken`,
                  ])}. `
          }
        }
        if (isRunOutOrStumped) {
          if (
            !isNil(ball.review.finalDecision) ||
            (ball.review.originalDecision === true && ball.review.finalDecision && ball.review.drsUmpiresCall)
          ) {
            const replayPrefix = sample(['Replays show', 'The replay shows'])
            if (!isNil(ball.dismissal?.dismissalTypeId)) {
              reviewDesc += ball.dismissal?.batterMp
                ? `${replayPrefix} ${ball.dismissal?.batterMp?.getShortName} is short of the popping crease`
                : `${replayPrefix} the batter is short of the popping crease`
              reviewDesc += sample([', and will have to go. ', ', and will have to depart. ', '. '])
            } else if (ball.dismissal?.batterMp) {
              reviewDesc += `${replayPrefix} ${ball.dismissal?.batterMp?.getShortName} made it in safely. `
            } else {
              reviewDesc +=
                Reference.DismissalMethods[ball.review.dismissalTypeId] === 'STUMPED'
                  ? sample([
                      `${replayPrefix} ${ball.batterMp?.getShortName} made it in safely. `,
                      `${replayPrefix} the batter made it in safely. `,
                      `${replayPrefix} the batter made their ground. `,
                    ])
                  : `${replayPrefix} the batter made it in safely. `
            }
          }
        } else {
          if (ball.review.finalDecision === true) {
            if (!isNil(ball.review.originalDecision)) {
              // if we have an original decision, give context for any change in the decision
              reviewDesc += !ball.review.originalDecision ? 'The decision is overturned' : 'The decision is upheld'
            } else {
              // if we don't have an original decision (i.e. DismissalMethodsNoOrigDecision)...
              // ...then give context for the third umpire having made a final decision
              reviewDesc += sample([
                `The third umpire has ${sample(['made', 'reached'])} their decision`,
                `${sample(['The', 'A'])} decision has been made by the third umpire`,
              ])
            }
            reviewDesc += sample([
              `, and ${ball.batterMp?.getShortName} has to go`,
              `, and ${ball.batterMp?.getShortName} must depart`,
              `, and ${ball.batterMp?.getShortName} is given out`,
            ])
          } else if (ball.review.finalDecision === false) {
            if (isBowledOrHitWkt) {
              // if B or HW, final decision is just third umpire (not DRS)
              reviewDesc +=
                ball.review.originalDecision === true
                  ? sample(['The decision is overturned', 'The decision is overturned by the third umpire'])
                  : !isNil(ball.review.originalDecision)
                  ? sample(['The decision is upheld', 'The decision is upheld by the third umpire'])
                  : sample([
                      'The third umpire reviews, and the decision is not out',
                      'The third umpire decides that the batter is not out',
                    ])
            } else {
              // otherwise, it is a DRS final decision
              reviewDesc +=
                ball.review.originalDecision === true
                  ? sample(['The decision is overturned', 'The decision is overturned by DRS'])
                  : !isNil(ball.review.originalDecision)
                  ? sample(['The decision is upheld', 'The decision is upheld by the DRS'])
                  : sample([
                      'The third umpire reviews, and the decision is not out',
                      'The third umpire decides that the batter is not out',
                    ])
              if (
                ball.review.originalDecision === true &&
                ball.review.getReviewSource === 'UMPIRE' &&
                ball.getExtraType &&
                startsWith(ball.getExtraType, 'NO_BALL')
              ) {
                // batter has been recalled due to a No Ball
                reviewDesc += ` ${sample([
                  'due to a no ball',
                  'due to a front-foot no ball',
                  'because of a no ball',
                  'because of a front-foot no ball',
                ])}`
                reviewDesc += sample(['', ` by ${ball.bowlerMp?.getShortName}`])
              }
            }
          } else if (ball.review.drsUmpiresCall) {
            const umpiresCallDefault = `is ${ball.review.originalDecision ? 'out' : 'not out'}`
            const umpiresCallSuffix =
              ball.review.originalDecision === true
                ? sample([umpiresCallDefault, `will have to go`, `must depart`])
                : umpiresCallDefault
            const umpiresCallConj = sample(['meaning that', 'and'])
            reviewDesc += `${
              Reference.DismissalMethods[ball.review.dismissalTypeId] === 'LBW'
                ? sample(["DRS comes back as umpire's call", "DRS has decided on umpire's call"])
                : sample([
                    "The decision is referred back to the umpire's call",
                    'The decision is referred back to the on-field umpire',
                  ])
            }, ${umpiresCallConj} ${ball.batterMp?.getShortName} ${umpiresCallSuffix}`
          }
          if (reviewDesc !== '' && !isNil(ball.review.dismissalTypeId)) reviewDesc += '. '
        }
        if (Reference.DismissalMethodsForBowler.indexOf(Reference.DismissalMethods[ball.review.dismissalTypeId]) > -1) {
          appealWicketDesc += reviewDesc
        } else {
          appealWicketFieldedDesc += reviewDesc
        }
      } else if (!ball.review && !ball.dismissal) {
        appealForMissedEvent =
          ball.fieldingAnalysis?.runOutMissed || ball.fieldingAnalysis?.stumpingMissed ? true : false // eslint-disable-line max-len
        appealWicketDesc += `${game.getBowlingTeam()?.name} appeal${
          ball.fieldingAnalysis?.stumpingMissed ? sample([' for stumped', ' for a stumping']) : ''
        }, ${ball.dismissal ? 'and ' : `${sample(conjunction)} `}${notOutText}`
      }
    }

    const primaryFielder = find(ball.fieldingAnalysis?.fieldingPlayers, { order: 1 })
    const secondaryFielder = find(ball.fieldingAnalysis?.fieldingPlayers, { order: 2 })

    // Wicket (non-reviewed, bowler credited only)
    let suffixDesc = ''
    if (
      !ball.review &&
      ball.dismissal &&
      Reference.DismissalMethodsForBowler.indexOf(Reference.DismissalMethods[ball.dismissal.dismissalTypeId]) > -1
    ) {
      descPointers.wicketCredited = true
      if (
        ball.dismissal &&
        Reference.DismissalMethodsForBowler.indexOf(Reference.DismissalMethods[ball.dismissal.dismissalTypeId]) > -1
      ) {
        const leaveSynonym = sample(['go', 'depart'])
        if (Reference.DismissalMethods[ball.dismissal.dismissalTypeId] === 'CAUGHT') {
          // C
          const adverb = sample(['brilliantly', 'spectacularly', 'impressively', 'remarkably'])
          const andOrBut = shotContact === 'GOOD' ? 'but' : 'and'
          if (footworkType || shotType !== 'LEAVE' || bodyContact) {
            arrivalShotDesc += `, ${andOrBut} `
          }
          arrivalShotDesc +=
            primaryFielder && !isNil(primaryFielder.difficultyRating) && primaryFielder.difficultyRating >= 3
              ? `is ${adverb} caught`
              : `is caught`
          if (primaryFielder && !isNil(primaryFielder.difficultyRating) && primaryFielder.difficultyRating >= 4) {
            const adverbRoot = sample(['terrific', 'stunning', 'special'])
            suffixDesc += sample([
              `a ${adverbRoot} catch there`,
              `what a catch`,
              `what an extraordinary effort that was`,
              `a simply ${adverbRoot} piece of fielding`,
              'an incredible display of catching',
            ])
          }
          if (ball.fieldingAnalysis?.fieldingPlayers && ball.fieldingAnalysis.fieldingPlayers.length > 0) {
            // catcher?
            arrivalShotDesc += ` by ${ball.fieldingAnalysis.fieldingPlayers[0].playerMp.getShortName}` // eslint-disable-line max-len
            if (primaryFielder && !isNil(primaryFielder.difficultyRating) && primaryFielder.difficultyRating >= 4) {
              suffixDesc += ` by ${ball.fieldingAnalysis.fieldingPlayers[0].playerMp.getShortName}`
            }
          }
        } else if (Reference.DismissalMethods[ball.dismissal.dismissalTypeId] === 'LBW') {
          // LBW
          arrivalShotDesc += '. '
          arrivalShotDesc += sample([
            `The umpire's finger goes up, and ${ball.batterMp?.getShortName} has to ${leaveSynonym}`,
            ball.umpireControl
              ? `${ball.umpireControl?.getShortName} raises the finger, there's no review, and ${ball.batterMp?.getShortName} is on ${playerPronoun} way` // eslint-disable-line max-len
              : `The umpire raises the finger, there's no review, and ${ball.batterMp?.getShortName} is on ${playerPronoun} way`, // eslint-disable-line max-len
            `${game.getBowlingTeam()?.name} appeal, the umpire agrees, and ${
              ball.batterMp?.getShortName
            } has to ${leaveSynonym}`,
          ])
        } else if (Reference.DismissalMethods[ball.dismissal.dismissalTypeId] === 'BOWLED') {
          // B
          if (shotType === 'LEAVE')
            arrivalShotDesc += sample([', leaves', ", doesn't offer a shot", ", doesn't play a shot"])
          arrivalShotDesc += sample([
            `, and the ball careens into the stumps`,
            `, the ball gets through, and ${ball.batterMp?.getShortName} is bowled`,
            `, the stumps are disturbed, and ${ball.batterMp?.getShortName} has to ${leaveSynonym}`,
          ])
        } else if (Reference.DismissalMethods[ball.dismissal.dismissalTypeId] === 'STUMPED') {
          // ST
          const wkName = game.getBowlingTeam()?.getWicketkeeper?.getShortName || 'the wicketkeeper'
          const speedSynonym = sample(['quickly', 'swiftly'])
          arrivalShotDesc += sample([
            `, ${wkName} whips the bails off, and ${ball.batterMp?.getShortName} is out`,
            `, ${wkName} gathers, whips the bails off, and ${ball.batterMp?.getShortName} has to ${leaveSynonym}`,
            // eslint-disable-next-line max-len
            `, ${wkName} ${speedSynonym} whips the bails off, and ${ball.batterMp?.getShortName} has to ${leaveSynonym}`,
          ])
        } else {
          // Other (HW, HT)
          arrivalShotDesc += `. ${ball.batterMp?.getShortName} is given out ${Reference.DismissalMethods[
            ball.dismissal.dismissalTypeId
          ]
            .replace(/_/g, ' ')
            .toLowerCase()}`
        }
        if (wagonWheelDesc === '') arrivalShotDesc += '. '
      }
    }

    const fielderSurnames: Array<string> = []
    if (ball.fieldingAnalysis?.fieldingPlayers) {
      ball.fieldingAnalysis?.fieldingPlayers.map((p: IFieldingPlayerModel) => {
        fielderSurnames.push(p.playerMp.getShortName)
        return true
      })
    }
    const batterSurnames: Array<string> = []
    if (ball.batterMp) batterSurnames.push(ball.batterMp?.getShortName)
    if (ball.batterNonStrikeMp) batterSurnames.push(ball.batterNonStrikeMp?.getShortName)

    // Wicket (non-reviewed, fielded wickets => Run Out, OTF)
    if (
      !ball.review &&
      ball.dismissal &&
      (Reference.DismissalMethods[ball.dismissal.dismissalTypeId] === 'RUN_OUT' ||
        Reference.DismissalMethods[ball.dismissal.dismissalTypeId] === 'OBSTRUCTING_THE_FIELD') &&
      ball.dismissal.batterMp?.getShortName
    ) {
      descPointers.wicketFielded = true
      const strikeBatterPronoun =
        ball.dismissal.batterMp.id === ball.batterMp?.id &&
        !isNil(ball.batterMp.player.person?.gender) &&
        (ball.batterMp.player.person?.gender === 'M' || ball.batterMp.player.person?.gender === 'F')
          ? ball.batterMp.player.person?.gender === 'M'
            ? 'he'
            : 'she'
          : null
      appealWicketFieldedDesc += `${strikeBatterPronoun || ball.dismissal.batterMp?.getShortName} is ${
        arrivalShotDesc !== '' ? 'then ' : ''
      }${Reference.DismissalMethods[ball.dismissal.dismissalTypeId].replace(/_/g, ' ').toLowerCase()}`

      const strikeBatterRunOut = ball.dismissal.batterMp?.id === ball.batterMp?.id
      const runOutAtStrikersEnd =
        (strikeBatterRunOut && (ball.runsBat || 0) % 2 === 0 && !ball.dismissal.battersCrossed) ||
        (strikeBatterRunOut && (ball.runsBat || 0) % 2 !== 0 && ball.dismissal.battersCrossed) ||
        (!strikeBatterRunOut && (ball.runsBat || 0) % 2 !== 0 && !ball.dismissal.battersCrossed) ||
        (!strikeBatterRunOut && (ball.runsBat || 0) % 2 === 0 && ball.dismissal.battersCrossed)
      appealWicketFieldedDesc += sample(
        runOutAtStrikersEnd ? ['', " at the striker's end"] : ['', " at the non-striker's end", " at the bowler's end"]
      )

      if (fielderSurnames.length > 0) {
        const fieldingEventSuperlative: string | undefined = secondaryFielder
          ? secondaryFielder.difficultyRating && secondaryFielder.difficultyRating >= 3
            ? sample(positiveSuperlatives)
            : sample(averageSuperlatives)
          : primaryFielder && primaryFielder.difficultyRating && primaryFielder.difficultyRating >= 3
          ? sample(positiveSuperlatives)
          : sample(averageSuperlatives)
        appealWicketFieldedDesc += `, ${sample([
          'following',
          'after',
          'as a result of',
        ])} some ${fieldingEventSuperlative} fielding by ${fielderSurnames.join(' and ')}`
      }
      arrivalShotDesc += runsDesc === '' ? '. ' : ''
    }

    if (
      !descPointers.wicketCredited &&
      !descPointers.wicketFielded &&
      runsDesc === '' &&
      reviewDesc === '' &&
      (pitchDesc !== '' || ballSpeedDesc !== '' || basicsDesc !== '')
    )
      arrivalShotDesc += '. '

    // Fielding (Dropped catch, missed stumping, missed run out)
    let fieldingDesc = ''
    if (ball.fieldingAnalysis) {
      const missedSuperlative =
        primaryFielder && !isNil(primaryFielder.difficultyRating) && primaryFielder.difficultyRating <= 2
          ? sample([` ${sample(['great', 'real', 'huge'])}`, ''])
          : ''
      if (ball.fieldingAnalysis.droppedCatch) {
        fieldingDesc += sample([
          `a${missedSuperlative} chance for a catch, but it's dropped`,
          `a${missedSuperlative} chance at a wicket, but the catch is dropped`,
          `a${missedSuperlative} chance at a wicket, but the catch is put down`,
        ])
        if (ball.fieldingAnalysis.fieldingPlayers && ball.fieldingAnalysis.fieldingPlayers.length >= 1) {
          fieldingDesc += ` by ${ball.fieldingAnalysis.fieldingPlayers[0].playerMp.getShortName}`
        }
      } else if (ball.fieldingAnalysis.stumpingMissed && !appealForMissedEvent) {
        fieldingDesc += sample([
          `a${missedSuperlative} chance for a stumping, but it's ${sample([
            'missed',
            'flubbed',
            'mishandled',
          ])} by the keeper`,
          `a${missedSuperlative} stumping chance but it's squandered, and ${ball.batterMp?.getShortName} survives`,
        ])
      } else if (ball.fieldingAnalysis.runOutMissed) {
        fieldingDesc += appealForMissedEvent
          ? "there's an attempt at a run out"
          : sample([
              "there's an attempt at a run out",
              `a${missedSuperlative} run out chance but ${game.getBattingTeam()?.name} survive the attempt`,
            ])
        if (ball.fieldingAnalysis.fieldingPlayers && ball.fieldingAnalysis.fieldingPlayers.length >= 1) {
          fieldingDesc += ` from ${ball.fieldingAnalysis.fieldingPlayers[0].playerMp.getShortName}'s throw`
        }
      }
      if (
        fieldingDesc !== '' &&
        primaryFielder &&
        primaryFielder.difficultyRating &&
        primaryFielder.difficultyRating >= 3 &&
        !appealForMissedEvent
      ) {
        const toughSynonym = sample(['tough', 'difficult', 'hard'])
        const fielderName =
          ball.fieldingAnalysis.fieldingPlayers && ball.fieldingAnalysis.fieldingPlayers.length
            ? ` for ${ball.fieldingAnalysis.fieldingPlayers[0].playerMp.getShortName}`
            : ''
        fieldingDesc += sample([
          `. That was a ${toughSynonym} chance${fielderName}`,
          `. Not an easy chance${fielderName}`,
          `. A really ${toughSynonym} chance${fielderName} there`,
        ])
      }
    }

    // Fielder / 2nd Fielder info
    let fielderDesc = ''
    if (ball.fieldingAnalysis?.fielded || ball.fieldingAnalysis?.misfielded) {
      if (ball.fieldingAnalysis?.misfielded && !ball.fieldingAnalysis?.overThrows) {
        // Misfield
        let costTense = 'costing'
        if (fieldingDesc !== '') {
          // fielding event means we need to start a new sentence here, with a slight 'tense' change
          fielderDesc += 'The misfield'
          costTense = 'costs'
        } else {
          fielderDesc += fieldingDesc !== '' ? ', following a misfield' : 'the ball is misfielded'
        }
        if (fielderSurnames.length > 0) {
          fielderDesc += ` by ${fielderSurnames.join(' and ')}`
        }
        if (ball.fieldingAnalysis.runsSaved < 0) {
          // Runs Cost
          fielderDesc += sample([
            ` ${costTense} ${
              ball.fieldingAnalysis.runsSaved * -1 <= 6
                ? sample(runsAsWords[ball.fieldingAnalysis.runsSaved * -1])
                : ball.fieldingAnalysis.runsSaved * -1
            } run`,
            ` ${costTense} ${game.getBowlingTeam()?.name} ${
              ball.fieldingAnalysis.runsSaved * -1 <= 6
                ? sample(runsAsWords[ball.fieldingAnalysis.runsSaved * -1])
                : ball.fieldingAnalysis.runsSaved * -1
            } run`,
          ])
          if (ball.fieldingAnalysis.runsSaved * -1 !== 1) fielderDesc += 's'
        }
      } else if (ball.fieldingAnalysis?.runsSaved > 0 && ball.fieldingAnalysis.fieldingPlayers) {
        // Runs Saved (and we know the player(s) responsible)
        descPointers.runsSaved = true
        if (!ball.fieldingAnalysis?.droppedCatch) {
          const runsSavedAdj =
            primaryFielder && primaryFielder.difficultyRating && primaryFielder.difficultyRating >= 3
              ? sample(positiveSuperlatives)
              : sample(averageSuperlatives)
          fielderDesc += sample([`${runsSavedAdj} fielding`, `${runsSavedAdj} work in the field`])
        } else {
          fielderDesc += `${sample(['regardless, ', 'however, ', ''])}the ${sample(['effort', 'attempt'])}`
        }
        if (fielderSurnames.length > 0) {
          fielderDesc += ` by ${fielderSurnames.join(' and ')}`
        }
        const runsPlusRunsSaved = (ball.runsBat || 0) + ball.fieldingAnalysis.runsSaved
        if (runsPlusRunsSaved === 4 || runsPlusRunsSaved === 6) {
          // if a boundary (4 or 6) was saved
          fielderDesc += sample([
            ' results in a boundary being saved',
            ' saves a boundary',
            ' saves a certain boundary',
            ' prevents a boundary',
            ' prevents a certain boundary',
          ])
        } else {
          fielderDesc += ` results in ${
            ball.fieldingAnalysis.runsSaved <= 6
              ? sample(runsAsWords[ball.fieldingAnalysis.runsSaved])
              : ball.fieldingAnalysis.runsSaved
          } run`
          if (ball.fieldingAnalysis.runsSaved !== 1) fielderDesc += 's'
          fielderDesc += ' being saved'
        }
      }
      if (ball.fieldingAnalysis?.overThrows) {
        if (fielderDesc !== '') fielderDesc += ', '
        if (descPointers.runsSaved) fielderDesc += `${sample(conjunction)} `
        fielderDesc += ball.fieldingAnalysis?.runOutMissed
          ? 'the missed run out attempt'
          : sample([
              'sloppy fielding',
              `some ${sample(negativeSuperlatives)} fielding`,
              'an error in the field',
              'a mistake in the field',
            ])
        if (fielderSurnames.length > 0 && !ball.fieldingAnalysis?.runOutMissed) {
          fielderDesc += ` by ${fielderSurnames.join(' and ')}`
        }
        if (ball.fieldingAnalysis.overThrows === 4) {
          fielderDesc += sample([
            ' results in the ball reaching the boundary for',
            ' is costly, as the ball goes to the boundary for',
            ` is costly for ${game.getBowlingTeam()?.name}, with the ball running away for`,
          ])
        } else {
          fielderDesc += sample([
            ' allows the batters',
            ` allows ${game.getBattingTeam()?.name}`,
            ` allows ${batterSurnames.join(' and ')}`,
          ])
          fielderDesc += sample([' to scamper through for', ' to run through for', ' to complete'])
        }
        fielderDesc += ` ${
          ball.fieldingAnalysis.overThrows <= 6
            ? sample(runsAsWords[ball.fieldingAnalysis.overThrows])
            : ball.fieldingAnalysis.overThrows
        } overthrow`
        if (ball.fieldingAnalysis.overThrows !== 1) fielderDesc += 's'
      }
      // TODO: Fielder position, wagon wheel (NTH)
      // (i.e. ...fielded by Maxwell at fine leg...)
    }

    // Compose everything
    let simpleOrDetailed = 0
    if (basicsDesc !== '') {
      desc += `${upperFirst(basicsDesc)}. `
      simpleOrDetailed++
    }
    if (ballSpeedDesc !== '') {
      desc += `${ballSpeedDesc} `
      simpleOrDetailed++
    }
    if (pitchDesc !== '') {
      desc += `${upperFirst(pitchDesc)}. `
      simpleOrDetailed++
    }
    if (arrivalShotDesc !== '') {
      desc += `${upperFirst(arrivalShotDesc)}${
        runsDesc === '' && (descPointers.footwork || descPointers.shot) ? '. ' : ''
      }`
      simpleOrDetailed++
    }
    if (runsDesc !== '') {
      desc += `${upperFirst(runsDesc)}. `
      simpleOrDetailed++
    }
    if (appealWicketDesc !== '' && !appealForMissedEvent) {
      desc += `${upperFirst(appealWicketDesc)}`
      simpleOrDetailed += 2
    }
    if (reviewDesc !== '') simpleOrDetailed++
    if (fieldingDesc !== '') {
      desc += `${upperFirst(fieldingDesc)}. `
      simpleOrDetailed++
    }
    if (appealWicketDesc !== '' && appealForMissedEvent) {
      desc += `${upperFirst(appealWicketDesc)}`
      simpleOrDetailed += 2
    }
    if (fielderDesc !== '') {
      desc += `${upperFirst(fielderDesc)}. `
      simpleOrDetailed++
    }
    if (appealWicketFieldedDesc !== '') {
      desc += `${upperFirst(appealWicketFieldedDesc)}. `
      simpleOrDetailed++
    }
    if (suffixDesc !== '') {
      desc += `${upperFirst(suffixDesc)}!`
      simpleOrDetailed++
    }

    // Important event prefix (four, six, wicket, dropped catch, missed run out)
    if (simpleOrDetailed >= 2) {
      const activeInning = game.getActiveInning()
      let prefixDesc = ''
      if (ball.fieldingAnalysis?.droppedCatch && !ball.dismissal) {
        prefixDesc += 'DROPPED!'
      } else if ((ball.fieldingAnalysis?.runOutMissed || ball.fieldingAnalysis?.stumpingMissed) && !ball.dismissal) {
        prefixDesc += 'CHANCE!'
      } else if (ball.runsBat === 4 && !ball.allRun) {
        prefixDesc +=
          lastBall?.runsBat === 4 && lastBall.overNumber === ball.overNumber
            ? sample(['FOUR MORE!', 'Back-to-back boundaries!', 'And again!', 'And another!'])
            : 'FOUR!'
      } else if (ball.runsBat === 6 && !ball.allRun) {
        prefixDesc +=
          lastBall?.runsBat === 6 && lastBall.overNumber === ball.overNumber
            ? sample(['SIX MORE!', 'Back-to-back maximums!', 'And again!', 'And another!'])
            : sample(['SIX!', 'MAXIMUM!'])
      } else if (ball.dismissal) {
        let hasBowlingMilestone = false
        if (descPointers.wicketCredited && activeInning && ball.bowlerMp) {
          // Bowling Milestones
          const bowlerWickets = (activeInning.getBowlingPerformance(ball.bowlerMp.id)?.allWickets || 0) + 1
          if (bowlerWickets === 5) {
            prefixDesc += `OUT! `
            prefixDesc += upperFirst(
              `${sample(['five-for', 'five wicket haul', "and that's five"])} for ${ball.bowlerMp.getShortName}!`
            )
            hasBowlingMilestone = true
          } else if (bowlerWickets === 10) {
            prefixDesc += `OUT! `
            prefixDesc += upperFirst(
              `${sample(['all ten', 'all ten wickets', 'a clean sweep', "and that's TEN wickets"])} for ${
                ball.bowlerMp.getShortName
              }!`
            )
            hasBowlingMilestone = true
          }
        }
        if (!hasBowlingMilestone) {
          const breakthroughText = sample([true, true, false])
          if (descPointers.wicketCredited && breakthroughText) {
            const bowlerSpellPerf = find(activeInning?.getCurrentBowlerPerformances, { id: ball.bowlerMp?.id })
            const strikeBatterPerf = find(activeInning?.getCurrentBatterPerformances, {
              id: ball.batterMp?.id,
            })
            if (
              lastBall?.bowlerMp?.id === ball.bowlerMp?.id &&
              lastBall?.dismissal &&
              !isNil(lastBall?.dismissal?.dismissalTypeId) &&
              Reference.DismissalMethodsForBowler.indexOf(
                Reference.DismissalMethods[lastBall.dismissal.dismissalTypeId]
              ) > -1
            ) {
              // bowler has wickets on back-to-back balls
              const wicketsDismissals = sample(['wickets', 'dismissals'])
              prefixDesc += `OUT! ${sample([
                `Back-to-back ${wicketsDismissals}`,
                `Consecutive ${wicketsDismissals}`,
                `Two ${wicketsDismissals} in a row`,
              ])}${sample([` for ${ball.bowlerMp?.getShortName}`, ''])}`
            } else if (bowlerSpellPerf?.wickets && bowlerSpellPerf?.wickets >= 1) {
              // bowler has already taken a wicket in this spell
              prefixDesc += `OUT! ${ball.bowlerMp?.getShortName} `
              prefixDesc += sample(['again', 'with another', 'gets another', 'grabs another wicket'])
            }
            if (
              strikeBatterPerf &&
              strikeBatterPerf?.runs >= 50 &&
              typeof activeInning?.progressiveScores.wickets === 'number' &&
              activeInning.progressiveScores.wickets <= 8
            ) {
              // batter dismissed for more than 50 runs
              prefixDesc += `OUT! ${ball.bowlerMp?.getShortName} `
              if (prefixDesc !== '') {
                prefixDesc += `, and ${sample(['this is', 'what'])} ${sample(['a key', 'an important'])} one`
              } else {
                prefixDesc += sample(['with a key wicket', 'with the breakthrough'])
              }
            }
            if (prefixDesc === '') {
              // otherwise, go more generic
              prefixDesc +=
                ball.overNumber === 0 && ball.ballDisplayNumber === 1
                  ? `OUT! What a start for ${game.getBowlingTeam()?.name}, as ${ball.bowlerMp?.getShortName} `
                  : `OUT! ${ball.bowlerMp?.getShortName} `
              prefixDesc += sample([
                'gets the wicket',
                Reference.DismissalMethods[ball.dismissal.dismissalTypeId] === 'BOWLED'
                  ? sample(['gets one through', 'finds a way through'])
                  : 'gets the wicket',
                Reference.DismissalMethods[ball.dismissal.dismissalTypeId] === 'LBW'
                  ? sample([
                      `traps ${ball.batterMp?.getShortName} in front`,
                      `traps ${ball.batterMp?.getShortName} on the crease`,
                    ])
                  : 'breaks through',
              ])
            }
            prefixDesc += '!'
          } else {
            // non-bowler credited wicket
            prefixDesc +=
              ball.overNumber === 0 && ball.ballDisplayNumber === 1
                ? `OUT! What a ${sample(['way to start for', 'way to begin this innings for', 'start for'])} ${
                    game.getBowlingTeam()?.name
                  }.`
                : Reference.DismissalMethods[ball.dismissal.dismissalTypeId] === 'LBW'
                ? 'OUT! LBW.'
                : `OUT! ${capitalize(
                    Reference.DismissalMethods[ball.dismissal.dismissalTypeId].replace(/_/g, ' ').toLowerCase()
                  )}.`
          }
        }
      }
      if (descPointers.noBall || descPointers.wide) {
        prefixDesc += descPointers.noBall
          ? `${prefixDesc !== '' ? ' ' : ''}No ball`
          : `${prefixDesc !== '' ? ' ' : ''}Wide`
        if (ball.freeHit) {
          prefixDesc += sample([
            ', and that will be another free hit.',
            ', which will trigger another free hit.',
            ', which will queue up another free hit.',
            ', which will queue up another free hit for the batting team.',
            `, which will trigger another free hit for ${activeInning?.getBattingTeamName}.`,
          ])
        } else {
          prefixDesc += '.'
        }
      }

      if (!ball.dismissal) {
        // Batting Milestones
        const batterRuns = ball.batterMp ? activeInning?.getBatterPerformance(ball.batterMp.id)?.allRuns || 0 : 0
        const passedBattingMilestone =
          batterRuns > 0 &&
          batterRuns % 50 !== 0 &&
          (batterRuns + ((ball.runsBat || 0) % 50) === 0 ||
            (batterRuns % 50 >= 44 && (batterRuns + (ball.runsBat || 0)) % 50 <= 5))
        if (ball.runsBat && batterRuns > 0 && passedBattingMilestone) {
          const milestoneReached = batterRuns + (ball.runsBat || 0) - ((batterRuns + (ball.runsBat || 0)) % 50)
          const milestoneAmounts = [50, 100, 150, 200, 250, 300, 350, 400]
          const milestones: Record<typeof milestoneAmounts[number], string[]> = {
            50: ['50', 'fifty'],
            100: ['100', 'hundred', 'century'],
            150: ['150'],
            200: ['200', 'two-hundred', 'double century'],
            250: ['250'],
            300: ['300', 'three-hundred', 'triple century'],
            350: ['350'],
            400: ['400'],
          }
          if (prefixDesc !== '') prefixDesc += ' '
          const milestoneFlair =
            ball.runsBat === 4 || ball.runsBat === 6
              ? sample([
                  ball.runsBat === 4 ? ' with a boundary' : sample([' with a maximum', ' by clearing the rope']),
                  ' in emphatic style',
                ])
              : ''
          prefixDesc += upperFirst(
            sample([
              `${milestones[milestoneReached] ? sample(milestones[milestoneReached]) : batterRuns}${sample([
                '',
                ' up',
                ' comes up',
              ])} for ${ball.batterMp?.getShortName}${milestoneFlair}!`,
              `${ball.batterMp?.getShortName} brings up ${playerPronoun} ${
                milestones[milestoneReached] ? sample(milestones[milestoneReached]) : batterRuns
              }${milestoneFlair}!`,
            ])
          )
        }
      }

      desc = `${prefixDesc} ${desc}`
    }

    // finally, return the final description (or use a simplified version if we have to)
    if (desc === '' || simpleOrDetailed < 2) {
      desc = generateBallSummary(ball, game.matchConfigs.noBallRuns || 1)
    } else {
      // clean up punctuation before we return the full ball description
      desc = desc.replaceAll(/\. \. \. /g, '. ').trim()
      desc = desc.replaceAll(/\. \. /g, '. ').trim()
      const descTail = desc.slice(-2)
      if (descTail === '. ' || descTail === ' .') {
        desc = desc.substr(0, desc.length - 3)
      }
    }
    // ...and save to the property on the ball and show on the interface
    ball.setTextDescription(desc)
    return desc
  }
  return {
    generateBallSummary: generateBallSummary,
    generateSimpleBallCommentary: generateSimpleBallCommentary,
    generateBallCommentary: generateBallCommentary,
  }
})()

export default CommsHelpers
