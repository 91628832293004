import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import type {
  IBallInningModel,
  IMatchOfficialModel,
  IMatchPlayerModel,
  IMatchTeamModel,
  IOfficialModel,
  IPlayerModel,
} from '@clsplus/cls-plus-data-models'
import fileDownload from 'js-file-download'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { db } from '../../data/dexie/Database'
import { PlayerActiveReasonTypes } from '../../data/reference'
import { useMst } from '../../data/stores/rootStore'
import { idCleaner, postPlayer } from '../../helpers/dataHelpers'
import S3PHelpers from '../../helpers/s3pHelpers'
import type { IBallStore, IOfficialsSearchStore, IPlayersSearchStore, ISettingsModel } from '../../types/models'
import type { SettingsContainerProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'
import SubHeading from '../Headings/SubHeading'
import { PersonList } from '../PersonList/PersonList'
import TeamList from '../TeamList/TeamList'
import AppSettings from './AppSettings'
import MatchSettings from './MatchSettings'
import { Officials } from './Officials'
import { Pitch } from './Pitch'
import { S3PBrowser } from './S3PBrowser'
import { Toss } from './Toss'
import Venue from './Venue'
import { Weather } from './Weather'

export const SettingsContainer = observer(
  ({
    game,
    mode,
    isSetup,
    isScoringManually,
    currentBall,
    timelineEvents,
    settingsConfirmed,
    setSettingsConfirmed,
    teamsConfirmed,
    setTeamsConfirmed,
  }: SettingsContainerProps) => {
    const [personListProps, setPersonListProps] = useState<{ type: string; teamId?: string } | null>(null)
    const [personListOpen, setPersonListOpen] = useState(false)
    const [personListType, setPersonListType] = useState<'team' | 'official' | null>(null)
    const [personListTeamId, setPersonListTeamId] = useState<string | null>(null)
    const [personListAdding, setPersonListAdding] = useState(false)
    const [recordsHome, setRecordsHome] = useState(game.getHomeTeam()?.getPlayersInBattingOrder() || [])
    const [recordsAway, setRecordsAway] = useState(game.getAwayTeam?.getPlayersInBattingOrder() || [])
    const [teamsUpdated, setTeamsUpdated] = useState(false)
    const [isS3PModalOpen, setIsS3PModalOpen] = useState(false)

    const {
      playersSearch,
      officialsSearch,
      balls,
      appSettings,
    }: {
      playersSearch: IPlayersSearchStore
      officialsSearch: IOfficialsSearchStore
      balls: IBallStore
      appSettings: ISettingsModel
    } = useMst()

    let data: (IPlayerModel | IOfficialModel)[] | undefined = []
    let existingData: (IMatchPlayerModel | IMatchOfficialModel)[] | undefined = []

    if (personListTeamId) {
      if (personListType === 'team') {
        playersSearch.getPlayers(personListTeamId, game.getCompetitionId, game.dataProvider)
        data = playersSearch.results.get(`${personListTeamId}_${game.getCompetitionId}`)?.data
        existingData = game.getTeamByTeamId(personListTeamId)?.matchPlayers
      }
      if (personListType === 'official') {
        officialsSearch.getOfficials(game.dataProvider)
        data = officialsSearch.results.get(personListTeamId)?.data
        existingData = game.matchOfficials ?? undefined
      }
    }

    const handleTeamsConfirmed = () => {
      if (!game) return
      db.createS3PMessage(
        S3PHelpers.metadata(appSettings.manualScoring.active ? 'postMatch' : mode, game),
        S3PHelpers.lineup(isSetup ? true : false, game.matchTeams, game.matchOfficials || undefined),
        isSetup
      )
      if (!isSetup) setTeamsUpdated(false)
    }
    const handleAddPerson = async (id: string, type?: number) => {
      if (!id) return
      if (personListType === 'team' && personListTeamId) {
        // add player to team (POST)
        const team: IMatchTeamModel | undefined = game.getTeamByTeamId(personListTeamId)
        if (team) {
          const activeReason = PlayerActiveReasonTypes.indexOf(
            team.matchPlayers.filter(player => PlayerActiveReasonTypes[player.activeReasonId ?? 1] === 'STARTING_TEAM')
              ?.length >= 11
              ? 'SUB_UNUSED'
              : 'STARTING_TEAM'
          )
          await postPlayer(team, id, activeReason, team.isHome ? setRecordsHome : setRecordsAway, setPersonListAdding)
        }
      } else if (personListType === 'official') {
        // add official to match (POST) - default to fourth umpire
        game.postOfficial(id, type || 2, game.id, setPersonListAdding)
      }
    }
    const handleManagePlayer = (teamId?: string) => {
      if (!teamId) return
      setPersonListType('team')
      setPersonListTeamId(teamId)
      setPersonListProps({
        type: 'team',
        teamId: teamId,
      })
      setPersonListOpen(true)
    }
    const handleManageOfficials = () => {
      setPersonListType('official')
      setPersonListTeamId('global')
      setPersonListProps({ type: 'official' })
      setPersonListOpen(true)
    }

    const downloadGame = (shallow?: boolean) => {
      const str = idCleaner(shallow ? { ...game } : toJS(game), 'MATCH')
      const blob = new Blob([JSON.stringify(str, null, 2)], {
        type: 'application/json',
      })
      fileDownload(blob, `game_data${shallow ? '_shallow' : ''}.json`)
    }
    const downloadBalls = () => {
      const ballInnings: IBallInningModel[] = []
      balls.results.forEach((inning: IBallInningModel) => {
        const jsInning: IBallInningModel = JSON.parse(JSON.stringify(inning))
        jsInning.balls = idCleaner(jsInning.balls, 'BALL')
        ballInnings.push(jsInning as IBallInningModel)
      })
      const blob = new Blob([JSON.stringify(ballInnings, null, 2)], {
        type: 'application/json',
      })
      fileDownload(blob, 'ball_data.json')
    }

    // get the latest conditions model here
    const conditions = game.getNearestDate?.getLatestConditions

    return (
      <Flex direction="column" marginBottom="37px">
        <Tabs size="md" colorScheme="cls.blue" defaultIndex={mode === 'fielding' ? 1 : isSetup ? 2 : 3}>
          <TabList borderColor="gray.200">
            <Tab>Team Selection</Tab>
            {mode !== 'fielding' && <Tab>Venue &amp; Umpires</Tab>}
            <Tab>Toss</Tab>
            {mode !== 'fielding' && <Tab>Conditions</Tab>}
            <Tab>Settings</Tab>
          </TabList>
          <TabPanels>
            <TabPanel paddingY="7px" paddingX={['0px', '7px', '7px', '7px']}>
              <Flex direction="column" paddingTop="14px">
                {(mode === 'core' || (mode === 'advanced' && isSetup)) && (
                  <Flex flex={1} justifyContent="center" marginY="10px">
                    <SimpleStateButton
                      onClick={() => {
                        if (!teamsConfirmed || !isSetup) handleTeamsConfirmed()
                        if (setTeamsConfirmed && isSetup) setTeamsConfirmed(!teamsConfirmed)
                      }}
                      value={teamsConfirmed}
                      isActive={teamsConfirmed && isSetup}
                      isDisabled={
                        (!isSetup && !teamsUpdated) ||
                        (game?.getHomeTeam()?.matchPlayers.length || 0) < 11 ||
                        (game?.getAwayTeam?.matchPlayers.length || 0) < 11
                      }
                      width="200px"
                      isSwitch
                      data-testid="confirmTeamSelections"
                    >
                      {isSetup ? 'Confirm Team Selections' : 'Update Team Selections'}
                    </SimpleStateButton>
                  </Flex>
                )}
                <Flex direction="column">
                  <Box paddingTop="14px">
                    <SubHeading
                      text={game.getHomeTeam()?.name || ''}
                      secondary
                      buttonProps={{
                        text: 'Manage Team',
                        height: '22px',
                        onClick: () => handleManagePlayer(game.getHomeTeam()?.teamId),
                      }}
                    />
                    <TeamList
                      mode={mode}
                      game={game}
                      team={game.getHomeTeam()}
                      currentBall={currentBall}
                      records={recordsHome}
                      setRecords={setRecordsHome}
                      setTeamsUpdated={setTeamsUpdated}
                      isSetup={isSetup}
                    />
                  </Box>
                </Flex>
                <Flex direction="column" marginTop="37px">
                  <Box paddingTop="14px">
                    <SubHeading
                      text={game.getAwayTeam?.name || ''}
                      secondary
                      buttonProps={{
                        text: 'Manage Team',
                        height: '22px',
                        onClick: () => handleManagePlayer(game.getAwayTeam?.teamId),
                      }}
                    />
                    <TeamList
                      mode={mode}
                      game={game}
                      team={game.getAwayTeam}
                      currentBall={currentBall}
                      records={recordsAway}
                      setRecords={setRecordsAway}
                      setTeamsUpdated={setTeamsUpdated}
                      isSetup={isSetup}
                    />
                  </Box>
                </Flex>
              </Flex>
            </TabPanel>
            {mode !== 'fielding' && (
              <TabPanel padding="7px">
                <Flex
                  flex={1}
                  direction={mode === 'advanced' ? 'row' : ['column', 'column', 'row', 'row']}
                  paddingTop="14px"
                >
                  <Flex
                    flex={3}
                    direction="column"
                    paddingRight={mode === 'advanced' ? '21px' : ['0px', '0px', '21px', '21px']}
                  >
                    <Venue game={game} mode={mode} currentBall={currentBall} conditions={conditions} />
                  </Flex>
                  <Flex
                    flex={3}
                    direction="column"
                    paddingLeft={mode === 'advanced' ? '21px' : ['0px', '0px', '21px', '21px']}
                  >
                    <Officials
                      game={game}
                      mode={mode}
                      currentBall={currentBall}
                      onManageClick={handleManageOfficials}
                    />
                  </Flex>
                </Flex>
              </TabPanel>
            )}
            <TabPanel padding="7px">
              <Flex flex={1} direction="row" paddingTop="14px">
                <Flex flex={3} direction="column" paddingRight={mode === 'advanced' ? '21px' : undefined}>
                  <Toss
                    game={game}
                    isDisabled={!isSetup || (game.getInningCount() > 0 && (game.matchStatusId || 0) > 0)}
                    timelineEvents={timelineEvents}
                  />
                </Flex>
                <Flex flex={3} display={['none', 'none', 'flex', 'flex']} />
              </Flex>
            </TabPanel>
            {mode !== 'fielding' && (
              <TabPanel padding="7px">
                <Flex flex={1} direction={['column', 'column', 'row', 'row']} paddingTop="14px">
                  <Flex flex={3} direction="column" paddingRight={['0px', '0px', '21px', '21px']}>
                    <Pitch game={game} mode={mode} conditions={conditions} />
                  </Flex>
                  <Flex flex={3} direction="column" paddingLeft={['0px', '0px', '21px', '21px']}>
                    <Weather game={game} mode={mode} conditions={conditions} />
                  </Flex>
                </Flex>
              </TabPanel>
            )}
            <TabPanel padding="7px">
              <Flex flex={1} direction={['column', 'column', 'row', 'row']} paddingTop="14px">
                <Flex flex={3} direction="column" paddingRight={['0px', '0px', '21px', '21px']}>
                  <MatchSettings game={game} mode={mode} isSetup={isSetup} />
                  <Flex flex={1} justifyContent="center" marginY="10px">
                    <SimpleStateButton
                      onClick={() => (setSettingsConfirmed ? setSettingsConfirmed(!settingsConfirmed) : {})}
                      value={settingsConfirmed}
                      isActive={settingsConfirmed}
                      isDisabled={!isSetup}
                      width="200px"
                      isSwitch
                      data-testid="confirmMatchSettings"
                    >
                      Confirm Match Settings
                    </SimpleStateButton>
                  </Flex>
                  <Flex marginTop="21px" paddingBottom="14px" direction="column">
                    <SubHeading text="Download/View data" secondary />
                    <Flex direction="row">
                      <Button
                        marginRight="10px"
                        colorScheme="green"
                        onClick={() => downloadGame()}
                        data-testid="downloadGameDataButton"
                      >
                        Download Game
                      </Button>
                      <Button
                        marginRight="10px"
                        colorScheme="green"
                        onClick={() => downloadGame(true)}
                        data-testid="downloadShallowGameDataButton"
                      >
                        Download Game (Shallow)
                      </Button>
                      {!isScoringManually && (
                        <Button
                          marginRight="10px"
                          colorScheme="green"
                          onClick={() => downloadBalls()}
                          data-testid="downloadBallsDataButton"
                        >
                          Download Balls
                        </Button>
                      )}
                      <Button
                        marginRight="10px"
                        onClick={() => setIsS3PModalOpen(true)}
                        data-testid="viewS3PDataButton"
                      >
                        View S3P Payloads
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  flex={3}
                  direction="column"
                  paddingLeft={['0px', '0px', '21px', '21px']}
                  paddingTop={['21px', '21px', '0px', '0px']}
                >
                  {!isScoringManually && <AppSettings game={game} mode={mode} isSetup={isSetup} />}
                </Flex>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <PersonList
          data={data}
          existingData={existingData}
          isLoading={
            personListType === 'team' ? playersSearch.state === 'pending' : officialsSearch.state === 'pending'
          }
          props={personListProps}
          personListOpen={personListOpen}
          setPersonListOpen={setPersonListOpen}
          personListAdding={personListAdding}
          setPersonListAdding={setPersonListAdding}
          onAddPerson={handleAddPerson}
        />
        <S3PBrowser matchId={game.id} isOpen={isS3PModalOpen} setIsOpen={setIsS3PModalOpen} />
      </Flex>
    )
  }
)
