import { Box, Flex } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { indexOf, isNil } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { BallDescription } from '../../components/BallDescription/BallDescription'
import { BallSummary } from '../../components/BallDescription/BallSummary'
import BasicScoring from '../../components/BasicScoring/BasicScoring'
import { Button } from '../../components/Buttons/Button'
import { Checkbox } from '../../components/Checkbox/Checkbox'
import SubHeading from '../../components/Headings/SubHeading'
import { ShortScorecard } from '../../components/Scorecard/Short'
import * as Reference from '../../data/reference'
import type { InBallProps } from '../../types/props'

const InBallCore = observer(
  ({
    game,
    ball,
    lastBall,
    ballStore,
    timelineEvents,
    completeBall,
    resyncBall,
    cancelEditBall,
    editBall,
    editBallProps,
    deadBall,
    ballRunsVal,
    setBallRunsVal,
    ballExtrasVal,
    setBallExtrasVal,
    commentaryChanged,
    setCommentaryChanged,
    activeInning,
    handlePenaltyRuns,
    insertingBall,
    isPrimaryEditCheck,
    isDisabled,
  }: Omit<InBallProps, 'triggerEndOver' | 'mode'>) => {
    const allowedToBeEndOfOver: boolean =
      !ball.getExtraType || ball.getExtraType === 'LEG_BYE' || ball.getExtraType === 'BYE'
    const [endOver, setEndOver] = useState(
      ball.ballDisplayNumber
        ? ball.ballDisplayNumber > (game.matchConfigs.ballsPerOver || 6) - 1 && allowedToBeEndOfOver
        : false
    )

    const wicketOrReviewAreIncomplete: boolean =
      (ball.pendingDismissal &&
        (!ball.dismissal || ball.dismissal.dismissalTypeId === null || isNil(ball.dismissal?.batterMp))) ||
      (ball.pendingReview &&
        (!ball.review ||
          ball.review.dismissalTypeId === null ||
          (ball.review.originalDecision === null &&
            indexOf(
              ball.review.reviewSourceTypeId === 0
                ? Reference.DismissalMethodsNoOrigDecisionUmpire
                : Reference.DismissalMethodsNoOrigDecisionPlayer,
              Reference.DismissalMethods[ball.review.dismissalTypeId]
            ) === -1) ||
          (ball.review.finalDecision === null && ball.review.drsUmpiresCall === null)))

    useEffect(() => {
      setEndOver(
        ball.ballDisplayNumber
          ? ball.ballDisplayNumber > (game.matchConfigs.ballsPerOver || 6) - 1 && allowedToBeEndOfOver
          : false
      )
    }, [ball.ballDisplayNumber, allowedToBeEndOfOver, game.matchConfigs.ballsPerOver])

    return (
      <Flex flex={1} w="100%" direction={['column', 'column', 'row', 'row']}>
        <Flex flex={2} direction="column" w="100%">
          <Box paddingX="7px" marginRight={['7px', '7px', '0', '0']}>
            <ShortScorecard
              inning={activeInning}
              game={game}
              mode="core"
              ball={ball}
              handlePenaltyRuns={handlePenaltyRuns}
              isDisabled={isDisabled}
              editBall={editBall ? true : false}
              scoresOnly
            />
          </Box>
          <Box
            position="relative"
            backgroundColor="white"
            borderRadius="7px"
            margin={['7px 0 0', '7px 0 0', '7px 7px 0', '14px 7px 0']}
          >
            <Flex
              flex={1}
              direction="column"
              justifyContent={['center', 'center', 'center', 'space-between']}
              padding={['7px 7px', '7px 14px', '7px 21px 4px', '7px 21px 4px']}
              borderBottomColor="cls.backgroundGray"
              borderBottomWidth="4px"
            >
              <Flex flex={1} direction={['column', 'column', 'column', 'row']}>
                <Flex
                  flex={0.75}
                  display={['none', 'block', 'block', 'block']}
                  justifyContent="center"
                  alignItems="center"
                  paddingTop={['7px', '7px', '7px', '10px']}
                >
                  <SubHeading
                    text={`${editBall ? 'Edit Ball' : insertingBall ? 'Insert Ball' : 'Current Ball'} (over ${
                      editBall ? editBall.overNumber : ball.overNumber
                    }.${editBall ? editBall.ballDisplayNumber : ball.ballDisplayNumber})`}
                    secondary
                    bold
                  />
                </Flex>
                <Flex
                  flex={1.25}
                  justifyContent={['center', 'flex-start', 'flex-start', 'flex-end']}
                  alignItems="center"
                  padding="4px 0 7px"
                >
                  <BallDescription
                    mode="core"
                    ball={ball}
                    ballStore={ballStore}
                    game={game}
                    editBall={editBall ? true : false}
                    isNewestBall={editBallProps?.isNewestBall}
                    preBall={false}
                    timelineEvents={timelineEvents}
                    isDisabled={isDisabled}
                  />
                </Flex>
              </Flex>
              {!isNil(commentaryChanged) && setCommentaryChanged && (
                <Flex flex={1} padding="4px 0 10px">
                  <BallSummary
                    mode="core"
                    game={game}
                    ball={ball}
                    lastBall={editBall ? ball.getBallBefore(ball) : lastBall}
                    editBall={editBall ? true : false}
                    commentaryChanged={commentaryChanged}
                    setCommentaryChanged={setCommentaryChanged}
                    isDisabled={isDisabled}
                    enabled
                    detailed
                  />
                </Flex>
              )}
            </Flex>
            <Flex
              direction="column"
              alignItems="center"
              margin="7px 7px 0"
              padding={['4px 7px 0px', '4px 7px 0px', '4px 14px 0px', '4px 14px 0px']}
            >
              <BasicScoring
                ball={ball}
                game={game}
                mode="core"
                editBall={editBall ? true : false}
                insertBall={insertingBall}
                ballRunsVal={ballRunsVal}
                setBallRunsVal={setBallRunsVal}
                ballExtrasVal={ballExtrasVal}
                setBallExtrasVal={setBallExtrasVal}
                isPrimaryEditCheck={isPrimaryEditCheck}
              />
            </Flex>
            <Flex
              position="sticky"
              bottom="0%"
              left="0%"
              backgroundColor="white"
              borderBottomLeftRadius="7px"
              borderBottomRightRadius="7px"
              margin="0px 7px 7px"
              padding={['0px 7px 7px', '0px 7px 7px', '0px 14px 7px', '0px 14px 7px']}
            >
              <Flex flex={1.5}>
                {editBall || insertingBall ? (
                  <Button
                    w="100%"
                    h="55px"
                    colorScheme="gray"
                    onClick={() => {
                      if (cancelEditBall) cancelEditBall(endOver)
                    }}
                    data-testid="cancelEditBallButton"
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button w="100%" h="55px" colorScheme="gray" onClick={deadBall} data-testid="deadBallButton">
                    Dead
                    <br />
                    Ball
                  </Button>
                )}
              </Flex>
              <Flex flex={3} flexDirection="row" paddingLeft="7px">
                {editBall && (
                  <Flex flex={0.75}>
                    <Button
                      w="100%"
                      h="55px"
                      colorScheme="teal"
                      onClick={() => resyncBall()}
                      data-testid="resyncBallDataButton"
                    >
                      <FontAwesomeIcon icon={['far', 'cloud-upload']} size="sm" style={{ fontSize: '16px' }} />
                    </Button>
                  </Flex>
                )}
                <Flex flex={2} marginLeft={editBall ? '7px' : '0px'}>
                  <Button
                    h="55px"
                    w="100%"
                    fontSize="lg"
                    colorScheme="cls.blue"
                    onClick={() => completeBall(editBall ? ball.endOfOver : endOver)}
                    isDisabled={wicketOrReviewAreIncomplete}
                    data-testid="doneConfirmBallButton"
                  >
                    {editBall ? 'Done' : 'Confirm'}
                  </Button>
                </Flex>
                {ball &&
                  ball.ballDisplayNumber &&
                  ball.ballDisplayNumber > (game.matchConfigs.ballsPerOver || 6) - 1 &&
                  !editBall && (
                    <Flex flex={1} justify="flex-end" marginLeft="7px">
                      <Checkbox
                        colorScheme="cls.yellow"
                        isChecked={endOver ? true : false}
                        isDisabled={!allowedToBeEndOfOver}
                        onChange={() => setEndOver(!endOver)}
                        data-testid="endOverCheckbox"
                      >
                        End Over
                      </Checkbox>
                    </Flex>
                  )}
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    )
  }
)

export default InBallCore
