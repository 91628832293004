import { Flex } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

import { InGameHeader } from '../../components/Headers/InGameHeader'
import type { InGameProps } from '../../types/props'
import { socketConnectionColors } from '../../types/props'

const InGame = ({ children, socketStatus }: InGameProps) => {
  const location = useLocation()
  const isPreGame = location.pathname.search('setup') > -1
  const isPreInnings = location.pathname.search('inning-') > -1

  return (
    <Flex direction="column" w="100vw" minH="100vh">
      <Flex backgroundColor={socketConnectionColors[socketStatus]} h="42px" w="100%" justify="center" align="center">
        <InGameHeader mode="advanced" preGame={isPreGame} preInnings={isPreInnings} />
      </Flex>
      {children}
    </Flex>
  )
}

export default InGame
