import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  useToast,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { find, isNil } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'

import { db } from '../../data/dexie/Database'
import { InningEndOptions, NewBallTypes } from '../../data/reference'
import { useMst } from '../../data/stores/rootStore'
import { timeMachineDate } from '../../helpers/generalHelpers'
import MatchHelpers from '../../helpers/matchHelpers'
import S3PHelpers from '../../helpers/s3pHelpers'
import Theme from '../../theme/theme'
import type { DropdownCallbackArgs, DropdownOption, PowerPlayInterface } from '../../types'
import type { IMatchSettingsModel } from '../../types/models'
import type { ControlsPanelProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import { CustomButton } from '../Buttons/CustomButton'
import { Dls } from '../Dls/Dls'
import Dropdown from '../Dropdown/Dropdown'
import { EndMatch } from '../EndMatch/EndMatch'
import MatchBreak from './MatchBreak'
import Metric from './Metric'

const ControlsPanel = observer(
  ({
    game,
    currentBall,
    setCurrentBall,
    mode,
    inning,
    timelineEvents,
    inningsInOrder,
    isDisabled,
    isSelectPlayer,
    editBall,
    cancelEditBall,
    currentInning,
    setCurrentInning,
    closedInning,
    setClosedInning,
    closeEditBallMode,
    setEndOver,
    setInBall,
  }: ControlsPanelProps) => {
    const { fieldingPlacements, appSettings, balls } = useMst()
    const matchSettings: IMatchSettingsModel = appSettings.getMatchSettings(game.id)

    const [closeInningType, setCloseInningType] = useState<string | null>(null)
    const [inningAlertOpen, setInningAlertOpen] = useState(false)
    const [dlsOpen, setDlsOpen] = useState(false)
    const cancelRef = useRef(null)
    const toast = useToast()
    const isLimitedOvers = game.isLimitedOversMatch
    const isEndOfMatch = !isNil(game.matchStatusId) && game.matchStatusId > 2
    const metrics = isLimitedOvers
      ? game.getInningCount() > 1 && game.getInningCount() % 2 === 0
        ? !inning?.superOver && game.matchDls?.targetScore
          ? ['target', 'runs_req', 'req_run_rate', 'overs_rem'] // 2nd innings of limited overs game (DLS)
          : ['runs_req', 'req_run_rate', 'last_5', 'overs_rem'] // 2nd innings or 2nd super over of limited overs game (no DLS)
        : ['run_rate', 'last_5', 'overs_rem', 'projected'] // 1st innings or 1st super over of limited overs game
      : ['run_rate', 'last_5', 'run_rate_last_5', 'new_ball_in'] // Any innings in a non-limited overs game

    const inningEndOptions: DropdownOption[] = InningEndOptions.map(endType => {
      return { value: endType }
    })

    const changeNewBall = ({ value, clearValue }: DropdownCallbackArgs) => {
      if (clearValue) {
        currentBall?.setNewBallTaken(null)
        return
      }

      if (value && inning) {
        currentBall?.setNewBallTaken(value)
        timelineEvents?.addNewBall(
          value.replace(/_/g, ' '),
          inning.inningsMatchOrder,
          appSettings.timeMachine.baseline,
          appSettings.timeMachine.activated
        )
        if (mode === 'core') {
          db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.newBall(value, game))
        }
      }
    }
    const newBallOptions: DropdownOption[] = NewBallTypes.map(newBallType => {
      return { value: newBallType }
    })
    const newBallDefault = 'New ball'
    const activeNewBall =
      currentBall && currentBall.newBallTakenId !== null && currentBall.newBallTakenId !== undefined
        ? NewBallTypes[currentBall.newBallTakenId]
        : newBallDefault

    const changePowerPlay = ({ value, clearValue }: DropdownCallbackArgs) => {
      let activePowerPlay
      if (inning) {
        if (clearValue) {
          // Turn OFF powerplay
          activePowerPlay = inning.getActivePowerPlay
          if (activePowerPlay) {
            // if manually clearing a power play, turn it off for both the inning AND the current ball
            inning.updateActivePowerPlay(null)
            currentBall?.setPowerPlay(null)

            timelineEvents?.addPowerPlayEnd(
              `${activePowerPlay.getDescription?.replace(/_/g, ' ')} complete`,
              activePowerPlay,
              inning.inningsMatchOrder,
              appSettings.timeMachine.baseline,
              appSettings.timeMachine.activated
            )
          }
        } else {
          // Turn ON powerplay
          inning.updateActivePowerPlay(value)
          activePowerPlay = inning.getActivePowerPlay
          if (activePowerPlay) {
            timelineEvents?.addPowerPlayStart(
              `${activePowerPlay.getDescription?.replace(/_/g, ' ')} starting`,
              activePowerPlay,
              inning.inningsMatchOrder,
              appSettings.timeMachine.baseline,
              appSettings.timeMachine.activated
            )
          }
        }
      }
    }
    const powerPlayDefault = 'Power Play'
    const activePowerPlay = inning?.getActivePowerPlay?.getDescription || powerPlayDefault
    const powerPlayTriggers = MatchHelpers.getPowerPlayTriggers(game)
    const getPowerPlayOptions = () => {
      if (!powerPlayTriggers) return []
      const latestPowerPlay = inning?.powerPlays ? inning.powerPlays[inning.powerPlays.length - 1] : null
      let filteredIndex = -1
      if (latestPowerPlay)
        filteredIndex = powerPlayTriggers.findIndex(
          (ppt: PowerPlayInterface) => ppt.powerPlay === latestPowerPlay.getDescription
        )
      return powerPlayTriggers.map((ppType: PowerPlayInterface, idx: number) => {
        return {
          value: ppType.powerPlay,
          label: ppType.powerPlay,
          disabled: filteredIndex !== -1 && idx <= filteredIndex,
        }
      })
    }

    const handleLikelyRainDelay = () => {
      const isActive: boolean = game.likelyRainDelay || false
      toast({
        title: `Rain Delay ${isActive ? 'NOT LIKELY' : 'LIKELY'}`,
        status: 'info',
        duration: Theme.toast.long,
        isClosable: true,
      })
      game.setLikelyRainDelay(!isActive)
    }

    const handleEndInning = ({ value }: { value: string }) => {
      setInningAlertOpen(true)
      setCloseInningType(value)
    }

    const handleEndInningConfirmation = () => {
      if (closeInningType) {
        setCurrentBall(undefined)
        if (setEndOver) setEndOver(false)
        if (setInBall) setInBall(false)
        if (closeEditBallMode) closeEditBallMode()
        game.setLatestBallId(null)
        const activeInning = game.getActiveInning()

        if (activeInning) {
          timelineEvents?.addInningEnd(
            closeInningType.replace(/_/g, ' '),
            activeInning.inningsMatchOrder,
            appSettings.timeMachine.baseline,
            appSettings.timeMachine.activated
          )
          if (mode === 'core') {
            db.createS3PMessage(
              S3PHelpers.metadata(appSettings.appMode, game),
              S3PHelpers.inningsState(mode, activeInning, game, balls, closeInningType)
            )
            db.createS3PMessage(
              S3PHelpers.metadata(appSettings.appMode, game),
              S3PHelpers.inningsStatePartnerships(activeInning, game.matchConfigs.ballsPerOver)
            )
            db.createS3PMessage(
              S3PHelpers.metadata(appSettings.appMode, game),
              S3PHelpers.inningsStateSpells(mode, activeInning, game.matchConfigs.ballsPerOver)
            )
            db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.innings('FINISH', game, closeInningType))
          }
        }

        const newestBall = activeInning && balls.getNewestBall(activeInning.id)
        game.endInning(
          closeInningType,
          newestBall,
          appSettings.timeMachine.baseline && appSettings.timeMachine.activated
            ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
            : undefined
        )
        if (mode === 'core' && activeInning && MatchHelpers.checkIfInningsBreakStatusRequired(game, activeInning)) {
          // if we are now in an innings break, compose an S3P message
          db.createS3PMessage(
            S3PHelpers.metadata(appSettings.appMode, game),
            S3PHelpers.matchBreak('START', game.getActiveMatchBreak?.getMatchBreakType || null, game)
          )
        }
        if (setClosedInning) setClosedInning(true)
        if (mode === 'fielding') {
          fieldingPlacements.resetFields()
          fieldingPlacements.createDefaultField(game.id)
          matchSettings.setActiveFieldID('default')
        }
      }
    }

    const handleNextInnings = () => {
      if (setClosedInning) setClosedInning(false)
      setCurrentBall(undefined)
      if (closeEditBallMode) {
        closeEditBallMode()
      }
    }

    const closeAlert = () => {
      setInningAlertOpen(false)
      setCloseInningType(null)
    }

    const changeInnings = ({ value }: DropdownCallbackArgs) => {
      if (!setCurrentInning || !value) return
      const selectedInning = game.getInningById(value)
      if (selectedInning) {
        if (editBall && cancelEditBall) cancelEditBall()
        setCurrentInning({
          id: value,
          value: MatchHelpers.getInningsLabel(selectedInning, selectedInning.getBattingTeam.shortName),
          isActiveInning: find(inningsInOrder, { isActiveInning: true })?.value === value,
          inning: selectedInning,
        })
      }
    }

    return (
      <>
        {mode === 'advanced' && (
          <Flex minW="280px" height="160px" flexDirection="column" justifyContent="center">
            {!closedInning && (
              <Flex flex={1} padding="7px 14px 0px">
                {metrics.map((metric: string) => (
                  <Metric key={`outer_metric_${metric}`} game={game} id={metric} />
                ))}
              </Flex>
            )}
            {closedInning && setClosedInning && !isEndOfMatch && (
              <Flex flex={1} padding="14px 14px 0px 0px">
                <Button data-testid="nextInningsButton" w="100%" colorScheme="green" onClick={handleNextInnings} ml={3}>
                  Next Innings
                </Button>
              </Flex>
            )}
            {(!closedInning || (closedInning && !isEndOfMatch)) && (
              <Flex flex={1} marginX="14px">
                {isLimitedOvers && !closedInning && (
                  <Flex flex={1} align="flex-end">
                    <Dropdown
                      options={getPowerPlayOptions()}
                      value={activePowerPlay}
                      origValue={powerPlayDefault}
                      onChange={changePowerPlay}
                      placement="bottom"
                      globalDisable={activePowerPlay !== powerPlayDefault || isDisabled}
                      isClearable
                      isWhite
                      hideCheck
                      data-testid="powerPlayDropdown"
                    />
                  </Flex>
                )}
                {!closedInning && (
                  <Flex flex={1} align="flex-end" paddingLeft={isLimitedOvers ? '4px' : '0px'}>
                    <Dropdown
                      options={newBallOptions}
                      value={activeNewBall}
                      origValue={newBallDefault}
                      onChange={changeNewBall}
                      placement="bottom"
                      globalDisable={activeNewBall !== newBallDefault || isDisabled}
                      isClearable
                      isWhite
                      hideCheck
                      data-testid="newBallDropdown"
                    />
                  </Flex>
                )}
                <Flex flex={1} align="flex-end" paddingLeft={!closedInning ? '4px' : '0px'}>
                  <MatchBreak
                    game={game}
                    timelineEvents={timelineEvents}
                    truncateButton={!closedInning ? (isLimitedOvers ? 5 : 10) : undefined}
                    isWhite
                  />
                </Flex>
              </Flex>
            )}
            <Flex
              flex={1}
              margin={closedInning && isEndOfMatch ? '0px 14px' : '7px 14px 0'}
              alignItems={closedInning && isEndOfMatch ? 'center' : undefined}
            >
              {(!closedInning || (closedInning && !isEndOfMatch)) && (
                <Flex flex={isLimitedOvers ? 0.5 : 1}>
                  <CustomButton
                    isActive={game.likelyRainDelay || false}
                    isDisabled={game.getActiveMatchBreak?.getMatchBreakType === 'RAIN_DELAY'}
                    onClick={() => handleLikelyRainDelay()}
                    width="100%"
                    paddingX="8px"
                    paddingXOuter="2px"
                    hideCheck
                    isFlex
                    isWhite
                    data-testid="likelyRainDelay"
                  >
                    <FontAwesomeIcon icon={['far', 'cloud-drizzle']} />
                  </CustomButton>
                </Flex>
              )}
              {isLimitedOvers && (
                <Flex paddingLeft="4px" flex={0.5} align="flex-start">
                  <CustomButton
                    isActive={game.matchDls?.active}
                    onClick={() => {
                      setDlsOpen(true)
                    }}
                    width="100%"
                    paddingX="8px"
                    paddingXOuter="4px"
                    isFlex
                    isWhite
                    hideCheck
                    data-testid="setDlsOpen"
                  >
                    DLS
                  </CustomButton>
                </Flex>
              )}
              {!closedInning && (
                <Flex paddingLeft="4px" flex={1} align="flex-start">
                  <Dropdown
                    options={inningEndOptions}
                    onChange={({ value }) => {
                      if (value) handleEndInning({ value })
                    }}
                    value="End Inn."
                    isWhite
                    ignoreState
                    placement="bottom"
                    data-testid="endInningDropdown"
                  />
                </Flex>
              )}
              <Flex paddingLeft="4px" flex={1} align="flex-start">
                <EndMatch game={game} mode={mode} height="32px" timelineEvents={timelineEvents} isWhite />
              </Flex>
            </Flex>
          </Flex>
        )}
        {mode !== 'advanced' && (
          <Flex w="100%" direction="column">
            <Flex flex={1} flexDirection="row" justifyContent="center" alignItems="center">
              {mode === 'core' && (
                <>
                  <Flex flex={[0.5, 0.5, 0.5, 0.75]}>
                    <CustomButton
                      isActive={game.likelyRainDelay || false}
                      isDisabled={game.getActiveMatchBreak?.getMatchBreakType === 'RAIN_DELAY'}
                      onClick={() => handleLikelyRainDelay()}
                      width="100%"
                      paddingX="8px"
                      paddingXOuter="2px"
                      hideCheck
                      isFlex
                      data-testid="likelyRainDelay"
                    >
                      <FontAwesomeIcon icon={['far', 'cloud-drizzle']} />
                    </CustomButton>
                  </Flex>
                  {isLimitedOvers && (
                    <>
                      <Flex flex={[0.5, 0.5, 0.5, 0.75]} marginLeft="4px">
                        <CustomButton
                          isActive={game.matchDls?.active}
                          onClick={() => {
                            setDlsOpen(true)
                          }}
                          width="100%"
                          paddingX="8px"
                          paddingXOuter="4px"
                          isFlex
                          hideCheck
                          data-testid="setDlsOpen"
                        >
                          DLS
                        </CustomButton>
                      </Flex>
                      {!closedInning && (
                        <Flex flex={1} marginLeft="4px">
                          <Dropdown
                            options={getPowerPlayOptions()}
                            value={activePowerPlay}
                            origValue={powerPlayDefault}
                            onChange={changePowerPlay}
                            placement="bottom"
                            globalDisable={activePowerPlay !== powerPlayDefault || isDisabled}
                            isClearable
                            hideCheck
                            data-testid="powerPlayDropdown"
                          />
                        </Flex>
                      )}
                    </>
                  )}
                  <Flex flex={1} marginLeft="4px">
                    <MatchBreak
                      game={game}
                      timelineEvents={timelineEvents}
                      truncateButton={10}
                      placement={isSelectPlayer ? 'top' : 'auto'}
                    />
                  </Flex>
                </>
              )}
              {mode === 'fielding' && currentInning && inningsInOrder && inningsInOrder.length > 0 && (
                <Flex flex={1}>
                  <Dropdown
                    target="current_innings"
                    options={inningsInOrder}
                    value={currentInning.value}
                    onChange={changeInnings}
                    ignoreState
                    isWhite
                    preserveCase
                    data-testid="currentInningsDropdown"
                  />
                </Flex>
              )}
            </Flex>
            <Flex flex={1} marginTop="4px" flexDirection="row" justifyContent="center" alignItems="center">
              {mode === 'core' && !closedInning && (
                <Flex flex={1} marginLeft="0px">
                  <Dropdown
                    options={newBallOptions}
                    value={activeNewBall}
                    origValue={newBallDefault}
                    onChange={changeNewBall}
                    placement={isSelectPlayer ? 'top' : 'auto'}
                    globalDisable={activeNewBall !== newBallDefault || isDisabled || isSelectPlayer}
                    isClearable
                    hideCheck
                    data-testid="newBallDropdown"
                  />
                </Flex>
              )}
              {!isEndOfMatch && (
                <Flex flex={1}>
                  {mode === 'core' && (
                    <>
                      {closedInning && setClosedInning ? (
                        <Flex flex={1}>
                          <Button
                            data-testid="nextInningsButton"
                            w="100%"
                            h="34px"
                            colorScheme="green"
                            onClick={handleNextInnings}
                          >
                            Next Innings
                          </Button>
                        </Flex>
                      ) : (
                        <Flex flex={1} marginLeft="4px">
                          <Dropdown
                            options={inningEndOptions}
                            onChange={({ value }) => {
                              if (value) handleEndInning({ value })
                            }}
                            value="End Inn."
                            ignoreState
                            placement={isSelectPlayer ? 'top' : 'auto'}
                            data-testid="endInningDropdown"
                          />
                        </Flex>
                      )}
                    </>
                  )}
                  {mode === 'fielding' && (
                    <>
                      {closedInning && setClosedInning ? (
                        <Button
                          data-testid="nextInningsButton"
                          w="100%"
                          h="34px"
                          colorScheme="green"
                          onClick={handleNextInnings}
                        >
                          Next Inn.
                        </Button>
                      ) : (
                        <CustomButton
                          onClick={() => {
                            handleEndInning({ value: 'COMPULSORY_CLOSE' })
                          }}
                          width="100%"
                          paddingX="8px"
                          paddingXOuter="4px"
                          isFlex
                          isWhite
                          hideCheck
                          data-testid="endInningsCompulsoryClose"
                        >
                          End Inn.
                        </CustomButton>
                      )}
                    </>
                  )}
                </Flex>
              )}
              <Flex flex={1} marginLeft={isEndOfMatch ? '0px' : '4px'}>
                <EndMatch
                  game={game}
                  mode={mode}
                  height="32px"
                  isSelectPlayer={isSelectPlayer}
                  isWhite={mode === 'fielding'}
                />
              </Flex>
            </Flex>
          </Flex>
        )}
        <AlertDialog
          isOpen={inningAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={closeAlert}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          isCentered
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              End Innings
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you wish to end the innings? You cannot undo this action.</AlertDialogBody>

            <AlertDialogFooter>
              <Button data-testid="cancelAlertDialogButton" onClick={closeAlert}>
                Cancel
              </Button>
              <Button
                data-testid="endInningsConfirmationButton"
                colorScheme="red"
                onClick={handleEndInningConfirmation}
                ml={3}
                textTransform="capitalize"
              >
                End Innings ({closeInningType?.replace(/_/g, ' ').toLowerCase()})
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        {mode !== 'fielding' && (
          <Dls
            game={game}
            inningsInOrder={inningsInOrder}
            dlsOpen={dlsOpen}
            setDlsOpen={setDlsOpen}
            inSuperOver={inning?.superOver || false}
          />
        )}
      </>
    )
  }
)

export default ControlsPanel
