import { Flex, Text } from '@chakra-ui/react'
import type { IInningModel, IMatchPlayerModel } from '@clsplus/cls-plus-data-models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '../components/Buttons/Button'
import { useMst } from '../data/stores/rootStore'
import { timeMachineDate } from '../helpers/generalHelpers'
import type { BattingSetupProps } from '../types/props'
import SelectPlayer from './SelectPlayer'

// This screen has 2 responsibilities
// 1: create an inning if needed
// 2: create 2 batting perfs in that inning
const BattingSetup = observer(({ mode }: BattingSetupProps) => {
  const navigate = useNavigate()
  const { detailedMatches, timelineEvents, appSettings } = useMst()
  const { id } = useParams()

  if (!id) {
    navigate('/')
    return null
  }

  detailedMatches.getMatch(id, mode)
  const game = detailedMatches.results.get(id)?.match
  timelineEvents.getEvents(id, mode, false, false)
  const gameTimelineEvents = timelineEvents.results.get(id)

  if (game?.tossDecisionId === null) {
    navigate('/')
  }

  const activeInning: IInningModel | undefined = game?.getActiveInning()

  const handlePlayerSelected = (player: IMatchPlayerModel) => {
    if (activeInning) {
      activeInning.newBatter(
        player,
        appSettings.timeMachine.baseline && appSettings.timeMachine.activated
          ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
          : undefined
      )
      gameTimelineEvents?.addNewBatter(
        player,
        activeInning.inningsMatchOrder,
        appSettings.timeMachine.baseline,
        appSettings.timeMachine.activated
      )
      if (activeInning.battingPerformances.length === 2) {
        navigate(`/game/${id}/${mode}/inning-bowling-setup`)
      }
    }
  }

  const handleGoBack = () => {
    // this handler delete's the latest inning, and either redirects back to setup or betweenInning
    game?.removeLatestInning()
    if (game?.getAllInningInOrder.length) {
      navigate(`/game/${id}/${mode}/scoring`)
    } else {
      navigate(`/game/${id}/${mode}/setup`)
    }
  }

  useEffect(() => {
    if (!activeInning) {
      // create an inning
      const teamBattingId: string | undefined = game?.getBattingTeamIdAfterToss
      if (teamBattingId)
        game?.createInning(
          teamBattingId,
          false,
          false,
          undefined,
          undefined,
          appSettings.timeMachine.baseline && appSettings.timeMachine.activated
            ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
            : undefined
        )
    }
  }, [activeInning, game, appSettings.timeMachine.baseline, appSettings.timeMachine.activated])

  if (activeInning) {
    const players = game?.getEligibleBattingPlayersInBattingOrder()
    return (
      <Flex direction="column" w="100%" alignItems="center" padding={mode === 'core' ? '14px' : '28px 14px 14px'}>
        <Flex w="100%" maxW={['100%', '100%', 750, 750]} direction="column">
          <Flex w="100%" direction="row">
            <Flex paddingX="7px">
              <Button colorScheme="teal" paddingX="7px" onClick={handleGoBack}>
                <FontAwesomeIcon
                  icon={['fas', 'arrow-left']}
                  size="sm"
                  style={{ marginRight: '4px', fontSize: '10px' }}
                  data-testid="battingSetupBackButton"
                />
                Back
              </Button>
            </Flex>
            <Flex marginLeft="14px">
              <Text
                textTransform="uppercase"
                fontWeight={900}
                fontSize="xx-large"
                fontStyle="italic"
                letterSpacing="-0.5px"
                lineHeight="40px"
                padding={'0px 7px 14px'}
              >
                <FontAwesomeIcon
                  icon={['fal', 'cricket']}
                  size="sm"
                  style={{
                    marginRight: '14px',
                    fontSize: '22px',
                    verticalAlign: 'middle',
                  }}
                />
                {activeInning.getBattingTeamName}
              </Text>
            </Flex>
          </Flex>
          <SelectPlayer
            title={activeInning.battingPerformances.length === 0 ? 'Select first batter' : 'Select second batter'}
            primaryButton="Next"
            players={players}
            onChange={handlePlayerSelected}
            mode={mode}
            setup={true}
          />
        </Flex>
      </Flex>
    )
  }

  return <Text>Batting setup</Text>
})

export default BattingSetup
