import { observer } from 'mobx-react-lite'

import { db } from '../../data/dexie/Database'
import { BreakTypes } from '../../data/reference'
import { useMst } from '../../data/stores/rootStore'
import { timeMachineDate } from '../../helpers/generalHelpers'
import S3PHelpers from '../../helpers/s3pHelpers'
import type { DropdownCallbackArgs, DropdownOption } from '../../types'
import type { MatchBreakProps } from '../../types/props'
import Dropdown from '../Dropdown/Dropdown'

const MatchBreak = observer(
  ({
    game,
    timelineEvents,
    placement = 'bottom',
    truncateButton,
    isWhite = false,
    height,
    defaultValue = 'Break',
  }: MatchBreakProps) => {
    const { appSettings } = useMst()
    const breakOptions: DropdownOption[] = BreakTypes.filter(breakType => breakType !== 'INNINGS_BREAK').map(
      breakType => {
        return { value: breakType }
      }
    )
    const breakDefault = defaultValue
    const activeBreak = game.getActiveMatchBreak?.getMatchBreakType || breakDefault
    const activeInning = game.getActiveInning()

    const changeMatchBreak = ({ value, clearValue }: DropdownCallbackArgs) => {
      if (clearValue) {
        const activeBreak = game.getActiveMatchBreak
        if (activeBreak) {
          timelineEvents?.addBreakEnd(
            `${activeBreak.getMatchBreakType?.replace(/_/g, ' ')} complete`,
            activeBreak,
            activeInning?.inningsMatchOrder ?? null,
            appSettings.timeMachine.baseline,
            appSettings.timeMachine.activated
          )
          if ((appSettings.appMode === 'core' || appSettings.manualScoring.active) && activeBreak.getMatchBreakType) {
            db.createS3PMessage(
              S3PHelpers.metadata(appSettings.appMode, game),
              S3PHelpers.matchBreak('STOP', activeBreak.getMatchBreakType || null, game)
            )
            if (activeBreak.getMatchBreakType === 'STUMPS') {
              db.createS3PMessage(
                S3PHelpers.metadata(appSettings.appMode, game),
                S3PHelpers.matchStatus('IN_PROGRESS_PLAYING', game)
              )
            }
          }
        }
      }
      game.updateMatchBreak(
        !clearValue ? value : null,
        false,
        appSettings.timeMachine.baseline && appSettings.timeMachine.activated
          ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
          : undefined
      )
      if (!clearValue) {
        const activeBreak = game.getActiveMatchBreak
        if (activeBreak) {
          timelineEvents?.addBreakStart(
            `${activeBreak.getMatchBreakType?.replace(/_/g, ' ')} starting`,
            activeBreak,
            activeInning?.inningsMatchOrder ?? null,
            appSettings.timeMachine.baseline,
            appSettings.timeMachine.activated
          )
          if ((appSettings.appMode === 'core' || appSettings.manualScoring.active) && activeBreak.getMatchBreakType) {
            db.createS3PMessage(
              S3PHelpers.metadata(appSettings.appMode, game),
              S3PHelpers.matchBreak('START', activeBreak.getMatchBreakType || null, game)
            )
            if (activeBreak.getMatchBreakType === 'STUMPS') {
              db.createS3PMessage(
                S3PHelpers.metadata(appSettings.appMode, game),
                S3PHelpers.matchStatus('IN_PROGRESS_IN_BREAK', game, true)
              )
            }
          }
        }
      }
    }

    return (
      <Dropdown
        options={breakOptions}
        value={activeBreak}
        origValue={breakDefault}
        height={height ? height : undefined}
        onChange={changeMatchBreak}
        placement={placement}
        globalDisable={activeBreak !== breakDefault}
        truncateButton={truncateButton}
        isClearable
        isWhite={isWhite}
        hideCheck
        data-testid="matchBreakDropdown"
      />
    )
  }
)

export default MatchBreak
