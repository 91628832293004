import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import { BowlerApproachOptions } from '../../data/reference'
import type { BowlerApproachProps } from '../../types/props'

export const BowlerApproach = observer(({ mode, ball, isDisabled }: BowlerApproachProps) => {
  return (
    <Menu placement={mode === 'advanced' ? 'right-start' : 'bottom-end'}>
      <MenuButton
        as={Button}
        boxShadow="cls.light"
        color="cls.black"
        textTransform="capitalize"
        marginRight={mode === 'advanced' ? '7px' : '0px'}
        size="sm"
        fontSize="md"
        disabled={isDisabled}
      >
        {ball.bowlingAnalysis?.bowlerBallApproachId !== null &&
          BowlerApproachOptions[ball.bowlingAnalysis?.bowlerBallApproachId || 0]?.replace('_', ' ').toLowerCase()}{' '}
        the wicket
      </MenuButton>
      <MenuList zIndex={20} borderColor="gray.200">
        {BowlerApproachOptions.map(type => {
          const typeName = type.replace('_', ' ').toLowerCase()
          return (
            <MenuItem key={typeName} textTransform="capitalize" onClick={() => ball.setBowlerApproach(type)}>
              {typeName}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
})
