import type { SnapshotIn } from 'mobx-state-tree'
import { destroy, flow, getRoot, types } from 'mobx-state-tree'

import Auth from '../../../../helpers/auth'
import { formatSocketMessage, matchDataCleaner } from '../../../../helpers/dataHelpers'
import type { IClspMatchModel } from '../../../../types/models'
import type { ClspMode } from '../../../../types/props'
import { RequestHandler } from '../../../api/RequestHandler'
import { db } from '../../../dexie/Database'
import type { IRootStore } from '../../rootStore'
import MatchMapModel from './MatchMapModel'

const MatchStore = types
  .model('DetailedMatchStore', {
    results: types.map(MatchMapModel),
    state: types.enumeration('State', ['pending', 'done', 'error']),
  })
  .actions(self => {
    const getMatch = async (matchId: string, mode: ClspMode | 'postMatch', force = false) => {
      const data = self.results.get(matchId)
      if (data && !force) {
        return data
      }
      if (force) {
        // set socket force back to false, destroy any reference
        try {
          const root = getRootStore()
          root.localMatches.removeLocalMatch(matchId)
          root.socketStore.setDataForceReloadMatch(false)
          if (data) destroy(data)
        } catch {
          // eslint-disable-next-line
          console.error('unable to get parent of MatchStore')
        }
        return fetchMatch(matchId, mode)
      }
      if (self.state === 'pending') {
        return null
      }
      return fetchMatch(matchId, mode)
    }
    const fetchMatch = flow(function* fetchMatch(matchId: string, mode: ClspMode | 'postMatch') {
      self.state = 'pending'
      // matches from API
      const tokens = Auth.getTokens()
      const method = 'GET'
      const url = `${import.meta.env.VITE_API_URL}match/${matchId}?mode=${mode}`
      const params = {}
      // make request
      try {
        const response = yield RequestHandler({
          method,
          url,
          params,
          headers: { Authorization: `Bearer ${tokens?.accessToken}` },
        })
        if (response && response.ok) {
          const data = yield response.json()
          self.results.put(
            MatchMapModel.create({
              id: `${matchId}`,
              match: matchDataCleaner(data),
            })
          )
          const root = getRootStore()
          db.matches.put({
            matchId: matchId,
            syncRequired: 0,
            matchSN: data, // does not need matchDataCleaner here
            message: formatSocketMessage(
              'updateMatch',
              matchId,
              root.appSettings.manualScoring.active ? 'postMatch' : mode,
              data.matchConfigs?.coverageLevelId ?? null,
              '123', // doesn't matter because we are not syncronising it
              data
            ),
          })
          root.appSettings.addMatch(data.id)
          self.state = 'done'
        }
      } catch (error) {
        console.warn('Error getting Match', error) // eslint-disable-line no-console
        self.state = 'error'
      }
    })
    const createMatch = (match: SnapshotIn<IClspMatchModel>) => {
      self.results.put(
        MatchMapModel.create({
          id: `${match.id}`,
          match,
        })
      )
      const root = getRootStore()
      root.appSettings.addMatch(match.id)
      return self.results.get(match.id)
    }
    const deleteMatch = (id: string) => {
      const match = self.results.get(id)
      if (match) {
        destroy(match)
      }
    }
    const resetMatch = flow(function* resetMatch(matchId: string) {
      // matches from API
      const tokens = Auth.getTokens()
      const method = 'DELETE'
      const url = `${import.meta.env.VITE_API_URL}resetMatch/${matchId}`
      const params = {}
      // make request
      try {
        yield RequestHandler({
          method,
          url,
          params,
          headers: { Authorization: `Bearer ${tokens?.accessToken}` },
        })
      } catch (error) {
        console.warn('Error resetting Match', error) // eslint-disable-line no-console
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loadMockData = (data: any) => {
      self.results.put(
        MatchMapModel.create({
          id: `${data.id}`,
          match: matchDataCleaner(data),
        })
      )
      const root = getRootStore()
      db.matches.put({
        matchId: `${data.id}`,
        syncRequired: 0,
        matchSN: data, // does not need matchDataCleaner here
        message: formatSocketMessage(
          'updateMatch',
          `${data.id}`,
          root.appSettings.manualScoring.active ? 'postMatch' : root.appSettings.appMode,
          data.matchConfigs?.coverageLevelId ?? null,
          '123', // doesn't matter because we are not syncronising it
          data
        ),
      })
      root.appSettings.addMatch(data.id)
      self.state = 'done'
    }
    const getRootStore = (): IRootStore => {
      return getRoot(self)
    }
    return {
      getMatch,
      fetchMatch,
      createMatch,
      deleteMatch,
      resetMatch,
      loadMockData,
    }
  })

export default MatchStore
