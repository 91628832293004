import { observer } from 'mobx-react-lite'

import { db } from '../../data/dexie/Database'
import S3PHelpers from '../../helpers/s3pHelpers'
import type { FreeHitProps } from '../../types/props'
import { CustomButton } from '../Buttons/CustomButton'

export const FreeHit = observer(({ mode, game, ball, isDisabled }: FreeHitProps) => {
  return (
    <CustomButton
      width="84px"
      onClick={() => {
        if (mode === 'core' && !ball.freeHit) {
          db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.freeHit(game))
        }
        ball.setFreeHit(!ball.freeHit)
      }}
      isActive={ball.freeHit}
      isDisabled={isDisabled}
      data-testid="freeHitBallDescription"
    >
      Free Hit
    </CustomButton>
  )
})
