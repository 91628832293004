import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import { capitalize, concat, filter, isNil } from 'lodash'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { db } from '../../data/dexie/Database'
import type { InningEndOptions } from '../../data/reference'
import { MatchResultType } from '../../data/reference'
import { useMst } from '../../data/stores/rootStore'
import { timeMachineDate } from '../../helpers/generalHelpers'
import S3PHelpers from '../../helpers/s3pHelpers'
import type { DropdownOption } from '../../types'
import type { IBallStore, ISettingsModel } from '../../types/models'
import type { EndMatchProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import { CustomButton } from '../Buttons/CustomButton'
import Dropdown from '../Dropdown/Dropdown'

type InningsCloseType = typeof InningEndOptions[number]

export const EndMatch = ({
  game,
  mode,
  isWhite = false,
  height,
  timelineEvents,
  isSelectPlayer = false,
  isScoringManually = false,
  handleValidate,
}: EndMatchProps) => {
  const { balls, appSettings }: { balls: IBallStore; appSettings: ISettingsModel } = useMst()
  const navigate = useNavigate()
  const [endMatchAlertIsOpen, setEndMatchAlertIsOpen] = useState<boolean>(false)
  const [endMatchType, setEndMatchType] = useState<string>('')
  const [isValidated, setIsValidated] = useState<boolean>(isScoringManually && handleValidate ? false : true)
  const forfeitTeamOptionsDefault = {
    label: '-- Please Select --',
    value: 'default',
  }
  const [forfeitWinningTeam, setForfeitWinningTeam] = useState<DropdownOption>(forfeitTeamOptionsDefault)
  const cancelEndMatchRef = useRef(null)

  const matchEndOptions: DropdownOption[] = filter(
    MatchResultType.map(endType => {
      return {
        value: endType,
        disabled:
          endType === 'RESULT' &&
          game.matchTeams &&
          (game.matchTeams[0].innings.length === 0 || game.matchTeams[1].innings.length === 0),
      }
    }),
    t => {
      return t.value !== 'NO_RESULT'
    }
  )
  const forfeitTeamOptions: DropdownOption[] = concat(forfeitTeamOptionsDefault, [
    {
      label: game.matchTeams[0].name,
      value: game.matchTeams[0].id,
    },
    {
      label: game.matchTeams[1].name,
      value: game.matchTeams[1].id,
    },
  ])

  const matchS3PMessages = () => {
    db.createS3PMessage(
      S3PHelpers.metadata(isScoringManually ? 'postMatch' : mode, game),
      S3PHelpers.matchStatus(endMatchType, game)
    )
    if (!isNil(game.matchResultTypeId)) {
      const winningTeam = !isNil(game.winningMatchTeamId) ? game.getTeamById(game.winningMatchTeamId) : null
      db.createS3PMessage(
        S3PHelpers.metadata(isScoringManually ? 'postMatch' : mode, game),
        S3PHelpers.matchResult(
          MatchResultType[game.matchResultTypeId],
          winningTeam || null,
          game,
          undefined,
          isScoringManually
        )
      )
    }
  }

  const handleEndMatchAlert = ({ value }: { value: string }) => {
    let endMatchValue = value
    if (
      endMatchValue === 'RESULT' &&
      ((!game.matchDls?.active && game.getMatchIsTied()) ||
        (game.matchDls?.active &&
          game.matchDls.targetScore === (game.getLatestInning?.progressiveScores.runs ?? 0) + 1)) &&
      mode !== 'fielding'
    ) {
      endMatchValue = 'TIED'
    }
    setEndMatchType(endMatchValue)
    setEndMatchAlertIsOpen(true)
  }

  const handleEndMatch = (proceedToEndMatch: boolean) => {
    setEndMatchAlertIsOpen(false)
    if (proceedToEndMatch) {
      game.setLatestBallId(null)
      const activeInning = game.getActiveInning()
      if (activeInning && !isScoringManually) {
        const closeInningType: InningsCloseType =
          forfeitWinningTeam.value !== forfeitTeamOptionsDefault.value
            ? 'FORFEIT'
            : game.getEligibleBattingPlayersInBattingOrder()?.length === 0 &&
              activeInning.getCurrentBatterPerformances?.length !== 2
            ? 'ALL_OUT'
            : 'COMPULSORY_CLOSE'
        if (mode === 'core') {
          db.createS3PMessage(
            S3PHelpers.metadata(appSettings.appMode, game),
            S3PHelpers.inningsState(mode, activeInning, game, balls, closeInningType)
          )
          db.createS3PMessage(
            S3PHelpers.metadata(appSettings.appMode, game),
            S3PHelpers.inningsStatePartnerships(activeInning, game.matchConfigs.ballsPerOver)
          )
          db.createS3PMessage(
            S3PHelpers.metadata(appSettings.appMode, game),
            S3PHelpers.inningsStateSpells(mode, activeInning, game.matchConfigs.ballsPerOver)
          )
          db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.innings('FINISH', game, closeInningType))
        }
        const newestBall = activeInning && balls.getNewestBall(activeInning.id)
        game.endInning(
          closeInningType,
          newestBall,
          appSettings.timeMachine.baseline && appSettings.timeMachine.activated
            ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
            : undefined
        ) // if inning still open at this point, close it
      }
      game.endMatch(
        endMatchType,
        forfeitWinningTeam.value !== forfeitTeamOptionsDefault.value ? forfeitWinningTeam.value : undefined,
        appSettings.timeMachine.baseline && appSettings.timeMachine.activated
          ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
          : undefined,
        isScoringManually
      )
      if (!isScoringManually) {
        // CORE/ADVANCED/FIELDING MODE: send match ending S3P messages and continue to post match screen
        timelineEvents?.addMatchEnd(
          endMatchType.replace(/_/g, ' '),
          null,
          appSettings.timeMachine.baseline,
          appSettings.timeMachine.activated
        )
        if (mode === 'core') {
          db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.matchStatus(endMatchType, game))
          if (!isNil(game.matchResultTypeId)) {
            const winningTeam = !isNil(game.winningMatchTeamId) ? game.getTeamById(game.winningMatchTeamId) : null
            db.createS3PMessage(
              S3PHelpers.metadata(mode, game),
              S3PHelpers.matchResult(MatchResultType[game.matchResultTypeId], winningTeam || null, game)
            )
          }
          matchS3PMessages()
        }
        if (mode === 'advanced' && appSettings.manualScoring.active) {
          appSettings.manualScoring.setForceSync(true)
          setTimeout(() => {
            game.setTimestamp()
            navigate(`/game/${game.id}/${mode}/post-match/manual`)
          }, 500)
        } else {
          navigate(`/game/${game.id}/${mode}/post-match`)
        }
      } else {
        // POST-MATCH MODE: send match ending S3P messages and continue to post match screen
        matchS3PMessages()
        appSettings.manualScoring.setForceSync(true)
        setTimeout(() => {
          game.setTimestamp()
          navigate(`/game/${game.id}/${mode}/post-match/manual`)
        }, 500)
      }
    }
  }

  return (
    <>
      {mode === 'fielding' || (isScoringManually && !isValidated) ? (
        <CustomButton
          onClick={() => {
            if (isScoringManually && handleValidate) {
              setIsValidated(handleValidate())
            } else {
              handleEndMatchAlert({ value: 'RESULT' })
            }
          }}
          width="100%"
          height={isScoringManually && !isValidated ? height : undefined}
          paddingX="8px"
          paddingXOuter="4px"
          isFlex
          isWhite={mode === 'fielding'}
          hideCheck
          data-testid="endMatch"
        >
          End Match
        </CustomButton>
      ) : (
        <Dropdown
          options={matchEndOptions}
          onChange={({ value }) => {
            if (value) handleEndMatchAlert({ value })
          }}
          onClose={() => {
            if (isScoringManually && isValidated) {
              setIsValidated(false)
            }
          }}
          value="End Match"
          isWhite={isWhite}
          height={height || undefined}
          ignoreState
          placement={isSelectPlayer ? 'top' : 'bottom'}
          defaultIsOpen={isScoringManually && isValidated}
          data-testid="endMatchAlertDropdown"
        />
      )}
      <AlertDialog
        isOpen={endMatchAlertIsOpen}
        leastDestructiveRef={cancelEndMatchRef}
        onClose={() => handleEndMatch(false)}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            End Match
          </AlertDialogHeader>

          <AlertDialogBody>
            <>
              <Text>
                {mode === 'fielding'
                  ? 'Are you sure you wish to end the match?'
                  : `Are you sure you wish to end the match (${capitalize(endMatchType.replace(/_/g, ' '))})?`}
              </Text>
              {mode !== 'fielding' && endMatchType === 'FORFEIT' && (
                <Flex direction="row" marginTop="21px" backgroundColor="cls.backgroundGray" padding="14px">
                  <Flex flex={1}>
                    <Text>Winning team:</Text>
                  </Flex>
                  <Flex flex={2}>
                    <Dropdown
                      options={forfeitTeamOptions}
                      value={forfeitWinningTeam.value}
                      origValue="Select"
                      onChange={({ value }) => {
                        if (value) setForfeitWinningTeam({ value, label: value })
                      }}
                      preserveCase
                      isWhite
                      ignoreState
                      data-testid="foreitTeamDropdown"
                    />
                  </Flex>
                </Flex>
              )}
            </>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={() => handleEndMatch(false)} data-testid="endMatchDialogNoButton">
              No
            </Button>
            <Button
              colorScheme="green"
              onClick={() => handleEndMatch(true)}
              ml={3}
              textTransform="capitalize"
              isDisabled={
                mode !== 'fielding' &&
                endMatchType === 'FORFEIT' &&
                forfeitWinningTeam.value === forfeitTeamOptionsDefault.value
              }
              data-testid="endMatchDialogYesButton"
            >
              Yes (End Match)
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
