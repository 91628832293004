import { Flex } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import type { PredictiveProps } from '../../types/props'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'

const Predictive = observer(({ mode, ball, editBall, updatePredictiveMessaging }: PredictiveProps) => {
  const changeLikelyBoundary = ({ value }: { value: boolean }) => {
    ball.setLikelyBoundary(value)
    if (value) updatePredictiveMessaging('boundary')
  }
  const changeLikelyWicket = ({ value }: { value: boolean }) => {
    ball.setLikelyWicket(value)
    if (value) updatePredictiveMessaging('wicket')
  }

  return (
    <Flex flex={1} flexDirection={mode !== 'advanced' ? 'row' : 'column'} align="flex-start">
      <Flex flexDirection={mode !== 'advanced' ? ['row', 'row', 'column', 'column'] : 'row'} w="100%" h="100%">
        <Flex flex={1} w="100%" justify={mode !== 'advanced' ? undefined : 'flex-end'}>
          <SimpleStateButton
            width={mode === 'advanced' ? '55px' : undefined}
            height={mode !== 'advanced' ? '44px' : '94px'}
            target="likely_boundary"
            value={ball.predictives?.likelyBoundary || false}
            onClick={changeLikelyBoundary}
            isActive={ball.predictives?.likelyBoundary || false}
            isDisabled={editBall}
            isFlex={mode !== 'advanced'}
            isSwitch
            isFirst={mode === 'advanced'}
            hideCheck
            paddingXOuter={mode !== 'advanced' ? '0px' : undefined}
            data-testid="likelyFourOrSix"
          >
            Likely
            <br />
            4/6
          </SimpleStateButton>
        </Flex>
        <Flex
          flex={1}
          w="100%"
          marginTop={mode !== 'advanced' ? ['0px', '0px', '7px', '7px'] : '0px'}
          marginLeft={mode !== 'advanced' ? ['21px', '21px', '0px', '0px'] : '0px'}
        >
          <SimpleStateButton
            width={mode === 'advanced' ? '55px' : undefined}
            height={mode !== 'advanced' ? '44px' : '94px'}
            target="likely_wicket"
            value={ball.predictives?.likelyWicket || false}
            onClick={changeLikelyWicket}
            isActive={ball.predictives?.likelyWicket || false}
            isDisabled={editBall}
            isFlex={mode !== 'advanced'}
            isSwitch
            isLast={mode === 'advanced'}
            hideCheck
            paddingXOuter={mode !== 'advanced' ? '0px' : undefined}
            data-testid="likelyWicket"
          >
            Likely
            <br />
            wicket
          </SimpleStateButton>
        </Flex>
      </Flex>
    </Flex>
  )
})

export default Predictive
