import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import type { IMatchOfficialModel } from '@clsplus/cls-plus-data-models'
import { capitalize, extend } from 'lodash'
import { useRef } from 'react'

import type { ScoringAlertsProps } from '../../types/props'
import { Button } from '../Buttons/Button'

export const ScoringAlerts = ({
  mode,
  activeInningMetaData,
  scorePassedIsOpen,
  setScorePassedIsOpen,
  oversPassedIsOpen,
  setOversPassedIsOpen,
  handleEndInning,
  wicketsPassedIsOpen,
  setWicketsPassedIsOpen,
  powerPlayStartIsOpen,
  setPowerPlayStartIsOpen,
  handleStartPowerPlay,
  cascadeEditIsOpen,
  setCascadeEditIsOpen,
  handleCascadeEdit,
  cascadeEditProps,
  powerPlayProps,
  venueEndsIsOpen,
  setVenueEndsIsOpen,
  handleVenueEnds,
  venueEndsProps,
  umpireEndsIsOpen,
  setUmpireEndsIsOpen,
  handleUmpireEnds,
  umpireEnds,
  insertingBall,
  cancelDismissalOrLastBall,
}: ScoringAlertsProps) => {
  const cancelScoreRef = useRef(null)
  const cancelOversRef = useRef(null)
  const cancelWicketsRef = useRef(null)
  const cancelPowerPlayRef = useRef(null)
  const cancelVenueEndsRef = useRef(null)
  const cancelCascadeEditRef = useRef(null)
  const cancelUmpireEndsRef = useRef(null)
  const powerPlayToActivate = powerPlayProps?.triggers?.find(
    trigger => Number(trigger.startOver) === Number(powerPlayProps.oversBowled)
  )?.powerPlay
  const venueEnd1 = venueEndsProps ? venueEndsProps[0] : null
  const venueEnd2 = venueEndsProps ? venueEndsProps[1] : null
  return (
    <>
      <AlertDialog
        isOpen={scorePassedIsOpen}
        leastDestructiveRef={cancelScoreRef}
        onClose={() => setScorePassedIsOpen(false)}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            End Innings
          </AlertDialogHeader>

          <AlertDialogBody>The score for this match has been passed.</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              onClick={() => {
                setScorePassedIsOpen(false)
                if (activeInningMetaData) activeInningMetaData.updatePassedScore(false)
                cancelDismissalOrLastBall(false)
              }}
              data-testid="cancelEndInningsAlertButton"
            >
              {mode === 'core' ? 'Cancel & Reset Ball' : 'Cancel'}
            </Button>
            <Button
              colorScheme="red"
              onClick={() => handleEndInning('TARGET_REACHED')}
              ml={3}
              textTransform="capitalize"
              data-testid="endInningsTargetReachedButton"
            >
              End Innings (Target Reached)
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isOpen={oversPassedIsOpen}
        leastDestructiveRef={cancelOversRef}
        onClose={() => setOversPassedIsOpen(false)}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            End Innings
          </AlertDialogHeader>

          <AlertDialogBody>The maximum amount of overs has been reached</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              onClick={() => {
                setOversPassedIsOpen(false)
                if (activeInningMetaData) activeInningMetaData.updatePassedOvers(false)
                cancelDismissalOrLastBall(false)
              }}
            >
              {mode === 'core' ? 'Cancel & Reset Ball' : 'Cancel'}
            </Button>
            <Button
              colorScheme="red"
              onClick={() => handleEndInning('COMPULSORY_CLOSE')}
              ml={3}
              textTransform="capitalize"
              data-testid="endInningsCompulsoryCloseButton"
            >
              End Innings (Compulsory Close)
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isOpen={wicketsPassedIsOpen}
        leastDestructiveRef={cancelWicketsRef}
        onClose={() => setWicketsPassedIsOpen(false)}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            End Innings
          </AlertDialogHeader>

          <AlertDialogBody>Batting team has no more batters</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              onClick={() => {
                setWicketsPassedIsOpen(false)
                if (activeInningMetaData) activeInningMetaData.updatePassedWickets(false)
                cancelDismissalOrLastBall(true)
              }}
              data-testid="wicketPassedEndInningsCancelButton"
            >
              {mode === 'core' ? 'Cancel & Reset Ball' : 'Cancel'}
            </Button>
            <Button
              colorScheme="red"
              onClick={() => handleEndInning('ALL_OUT')}
              ml={3}
              textTransform="capitalize"
              data-testid="endInningsAllOutButton"
            >
              End Innings (All Out)
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isOpen={powerPlayStartIsOpen}
        leastDestructiveRef={cancelPowerPlayRef}
        onClose={() => setPowerPlayStartIsOpen(false)}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Start Power Play
          </AlertDialogHeader>

          <AlertDialogBody>
            {`${capitalize(
              powerPlayToActivate?.replace(/_/g, ' ').toLowerCase() || 'A power play'
            )} is due to begin at this point in the match.${
              powerPlayProps?.dlsActive
                ? ' Due to DLS, Power Plays will need to be manually stopped and started for the remainder of the innings.' // eslint-disable-line max-len
                : ''
            }`}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme="green"
              onClick={() => handleStartPowerPlay(powerPlayToActivate)}
              ml={3}
              textTransform="capitalize"
              data-testid="startPowerPlayButton"
            >
              Start Power Play
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isOpen={venueEndsIsOpen && !powerPlayStartIsOpen}
        leastDestructiveRef={cancelVenueEndsRef}
        onClose={() => setVenueEndsIsOpen(false)}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Starting End
          </AlertDialogHeader>

          <AlertDialogBody>Select the end the first over will be bowled from.</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme="green"
              onClick={() => handleVenueEnds(venueEnd1)}
              ml={3}
              textTransform="capitalize"
              data-testid={'startingEnd' + venueEnd1?.name}
            >
              {venueEnd1?.name}
            </Button>
            <Button
              colorScheme="green"
              onClick={() => handleVenueEnds(venueEnd2)}
              ml={3}
              textTransform="capitalize"
              data-testid={'startingEnd' + venueEnd2?.name}
            >
              {venueEnd2?.name}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isOpen={cascadeEditIsOpen && !powerPlayStartIsOpen && !venueEndsIsOpen}
        leastDestructiveRef={cancelCascadeEditRef}
        onClose={() => setCascadeEditIsOpen(false)}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {insertingBall ? 'Insert Ball' : 'Edit Ball'}
          </AlertDialogHeader>
          {cascadeEditProps && (
            <AlertDialogBody>
              {`The cascade process has encountered a wicket at over ${cascadeEditProps.wicketOverBall}${
                cascadeEditProps.wicketDismissed ? ` (${cascadeEditProps.wicketDismissed} dismissed)` : ''
              }. Do you wish to update this wicket, and then continue the cascade process?`}
            </AlertDialogBody>
          )}
          {!cascadeEditProps && (
            <AlertDialogBody>
              {`This ${
                insertingBall ? 'insert' : 'edit'
              } would result in the strike batter being changed for the next ball. Would you like this ${
                insertingBall ? 'insert' : 'edit'
              } `}
              to cascade, and update subsequent balls?
              <br />
              <br />
              Note: The cascade process will pause and re-prompt you any time it encounters a wicket.
            </AlertDialogBody>
          )}
          <AlertDialogFooter>
            <Button onClick={() => handleCascadeEdit({ type: null })} data-testid="cancelCascadeEditButton">
              No
            </Button>
            {cascadeEditProps && (
              <Button
                colorScheme="green"
                onClick={() => handleCascadeEdit(extend(cascadeEditProps, { continueAfterWicket: true }))}
                ml={3}
                data-testid="cascadeEditButton"
              >
                Yes (Continue cascade)
              </Button>
            )}
            {!cascadeEditProps && (
              <>
                <Button
                  colorScheme="green"
                  onClick={() => handleCascadeEdit({ type: 'over' })}
                  ml={3}
                  data-testid="cascadeEditCurrentOverButton"
                >
                  Cascade (This over)
                </Button>
                <Button
                  colorScheme="green"
                  onClick={() => handleCascadeEdit({ type: 'innings' })}
                  ml={3}
                  data-testid="cascadeEditEntireInningsButton"
                >
                  Cascade (Entire innings)
                </Button>
              </>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isOpen={umpireEndsIsOpen && !powerPlayStartIsOpen && !venueEndsIsOpen}
        leastDestructiveRef={cancelUmpireEndsRef}
        onClose={() => setUmpireEndsIsOpen(false)}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Umpires
          </AlertDialogHeader>
          <AlertDialogBody>
            {`Select the umpire standing at the ${umpireEnds?.startingEnd} end for the first over.`}
          </AlertDialogBody>
          <AlertDialogFooter>
            {umpireEnds?.umpires && umpireEnds.umpires.length < 2 && (
              <Button
                onClick={() => handleUmpireEnds(null, umpireEnds.umpires.length === 1 ? umpireEnds.umpires[0] : null)}
                data-testid="cancelUmpireEndsAlertButton"
              >
                Cancel
              </Button>
            )}
            {umpireEnds?.umpires.map((umpire: IMatchOfficialModel, idx: number) => (
              <Button
                key={`umpire${umpire.id}`}
                colorScheme="green"
                onClick={() => handleUmpireEnds(umpireEnds.umpires[idx], umpireEnds.umpires[1 - idx])}
                ml={3}
                data-testid={'updateUmpire_' + umpire.getDisplayName + 'Button'}
              >
                {umpire.getDisplayName}
              </Button>
            ))}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
