import { Flex } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

import { InGameHeader } from '../../components/Headers/InGameHeader'
import type { InGameAlternateProps } from '../../types/props'
import { socketConnectionColors } from '../../types/props'

const InGameAlternate = ({ children, socketStatus, mode }: InGameAlternateProps) => {
  const location = useLocation()
  const isPreGame = location.pathname.search('setup') > -1
  const isPreInnings = location.pathname.search('inning-') > -1

  return (
    <Flex direction="column" align="center" w="100vw" minH="100vh">
      <Flex direction="column" w="100%" h="100vh" align="flex-start">
        <Flex backgroundColor={socketConnectionColors[socketStatus]} h="42px" w="100%" justify="center" align="center">
          <InGameHeader preGame={isPreGame} preInnings={isPreInnings} mode={mode} />
        </Flex>
        <Flex w="100%" h="calc(100vh - 70px)" justify={['flex-start', 'flex-start', 'center', 'center']}>
          <Flex direction="column" w="100%" maxW="100%" h="100%" marginTop={['7px', '7px', '14px', '14px']}>
            {children}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default InGameAlternate
