import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import { NavLink, useParams } from 'react-router-dom'

import { useMst } from '../../data/stores/rootStore'
import Theme from '../../theme/theme'
import type { IClspMatchModel, IDetailedMatchStore, ISettingsModel } from '../../types/models'
import type { ClspMode } from '../../types/props'

const TabLink = styled(NavLink)`
  height: 100%;
  width: 100%;
  font-size: 19px;
  font-weight: 400;
  font-style: italic;
  text-transform: uppercase;
  color: white;
  letter-spacing: -0.5px;
`

const activeStyles = {
  backgroundColor: Theme.colors.cls.backgroundGray,
  borderRadius: '8px 8px 0 0',
  color: Theme.colors.cls.black,
  fontWeight: 700,
}

interface InGameTabsProps {
  preGame?: boolean
  preInnings?: boolean
  mode?: ClspMode
  isScoringManually?: boolean
}

export const InGameTabs = observer(({ preGame, preInnings, mode, isScoringManually }: InGameTabsProps) => {
  const { id } = useParams()
  const { detailedMatches, appSettings }: { detailedMatches: IDetailedMatchStore; appSettings: ISettingsModel } =
    useMst()
  if (!id) return null
  const game: IClspMatchModel | undefined = detailedMatches.results.get(id)?.match

  return (
    <Flex h="100%" flex={1} align="flex-end" justify="center">
      {preGame && !preInnings && (
        <Flex h="36px" flex={1} maxWidth="176px" roundedTop={8} align="center" justify="center">
          <TabLink to={`/game/${id}/${mode}/setup`} style={({ isActive }) => (isActive ? activeStyles : undefined)}>
            <Flex h="100%" w="100%" align="center" justify="center" fontSize="lg" data-testid="tab_setup">
              Setup
            </Flex>
          </TabLink>
        </Flex>
      )}
      {!preGame && !preInnings && game && (
        <>
          {!isScoringManually && (
            <Flex h="36px" flex={1} maxWidth="176px" roundedTop={8} align="center" justify="center">
              <TabLink
                to={`/game/${id}/${mode}/scoring`}
                style={({ isActive }) => (isActive ? activeStyles : undefined)}
              >
                <Flex
                  h="100%"
                  w="100%"
                  direction="row"
                  align="center"
                  justify="center"
                  fontSize="lg"
                  data-testid="tab_scoring"
                >
                  {appSettings.timeMachine.baseline && (
                    <Flex mr="7px">
                      <FontAwesomeIcon icon={['far', 'flux-capacitor']} size="lg" />
                    </Flex>
                  )}
                  Scoring
                </Flex>
              </TabLink>
            </Flex>
          )}
          {mode !== 'fielding' && (
            <Flex h="36px" flex={1} maxWidth="176px" roundedTop={8} align="center" justify="center">
              <TabLink
                to={`/game/${id}/${mode}/scorebook${isScoringManually ? '/manual' : ''}`}
                style={({ isActive }) => (isActive ? activeStyles : undefined)}
              >
                <Flex h="100%" w="100%" align="center" justify="center" fontSize="lg" data-testid="tab_scorebook">
                  Scorebook
                </Flex>
              </TabLink>
            </Flex>
          )}
          {mode === 'fielding' && (
            <Flex h="36px" flex={1} maxWidth="176px" roundedTop={8} align="center" justify="center">
              <TabLink
                to={`/game/${id}/${mode}/metrics`}
                style={({ isActive }) => (isActive ? activeStyles : undefined)}
              >
                <Flex h="100%" w="100%" align="center" justify="center" fontSize="lg" data-testid="tab_metrics">
                  Metrics
                </Flex>
              </TabLink>
            </Flex>
          )}
          <Flex h="36px" flex={1} maxWidth="176px" roundedTop={8} align="center" justify="center">
            <TabLink
              to={`/game/${id}/${mode}/settings${isScoringManually ? '/manual' : ''}`}
              style={({ isActive }) => (isActive ? activeStyles : undefined)}
            >
              <Flex h="100%" w="100%" align="center" justify="center" fontSize="lg" data-testid="tab_settings">
                Settings
              </Flex>
            </TabLink>
          </Flex>
        </>
      )}
      {game && game.matchResultTypeId !== null && game.matchResultTypeId > 0 && (
        <Flex h="36px" flex={1} maxWidth="176px" roundedTop={8} align="center" justify="center">
          <TabLink
            to={`/game/${id}/${mode}/post-match${isScoringManually ? '/manual' : ''}`}
            style={({ isActive }) => (isActive ? activeStyles : undefined)}
          >
            <Flex h="100%" w="100%" align="center" justify="center" fontSize="lg" data-testid="tab_postmatch">
              Post-Match
            </Flex>
          </TabLink>
        </Flex>
      )}
    </Flex>
  )
})
