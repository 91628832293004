import { Flex } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import BallHelpers from '../../helpers/ballHelpers'
import type { AdvancedScoringProps } from '../../types/props'
import Arrival from '../Arrival/Arrival'
import WagonWheel from '../Field/WagonWheel'
import PitchMap from '../PitchMap/PitchMap'
import Delivery from './Delivery'
import Fielding from './Fielding/Fielding'
import Footwork from './Footwork'
import Shot from './Shot'
import SpeedSpin from './SpeedSpin'

const borderStyle = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'gray.200',
}
const paddingStyleTopRow = {
  padding: '4px 7px',
}
const paddingStyleBottomRow = {
  padding: '4px 7px 0',
}

const AdvancedScoring = observer(({ ball, game, editBall = false, isPrimaryEditCheck }: AdvancedScoringProps) => {
  const incompleteBallDataAreas = BallHelpers.incompleteBallData(ball)
  const isIncomplete = (area: string) => {
    return editBall && incompleteBallDataAreas && incompleteBallDataAreas.indexOf(area) > -1 ? true : false
  }

  return (
    <>
      <Flex flex={1} flexDirection="column">
        <Flex flex={1} flexDirection="row" marginTop="14px">
          <Flex flex={1} flexDirection="column">
            <Flex {...borderStyle} {...paddingStyleTopRow} flex={1} minHeight="364px">
              <PitchMap ball={ball} editBall={editBall} incompleteData={isIncomplete('PitchMap')} />
            </Flex>
            <Flex {...borderStyle} {...paddingStyleBottomRow} flex={1} marginTop="7px">
              <SpeedSpin ball={ball} editBall={editBall} />
            </Flex>
          </Flex>
          <Flex flex={1} flexDirection="column" marginLeft="7px">
            <Flex {...borderStyle} {...paddingStyleTopRow} flex={1} minHeight="364px">
              <Arrival ball={ball} editBall={editBall} incompleteData={isIncomplete('Arrival')} />
            </Flex>
            <Flex {...borderStyle} {...paddingStyleBottomRow} flex={1} marginTop="7px">
              <Delivery ball={ball} />
            </Flex>
          </Flex>
          <Flex flex={4} flexDirection="column">
            <Flex flex={1} minHeight="364px" flexDirection="row">
              <Flex {...borderStyle} {...paddingStyleTopRow} flex={3.25} marginLeft="7px" flexDirection="column">
                <Footwork ball={ball} incompleteData={isIncomplete('Footwork')} />
                <Shot
                  mode="advanced"
                  ball={ball}
                  incompleteData={{ type: isIncomplete('ShotType'), contact: isIncomplete('ShotContact') }}
                  isPrimaryEditCheck={isPrimaryEditCheck}
                />
              </Flex>
              <Flex {...borderStyle} {...paddingStyleTopRow} flex={1.5} marginLeft="7px" alignItems="flex-start">
                <WagonWheel ball={ball} editBall={editBall} incompleteData={isIncomplete('WagonWheel')} />
              </Flex>
            </Flex>
            <Flex {...borderStyle} {...paddingStyleBottomRow} flex={1} marginTop="7px" marginLeft="7px">
              <Fielding
                game={game}
                ball={ball}
                editBall={editBall}
                incompleteData={{ fielder: isIncomplete('Fielder'), byeBlame: isIncomplete('ByeBlame') }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
})

export default AdvancedScoring
