import { Box, Flex, FormLabel, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { db } from '../../data/dexie/Database'
import { useMst } from '../../data/stores/rootStore'
import { timeMachineDate } from '../../helpers/generalHelpers'
import S3PHelpers from '../../helpers/s3pHelpers'
import Theme from '../../theme/theme'
import type { BetweenInningProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import { CustomButton } from '../Buttons/CustomButton'
import { Checkbox } from '../Checkbox/Checkbox'
import MatchBreak from '../ControlsPanel/MatchBreak'
import { EndMatch } from '../EndMatch/EndMatch'
import SubHeading from '../Headings/SubHeading'

export const BetweenInning = ({ game, timelineEvents, mode }: BetweenInningProps) => {
  const { appSettings } = useMst()
  const navigate = useNavigate()
  const [teamSelected, setTeamSelected] = useState(game.getNextBattingTeam)
  const [followOn, setFollowOn] = useState(false)
  const [superOver, setSuperOver] = useState(game.shouldGoToSuperOver)
  const toast = useToast()
  const startNextInning = () => {
    if (teamSelected) {
      if (mode === 'core') {
        if (followOn) {
          const teamEnforcing = game.getTeamByNotId(teamSelected.id)
          if (teamEnforcing) {
            db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.followOn(teamSelected, teamEnforcing, game))
          }
        }
        if (game.matchDls) {
          if (!game.matchDls.targetScore) {
            // for manual DLS games (i.e. The Hundred), if target overs were adjusted in the first innings...
            // ...then set a default target score now
            game.updateDlsTargetScore((game.getInningByNum(1)?.progressiveScores.runs || 0) + 1)
          }
          db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.dls(game, false))
        }
      }

      game.createInning(
        teamSelected.id,
        followOn,
        superOver,
        undefined,
        undefined,
        appSettings.timeMachine.baseline && appSettings.timeMachine.activated
          ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
          : undefined
      )

      if (mode === 'fielding') {
        navigate(`/game/${game.id}/${mode}/inning-bowling-setup`)
      } else {
        // check for any penalty runs that need to be applied to the new inning
        const penaltyRunsPending = game.getPenaltyRunsPending(teamSelected.id)
        const activeInning = game.getActiveInning()
        if (activeInning && penaltyRunsPending && penaltyRunsPending.length > 0) {
          penaltyRunsPending.map(penalty => {
            activeInning.updatePenaltyRuns(penalty.runs)
            return true
          })
          game.updatePenaltyRunsPending(teamSelected.id, activeInning.inningsMatchOrder)

          // notify the scorer that penalty runs have been added to start this innings
          toast({
            title: 'Penalty Runs',
            description:
              `${activeInning.progressiveScores.penaltyRuns} penalty runs ` +
              `have been added to the starting score for ${teamSelected.name}.`,
            status: 'info',
            duration: Theme.toast.long,
            isClosable: true,
          })
        }

        // then, move onto the batters & bowler selection for the new inning
        navigate(`/game/${game.id}/${mode}/inning-batting-setup`)
      }
    }
  }

  const handleSuperOver = () => {
    setSuperOver(!superOver)
    setFollowOn(false)
  }

  const handleFollowOn = () => {
    setFollowOn(!followOn)
    setSuperOver(false)
  }

  return (
    <Flex flex={1} w={mode === 'advanced' ? 'auto' : '100%'} marginLeft={['0', '0', '14px', '14px']}>
      <Flex
        flex={10}
        direction="column"
        padding={mode === 'advanced' ? '14px 21px 0' : '14px 14px 0'}
        backgroundColor="cls.white.400"
        borderTopLeftRadius={mode === 'advanced' ? '7px' : ['0px', '0px', '7px', '7px']}
        borderTopRightRadius={mode === 'advanced' ? '7px' : ['0px', '0px', '7px', '7px']}
      >
        <SubHeading text="Next Inning" />
        <Flex justifyContent="center" h={['300px', '300px', '500px', '500px']}>
          <Flex
            flex={1}
            align="flex-start"
            justify="center"
            direction="column"
            marginBottom={mode !== 'advanced' ? '14px' : undefined}
          >
            <SubHeading text="Which team is batting next?" secondary />
            <Flex marginBottom="37px">
              {game.matchTeams.map(t => (
                <Box key={t.id} marginRight="7px">
                  <CustomButton
                    data-testid={t.name + '_selectTeam'}
                    isActive={teamSelected && teamSelected.id === t.id}
                    onClick={() => {
                      setFollowOn(
                        !!game.battedFirstMatchTeamId &&
                          game.battedFirstMatchTeamId !== t.id &&
                          game.getInningCount() === 2
                      )
                      setTeamSelected(t)
                    }}
                  >
                    {t.name}
                  </CustomButton>
                </Box>
              ))}
            </Flex>
            <Flex justify="flex-start" align="center" marginBottom="7px">
              <FormLabel htmlFor="follow-on">Is this a Follow On?</FormLabel>
              <Checkbox
                id="follow-on"
                colorScheme="cls.yellow"
                isChecked={followOn}
                onChange={handleFollowOn}
                data-testid="followOnCheckbox"
              />
            </Flex>
            <Flex justify="flex-start" align="center" marginBottom="37px">
              <FormLabel htmlFor="super-over">Is this a Super Over?</FormLabel>
              <Checkbox
                id="super-over"
                colorScheme="cls.yellow"
                isChecked={superOver}
                onChange={handleSuperOver}
                data-testid="superOverCheckbox"
              />
            </Flex>
            <Flex justify="flex-start" align="center">
              <Button
                isDisabled={!teamSelected}
                onClick={() => startNextInning()}
                colorScheme="cls.blue"
                size="lg"
                data-testid="startInning"
              >
                Start Inning
              </Button>
              <Box marginLeft="7px">
                <MatchBreak
                  game={game}
                  timelineEvents={timelineEvents}
                  defaultValue="Break / Delay"
                  height="48px"
                  placement="right"
                />
              </Box>
              <Box marginLeft="7px">
                <EndMatch game={game} mode={mode} height="48px" />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex flex={2} direction="column" display={mode !== 'advanced' ? ['none', 'none', 'flex', 'flex'] : undefined} />
    </Flex>
  )
}
