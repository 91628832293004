import { types } from 'mobx-state-tree'

import type { IInningMetaModel, IMatchSettingsModel } from '../../../types/models'
import type { ClspMode } from '../../../types/props'
import { ScoreFormats } from '../../reference'
import MatchMap from './MatchMap'
import MatchSettingsModel from './MatchSettingsModel'

const ManualScoringSettingsModel = types
  .model('ManualScoringSettingsModel', {
    active: false,
    forceSync: false,
  })
  .actions(self => {
    const setActive = (value: boolean) => {
      self.active = value
    }
    const setForceSync = (value: boolean) => {
      self.forceSync = value
    }
    return {
      setActive,
      setForceSync,
    }
  })

export const TimeMachineSettingsModel = types
  .model('TimeMachineSettingsModel', {
    baseline: types.maybeNull(types.string),
    activated: types.maybeNull(types.string),
  })
  .actions(self => {
    const setBaseline = (dateTime: string | null) => {
      self.baseline = dateTime
    }
    const setActivated = (dateTime: string | null, matchId: string) => {
      self.activated = dateTime
      // update local storage item
      window.localStorage.setItem(
        'cls_plus_timeMachine',
        JSON.stringify({
          matchId: matchId,
          baseline: self.baseline,
          activated: dateTime,
        })
      )
    }
    return {
      setBaseline,
      setActivated,
    }
  })

const SettingsModel = types
  .model('SettingsModel', {
    appMode: types.optional(
      types.union(types.literal('core'), types.literal('advanced'), types.literal('fielding')),
      'core'
    ),
    manualScoring: ManualScoringSettingsModel,
    timeMachine: TimeMachineSettingsModel,
    match: types.map(MatchMap),
    defaultMatch: MatchSettingsModel,
  })
  .views(self => {
    const getInningMetaData = (matchId: string, inningNum: number): IInningMetaModel | undefined => {
      const match = self.match.get(matchId)
      if (!match) return
      return match.meta.inning.get(inningNum.toString())
    }
    const getMatchSettings = (matchId?: string): IMatchSettingsModel => {
      if (!matchId) return self.defaultMatch
      const match = self.match.get(matchId)
      return match ? match.settings : self.defaultMatch
    }
    return {
      getInningMetaData,
      getMatchSettings,
    }
  })
  .actions(self => {
    const addMatch = (id: string) => {
      self.match.put({
        id,
        meta: {},
        settings: {
          scoreFormat: ScoreFormats[1],
          fielderPlacement: self.appMode === 'fielding',
          fielderEvents: self.appMode === 'fielding',
          fielderShirtNumbers: true,
          ballPreview: true,
        },
      })
    }
    const addInning = (matchId: string, inningId: number) => {
      let match = self.match.get(matchId)
      if (!match) {
        addMatch(matchId)
        match = self.match.get(matchId)
      }
      if (!match) return
      match.meta.addInning(`${inningId}`)
    }
    const setAppMode = (mode: ClspMode) => {
      self.appMode = mode
    }
    return {
      addMatch,
      addInning,
      setAppMode,
    }
  })

export default SettingsModel
