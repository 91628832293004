import { Box, Flex } from '@chakra-ui/react'

import SradLogoSVG from '../../assets/data_by_SPO_logo_positive.svg'
import LogoSVG from '../../assets/logo_white_bg.svg'
import Auth from '../../helpers/auth'
import { Button } from '../Buttons/Button'

const Login = () => {
  return (
    <Flex align="center" justify="center" h="100vh" direction="column" backgroundColor="#FFF">
      <Box style={{ marginBottom: '38px' }}>
        <img src={LogoSVG} style={{ height: '150px', marginBottom: '12px' }} alt="Cricket Live Score Pluss Logo" />
        <img src={SradLogoSVG} alt="Data by Sportradar Logo" />
      </Box>

      {/** @ts-ignore */}
      <Button
        as="a"
        href={Auth.loginUrl}
        colorScheme="cls.blue"
        size="lg"
        marginBottom="35px"
        data-testid="loginButton"
      >
        Login
      </Button>
    </Flex>
  )
}

export default Login
