import { EditIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Flex,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import type {
  IBattingPerformanceModel,
  IBowlingPerformanceModel,
  IDismissalFieldersModel,
  IInningModel,
  IMatchPlayerModel,
  IMatchTeamModel,
} from '@clsplus/cls-plus-data-models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cloneDeep, compact, findIndex, indexOf, isNil, orderBy, upperFirst } from 'lodash'
import { observer } from 'mobx-react-lite'
import type { SnapshotIn } from 'mobx-state-tree'
import { getSnapshot } from 'mobx-state-tree'
import { useEffect, useRef, useState } from 'react'

import { Button } from '../components/Buttons/Button'
import { Checkbox } from '../components/Checkbox/Checkbox'
import MatchBreak from '../components/ControlsPanel/MatchBreak'
import Dropdown from '../components/Dropdown/Dropdown'
import EditableField from '../components/EditableField/EditableField'
import { EndMatch } from '../components/EndMatch/EndMatch'
import SubHeading from '../components/Headings/SubHeading'
import MatchValidation from '../components/MatchValidation/MatchValidation'
import { Batting } from '../components/ScorebookPanels/Batting'
import { Bowling } from '../components/ScorebookPanels/Bowling'
import { Extras } from '../components/ScorebookPanels/Extras'
import { Partnerships } from '../components/ScorebookPanels/Partnerships'
import Stepper from '../components/Stepper/Stepper'
import type { TextFieldCallbackArgs } from '../components/TextField/TextField'
import TextField from '../components/TextField/TextField'
import { db } from '../data/dexie/Database'
import * as Reference from '../data/reference'
import { useMst } from '../data/stores/rootStore'
import { suffixNumber, timeMachineDate } from '../helpers/generalHelpers'
import MatchHelpers from '../helpers/matchHelpers'
import S3PHelpers from '../helpers/s3pHelpers'
import type { DropdownCallbackArgs, InningInOrder } from '../types'
import type { IBallStore } from '../types/models'
import type { MatchValidationResponse, ScorebookProps } from '../types/props'

const Scorebook = observer(({ game, appSettings }: ScorebookProps) => {
  const { balls }: { balls: IBallStore } = useMst()
  const [editDismissalOpen, setEditDismissalOpen] = useState<boolean>(false)
  const [editDismissalPerf, setEditDismissalPerf] = useState<IBattingPerformanceModel | null>(null)
  const [deletePerfOpen, setDeletePerfOpen] = useState<boolean>(false)
  const [deletePerf, setDeletePerf] = useState<IBattingPerformanceModel | IBowlingPerformanceModel | null>(null)
  const [validationIssueOpen, setValidationIssueOpen] = useState(false)
  const [validationIssues, setValidationIssues] = useState<MatchValidationResponse | null>(null)
  const activeInning: IInningModel | undefined = game.getActiveInning()
  const allInnings: IInningModel[] = game.getAllInningInOrder
  const hasActiveInning = game.getActiveInning() ? true : false
  const allTeams: IMatchTeamModel[] = compact([game.getHomeTeam(false), game.getAwayTeam])
  const activeIndex = findIndex(allInnings, i => i.inningsMatchOrder === activeInning?.inningsMatchOrder)
  const matchSettings = appSettings.getMatchSettings(game.id)
  const [tabIndex, setTabIndex] = useState(activeIndex !== -1 ? activeIndex : 0)
  const [editHeadScore, setEditHeadScore] = useState(false)
  const cancelDeletePerf = useRef(null)

  /* Post-match scoring */
  const inningsInOrder: InningInOrder[] = allInnings.map((innings: IInningModel) => {
    const team = game.getTeamById(innings.battingTeamId)
    return {
      value: innings.id,
      label: MatchHelpers.getInningsLabel(innings, team ? team.shortName || team.name : null),
      inningsMatchOrder: innings.inningsMatchOrder,
      isActiveInning: innings.inningsMatchOrder === activeInning?.inningsMatchOrder,
      superOver: innings.superOver,
    }
  })
  const dismissalOptions = Reference.DismissalMethods.map(method => {
    return { value: method, label: method === 'LBW' ? method : upperFirst(method.replace(/_/g, ' ').toLowerCase()) }
  })
  const inningEndOptions = Reference.InningEndOptions.map(endType => {
    return { value: endType, label: endType, disabled: false }
  })
  inningEndOptions.push({ value: 'IN_PROGRESS', label: 'IN_PROGRESS', disabled: !hasActiveInning })
  const fielders: IMatchPlayerModel[] | undefined =
    allInnings && allInnings.length > 0 ? game.getBowlingTeam(allInnings[tabIndex].id)?.matchPlayers : undefined
  let fieldersOptions = fielders?.map((fielder: IMatchPlayerModel) => {
    return {
      value: fielder.id,
      sortName: `${
        fielder.player.person?.lastName ||
        fielder.player.person?.cardNameS ||
        fielder.player.person?.cardNameF ||
        fielder.getDisplayName()
      }, ${fielder.player.person?.firstName || ''}`,
      label: `${fielder.getDisplayName()}${fielder.getShirtNumber(true)}${fielder.wicketKeeper ? ' (WK)' : ''}${
        fielder.substitute && !fielder.getActiveReason?.endsWith('_ON') ? ' (sub)' : ''
      }`,
    }
  })
  fieldersOptions = orderBy(fieldersOptions, ['sortName'], ['asc'])

  const syncMatchManual = () => {
    const isValidated = handleValidate(true)
    if (isValidated) {
      appSettings.manualScoring.setForceSync(true)
      setTimeout(() => {
        game.setTimestamp()
        triggerInningsStateS3P(true)
      }, 250)
    }
  }
  const createInnings = ({ value }: DropdownCallbackArgs) => {
    if (value) {
      game.createInning(
        value,
        false,
        false,
        'IN_PROGRESS',
        undefined,
        appSettings.timeMachine.baseline && appSettings.timeMachine.activated
          ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
          : undefined
      )
      if (game.getAllInningInOrder.length === 1) {
        // if we are creating the first innings for the match, then we need to send the S3P match status to "in progress"
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.manualScoring.active ? 'postMatch' : appSettings.appMode, game),
          S3PHelpers.matchStatus('IN_PROGRESS_PLAYING', game, undefined, appSettings.manualScoring.active)
        )
      }
      db.createS3PMessage(S3PHelpers.metadata('postMatch', game), S3PHelpers.innings('START', game, undefined, true))
    }
  }
  const deleteInnings = ({ value }: DropdownCallbackArgs) => {
    if (tabIndex > 0) setTabIndex(tabIndex - 1)
    if (value) {
      game.removeInning(value)
    }
  }
  const changeInningsCloseTypeId = (value: string, index: number) => {
    allInnings[index].setCloseReason(value === 'IN_PROGRESS' ? null : value)
    allInnings[index].setStatus(value === 'IN_PROGRESS' ? 'IN_PROGRESS' : 'COMPLETED')
    if (value !== 'IN_PROGRESS') {
      // if we are closing the innings, then we need to send the S3P innings state & finish messages
      triggerInningsStateS3P(true)
      triggerInningsFinishS3P(allInnings[index], value)
    }
  }
  const closeEditDismissal = () => {
    setEditDismissalOpen(false)
    setEditDismissalPerf(null)
    triggerInningsStateS3P()
  }
  const closeDeletePerfAlert = () => {
    setDeletePerfOpen(false)
    setDeletePerf(null)
  }
  const handleFollowOn = (index: number) => {
    allInnings[index].setFollowOn(!allInnings[index].followOn)
  }
  const handleSuperOver = (index: number) => {
    allInnings[index].setSuperOver(!allInnings[index].superOver)
  }
  const changeDismissalType = ({ value }: DropdownCallbackArgs) => {
    if (!editDismissalPerf) return
    const dismissalType = Reference.DismissalMethods.findIndex(d => d === value)
    if (dismissalType > -1) {
      editDismissalPerf.createDismissal(Reference.DismissalMethods.findIndex(d => d === value))
      if (editDismissalPerf.dismissal) {
        const dismissalSnap = cloneDeep(getSnapshot(editDismissalPerf.dismissal))
        editDismissalPerf.updateDismissal(dismissalSnap)
      }
    } else {
      editDismissalPerf.updateDismissal(null, false, true)
    }
  }
  const changeBowlerOrFielder = ({ target, value }: DropdownCallbackArgs) => {
    if (!editDismissalPerf || !editDismissalPerf.dismissal) return
    const dismissalSnap = cloneDeep(getSnapshot(editDismissalPerf.dismissal))
    if (!dismissalSnap) return
    switch (target) {
      case 'bowler':
        dismissalSnap.bowler = fielders?.find((f: IMatchPlayerModel) => f.id === value)?.id || undefined
        break
      case 'fielder1':
      case 'fielder2': {
        const fielderSelected = fielders?.find((f: IMatchPlayerModel) => f.id === value)?.id || undefined
        if (!dismissalSnap.fielders) dismissalSnap.fielders = []
        const fielderOrder = target === 'fielder1' ? 1 : 2
        const existingFielderAtOrder = dismissalSnap.fielders.findIndex(
          (f: SnapshotIn<IDismissalFieldersModel>) => f.order === fielderOrder
        )
        if (existingFielderAtOrder > -1) dismissalSnap.fielders.splice(existingFielderAtOrder, 1)
        if (fielderSelected) {
          dismissalSnap.fielders.push({
            player: fielderSelected,
            order: fielderOrder,
          })
        }
        break
      }
    }
    editDismissalPerf.updateDismissal(dismissalSnap)
  }
  const changeFowOrder = (value: number) => {
    if (!editDismissalPerf || !editDismissalPerf.dismissal) return
    const dismissalSnap = cloneDeep(getSnapshot(editDismissalPerf.dismissal))
    if (!dismissalSnap) return
    dismissalSnap.wicketNumber = value
    editDismissalPerf.updateDismissal(dismissalSnap)
  }
  const changeFowOverOrRuns = ({ id, value }: TextFieldCallbackArgs) => {
    if (!editDismissalPerf || !editDismissalPerf.dismissal) return
    const dismissalSnap = cloneDeep(getSnapshot(editDismissalPerf.dismissal))
    if (!dismissalSnap) return
    switch (id) {
      case 'fowOver':
        dismissalSnap.fowOver = `${value}`
        break
      case 'fowRuns':
        dismissalSnap.fowRuns = Number(value)
        break
    }
    editDismissalPerf.updateDismissal(dismissalSnap)
  }
  const deletePerformance = () => {
    if (!deletePerf) return
    allInnings[tabIndex].removePerformance(deletePerf)
  }
  const handleValidate = (inProgressMatch?: boolean) => {
    const validationProgress: MatchValidationResponse = {
      match: [],
      competition: [],
      players: [],
      officials: [],
      team: [],
      venue: [],
    }
    game.matchTeams.forEach(team => {
      team.innings.forEach(inn => {
        if (inn.getInningStatus === 'IN_PROGRESS' && !inProgressMatch) {
          validationProgress.team.push({
            id: `${team.name} - ${suffixNumber(inn.inningsNumber)} Innings`,
            name: 'Innings must not be "In Progress"',
          })
        }
        if (isNaN(Number(inn.progressiveScores.oversBowled)) || Number(inn.progressiveScores.oversBowled) <= 0) {
          validationProgress.team.push({
            id: `${team.name} - ${suffixNumber(inn.inningsNumber)} Innings`,
            name: 'Overs bowled must be greater than 0',
          })
        }
        if (inn.bowlingPerformances.length === 0) {
          validationProgress.team.push({
            id: `${team.name} - ${suffixNumber(inn.inningsNumber)} Innings`,
            name: 'Must have at least 1 bowler performance',
          })
        }
        if (inn.battingPerformances.length < 2) {
          validationProgress.team.push({
            id: `${team.name} - ${suffixNumber(inn.inningsNumber)} Innings`,
            name: 'Must have at least 2 batter performances',
          })
        }
        if (inn.battingPerformances.filter(perf => perf.notOut)?.length > 2) {
          validationProgress.team.push({
            id: `${team.name} - ${suffixNumber(inn.inningsNumber)} Innings`,
            name: 'Cannot have more than 2 not out batters',
          })
        }
        if (
          game.matchDls &&
          game.matchDls.active &&
          inn.inningsMatchOrder === 2 &&
          (!game.matchDls.targetOvers || !game.matchDls.targetScore)
        ) {
          validationProgress.team.push({
            id: `${team.name} - ${suffixNumber(inn.inningsNumber)} Innings`,
            name: 'Target overs and score must be greater than 0 if DLS is active',
          })
        }
        inn.bowlingPerformances.forEach(perf => {
          if (perf.allOvers === 0) {
            validationProgress.players.push({
              id: `${perf.playerMp.getFullName} (${team.shortName ?? team.name} ${suffixNumber(
                inn.inningsNumber
              )} Innings)`,
              name: 'Bowler must have bowled greater than 0 overs',
            })
          }
        })
      })
    })
    if (validationProgress.team.length > 0 || validationProgress.players.length > 0) {
      setValidationIssues(validationProgress)
      setValidationIssueOpen(true)
      return false
    } else {
      setValidationIssues(null)
      return true
    }
  }

  /* S3P */
  const triggerInningsFinishS3P = (
    inning: IInningModel,
    closeInningType?: typeof Reference.InningEndOptions[number]
  ) => {
    inning.battingPerformances.forEach(perf => {
      if (perf.dismissal) {
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.manualScoring.active ? 'postMatch' : appSettings.appMode, game),
          S3PHelpers.dismissal(game, perf.dismissal.getDismissalType, perf, undefined, appSettings.manualScoring.active)
        )
      }
    })
    db.createS3PMessage(
      S3PHelpers.metadata(appSettings.manualScoring.active ? 'postMatch' : appSettings.appMode, game),
      S3PHelpers.innings('FINISH', game, closeInningType, appSettings.manualScoring.active)
    )
  }
  const triggerInningsStateS3P = (isScoringManually?: boolean) => {
    if (isScoringManually || appSettings.appMode === 'core') {
      const closeReasonId = allInnings[tabIndex].closeReasonId
      db.createS3PMessage(
        S3PHelpers.metadata(appSettings.manualScoring.active ? 'postMatch' : appSettings.appMode, game),
        S3PHelpers.inningsState(
          appSettings.appMode,
          allInnings[tabIndex],
          game,
          balls,
          closeReasonId !== null ? Reference.InningEndOptions[closeReasonId] : undefined
        )
      )
      if (appSettings.appMode === 'core') {
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.manualScoring.active ? 'postMatch' : appSettings.appMode, game),
          S3PHelpers.inningsStatePartnerships(allInnings[tabIndex], game.matchConfigs.ballsPerOver)
        )
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.manualScoring.active ? 'postMatch' : appSettings.appMode, game),
          S3PHelpers.inningsStateSpells(appSettings.appMode, allInnings[tabIndex], game.matchConfigs.ballsPerOver)
        )
      }
    }
  }

  useEffect(() => {
    if (appSettings.manualScoring.active) {
      // pre-process batting performances here for games being resumed, to ensure dismissal has "text" property
      // NB: cannot be done in mobx snapshotProcessor due to the need for instances of Bowler and Fielders, rather than snapshots
      allInnings.map((i: IInningModel) => {
        i.battingPerformances.map((b: IBattingPerformanceModel) => {
          if (!b.text) b.updateDismissal(b.dismissal, b.notOut, true)
          return b
        })
        return i
      })
    }
  }, [allInnings, appSettings.manualScoring.active])

  return (
    <>
      <Flex flex={1} width="100%" justifyContent="center" alignItems="flex-start">
        <Flex
          direction="column"
          paddingX="42px"
          width="100%"
          align="center"
          justify="center"
          maxW={appSettings.appMode === 'core' ? ['100%', '100%', 1280, 1280] : 'unset'}
        >
          {appSettings.manualScoring.active && (
            <Flex
              w="100%"
              marginY="14px"
              padding="14px"
              alignItems="center"
              justifyContent="space-between"
              backgroundColor="white"
              borderRadius="7px"
            >
              <Stack flex={1} spacing="14px" direction="row" align="center" justifyContent="flex-start">
                <>
                  <Box width="150px">
                    <Dropdown
                      target="new_innings"
                      options={allTeams.map((t: IMatchTeamModel) => {
                        return { value: t.id, label: t.name }
                      })}
                      buttonDisable={hasActiveInning}
                      value="+ Add New Innings"
                      placement="bottom"
                      onChange={createInnings}
                      height="40px"
                      preserveCase
                      ignoreState
                      data-testid="newInningsDropdown"
                    />
                  </Box>
                  {allInnings.length > 0 && (
                    <Box width="150px">
                      <Dropdown
                        target="delete_innings"
                        options={inningsInOrder}
                        value="Delete Innings"
                        onChange={deleteInnings}
                        height="40px"
                        colorScheme="red"
                        preserveCase
                        ignoreState
                        data-testid="deleteInningsDropdown"
                      />
                    </Box>
                  )}
                </>
              </Stack>
              <Flex flex={0.5} alignItems="center" justifyContent="center">
                {allInnings.length > 0 && import.meta.env.VITE_ENV_SOCKETS !== 'false' && (
                  <Button
                    height="40px"
                    isDisabled={appSettings.manualScoring.forceSync}
                    colorScheme="teal"
                    onClick={syncMatchManual}
                  >
                    <FontAwesomeIcon icon={['far', 'cloud-upload']} size="sm" style={{ fontSize: '16px' }} />
                  </Button>
                )}
              </Flex>
              <Stack flex={1} direction="row" spacing="14px" alignItems="center" justifyContent="flex-end">
                <>
                  <Box width="150px">
                    <MatchBreak game={game} height="40px" />
                  </Box>
                  {allInnings.length > 0 && (
                    <Box width="150px">
                      <EndMatch
                        game={game}
                        mode={appSettings.appMode}
                        height="40px"
                        isScoringManually={appSettings.manualScoring.active}
                        handleValidate={handleValidate}
                      />
                    </Box>
                  )}
                </>
              </Stack>
            </Flex>
          )}
          <Tabs
            isFitted
            variant="unstyled"
            w="100%"
            margin={{ base: '21px 14px', md: '21px 200px' }}
            index={tabIndex}
            onChange={i => setTabIndex(i)}
          >
            <TabList borderColor="gray.200" marginBottom="7px">
              {allInnings.map((i, index) => (
                <Tab
                  key={index}
                  _first={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
                  _last={{ borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }}
                  backgroundColor={'white'}
                  _selected={{ color: 'white', bg: 'cls.blue.400' }}
                >
                  {matchSettings.scoreFormat === 'WICKETS-RUNS' &&
                    `${i.getBattingTeamShortName} ${i.progressiveScores.wickets}/${i.progressiveScores.runs}`}
                  {matchSettings.scoreFormat === 'RUNS-WICKETS' &&
                    `${i.getBattingTeamShortName} ${i.progressiveScores.runs}/${i.progressiveScores.wickets}`}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {allInnings.map((i, index) => (
                <TabPanel padding="7px" key={index}>
                  <Flex
                    direction="row"
                    justifyContent={appSettings.manualScoring.active && index >= 2 ? 'space-between' : 'flex-end'}
                  >
                    {appSettings.manualScoring.active && index >= 2 && (
                      <Flex direction="row" alignItems="center">
                        <Flex justify="center" align="center">
                          <Checkbox
                            id={`follow-on-${index}`}
                            isChecked={allInnings[index].followOn}
                            colorScheme="cls.yellow"
                            onChange={() => handleFollowOn(index)}
                            backgroundColor="white"
                            data-testid="followOnCheckbox"
                          />
                          <FormLabel htmlFor={`follow-on-${index}`} margin="0 0 0 7px">
                            Follow On?
                          </FormLabel>
                        </Flex>
                        <Flex justify="center" align="center" marginLeft="28px">
                          <Checkbox
                            id={`super-over-${index}`}
                            isChecked={allInnings[index].superOver}
                            colorScheme="cls.yellow"
                            onChange={() => handleSuperOver(index)}
                            backgroundColor="white"
                            data-testid="superOverCheckbox"
                          />
                          <FormLabel htmlFor={`super-over-${index}`} margin="0 0 0 7px">
                            Super Over?
                          </FormLabel>
                        </Flex>
                      </Flex>
                    )}
                    {/** Post match wicket and runs edit */}
                    <Flex direction="column">
                      <Heading fontStyle="italic" textTransform="uppercase" textAlign="right">
                        {matchSettings.scoreFormat === 'WICKETS-RUNS' &&
                          `${i.getBattingTeamName} ${i.progressiveScores.wickets}/${i.progressiveScores.runs}`}
                        {matchSettings.scoreFormat === 'RUNS-WICKETS' &&
                          `${i.getBattingTeamName} ${i.progressiveScores.runs}/${i.progressiveScores.wickets}`}
                        <Text as="span" fontSize="24px" fontWeight={500} marginLeft="7px">
                          ({i.progressiveScores.oversBowled})
                        </Text>
                        <EditIcon
                          data-testid={`inningsEditButton_${i.inningsMatchOrder}`}
                          marginLeft={'16px'}
                          onClick={() => setEditHeadScore(val => !val)}
                          _hover={{ cursor: 'pointer' }}
                        />
                      </Heading>
                      {editHeadScore && (
                        <Box textAlign="right" display="flex" padding="24px 0" justifyContent="right">
                          <EditableField
                            data-testid={`inningsWickets_${i.inningsMatchOrder}`}
                            value={i.progressiveScores.wickets ?? 0}
                            type="number"
                            width={'15'}
                            pattern="^\d+$"
                            errorMessage="Please enter numbers only"
                            background="white"
                            label="Wickets"
                            onChange={(value: number) => {
                              const val = value - (i.progressiveScores.wickets || 0)
                              if (val !== 0 && appSettings.appMode === 'core') {
                                db.createS3PMessage(
                                  S3PHelpers.metadata(appSettings.appMode, game),
                                  S3PHelpers.manualWicketChange(val, game, i)
                                )
                              }
                              i?.setWickets(value)
                              if (val !== 0) {
                                triggerInningsStateS3P()
                              }
                            }}
                          />
                          <EditableField
                            data-testid={`inningsRuns_${i.inningsMatchOrder}`}
                            value={i.progressiveScores.runs ?? 0}
                            type="number"
                            width={'15'}
                            pattern="^\d+$"
                            errorMessage="Please enter numbers only"
                            background="white"
                            marginLeft={'16px'}
                            label="Runs"
                            onChange={(value: number) => {
                              const val = value - (i.progressiveScores.runs || 0)
                              if (val !== 0 && appSettings.appMode === 'core') {
                                db.createS3PMessage(
                                  S3PHelpers.metadata(appSettings.appMode, game),
                                  S3PHelpers.manualScoreChange(val, game, undefined, i)
                                )
                              }
                              i?.setRuns(value)
                              game.setDescription(
                                MatchHelpers.gameDescriptionString(game, appSettings.manualScoring.active)
                              )
                              if (val !== 0) {
                                triggerInningsStateS3P()
                              }
                            }}
                          />
                          <EditableField
                            data-testid={`inningsOvers_${i.inningsMatchOrder}`}
                            value={i.progressiveScores.oversBowled ?? 0}
                            width={'15'}
                            pattern="^\d{0,4}(?:\.[0-5]{1})?$"
                            errorMessage="Please enter numbers, or decimals up to 1 digit"
                            background="white"
                            marginLeft={'16px'}
                            label="Overs"
                            onChange={(value: string) => {
                              i?.setOvers(value)
                              triggerInningsStateS3P()
                            }}
                          />
                          {appSettings.manualScoring.active && index <= 1 && (
                            <Flex direction="row" mx={game.matchDls?.active ? '35px' : '14px'}>
                              <Checkbox
                                colorScheme="cls.yellow"
                                borderColor="black"
                                isChecked={game.matchDls && game.matchDls.active ? true : false}
                                onChange={() => {
                                  if (!game.matchDls) {
                                    game.createDlsObject()
                                  } else {
                                    game.clearDlsObject()
                                  }
                                }}
                                data-testid={`inningsDls_${i.inningsMatchOrder}`}
                              >
                                DLS?
                              </Checkbox>
                              {game.matchDls?.active && (
                                <>
                                  {index === 1 && (
                                    <EditableField
                                      data-testid={`inningsDlsTargetScore_${i.inningsMatchOrder}`}
                                      value={game.matchDls?.targetScore ?? 0}
                                      type="number"
                                      width={'15'}
                                      pattern="^\d+$"
                                      errorMessage="Please enter numbers only"
                                      background="white"
                                      marginLeft={'16px'}
                                      label="Target Score"
                                      onChange={(value: number) => {
                                        game.updateDlsTargetScore(value)
                                        game.setDescription(
                                          MatchHelpers.gameDescriptionString(game, appSettings.manualScoring.active)
                                        )
                                      }}
                                    />
                                  )}
                                  <EditableField
                                    data-testid={`inningsDlsTargetOvers_${i.inningsMatchOrder}`}
                                    value={game.matchDls?.targetOvers ?? 0}
                                    width={'15'}
                                    pattern="^\d{0,4}(?:\.[0-5]{1})?$"
                                    errorMessage="Please enter numbers, or decimals up to 1 digit"
                                    background="white"
                                    marginLeft={'16px'}
                                    label="Target Overs"
                                    onChange={(val: string) => {
                                      game.updateDlsTargetOvers(Number(val))
                                    }}
                                  />
                                </>
                              )}
                            </Flex>
                          )}
                          {appSettings.manualScoring.active && (
                            <Box pl={index === 1 ? '0px' : '14px'}>
                              <Dropdown
                                options={inningEndOptions}
                                width="130px"
                                height="40px"
                                onChange={({ value }) => {
                                  if (value) changeInningsCloseTypeId(value, index)
                                }}
                                value={
                                  !isNil(i.closeReasonId) ? Reference.InningEndOptions[i.closeReasonId] : 'IN_PROGRESS'
                                }
                                data-testid={`inningsScorebookClose${index + 1}`}
                                isWhite
                                ignoreState
                                placement="bottom"
                                lastOptionSeparated
                              />
                            </Box>
                          )}
                        </Box>
                      )}
                    </Flex>
                  </Flex>
                  <Box marginY="14px">
                    <Batting
                      inning={i}
                      game={game}
                      mode={appSettings.appMode}
                      matchSettings={matchSettings}
                      scoreManually={appSettings.manualScoring.active}
                      setEditDismissalPerf={setEditDismissalPerf}
                      setEditDismissalOpen={setEditDismissalOpen}
                      setDeletePerf={setDeletePerf}
                      setDeletePerfOpen={setDeletePerfOpen}
                      triggerInningsState={triggerInningsStateS3P}
                    />
                    <Extras inning={i} noBallRuns={game.matchConfigs.noBallRuns} />
                  </Box>
                  <Box marginY="14px">
                    <Bowling
                      inning={i}
                      game={game}
                      scoreManually={appSettings.manualScoring.active}
                      ballsPerOver={game.matchConfigs.ballsPerOver || 6}
                      triggerInningsState={triggerInningsStateS3P}
                      setDeletePerf={setDeletePerf}
                      setDeletePerfOpen={setDeletePerfOpen}
                    />
                  </Box>
                  {!appSettings.manualScoring.active && (
                    <Box marginY="14px">
                      <Partnerships inning={i} />
                    </Box>
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
      <Modal isOpen={editDismissalOpen} onClose={closeEditDismissal} size="xl" closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Edit Dismissal — ${editDismissalPerf?.playerMp.getDisplayName()}`}</ModalHeader>
          <ModalBody>
            <Flex w="100%" direction="column" justifyContent="center">
              <Flex h="60px" direction="row">
                <Flex flex={1} alignItems="center">
                  <Text fontWeight="bold">Type:</Text>
                </Flex>
                <Flex flex={1.75} alignItems="center">
                  <Dropdown
                    options={dismissalOptions}
                    value={
                      editDismissalPerf?.dismissal?.dismissalTypeId !== null &&
                      editDismissalPerf?.dismissal?.dismissalTypeId !== undefined
                        ? Reference.DismissalMethods[editDismissalPerf?.dismissal?.dismissalTypeId]
                        : 'WICKET TYPE'
                    }
                    origValue="WICKET TYPE"
                    onChange={changeDismissalType}
                    listHeight="215px"
                    placement="right-end"
                    hideCheck={true}
                    isClearable={true}
                    preserveCase
                    data-testid="editDismissalWicketDismissalTypeDropdown"
                  />
                </Flex>
              </Flex>
              <Flex h="60px" direction="row">
                <Flex flex={1} alignItems="center">
                  <Text fontWeight="bold">Bowler:</Text>
                </Flex>
                <Flex flex={1.75} alignItems="center">
                  <Dropdown
                    target="bowler"
                    options={fieldersOptions}
                    globalDisable={
                      !editDismissalPerf ||
                      !editDismissalPerf?.dismissal ||
                      isNil(editDismissalPerf.dismissal.dismissalTypeId)
                    }
                    origValue="Select Bowler"
                    value={
                      editDismissalPerf?.dismissal?.bowler
                        ? `${editDismissalPerf.dismissal.bowler.getDisplayName()}`
                        : 'Select Bowler'
                    }
                    onChange={changeBowlerOrFielder}
                    placement="bottom-start"
                    hideCheck
                    isClearable
                    preserveCase
                    data-testid="editDismissalBowlerDropdown"
                  />
                </Flex>
              </Flex>
              <Flex h="60px" direction="row">
                <Flex flex={1} alignItems="center">
                  <Text fontWeight="bold">Fielder #1:</Text>
                </Flex>
                <Flex flex={1.75} alignItems="center">
                  <Dropdown
                    target="fielder1"
                    options={fieldersOptions}
                    globalDisable={
                      !editDismissalPerf ||
                      !editDismissalPerf?.dismissal ||
                      isNil(editDismissalPerf.dismissal.dismissalTypeId) ||
                      (!isNil(editDismissalPerf.dismissal.dismissalTypeId) &&
                        indexOf(
                          Reference.DismissalMethodsFielder1,
                          Reference.DismissalMethods[editDismissalPerf.dismissal.dismissalTypeId]
                        ) === -1)
                    }
                    origValue="Select Fielder #1"
                    value={
                      editDismissalPerf?.dismissal?.fielders && editDismissalPerf.dismissal.fielders.length > 0
                        ? `${editDismissalPerf.dismissal.fielders[0].player.getDisplayName()}`
                        : 'Select Fielder #1'
                    }
                    onChange={changeBowlerOrFielder}
                    placement="bottom-start"
                    hideCheck
                    isClearable
                    preserveCase
                    data-testid="editDismissalFirstFielderDropdown"
                  />
                </Flex>
              </Flex>
              <Flex h="60px" direction="row">
                <Flex flex={1} alignItems="center">
                  <Text fontWeight="bold">Fielder #2:</Text>
                </Flex>
                <Flex flex={1.75} alignItems="center">
                  <Dropdown
                    target="fielder2"
                    options={fieldersOptions}
                    globalDisable={
                      !editDismissalPerf ||
                      !editDismissalPerf?.dismissal ||
                      isNil(editDismissalPerf.dismissal.dismissalTypeId) ||
                      (!isNil(editDismissalPerf.dismissal.dismissalTypeId) &&
                        indexOf(
                          Reference.DismissalMethodsFielder2,
                          Reference.DismissalMethods[editDismissalPerf.dismissal.dismissalTypeId]
                        ) === -1)
                    }
                    origValue="Select Fielder #2"
                    value={
                      editDismissalPerf?.dismissal?.fielders && editDismissalPerf.dismissal.fielders.length > 1
                        ? `${editDismissalPerf.dismissal.fielders[1].player.getDisplayName()}`
                        : 'Select Fielder #2'
                    }
                    onChange={changeBowlerOrFielder}
                    placement="bottom-start"
                    hideCheck
                    isClearable
                    preserveCase
                    data-testid="editDismissalSecondFielderDropdown"
                  />
                </Flex>
              </Flex>
              <Flex h="60px" direction="row" marginTop="7px">
                <Flex flex={1} alignItems="center">
                  <Text fontWeight="bold">Fall of Wicket:</Text>
                </Flex>
                <Flex flex={1.75} alignItems="flex-start" direction="row">
                  <Flex flex={1} direction="column">
                    <SubHeading text="Wicket #" tertiary noPadding />
                    <Box pt="4px">
                      <Stepper
                        id="wicketNumber"
                        value={editDismissalPerf?.dismissal?.wicketNumber ?? 0}
                        onChange={changeFowOrder}
                        min={0}
                        max={fielders?.length ? fielders.length - 1 : 10}
                        ignoreState
                        isDisabled={
                          !editDismissalPerf ||
                          !editDismissalPerf?.dismissal ||
                          isNil(editDismissalPerf.dismissal.dismissalTypeId)
                        }
                      />
                    </Box>
                  </Flex>
                  <Flex flex={1} direction="column" alignItems="center" marginLeft="14px">
                    <SubHeading text="Runs" tertiary noPadding />
                    <TextField
                      id="fowRuns"
                      type="number"
                      value={`${editDismissalPerf?.dismissal?.fowRuns ?? 0}`}
                      isDisabled={!editDismissalPerf?.dismissal}
                      onChange={changeFowOverOrRuns}
                      data-testid="editDismissalFowRuns"
                      ignoreState
                    />
                  </Flex>
                  <Flex flex={1} direction="column" alignItems="center" marginLeft="14px">
                    <SubHeading text="Over.Ball" tertiary noPadding />
                    <TextField
                      id="fowOver"
                      type="number"
                      value={editDismissalPerf?.dismissal?.fowOver || '0'}
                      isDisabled={!editDismissalPerf?.dismissal}
                      onChange={changeFowOverOrRuns}
                      data-testid="editDismissalFowOver"
                      ignoreState
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={closeEditDismissal}
              size="md"
              colorScheme="green"
              ml={3}
              data-testid="doneEditDismissalButton"
            >
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={deletePerfOpen}
        leastDestructiveRef={cancelDeletePerf}
        onClose={() => closeDeletePerfAlert()}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Performance
          </AlertDialogHeader>
          <AlertDialogBody>
            {`Are you sure you wish to delete the performance for ${deletePerf?.playerMp.getDisplayName()}?`}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={() => closeDeletePerfAlert()}>Cancel</Button>
            <Button
              colorScheme="red"
              onClick={() => {
                deletePerformance()
                closeDeletePerfAlert()
                triggerInningsStateS3P()
              }}
              ml={3}
              textTransform="capitalize"
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {appSettings.manualScoring.active && (
        <MatchValidation
          title="Post-match validation"
          message="Please action each issue below before syncing data or ending the match:"
          issues={validationIssues}
          isOpen={validationIssueOpen}
          onClose={() => setValidationIssueOpen(false)}
          separateById={true}
        />
      )}
    </>
  )
})

export default Scorebook
