import createHash from 'create-hash'
import type { JwtPayload } from 'jwt-decode'
import jwt_decode from 'jwt-decode'
import randomBytes from 'randombytes'

import { createKinesisClient } from './kinesisHelper'

type UserProfile = JwtPayload & {
  at_hash?: string
  auth_time?: number
  'cognito:groups'?: string[]
  'cognito:username'?: string
  email?: string
  email_verified?: boolean
  event_id?: string
  family_name?: string
  given_name?: string
  token_use?: string
}

const Auth = (function () {
  const SCOPE = 'email+openid+profile+aws.cognito.signin.user.admin'
  const accessToken = window.localStorage.getItem('cls_plus_accessToken')
  const idToken = window.localStorage.getItem('cls_plus_idToken')
  const refreshToken = window.localStorage.getItem('cls_plus_refreshToken')
  let userProfile = idToken ? jwt_decode<UserProfile>(idToken) : null

  let tokens =
    accessToken === null
      ? null
      : {
          accessToken,
          idToken,
          refreshToken,
        }
  const base64URLEncode = (str: Buffer) => {
    return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
  }
  const sha256 = (buffer: Buffer | string) => {
    return createHash('sha256').update(buffer).digest()
  }
  let storedVerifier = window.localStorage.getItem('cls_plus_verifier')
  if (storedVerifier === null) {
    const newVerifier = base64URLEncode(randomBytes(32))
    window.localStorage.setItem('cls_plus_verifier', newVerifier)
    storedVerifier = newVerifier
  }
  const verifier = storedVerifier
  const challenge = base64URLEncode(sha256(storedVerifier))
  const baseUrl = import.meta.env.VITE_AUTH_BASE_URL
  const clientId = import.meta.env.VITE_AUTH_CLIENT_ID
  const redirectUri = import.meta.env.VITE_AUTH_REDIRECT_URI
  const tokenUrl = `${baseUrl}/oauth2/token`

  /* Exposed Funcs */
  let loginUrl = `${baseUrl}/login?response_type=code`
  loginUrl += `&scope=${SCOPE}`
  loginUrl += `&client_id=${clientId}`
  loginUrl += `&code_challenge=${challenge}`
  loginUrl += '&code_challenge_method=S256'
  loginUrl += `&redirect_uri=${redirectUri}`
  const logoutUrl = `${baseUrl}/oauth2/authorize?client_id=${clientId}&logout_uri=${redirectUri}`
  const getTokens = () => tokens
  const getUserProfile = () => userProfile
  const logout = async () => {
    window.localStorage.removeItem('cls_plus_accessToken')
    window.localStorage.removeItem('cls_plus_idToken')
    window.localStorage.removeItem('cls_plus_refreshToken')
    tokens = null
    userProfile = null
  }
  const login = async (code: string) => {
    // eslint-disable-next-line max-len
    const body = `grant_type=authorization_code&client_id=${clientId}&code_verifier=${verifier}&code=${code}&redirect_uri=${redirectUri}`
    const response = await fetch(tokenUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body,
    })
    if (!response.ok) {
      throw Error()
    }
    const { access_token, id_token, refresh_token } = await response.json()
    window.localStorage.setItem('cls_plus_accessToken', access_token)
    window.localStorage.setItem('cls_plus_idToken', id_token)
    window.localStorage.setItem('cls_plus_refreshToken', refresh_token)
    tokens = {
      accessToken: access_token,
      idToken: id_token,
      refreshToken: refresh_token,
    }
    await createKinesisClient(id_token)
    userProfile = jwt_decode<UserProfile>(id_token)
  }
  const refreshTokens = async (refreshToken: string) => {
    const body = `grant_type=refresh_token&client_id=${clientId}&refresh_token=${refreshToken}`
    const response = await fetch(tokenUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body,
    })
    if (!response.ok) {
      // basically if the refresh did not work, we should log them out
      logout()
      return false
    }
    const { access_token, id_token } = await response.json()
    window.localStorage.setItem('cls_plus_accessToken', access_token)
    window.localStorage.setItem('cls_plus_idToken', id_token)
    window.localStorage.setItem('cls_plus_refreshToken', refreshToken)
    tokens = {
      accessToken: access_token,
      idToken: id_token,
      refreshToken,
    }
    userProfile = jwt_decode<UserProfile>(id_token)
    try {
      await createKinesisClient(id_token)
    } catch (e) {
      console.warn('Failed to create Kinesis client, logging user out', e)
      logout()
      return false
    }

    return tokens
  }
  const checkTokens = async (accessToken: string | null, idToken: string | null, refreshToken: string | null) => {
    if (!accessToken || !refreshToken) {
      return false
    }
    if (accessToken === 'ZMU2X6DKfqWqi5BuInAc') return true
    const decodedToken = jwt_decode<UserProfile>(accessToken)
    // if the expiry of this token is not beyond 12 hours from now, then we will refresh the token
    const targetTime = Math.floor(Date.now() / 1000) + 43200 // now plus 12 hours
    if (decodedToken && decodedToken.exp && refreshToken) {
      if (decodedToken.exp > targetTime) {
        // Token has a valid date so try to create the kinesis client
        try {
          await createKinesisClient(idToken)
          return true
        } catch (e) {
          console.warn('Failed to create Kinesis client, logging user out', e)
          logout()
          return false
        }
      } else {
        // Token has expired so try to refresh
        console.warn('Found expired token... attempting refresh')
        const result = await refreshTokens(refreshToken)
        return !!result
      }
    } else {
      // we have no token - log out?
      logout()
      return false
    }
  }
  return {
    checkTokens: checkTokens,
    refreshTokens: refreshTokens,
    login: login,
    logout: logout,
    getUserProfile: getUserProfile,
    getTokens: getTokens,
    logoutUrl: logoutUrl,
    loginUrl: loginUrl,
  }
})()

export default Auth
