import { Alert, AlertIcon, Box, Flex, Text } from '@chakra-ui/react'
import type { IMatchPlayerModel } from '@clsplus/cls-plus-data-models'
import { isNil } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { Button } from '../components/Buttons/Button'
import { SimpleStateButton } from '../components/Buttons/SimpleStateButton'
import ControlsPanel from '../components/ControlsPanel/ControlsPanel'
import { toCamelCase } from '../helpers/generalHelpers'
import type { SelectPlayerProps } from '../types/props'

const SelectPlayer = observer(
  ({
    title,
    subtitle,
    primaryButton,
    cancelButton,
    mode,
    players,
    playersDisabled,
    playersNotRetiredNotOut,
    playerPreselected,
    playerToSwap,
    onChange,
    onCancel,
    handleEndInning,
    controlsPanelProps,
    setup = false,
    type,
  }: SelectPlayerProps) => {
    const showNoCancelMessage =
      (mode === 'core' && import.meta.env.VITE_ENV_BETTING_EDITING_DISABLED === 'true') || (type && !onCancel)
    const [newPlayer, setNewPlayer] = useState<IMatchPlayerModel | null>(playerPreselected || null)

    const updateNewPlayer = ({ value }: { value: IMatchPlayerModel }) => {
      if (!value) return
      if (newPlayer?.id === value.id) {
        setNewPlayer(null)
      } else {
        setNewPlayer(value)
      }
    }

    const selectedPlayer = () => {
      if (!newPlayer) return
      // check if the player selected is missing any required properties. if so, set to default values
      if (mode !== 'fielding') {
        if (newPlayer?.player.battingHandedId === null) newPlayer.setBattingHandedId(1) // RIGHT
        if (newPlayer?.player.bowlingHandedId === null) newPlayer.setBowlingHandedId(1) // RIGHT
        if (newPlayer?.player.primaryThrowingArmId === null) newPlayer.setPrimaryThrowingArmId(1) // RIGHT
        if (newPlayer?.player.bowlingTypeId === null) newPlayer.setBowlingTypeId(2) // SLOW
      }

      onChange(newPlayer, type, mode === 'fielding' && playerToSwap ? playerToSwap : undefined)
    }

    const testId = title ? toCamelCase(title.toLocaleUpperCase()) : undefined

    return (
      <Flex flexDirection="column" padding={'0px 14px 14px'} w="100%" maxW={['100%', '100%', 750, 750]}>
        <Box paddingTop="14px">
          <Text
            textTransform="uppercase"
            fontWeight={600}
            fontSize="20px"
            fontStyle="italic"
            letterSpacing="-0.5px"
            lineHeight="20px"
          >
            {`${title || 'Select New Player'}`}
          </Text>
          {subtitle && (
            <Text
              textTransform="uppercase"
              fontWeight={600}
              fontSize="16px"
              fontStyle="italic"
              letterSpacing="-0.5px"
              lineHeight="16px"
              color="cls.gray.400"
            >
              {subtitle}
            </Text>
          )}
        </Box>
        <Flex flexDirection={'column'} paddingTop="14px">
          <Flex flex={8.5} flexDirection="column">
            {players?.map(player => (
              <Box marginBottom="7px" key={player.id} width="100%">
                <SimpleStateButton
                  key={player.id}
                  onClick={updateNewPlayer}
                  value={player}
                  isActive={newPlayer?.id === player.id}
                  isDisabled={playersDisabled?.find(pd => pd.id === player.id) ? true : false}
                  width="100%"
                  isWhite={mode !== 'advanced' || (mode === 'advanced' && setup)}
                  data-testid={player.getDisplayName() + '_updateNewPlayer'}
                >
                  {`${player.getDisplayName()}${player.getShirtNumber(true)}`}
                </SimpleStateButton>
              </Box>
            ))}
          </Flex>
          <Flex flex={1.7} flexDirection="row">
            {onCancel && (
              <Flex flex={1} padding={'14px 14px 7px 0'}>
                <Button
                  colorScheme={mode === 'advanced' ? 'gray' : 'cls.white'}
                  color="cls.black"
                  onClick={onCancel}
                  width="100%"
                  height="87px"
                  data-testid="cancelSelectPlayerButton"
                >
                  {cancelButton || 'Cancel'}
                </Button>
              </Flex>
            )}
            <Flex flex={3} direction="column" padding={'14px 0 7px 0'}>
              <Button
                w="100%"
                colorScheme="cls.blue"
                onClick={() => selectedPlayer()}
                isDisabled={!newPlayer}
                width="100%"
                height="87px"
                data-testid={testId ? testId : 'select'}
              >
                {primaryButton || 'Select'}
              </Button>
              {showNoCancelMessage && !primaryButton && (
                <Box marginTop="16px">
                  <Alert status="warning">
                    <AlertIcon />
                    Dismissal cancel disabled. Please complete the wicket and then undo the ball or dismissal from the
                    Activity Log if you need to cancel.
                  </Alert>
                </Box>
              )}
            </Flex>
            {!isNil(playersNotRetiredNotOut) && playersNotRetiredNotOut.length === 0 && handleEndInning && (
              <Flex flex={2} padding={'14px 0 7px 28px'}>
                <Button
                  w="100%"
                  colorScheme="red"
                  onClick={() => handleEndInning('ALL_OUT', true, type)}
                  width="100%"
                  height="87px"
                  data-testid="endInningsAllOutButton"
                >
                  End Innings (All Out)
                </Button>
              </Flex>
            )}
          </Flex>
          {controlsPanelProps && mode === 'core' && (
            <Flex flex={1} backgroundColor="cls.white.400" padding="14px" marginTop="21px" borderRadius="7px">
              <ControlsPanel {...controlsPanelProps} mode="core" isSelectPlayer={true} />
            </Flex>
          )}
        </Flex>
      </Flex>
    )
  }
)

export default SelectPlayer
