/* eslint-disable no-console */

import { Box, Flex, useToast } from '@chakra-ui/react'
import type {
  BallModel,
  IBallModel,
  IBowlingPerformanceModel,
  IFieldingPlacementModel,
  IFieldingPositionModel,
  IInningModel,
  IMatchOfficialModel,
  IMatchPlayerModel,
  ITimelineEventModel,
  IVenueEndModel,
  PlayerToSwap,
} from '@clsplus/cls-plus-data-models'
import {
  addBallToPerformance,
  bowlingPerformanceAddBallPrepare,
  bowlingPerformanceRemoveBallCleanup,
  closeSpellForBowler,
  closeSpellForPreviousBallForBowler,
  removeBallFromPerformance,
} from '@clsplus/cls-plus-data-models'
import { getOversRemaining } from '@clsplus/cricket-logic'
import { cloneDeep, each, find, includes, indexOf, isNil, map, orderBy, startsWith, take } from 'lodash'
import { observer } from 'mobx-react-lite'
import type { SnapshotOrInstance } from 'mobx-state-tree'
import { applySnapshot, getSnapshot } from 'mobx-state-tree'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import ActivityLog from '../components/ActivityLog/ActivityLog'
import { DescriptionSection } from '../components/BallDescription/DescriptionSection'
import { BetweenInning } from '../components/BetweenInning/BetweenInning'
import { Button } from '../components/Buttons/Button'
import ControlsPanel from '../components/ControlsPanel/ControlsPanel'
import { ShortScorecard } from '../components/Scorecard/Short'
import { ScoringAlerts } from '../components/ScoringAlerts/ScoringAlerts'
import { db } from '../data/dexie/Database'
import * as Reference from '../data/reference'
import { addBallToTimeline } from '../data/stores/rootStore'
import BallHelpers from '../helpers/ballHelpers'
import CommsHelpers from '../helpers/commsHelpers'
import { suffixNumber, swapObjectValues, timeMachineDate } from '../helpers/generalHelpers'
import MatchHelpers from '../helpers/matchHelpers'
import S3PHelpers from '../helpers/s3pHelpers'
import Theme from '../theme/theme'
import type {
  BallEditContainerModel,
  BowlerChangeType,
  CascadeEditProps,
  InningInOrder,
  TriggerEditBallArgs,
} from '../types'
import type { IInningMetaModel } from '../types/models'
import type { ScoringProps } from '../types/props'
import InBallAdvanced from './InBall/InBallAdvanced'
import InBallCore from './InBall/InBallCore'
import InBallFielding from './InBall/InBallFielding'
import PreBallAdvanced from './PreBall/PreBallAdvanced'
import PreBallCore from './PreBall/PreBallCore'
import PreBallFielding from './PreBall/PreBallFielding'
import SelectPlayer from './SelectPlayer'

export const Scoring = observer(
  ({
    game,
    balls,
    appSettings,
    timelineEvents,
    relevantTimelineEvents,
    fieldingPlacements,
    inBall,
    endOver,
    endInning,
    closedInning,
    betweenOvers,
    awaitingFirstBallOfOver,
    dismissal,
    retired,
    nonBallDismissal,
    mistake,
    changeBowler,
    mistakeBowler,
    changeBowlerBallOver,
    changeFielder,
    fieldHasChanged,
    scorePassedIsOpen,
    oversPassedIsOpen,
    wicketsPassedIsOpen,
    powerPlayStartIsOpen,
    venueEndsIsOpen,
    umpireEndsIsOpen,
    umpireEnds,
    cascadeEditIsOpen,
    cascadeEditProps,
    cascadeFromEditBallDismissed,
    editBall,
    editBallProps,
    editBallPrimary,
    batterPerformance,
    bowlerPerformance,
    currentBall,
    ballsSnapshot,
    ballRunsVal,
    ballExtrasVal,
    currentInning,
    commentaryChanged,
    cancelledDismissal,
    insertingBall,
    setInBall,
    setEndOver,
    setEndInning,
    setClosedInning,
    setBetweenOvers,
    setAwaitingFirstBallOfOver,
    setDismissal,
    setRetired,
    setNonBallDismissal,
    setMistake,
    setChangeBowler,
    setMistakeBowler,
    setChangeBowlerBallOver,
    setChangeFielder,
    setFieldHasChanged,
    setScorePassedIsOpen,
    setOversPassedIsOpen,
    setWicketsPassedIsOpen,
    setPowerPlayStartIsOpen,
    setVenueEndsIsOpen,
    setUmpireEndsIsOpen,
    setUmpireEnds,
    setCascadeEditIsOpen,
    setCascadeEditProps,
    setCascadeFromEditBallDismissed,
    setEditBall,
    setEditBallProps,
    setEditBallPrimary,
    setBatterPerformanceToChange,
    setBowlerPerformanceToChange,
    setCurrentBall,
    setBallsSnapshot,
    setBallRunsVal,
    setBallExtrasVal,
    setCurrentInning,
    setCommentaryChanged,
    setCancelledDismissal,
    setInsertingBall,
  }: ScoringProps) => {
    const coreModeEditingDisabled =
      appSettings.appMode === 'core' && import.meta.env.VITE_ENV_BETTING_EDITING_DISABLED === 'true'
    const navigate = useNavigate()
    const activeInning: IInningModel | undefined = game.getActiveInning(closedInning)
    const matchSettings = appSettings.getMatchSettings(game.id)
    const eligibleBatters: IMatchPlayerModel[] | undefined = game.getEligibleBattingPlayersInBattingOrder()
    const eligibleBattersOmitRetiredNotOut: IMatchPlayerModel[] | undefined =
      game.getEligibleBattingPlayersInBattingOrder(true)
    const disableInterfaceEndInning =
      (dismissal || endInning) &&
      !editBall &&
      eligibleBattersOmitRetiredNotOut &&
      eligibleBattersOmitRetiredNotOut.length === 0
    const previousOverWasDifferentEnd =
      currentBall && currentBall.overNumber > 0
        ? currentBall.getOver(currentBall.overNumber - 1).length > 0
          ? currentBall.getOver(currentBall.overNumber - 1)[0].venueEnd?.id !== currentBall.venueEnd?.id
          : true
        : true
    const toast = useToast()

    const inningsInOrder: InningInOrder[] = map(game.getAllInningInOrder, (innings: IInningModel) => {
      const team = game.getTeamById(innings.battingTeamId)
      const inningsNum = innings.superOver ? `Sup ${innings.inningsNumber - 1}` : suffixNumber(innings.inningsNumber)
      return {
        value: innings.id,
        label: `${team?.shortName ? team?.shortName : team?.name} ${inningsNum}`,
        inningsMatchOrder: innings.inningsMatchOrder,
        isActiveInning: innings.inningsMatchOrder === activeInning?.inningsMatchOrder,
        superOver: innings.superOver,
        startTime: innings.startTime,
      }
    })
    const rehydrateCurrentInning = useCallback(() => {
      if (!activeInning) return undefined
      const inningsNum = activeInning.superOver
        ? `Sup ${activeInning.inningsNumber - 1}`
        : suffixNumber(activeInning.inningsNumber)
      return {
        id: activeInning.id,
        value: `${
          game.getBattingTeam(activeInning.id)?.shortName
            ? game.getBattingTeam(activeInning.id)?.shortName
            : game.getBattingTeam(activeInning.id)?.name
        } ${inningsNum}`,
        isActiveInning: true,
        inning: activeInning,
      }
    }, [activeInning, game])

    let activeInningMetaData: IInningMetaModel | undefined
    let confirmedBalls: IBallModel[] = []
    let lastBall: IBallModel | undefined

    if (activeInning && currentInning && game) {
      activeInningMetaData = appSettings.getInningMetaData(game.id, activeInning.inningsMatchOrder)
      confirmedBalls = balls.confirmedBallsInOrder(currentInning.id)
      lastBall = balls.lastBall(activeInning.id)
    }

    const powerPlayTriggers = MatchHelpers.getPowerPlayTriggers(game)

    const closeBallVals = () => {
      setBallRunsVal(null)
      setBallExtrasVal(null)
    }

    const deadBall = () => {
      if (appSettings.appMode === 'fielding' && editBall) {
        cancelEditBall()
      } else {
        setAwaitingFirstBallOfOver(currentBall?.ballNumber === 1)
        if (activeInning && appSettings.appMode !== 'fielding') {
          timelineEvents?.addDeadBall(
            activeInning.inningsMatchOrder,
            appSettings.timeMachine.baseline,
            appSettings.timeMachine.activated
          )
        }
        const currentBallBallNumber = currentBall?.ballNumber
        let currentBallFieldingPositions: SnapshotOrInstance<IFieldingPlacementModel>[] | undefined | null
        if (appSettings.appMode === 'fielding' && currentBall?.fieldingAnalysis?.fieldingPositions) {
          currentBallFieldingPositions = getSnapshot(currentBall.fieldingAnalysis.fieldingPositions)
        }
        if (currentBall && activeInning) {
          // remove dead ball here so that it triggers a socket message
          balls.removeBall({
            inningsId: activeInning.id,
            matchId: game.id,
            ballId: currentBall.id,
            deadBall: true,
            ballComplete: !!currentBall.timestamps.confirmed,
            overId: currentBall.overId,
          })
          if (appSettings.appMode === 'core') {
            db.createS3PMessage(S3PHelpers.metadata(appSettings.appMode, game), S3PHelpers.deadBall(game, currentBall))
          }
        }
        createNewBall(currentBallBallNumber === 1, undefined, currentBallFieldingPositions)
        setInBall(false)
        setCancelledDismissal(false)
        setCommentaryChanged(false)
        if (setFieldHasChanged) setFieldHasChanged(false)
        closeBallVals()
      }
    }

    const isPrimaryEditCheck = () => {
      if (activeInning && editBall && !editBallPrimary) {
        setEditBallPrimary(true)
        timelineEvents?.addEditStart(
          `Edit Started (Over ${editBall?.overNumber}.${editBall?.ballDisplayNumber})`,
          activeInning.inningsMatchOrder,
          appSettings.timeMachine.baseline,
          appSettings.timeMachine.activated
        )
      }
    }

    const cancelEditBall = (endOfOver = false, resyncingBall = false) => {
      if (insertingBall) {
        setInsertingBall(false)
      } else {
        // trigger edit cancel event
        if (appSettings.appMode !== 'fielding' && activeInning && editBallPrimary && !resyncingBall) {
          timelineEvents?.addEditCancel(
            `Edit Cancelled (Over ${editBall?.overNumber}.${editBall?.ballDisplayNumber})`,
            activeInning.inningsMatchOrder,
            appSettings.timeMachine.baseline,
            appSettings.timeMachine.activated
          )
        }
        if (appSettings.appMode !== 'fielding' || !closedInning) {
          applySnapshot(balls, ballsSnapshot)
        }
      }
      setInBall(false)
      setCancelledDismissal(false)
      setCommentaryChanged(false)
      closeBallVals()
      setEditBall(undefined)
      setEditBallProps(undefined)
      setEditBallPrimary(false)
      setBallsSnapshot(undefined)
      if (!endInning && !closedInning) {
        createNewBall((endOfOver && awaitingFirstBallOfOver) || awaitingFirstBallOfOver)
      } else {
        setCurrentBall(lastBall)
      }
    }

    const bowlerRunningIn = () => {
      if (currentInning && !currentInning.isActiveInning) setCurrentInning(rehydrateCurrentInning())
      if (appSettings.appMode !== 'fielding') {
        const currentMatchBreak = game?.getActiveMatchBreak
        if (currentMatchBreak) {
          game?.updateMatchBreak(
            null,
            false,
            appSettings.timeMachine.baseline && appSettings.timeMachine.activated
              ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
              : undefined
          )
          timelineEvents?.addBreakEnd(
            `${currentMatchBreak.getMatchBreakType?.replace(/_/g, ' ')} complete`,
            currentMatchBreak,
            currentInning.inning.inningsMatchOrder,
            appSettings.timeMachine.baseline,
            appSettings.timeMachine.activated
          )
          if (appSettings.appMode === 'core' && currentMatchBreak.getMatchBreakType) {
            db.createS3PMessage(
              S3PHelpers.metadata(appSettings.appMode, game),
              S3PHelpers.matchBreak('STOP', currentMatchBreak.getMatchBreakType || null, game)
            )
          }
        }
        if (activeInning?.getActivePowerPlay) {
          currentBall?.setPowerPlay(activeInning?.getActivePowerPlay?.getDescription)
        }
        if (
          game.getStatus === 'NOT_YET_STARTED' ||
          (activeInning?.progressiveScores.oversBowled === '0.0' &&
            activeInning?.inningsMatchOrder === 1 &&
            !awaitingFirstBallOfOver)
        ) {
          game.setMatchStatus('IN_PROGRESS_PLAYING')
          timelineEvents?.addMatchStart(
            activeInning?.inningsMatchOrder ?? null,
            appSettings.timeMachine.baseline,
            appSettings.timeMachine.activated
          )
          if (appSettings.appMode === 'core') {
            db.createS3PMessage(
              S3PHelpers.metadata(appSettings.appMode, game),
              S3PHelpers.matchStatus('IN_PROGRESS_PLAYING', game)
            )
          }
        }
        if (activeInning && !activeInning.startTime) {
          activeInning.setStartTime(
            appSettings.timeMachine.baseline && appSettings.timeMachine.activated
              ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
              : new Date().toISOString()
          )
          activeInning.setStatus('IN_PROGRESS')
          timelineEvents?.addInningStart(
            activeInning.inningsMatchOrder,
            appSettings.timeMachine.baseline,
            appSettings.timeMachine.activated
          )
          if (appSettings.appMode === 'core') {
            db.createS3PMessage(S3PHelpers.metadata(appSettings.appMode, game), S3PHelpers.innings('START', game))
            S3PHelpers.resendInningsStateS3pMessages(appSettings.appMode, game, activeInning, balls)
          }
        }
      }
      currentBall?.setBRI(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
      if (activeInning && currentBall && appSettings.appMode !== 'fielding') {
        if (appSettings.appMode === 'core') {
          if (currentBall.ballNumber === 1) {
            db.createS3PMessage(
              S3PHelpers.metadata(appSettings.appMode, game),
              S3PHelpers.over(
                'START',
                game,
                currentBall,
                powerPlayTriggers
                  ? !isNil(currentBall.powerPlayId)
                    ? powerPlayTriggers[currentBall.powerPlayId].powerPlay
                    : currentBall.overNumber === 0
                    ? powerPlayTriggers[0].powerPlay
                    : undefined
                  : undefined,
                previousOverWasDifferentEnd
              )
            )
          }
          db.createS3PMessage(
            S3PHelpers.metadata(appSettings.appMode, game),
            S3PHelpers.ball('START', game, currentBall)
          )
        }
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.appMode, game),
          S3PHelpers.batting(appSettings.appMode, currentBall, game, true)
        )
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.appMode, game),
          S3PHelpers.bowling(appSettings.appMode, currentBall, game)
        )
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.appMode, game),
          S3PHelpers.fielding(appSettings.appMode, currentBall, game)
        )
      }
      setInBall(true)
    }

    const resyncBall = () => {
      if (editBall) {
        // re-send ball message
        const ballSingleton = Object.assign({}, editBall, {
          inningsId: currentInning.id,
          matchId: game.id,
          ballId: editBall.id,
        })
        addBallToTimeline(ballSingleton, game)
      }
      cancelEditBall(false, true)
    }

    const completeBall = (endOfOver = false, incomplete = false) => {
      if (
        awaitingFirstBallOfOver &&
        !insertingBall &&
        balls.getNewestBall(currentInning.inning.id, true)?.overNumber === currentBall?.overNumber
      ) {
        setAwaitingFirstBallOfOver(false)
      }
      if (incomplete) {
        currentBall?.setIncomplete(true)
      } else {
        if (appSettings.appMode !== 'fielding')
          currentBall?.setIncomplete(BallHelpers.incompleteBallData(currentBall) ? true : false)
        if (appSettings.appMode === 'fielding') currentBall?.setIncomplete(false)
      }
      if (appSettings.appMode !== 'fielding') {
        if (currentBall && currentBall.batterMp && currentBall.dismissal && !currentBall.dismissal.batterMp) {
          // if a dismissal is set but no batter dismissed (likely run out/OTF), we need to default to the strike batter
          currentBall.setBatterOut(currentBall.batterMp)
        }
      }
      if (endOfOver !== currentBall?.endOfOver) {
        currentBall?.setEndOfOver(endOfOver)
      }

      if (!editBall) {
        // for new balls, set inningsProgress & mark ball as confirmed
        updateBallInningsProgress(currentBall)
      }

      if (!insertingBall && activeInning && currentBall && appSettings.appMode === 'core') {
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.appMode, game),
          S3PHelpers.runs(
            currentBall,
            !isNil(currentBall.extrasTypeId) ? Reference.ExtrasTypeOptions[currentBall.extrasTypeId] : null,
            game,
            currentInning.inning
          )
        )
        if (!editBall && !currentBall.dismissal) {
          db.createS3PMessage(
            S3PHelpers.metadata(appSettings.appMode, game),
            S3PHelpers.ball('COMPLETE', game, currentBall)
          )
        }
      }

      let insertingBallTriggeringEndOver = false
      if (insertingBall) {
        // update balls in over after the now-inserted ball and do validity check on inserted ball to calc ballDisplayNumber changes
        currentBall?.getOver().map((b: IBallModel) => {
          if (b.ballNumber >= currentBall.ballNumber && b.id !== currentBall.id) {
            b.setBallNumber(b.ballNumber + 1)
            if (currentBall?.isValidBall() && b.ballDisplayNumber) b.setBallDisplayNumber(b.ballDisplayNumber + 1)
            b.setLastUpdated(
              appSettings.timeMachine.baseline && appSettings.timeMachine.activated
                ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
                : undefined
            )
          }
          return true
        })
        const currentBallOver = currentBall?.getOver()
        if (currentBallOver && currentBall) {
          // if we inserted a ball into the current over, and in doing so made it so we have completed the over
          if (
            !currentBall.isCurrentOver() ||
            ((currentBallOver[currentBallOver.length - 1].ballDisplayNumber || 0) >=
              (game.matchConfigs.ballsPerOver || 6) &&
              currentBallOver[currentBallOver.length - 1].isValidBall())
          ) {
            currentBallOver[currentBallOver.length - 1].setEndOfOver(true)
          }
          if (
            currentBall.isCurrentOver() &&
            (currentBallOver[currentBallOver.length - 1].ballDisplayNumber || 0) >=
              (game.matchConfigs.ballsPerOver || 6) &&
            currentBallOver[currentBallOver.length - 1].isValidBall() &&
            currentBall.bowlerMp?.id ===
              activeInning?.getCurrentBowlerPerformances.find(
                (p: IBowlingPerformanceModel) => p.onStrike && p.isBowling
              )?.playerMp.id
          ) {
            setEndOver(true)
            insertingBallTriggeringEndOver = true
          }
        }
        if (currentBall?.runsBat && currentBall?.batterMp) {
          db.createS3PMessage(
            S3PHelpers.metadata(appSettings.appMode, game),
            S3PHelpers.manualScoreChange(currentBall?.runsBat, game, currentBall.batterMp.id, activeInning)
          )
        }
      }

      let editDiff: any // eslint-disable-line @typescript-eslint/no-explicit-any
      let playerToSwap: PlayerToSwap | null = null
      if (editBall && editBallProps && appSettings.appMode !== 'fielding') {
        editDiff = MatchHelpers.differenceBetweenBalls({
          ball: editBall,
          currentBall: currentBall,
          isNewestBallInInnings: currentBall?.isCurrentOver() && currentBall?.isNewestBallInOver,
          wasInMaiden: editBallProps.wasInMaiden,
          noBallValue: game.matchConfigs.noBallRuns,
          overStatus: editBallProps.overStatus,
        })
        if (
          currentBall &&
          currentBall.batterMp &&
          currentBall.batterNonStrikeMp &&
          currentBall.dismissal &&
          editDiff.is &&
          editDiff.was.dismissedBatterId !== null &&
          editDiff.was.dismissedBatterId !== editDiff.is.dismissedBatterId
        ) {
          // dismissed batter has been changed on the ball
          const strikeBatterNowOut: boolean = editDiff.is.dismissedBatterId === currentBall.batterMp.id
          playerToSwap = {
            from: strikeBatterNowOut ? currentBall.batterMp.id : currentBall.batterNonStrikeMp.id,
            fromInstance: strikeBatterNowOut ? currentBall.batterInstance : currentBall.batterNonStrikeInstance,
            to: strikeBatterNowOut ? currentBall.batterNonStrikeMp.id : currentBall.batterMp.id,
            toInstance: strikeBatterNowOut ? currentBall.batterNonStrikeInstance : currentBall.batterInstance,
          }
          currentInning?.inning?.swapDismissal(playerToSwap)
          handleEditPlayerReplace(
            cloneDeep(getSnapshot(currentBall)),
            'innings',
            playerToSwap,
            balls.getNewestBall(currentInning.inning.id),
            false,
            strikeBatterNowOut
          )
          setCascadeFromEditBallDismissed(true)
        }
      } else if (insertingBall && currentBall && appSettings.appMode !== 'fielding') {
        editDiff = { changeStrike: MatchHelpers.strikeShouldChange(currentBall, game.matchConfigs.noBallRuns) }
        if (editDiff.changeStrike) {
          setEditBall(getSnapshot(currentBall))
          setEditBallProps({
            isNewestOver: activeInning?.getCurrentOver === currentBall.overNumber,
            isNewestBall: false,
            isNewestBallInOver: false,
            wasInMaiden: currentBall.overIsMaiden(),
            overStatus: currentBall.getOverStatus(game.matchConfigs.ballsPerOver || 6),
          })
        }
      }
      if (appSettings.appMode !== 'fielding') {
        if (editBall && editDiff.difference.balls !== 0) {
          // if we edited and changed from valid to invalid ball (or vice versa)
          // ...then update subsequent balls' ballDisplayNumber
          handleUpdateBallDisplayNumbers({
            overNumber: editBall.overNumber,
            ballNumber: editBall.ballNumber,
            difference: editDiff.difference.balls,
          })
        }
        currentInning?.inning?.updateFromBall({
          ball: currentBall,
          editBall: editBall ? editDiff : undefined,
          insertBall: insertingBall,
          insertingBallTriggeringEndOver: insertingBallTriggeringEndOver,
          isNewestOver:
            editBall && editBallProps
              ? editBallProps.isNewestOver
              : insertingBall
              ? activeInning?.getCurrentOver === currentBall?.overNumber
              : true,
          isNewestBall: editBall && editBallProps ? editBallProps.isNewestBall : true,
          cascade: !!playerToSwap,
          newestBallIncludingUnconfirmed: balls.getNewestBall(currentInning.inning.id, true),
          ballsPerOver: game.matchConfigs.ballsPerOver || 6,
          noBallValue: game.matchConfigs.noBallRuns || 1,
          ballStore: balls,
          timeMachineOverride:
            appSettings.timeMachine.baseline && appSettings.timeMachine.activated
              ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
              : undefined,
        })
        if (editBall) {
          // for edited balls, update the existing ball.inningsProgress if required
          updateBallInningsProgress(currentBall, editDiff)
        }
        setBallsSnapshot(undefined)
      }

      if (!editBall) {
        if (appSettings.appMode !== 'fielding') {
          // we need to check if the score has been passed (if second inning) and if so, alert scorer
          // to ask whether the inning should proceed (unless last ball is also a wicket - in that case we will show the All Out alert)
          if (game?.getScoreHasBeenPassed(activeInning?.superOver) && !activeInningMetaData?.alertedPassedScore) {
            setScorePassedIsOpen(true)
            activeInningMetaData?.updatePassedScore(true)
          } else if (
            game?.isLimitedOversMatch &&
            getOversRemaining({
              oversBowled: activeInning?.progressiveScores.oversBowled || 0,
              oversPerInnings: activeInning?.superOver
                ? 1
                : game.matchDls?.targetOvers || game.matchConfigs.maxOvers || 0,
            }) === 0 &&
            !(currentBall?.dismissal && eligibleBatters?.length === 0) &&
            !activeInningMetaData?.alertedPassedMaxOvers
          ) {
            setOversPassedIsOpen(true)
            activeInningMetaData?.updatePassedOvers(true)
          }

          const currentOrLastPartnership = activeInning?.getLastPartnership()
          if (
            currentBall?.ballDisplayNumber &&
            currentBall?.ballDisplayNumber > (game.matchConfigs.ballsPerOver || 6) &&
            currentOrLastPartnership &&
            currentOrLastPartnership.start > `${currentBall?.overNumber}.${currentBall?.ballDisplayNumber}`
          ) {
            // if current/last partnership start value was set for first ball of next over, but we cancelled end of over
            // then we need to reset the start value for the partnership accordingly
            currentOrLastPartnership.setStart(`${currentBall?.overNumber}.${currentBall?.ballDisplayNumber}`)
          }
        }
        ballSideEffects(endOfOver)
      } else if (!endInning && !closedInning) {
        let awaitingFirstBallOfNewOver = (endOfOver && awaitingFirstBallOfOver) || awaitingFirstBallOfOver
        if (awaitingFirstBallOfNewOver) {
          if (editDiff?.changeStrike && editBallProps?.isNewestOver && editBallProps?.isNewestBall) {
            // if only ball after this is unconfirmed first ball in next over, we need to change the strike for it...
            activeInning?.switchStrike()
          }
          const newestBallIncludingUnconfirmed = balls.getNewestBall(currentInning.inning.id, true)
          if (
            newestBallIncludingUnconfirmed &&
            newestBallIncludingUnconfirmed.bowlerMp &&
            currentBall &&
            currentBall.bowlerMp &&
            newestBallIncludingUnconfirmed.bowlerMp !== currentBall.bowlerMp &&
            !currentBall.isValidBall() &&
            editBallProps?.isNewestBallInOver
          ) {
            // if last ball of previous over was being edited, and it's now invalid (and we will reopen the over)
            // then switch the bowler back to the previous over's bowler ...
            activeInning?.switchStrikeBowlers({
              onStrike: newestBallIncludingUnconfirmed.bowlerMp,
              offStrike: currentBall.bowlerMp,
              changeStrike: true,
            })

            // then roll back the innings/perf/spell overs values due to the invalid ball
            setAwaitingFirstBallOfOver(false)
            awaitingFirstBallOfNewOver = false
            currentBall.setEndOfOver(false)
            activeInning?.undoEndOfOver(currentBall, game.matchConfigs.ballsPerOver || 6, true)

            // set bowler chosen for next over to not be "isBowling" any longer
            activeInning?.getBowlingPerformance(newestBallIncludingUnconfirmed.bowlerMp.id)?.setIsBowling(false)

            // and finally remove the unconfirmed ball we no longer need
            balls.removeBall({
              inningsId: currentInning.inning.id,
              matchId: game.id,
              ballId: newestBallIncludingUnconfirmed.id,
              deadBall: false,
              ballComplete: !!newestBallIncludingUnconfirmed.timestamps.confirmed,
              overId: newestBallIncludingUnconfirmed.overId,
            })
            cancelEndOfOverFinalise()

            // check which batter should be on strike now
            if (activeInning && currentBall && currentBall.batterMp && currentBall.batterNonStrikeMp) {
              activeInning.switchStrike({
                onStrike: currentBall.batterMp,
                offStrike: currentBall.batterNonStrikeMp,
                changeStrike: MatchHelpers.strikeShouldChange(currentBall, game.matchConfigs.noBallRuns),
              })
            }
          }
        }
        createNewBall(awaitingFirstBallOfNewOver)
      } else if (endInning || closedInning) {
        setCurrentBall(lastBall)
      }
      setInBall(false)
      setCancelledDismissal(false)
      setCommentaryChanged(false)
      closeBallVals()

      if (editBall && editBallPrimary && activeInning && appSettings.appMode !== 'fielding') {
        // trigger edit complete event
        timelineEvents?.addEditComplete(
          `Edit Complete (Over ${editBall?.overNumber}.${editBall?.ballDisplayNumber})`,
          activeInning.inningsMatchOrder,
          appSettings.timeMachine.baseline,
          appSettings.timeMachine.activated
        )
      }

      if (
        currentBall &&
        !insertingBall &&
        ((appSettings.appMode === 'core' && game.matchConfigs.coverageLevelId === 1) ||
          (appSettings.appMode === 'advanced' && (game.matchConfigs.coverageLevelId || 3 >= 2)))
      ) {
        // send commentary S3P message (for CORE coverage, from core mode; all other scenarios, from advanced mode)
        db.createS3PMessage(S3PHelpers.metadata(appSettings.appMode, game), S3PHelpers.commentary(game, currentBall))
      }

      if (
        appSettings.appMode !== 'fielding' &&
        (editBall || insertingBall) &&
        editDiff.changeStrike &&
        (insertingBall || !editBallProps?.isNewestBall)
      ) {
        // if we edited/inserted and changed the strike, and it wasn't the newest ball in the innings...
        // ...then prompt the scorer if they want to cascade their edit to subsequent balls
        setCascadeEditIsOpen(true)
      } else {
        if (editBall || insertingBall) {
          if (activeInning && appSettings.appMode === 'core') {
            const dismissalType =
              currentBall?.dismissal && !isNil(currentBall.dismissal.dismissalTypeId)
                ? Reference.DismissalMethods[currentBall.dismissal.dismissalTypeId]
                : null
            if (currentBall && (!currentBall.isCurrentOver() || awaitingFirstBallOfOver)) {
              db.createS3PMessage(
                S3PHelpers.metadata(appSettings.appMode, game),
                S3PHelpers.overState(activeInning, currentBall)
              )
              if (dismissalType && editDiff.was && dismissalType !== editDiff.was.wicketType) {
                // re-send dismissal S3P message if the dismissal type has been edited
                db.createS3PMessage(
                  S3PHelpers.metadata(appSettings.appMode, game),
                  S3PHelpers.dismissal(
                    game,
                    dismissalType,
                    includes(['RUN_OUT', 'ABSENT'], dismissalType) ? batterPerformance : undefined,
                    currentBall
                  )
                )
              }
            }

            S3PHelpers.resendInningsStateS3pMessages(appSettings.appMode, game, activeInning, balls)
          }
        }
        closeEditBallMode()
      }

      if (!editBall && !insertingBall && currentBall && game) {
        if (activeInning && appSettings.appMode === 'core') {
          S3PHelpers.resendInningsStateS3pMessages(appSettings.appMode, game, activeInning, balls)
        }
        // set the currentBall id as the latestBallId on the match object - for Feed Reconciliation purposes
        game.setLatestBallId(currentBall.id)
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updateBallInningsProgress = (ball?: IBallModel, editDiff?: any) => {
      if (ball) {
        if (editDiff) {
          // EDITING AN EXISTING BALL(S)
          const ballsToUpdateIP = balls.getBallsAfter(currentInning.id, ball.overNumber, ball.ballNumber, false, true)
          ballsToUpdateIP?.forEach((ballIP: IBallModel) => {
            // iterate through the ball being edited AND any balls after it, and update inningsProgress accordingly
            ballIP.setInningsProgress(
              (ballIP.inningsProgress?.runs || 0) + editDiff.difference.runs,
              (ballIP.inningsProgress?.wickets || 0) + editDiff.difference.wickets,
              appSettings.timeMachine.baseline && appSettings.timeMachine.activated
                ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
                : new Date().toISOString()
            )
          })
        } else {
          // NEW BALL
          ball.setBallConfirm(true, appSettings.timeMachine.baseline, appSettings.timeMachine.activated, {
            runs: (currentInning?.inning.progressiveScores.runs || 0) + (ball.runsBat || 0) + (ball.runsExtra || 0),
            wickets: (currentInning?.inning.progressiveScores.wickets || 0) + (ball.dismissal ? 1 : 0),
          })
        }
      }
      game.setDescription(MatchHelpers.gameDescriptionString(game))
    }

    const closeEditBallMode = () => {
      setEditBall(undefined)
      setEditBallProps(undefined)
      setEditBallPrimary(false)
      setInsertingBall(false)
      setCascadeFromEditBallDismissed(false)
    }

    const handleUpdateBallDisplayNumbers = ({
      overNumber,
      ballNumber,
      difference,
    }: {
      overNumber: number
      ballNumber: number
      difference: number
    }) => {
      const ballsToUpdate = balls.getBallsAfter(currentInning?.inning.id, overNumber, ballNumber, true, false, true)
      each(ballsToUpdate, (ball: IBallModel) => {
        ball.setBallDisplayNumber((ball.ballDisplayNumber || ball.ballNumber) + difference)
        ball.setLastUpdated(
          appSettings.timeMachine.baseline && appSettings.timeMachine.activated
            ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
            : undefined
        )
      })
    }

    const handleCascadeEdit = ({
      type = null,
      overNumber,
      ballNumber,
      continueAfterWicket = false,
      wicketsEncountered = 0,
    }: CascadeEditProps) => {
      let pendingWicket = false
      const firstBallEdited: CascadeEditProps = {
        overNumber: editBall.overNumber,
        ballNumber: editBall.ballNumber,
        ballDisplayNumber: editBall.ballDisplayNumber,
        type: type,
      }
      let lastBallEdited: CascadeEditProps = {
        overNumber: editBall.overNumber,
        ballNumber: editBall.ballNumber,
        ballDisplayNumber: editBall.ballDisplayNumber,
        wicketsEncountered: wicketsEncountered,
        wicketDismissed: null,
        wicketOverBall: null,
        type: type,
      }
      if (type) {
        // get all the balls we need to update after the ball we were editing
        // (either rest of over or rest of innings)
        const ballsToEdit = balls.getBallsAfter(
          currentInning?.inning.id,
          overNumber || editBall.overNumber,
          ballNumber || editBall.ballNumber,
          type === 'over'
        )
        const newestBall = balls.getNewestBall(currentInning.inning.id)
        let playerToSwap: PlayerToSwap | null = null
        let wicketsEncountered2: number = editBall.dismissal ? wicketsEncountered + 1 : wicketsEncountered
        each(ballsToEdit, (ball: IBallModel) => {
          let ballWithStrikeChanged = cloneDeep(getSnapshot(ball))
          if (ball.dismissal) {
            if (playerToSwap) {
              // If the previous ball was also a wicket, we first need to update all subsequent balls with the dismissed batter swapped
              handleEditPlayerReplace(ballWithStrikeChanged, type, playerToSwap, newestBall, true)
            }
            // if ball is a wicket, jump out and prompt the user to see if they wish to continue cascading
            wicketsEncountered2++
            lastBallEdited.wicketOverBall = `${ball.overNumber}.${ball.ballDisplayNumber}`
            lastBallEdited.wicketDismissed = ball.dismissal ? ball.dismissal.batterMp?.cardNameF : null
            if (!continueAfterWicket) {
              pendingWicket = true
              playerToSwap = null
              return false
            }
          }

          // flip the strike/non-strike batters (unless we are just swapping a player in/out after the first wicket encountered)
          if (!playerToSwap && wicketsEncountered2 <= 1 && !cascadeFromEditBallDismissed) {
            ballWithStrikeChanged = swapObjectValues(ballWithStrikeChanged, 'batterMp', 'batterNonStrikeMp')
            ballWithStrikeChanged = swapObjectValues(ballWithStrikeChanged, 'batterInstance', 'batterNonStrikeInstance')
            if (
              ball.batterMp?.player.battingHandedId !== ball.batterNonStrikeMp?.player.battingHandedId &&
              ballWithStrikeChanged.battingAnalysis?.arrival?.x
            ) {
              // if the strike batter for the cascaded ball has changed from R->L or L->R, and we have an arrival data point...
              // ...then flip the X axis value
              ballWithStrikeChanged.battingAnalysis.arrival.x = 100 - ballWithStrikeChanged.battingAnalysis.arrival.x
            }
          }

          if (ballWithStrikeChanged.dismissal && continueAfterWicket && wicketsEncountered2 <= 1) {
            // switch dismissal details around
            playerToSwap =
              ballWithStrikeChanged.dismissal.batterMp === ballWithStrikeChanged.batterMp
                ? {
                    from: ballWithStrikeChanged.batterNonStrikeMp,
                    fromInstance: ballWithStrikeChanged.batterNonStrikeInstance,
                    to: ballWithStrikeChanged.batterMp,
                    toInstance: ballWithStrikeChanged.batterInstance,
                  }
                : {
                    from: ballWithStrikeChanged.batterMp,
                    fromInstance: ballWithStrikeChanged.batterInstance,
                    to: ballWithStrikeChanged.batterNonStrikeMp,
                    toInstance: ballWithStrikeChanged.batterNonStrikeInstance,
                  }
            ballWithStrikeChanged.dismissal.batterMp = playerToSwap.from ?? undefined
            currentInning?.inning.swapDismissal(playerToSwap)
          } else if (playerToSwap) {
            // Update the dismissed vs now-not-dismissed batter on all subsequent balls (if applicable)
            handleEditPlayerReplace(ballWithStrikeChanged, type, playerToSwap, newestBall, true)
          }
          const overStatus = ball.getOverStatus(game.matchConfigs.ballsPerOver || 6)
          const originalBall = cloneDeep(getSnapshot(ball))

          // then, replace the original ball with the "flipped strike" copy
          applySnapshot(ball, ballWithStrikeChanged)

          // ensure the normal edit ball process takes place to update inning/perfs
          if (ball.confirmed) {
            const editDiff = MatchHelpers.differenceBetweenBalls({
              ball: originalBall,
              currentBall: ball,
              wasInMaiden: ball.overIsMaiden(),
              cascade: true,
              noBallValue: game.matchConfigs.noBallRuns,
              overStatus,
            })
            currentInning?.inning.updateFromBall({
              ball: ball,
              endOfOver: false,
              editBall: editDiff,
              isNewestOver: originalBall.overNumber === newestBall?.overNumber,
              isNewestBall:
                originalBall.overNumber === newestBall?.overNumber &&
                originalBall.ballNumber === newestBall?.ballNumber,
              cascade: true,
              cascadeAfterWicket: playerToSwap !== undefined,
              ballsPerOver: game.matchConfigs.ballsPerOver || 6,
              noBallValue: game.matchConfigs.noBallRuns || 1,
              ballStore: balls,
              timeMachineOverride:
                appSettings.timeMachine.baseline && appSettings.timeMachine.activated
                  ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
                  : undefined,
            })
            updateBallInningsProgress(ball, editDiff)
            continueAfterWicket = false
          }

          // construct S3P messages for the edited ball
          S3PHelpers.resendBallS3pMessages(
            appSettings.appMode,
            activeInning,
            currentInning.inning,
            game,
            ball,
            batterPerformance
          )

          // keep track of the last ball we edited, just in case we need to break out because we encountered a wicket
          lastBallEdited = {
            overNumber: ball.overNumber,
            ballNumber: ball.ballNumber,
            ballDisplayNumber: ball.ballDisplayNumber || ball.ballNumber,
            wicketsEncountered: wicketsEncountered2,
            wicketDismissed: null,
            wicketOverBall: null,
            type: type,
          }

          // re-generate the auto-commentary
          ball.setTextDescription(
            CommsHelpers.generateBallCommentary(
              game,
              ball,
              lastBall && lastBall.overNumber === ball.overNumber ? lastBall : undefined
            )
          )

          // update lastUpdated date on the ball
          ball.setLastUpdated(
            appSettings.timeMachine.baseline && appSettings.timeMachine.activated
              ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
              : undefined
          )

          // if newest ball in innings, adjust the strike accordingly as required
          if (
            currentInning?.isActiveInning &&
            ball.overNumber === newestBall?.overNumber &&
            ball.ballNumber === newestBall?.ballNumber
          ) {
            const newestBallIncludingUnconfirmed = balls.getNewestBall(currentInning.inning.id, true)
            const strikeShouldChange = MatchHelpers.strikeShouldChange(ball, game.matchConfigs.noBallRuns)
            const cascadeEndedAtEndOfOver =
              newestBallIncludingUnconfirmed?.overNumber === ball.overNumber + 1 &&
              newestBallIncludingUnconfirmed?.ballNumber === 1 &&
              (!game.matchConfigs.bowlerConsecutiveOvers ||
                (game.matchConfigs.bowlerConsecutiveOvers && (ball?.overNumber || 0) % 2 !== 0))
            if (
              !ball.dismissal ||
              (ball.dismissal &&
                ((strikeShouldChange &&
                  (!cascadeEndedAtEndOfOver || (cascadeEndedAtEndOfOver && !ball.dismissal.battersCrossed))) ||
                  (!strikeShouldChange && cascadeEndedAtEndOfOver) ||
                  ball.dismissal.batterMp?.id === ball.batterNonStrikeMp?.id))
            ) {
              // switch strike if there wasn't a wicket on the newest ball, or if there was a wicket and we actually need to change strike or the non-striker was dismissed
              currentInning?.inning.switchStrike({
                onStrike: cascadeEndedAtEndOfOver ? ball.batterNonStrikeMp : ball.batterMp,
                offStrike: cascadeEndedAtEndOfOver ? ball.batterMp : ball.batterNonStrikeMp,
                changeStrike: strikeShouldChange,
              })
            }
          }
        })

        if (
          currentInning &&
          playerToSwap &&
          ballsToEdit &&
          ballsToEdit.length > 0 &&
          ballsToEdit[ballsToEdit.length - 1].dismissal
        ) {
          // if final ball in our cascade was a dismissal
          const finalBallOfCascade = cloneDeep(getSnapshot(ballsToEdit[ballsToEdit.length - 1]))
          const ballsAfterFinalBallOfCascade = balls.getBallsAfter(
            currentInning.id,
            finalBallOfCascade.overNumber,
            finalBallOfCascade.ballNumber,
            false,
            false,
            true
          )
          if (ballsAfterFinalBallOfCascade && ballsAfterFinalBallOfCascade.length > 0) {
            handleEditPlayerReplace(
              cloneDeep(getSnapshot(ballsAfterFinalBallOfCascade[0])),
              type,
              playerToSwap,
              newestBall,
              true
            )
            if (type !== 'over' || (type === 'over' && insertingBall && ballsAfterFinalBallOfCascade.length === 1)) {
              // check and adjust strike if cascading innings...
              // ...or cascading an over to a point where only an unconfirmed ball exists after the last cascaded ball
              currentInning?.inning.switchStrike({
                onStrike: ballsAfterFinalBallOfCascade[ballsAfterFinalBallOfCascade.length - 1].batterMp,
                offStrike: ballsAfterFinalBallOfCascade[ballsAfterFinalBallOfCascade.length - 1].batterNonStrikeMp,
                changeStrike: insertingBall
                  ? true
                  : MatchHelpers.strikeShouldChange(ballsToEdit[ballsToEdit.length - 1], game.matchConfigs.noBallRuns),
              })
            }
          }
        }
      }

      setCascadeFromEditBallDismissed(false)
      if (pendingWicket) {
        // show "wicket encountered" prompt
        setCascadeEditIsOpen(false)
        setCascadeEditProps(lastBallEdited)
        setCascadeEditIsOpen(true)
      } else {
        // remove and create a new pre-ball
        if (!endInning && !closedInning) {
          createNewBall(false, currentBall)
        } else {
          setCurrentBall(lastBall)
        }

        // finally, close the alert and leave edit ball mode
        setCascadeEditIsOpen(false)
        setCascadeEditProps(undefined)
        closeEditBallMode()
        if (currentInning && appSettings.appMode === 'core') {
          if (!isNil(firstBallEdited.overNumber)) {
            const overFirstBall = balls.getBall(
              currentInning.inning.id,
              firstBallEdited.overNumber,
              firstBallEdited.ballNumber ?? 1
            )
            if (overFirstBall && (!overFirstBall.isCurrentOver() || awaitingFirstBallOfOver)) {
              // S3P: re-send over state message for over containing the ball that triggered cascade
              db.createS3PMessage(
                S3PHelpers.metadata(appSettings.appMode, game),
                S3PHelpers.overState(currentInning.inning, overFirstBall)
              )
            }
          }
          S3PHelpers.resendInningsStateS3pMessages(appSettings.appMode, game, currentInning.inning, balls)
        }

        toast({
          title: 'Cascade completed',
          status: 'info',
          duration: Theme.toast.normal,
          isClosable: true,
        })
      }
    }

    const handleEditPlayerReplace = (
      ballWithStrikeChanged: SnapshotOrInstance<typeof BallModel>,
      type: string,
      playerToSwap: PlayerToSwap,
      newestBall: IBallModel | undefined,
      cascade: boolean,
      strikeBatterNowOut?: boolean
    ) => {
      const ballsToChangePlayer = balls.getBallsAfter(
        currentInning?.inning.id,
        ballWithStrikeChanged.overNumber,
        ballWithStrikeChanged.ballNumber,
        false,
        cascade
      )
      each(ballsToChangePlayer, (ballC: IBallModel) => {
        const ballCS = cloneDeep(getSnapshot(ballC))
        if (ballCS.batterMp === playerToSwap.from) {
          ballCS.batterMp = playerToSwap.to
          if (
            ballC.overNumber === ballWithStrikeChanged.overNumber &&
            ballC.ballNumber === ballWithStrikeChanged.ballNumber
          ) {
            ballWithStrikeChanged.batterMp = playerToSwap.to
          }
        } else if (ballCS.batterNonStrikeMp === playerToSwap.from) {
          ballCS.batterNonStrikeMp = playerToSwap.to
          if (
            ballC.overNumber === ballWithStrikeChanged.overNumber &&
            ballC.ballNumber === ballWithStrikeChanged.ballNumber
          ) {
            ballWithStrikeChanged.batterNonStrikeMp = playerToSwap.to
          }
        } else {
          // if a ball no longer has this player at all, we can stop checking
          return false
        }
        if (ballCS.dismissal && ballCS.dismissal.batterMp === playerToSwap.from) {
          // if ball has a dismissal for the old (from) batter, change it to the new (to) batter
          ballCS.dismissal.batterMp = playerToSwap.to ?? undefined
        }
        const overStatus = ballC.getOverStatus(game.matchConfigs.ballsPerOver || 6)
        const originalBall = cloneDeep(getSnapshot(ballC))

        // then, replace the original ball with the "flipped strike" copy
        applySnapshot(ballC, ballCS)

        // check the difference between the balls now that we have swapped a batter out
        const editDiffC = MatchHelpers.differenceBetweenBalls({
          ball: originalBall,
          currentBall: ballC,
          cascade: true,
          wasInMaiden: ballC.overIsMaiden(),
          noBallValue: game.matchConfigs.noBallRuns,
          overStatus,
        })
        // ...and apply that to the ball (should only make changes to the batting perfs, really)
        currentInning?.inning.updateFromBall({
          ball: ballC,
          endOfOver: false,
          editBall: editDiffC,
          isNewestOver: originalBall.overNumber === newestBall?.overNumber,
          isNewestBall:
            originalBall.overNumber === newestBall?.overNumber && ballC.ballNumber === newestBall?.ballNumber,
          cascade: true,
          cascadeAfterWicket: playerToSwap !== undefined,
          playerToSwap: playerToSwap,
          ballsPerOver: game.matchConfigs.ballsPerOver || 6,
          noBallValue: game.matchConfigs.noBallRuns || 1,
          ballStore: balls,
          timeMachineOverride:
            appSettings.timeMachine.baseline && appSettings.timeMachine.activated
              ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
              : undefined,
        })

        if (
          currentInning?.isActiveInning &&
          strikeBatterNowOut &&
          ballC.overNumber === newestBall?.overNumber &&
          ballC.ballNumber === newestBall?.ballNumber
        ) {
          currentInning?.inning.switchStrike({
            onStrike: ballC.batterMp,
            offStrike: ballC.batterNonStrikeMp,
            changeStrike: MatchHelpers.strikeShouldChange(ballC, game.matchConfigs.noBallRuns),
          })
        }
      })
    }

    const ballSideEffects = (endOfOver: boolean) => {
      if (currentBall?.pendingDismissal && appSettings.appMode !== 'fielding') {
        if (eligibleBatters?.length === 0 && !activeInningMetaData?.alertedPassedMaxWickets) {
          setWicketsPassedIsOpen(true)
          setEndInning(true)
          activeInningMetaData?.updatePassedWickets(true)
        }
        triggerNewBatter('DISMISSAL')
        if (endOfOver && !insertingBall) {
          triggerEndOver()
        }
      } else if (endOfOver && !insertingBall && appSettings.appMode !== 'fielding') {
        triggerEndOver()
      } else {
        createNewBall(endOfOver)
      }
    }

    const triggerNewFielder = (playerMpId: string | null | undefined) => {
      if (!playerMpId) return
      setChangeFielder(playerMpId)
    }

    const triggerNewBowler = (type: BowlerChangeType, ballOver?: IBallModel) => {
      if (type === 'CHANGE') {
        setChangeBowler(true)
      } else if (type === 'MISTAKE') {
        setMistakeBowler(true)
      } else if (type === 'CHANGE_OVER' && ballOver) {
        setChangeBowlerBallOver(ballOver)
        setMistakeBowler(true)
      }
    }

    const triggerNewBatter = (type: string) => {
      if (type === 'DISMISSAL') {
        setDismissal(true)
      }
      if (type === 'MISTAKE') {
        setBatterPerformanceToChange(batterPerformance)
        setMistake(true)
      }
      // Non-ball dismissal checks
      let isNonBallDismissal = false
      let isNonBallRetiredDismissal = false
      if (includes(['RETIRED_HURT', 'RETIRED', 'RETIRED_NO'], type)) {
        setBatterPerformanceToChange(batterPerformance)
        isNonBallRetiredDismissal = true
      }
      if (includes(['RUN_OUT', 'ABSENT'], type)) {
        setBatterPerformanceToChange(batterPerformance)
        isNonBallDismissal = true
      }
      // Close batting performance for non-ball dismissals
      if (isNonBallDismissal) {
        activeInning?.dismissBattingPerformance(
          type,
          batterPerformance,
          currentBall,
          appSettings.timeMachine.baseline && appSettings.timeMachine.activated
            ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
            : undefined
        )
      }
      if (isNonBallRetiredDismissal) {
        activeInning?.retireBattingPerformance(
          type,
          batterPerformance,
          currentBall,
          undefined,
          appSettings.timeMachine.baseline && appSettings.timeMachine.activated
            ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
            : undefined
        )
      }
      if (activeInning && appSettings.appMode !== 'fielding' && type !== 'MISTAKE') {
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.appMode, game),
          S3PHelpers.dismissal(
            game,
            currentBall && currentBall.dismissal ? currentBall?.dismissal?.getDismissalType : type,
            isNonBallDismissal || isNonBallRetiredDismissal ? batterPerformance : undefined,
            !isNonBallDismissal && !isNonBallRetiredDismissal && currentBall ? currentBall : undefined
          )
        )
        if (
          appSettings.appMode === 'core' &&
          currentBall &&
          type === 'DISMISSAL' &&
          !isNonBallRetiredDismissal &&
          !isNonBallDismissal
        ) {
          db.createS3PMessage(
            S3PHelpers.metadata(appSettings.appMode, game),
            S3PHelpers.ball('COMPLETE', game, currentBall)
          )
        }
      }
      if (
        (isNonBallDismissal || isNonBallRetiredDismissal) &&
        eligibleBatters?.length === 0 &&
        !activeInningMetaData?.alertedPassedMaxWickets
      ) {
        // non ball dismissal as last wicket in an innings = we need to properly close the innings
        setWicketsPassedIsOpen(true)
        setEndInning(true)
        activeInningMetaData?.updatePassedWickets(true)
      } else if (isNonBallRetiredDismissal) {
        setRetired(type)
      } else if (isNonBallDismissal) {
        setNonBallDismissal(type)
      }
    }

    const cancelChangePerson = () => {
      setRetired(undefined)
      setMistake(false)
      setChangeBowler(false)
      setMistakeBowler(false)
      setChangeFielder(null)
      setNonBallDismissal(undefined)
    }

    const triggerEndOver = () => {
      const ballNumberValues = BallHelpers.calculateBallNumberInnings(
        currentBall,
        true,
        game.matchConfigs.ballsPerOver || 6
      )
      activeInning?.manualEndOfOver(ballNumberValues.overNumber, game.matchConfigs.ballsPerOver || 6)
      triggerEndOverFinalise()
    }

    const triggerEndOverManual = () => {
      // manual end of over after continuing beyond normal end of over
      const ballNumberValues = BallHelpers.calculateBallNumberInnings(
        currentBall,
        true,
        game.matchConfigs.ballsPerOver || 6
      )
      lastBall?.setEndOfOver(true)
      const bpo = game.matchConfigs.ballsPerOver || 6
      activeInning?.manualEndOfOver(
        ballNumberValues.overNumber,
        bpo,
        lastBall,
        lastBall &&
          ((lastBall.isValidBall() && (lastBall.ballDisplayNumber || 0) >= bpo) ||
            (!lastBall.isValidBall() && (lastBall.ballDisplayNumber || 0) - 1 >= bpo))
      )
      triggerEndOverFinalise()
    }

    const triggerEndOverFinalise = () => {
      setEndOver(true)
      if (activeInning && currentBall && appSettings.appMode === 'core') {
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.appMode, game),
          S3PHelpers.over(
            'FINISH',
            game,
            currentBall,
            powerPlayTriggers && !isNil(currentBall.powerPlayId)
              ? powerPlayTriggers[currentBall.powerPlayId].powerPlay
              : undefined,
            previousOverWasDifferentEnd
          )
        )
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.appMode, game),
          S3PHelpers.overState(activeInning, currentBall)
        )
      }
    }

    const cancelEndOfOver = () => {
      currentBall?.setEndOfOver(false)
      setEndOver(false)
      activeInning?.undoEndOfOver(lastBall, game.matchConfigs.ballsPerOver || 6, lastBall && !lastBall.isValidBall())
      createNewBall(false)
      cancelEndOfOverFinalise()
    }

    const cancelEndOfOverFinalise = () => {
      if (activeInning && lastBall && appSettings.appMode === 'core') {
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.appMode, game),
          S3PHelpers.over(
            'REOPEN',
            game,
            lastBall,
            powerPlayTriggers && !isNil(lastBall.powerPlayId)
              ? powerPlayTriggers[lastBall.powerPlayId].powerPlay
              : undefined,
            previousOverWasDifferentEnd
          )
        )
      }
    }

    const completeEndOfOver = (player: IMatchPlayerModel) => {
      let bowlingPerformance
      let overNumber
      activeInning?.newBowler(player, true, true)
      if (
        !game.matchConfigs.bowlerConsecutiveOvers ||
        (game.matchConfigs.bowlerConsecutiveOvers && (currentBall?.overNumber || 0) % 2 !== 0)
      ) {
        // for "bowler consecutive overs" games, only change ends every 2nd over
        // for normal games, change ends after every over
        activeInning?.switchStrike()
      }
      if (currentBall?.confirmed === false) {
        balls.removeBall({
          inningsId: activeInning?.id,
          matchId: game.id,
          ballId: currentBall.id,
          deadBall: false,
          ballComplete: !!currentBall.timestamps.confirmed,
          overId: currentBall.overId,
        })
        const lastConfirmedBall = balls.lastBall(activeInning?.id || '')
        if (lastConfirmedBall && lastConfirmedBall.bowlerMp) {
          if (!lastConfirmedBall.endOfOver) {
            lastConfirmedBall.setEndOfOver(true)
          }
          overNumber = lastConfirmedBall.overNumber
          bowlingPerformance = activeInning?.getBowlingPerformance(lastConfirmedBall.bowlerMp.id)

          if (player.id !== bowlingPerformance?.playerMp.id) {
            // only change on strike bowler if we are selecting a different bowler
            // i.e. for bowlerConsecutiveOvers matches, it is not always the case that the bowler is changing
            bowlingPerformance?.setIsOnStrike(false)
          }
          if (bowlingPerformance && lastConfirmedBall.overIsMaiden()) {
            bowlingPerformance.setMaidensBad('INCREMENT')
          }
        }
      } else {
        if (currentBall && currentBall.bowlerMp) {
          if (!currentBall.endOfOver) {
            currentBall.setEndOfOver(true)
          }
          overNumber = currentBall.overNumber
          bowlingPerformance = activeInning?.getBowlingPerformance(currentBall.bowlerMp.id)
          if (player.id !== currentBall.bowlerMp.id) {
            // only change on strike bowler if we are selecting a different bowler
            // i.e. for bowlerConsecutiveOvers matches, it is not always the case that the bowler is changing
            bowlingPerformance?.setIsOnStrike(false)
          }
          if (
            bowlingPerformance &&
            currentBall.ballDisplayNumber &&
            currentBall.ballDisplayNumber < (game.matchConfigs.ballsPerOver || 6)
          ) {
            // over was ended early (i.e. before the 6th valid ball)
            bowlingPerformance.setManualIncrementOvers(
              (game.matchConfigs.ballsPerOver || 6) - currentBall.ballDisplayNumber,
              currentBall.ballDisplayNumber,
              game.matchConfigs.ballsPerOver || 6
            )
          }
        }
      }
      // Get last ball from the over before last - if bowler isn't the same as new player, close that bowlers spell
      if (activeInning && overNumber) {
        const firstBallInOver = balls.getBall(activeInning?.id, overNumber - 1, 1)
        if (firstBallInOver) {
          let overOfBalls = firstBallInOver.getOver()
          overOfBalls = orderBy(overOfBalls, ['ballNumber'], ['desc'])
          if (
            overOfBalls[0] &&
            overOfBalls[0].bowlerMp &&
            overOfBalls[0].bowlerMp.id !== player.id &&
            (!game.matchConfigs.bowlerConsecutiveOvers ||
              (game.matchConfigs.bowlerConsecutiveOvers && overOfBalls[0].bowlerMp.id !== currentBall?.bowlerMp?.id))
          ) {
            closeSpellForBowler(overOfBalls[0].bowlerMp.id, overOfBalls[0], activeInning)
          }
        }
      }
      createNewBall(true)
      if (appSettings.appMode === 'fielding') setFieldHasChanged(true)
      setEndOver(false)
      setBetweenOvers(true)
      setAwaitingFirstBallOfOver(true)
      if (activeInning) {
        timelineEvents?.addNewBowler(
          player,
          activeInning?.inningsMatchOrder,
          appSettings.timeMachine.baseline,
          appSettings.timeMachine.activated
        )
      }
    }

    const cancelDismissalOrLastBall = (isDismissal?: boolean) => {
      if (!activeInning) return
      let newestBall = balls.getNewestBall(activeInning?.id)
      if (!newestBall) return

      // revert and undo the dismissal ball
      const currentBallWasFirstBallOfOver = currentBall?.ballNumber === 1
      if (isDismissal || newestBall.dismissal) setDismissal(false)
      triggerUndo(newestBall, true)
      createNewBall(currentBallWasFirstBallOfOver, undefined, undefined, null, true)

      if (appSettings.appMode !== 'core') {
        // create deep snapshot of the dismissal ball
        const newestBallSnapshot = cloneDeep(getSnapshot(newestBall))
        newestBall = balls.getNewestBall(activeInning?.id, true)
        if (!newestBall) return
        // overwrite with the original snapshot
        applySnapshot(newestBall, newestBallSnapshot)
        // set the ball back to 'unconfirmed' and show the InBall display
        newestBall.setBallConfirm(false, appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
        newestBall.setUnconfirmed(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
      }

      setEndOver(false)
      setInBall(true)
      setEndInning(false)
      if (isDismissal || newestBall.dismissal) setCancelledDismissal(true)
      if (appSettings.appMode === 'core' && currentBall) {
        // create BRI timestamp for the ball, and send S3P BRI ("ball start") message
        newestBall = balls.getNewestBall(activeInning?.id, true)
        if (!newestBall) return
        newestBall.setBRI(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
        db.createS3PMessage(S3PHelpers.metadata(appSettings.appMode, game), S3PHelpers.ball('START', game, newestBall))
      }
    }

    const completeNewBatsmen = (player: IMatchPlayerModel) => {
      if (!activeInning) return
      activeInning.newBatter(
        player,
        appSettings.timeMachine.baseline && appSettings.timeMachine.activated
          ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
          : undefined
      )
      timelineEvents?.addNewBatter(
        player,
        activeInning.inningsMatchOrder,
        appSettings.timeMachine.baseline,
        appSettings.timeMachine.activated
      )
      if (!endOver) {
        createNewBall(false)
      }
      setDismissal(false)
    }

    const completeNonBallDismissal = (player: IMatchPlayerModel, type: string) => {
      if (!activeInning) return
      timelineEvents?.addBatterRetirement(
        type.replace('_', ' '),
        activeInning.inningsMatchOrder ?? null,
        batterPerformance?.getPlayerProfile,
        appSettings.timeMachine.baseline,
        appSettings.timeMachine.activated
      )
      const battingPerf = activeInning?.newBatter(
        player,
        appSettings.timeMachine.baseline && appSettings.timeMachine.activated
          ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
          : undefined
      )
      timelineEvents?.addNewBatter(
        player,
        activeInning.inningsMatchOrder,
        appSettings.timeMachine.baseline,
        appSettings.timeMachine.activated
      )
      if (battingPerf?.onStrike) {
        currentBall?.setStrikeBatter(player, true)
      } else {
        currentBall?.setNonStrikeBatter(player)
      }
      setNonBallDismissal(undefined)
    }

    const completeRetired = (player: IMatchPlayerModel, type: string) => {
      if (!activeInning) return
      timelineEvents?.addBatterRetirement(
        type.replace('_', ' '),
        activeInning.inningsMatchOrder,
        batterPerformance?.getPlayerProfile,
        appSettings.timeMachine.baseline,
        appSettings.timeMachine.activated
      )
      const battingPerf = activeInning?.newBatter(
        player,
        appSettings.timeMachine.baseline && appSettings.timeMachine.activated
          ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
          : undefined
      )
      timelineEvents?.addNewBatter(
        player,
        activeInning.inningsMatchOrder,
        appSettings.timeMachine.baseline,
        appSettings.timeMachine.activated
      )
      if (battingPerf?.onStrike) {
        currentBall?.setStrikeBatter(player, true)
      } else {
        currentBall?.setNonStrikeBatter(player)
      }
      setRetired(undefined)
    }

    // batter mistake
    const completeMistake = (player: IMatchPlayerModel) => {
      // swap the ball details
      if (!activeInning) return
      const replaceBalls = balls.getBallsFromPerf(activeInning.id, 'bat', batterPerformance)
      each(replaceBalls, ball => {
        if (ball.batterMp?.id === batterPerformance?.playerMp.id) {
          ball.setStrikeBatter(player, true)
        } else if (ball.batterNonStrikeMp?.id === batterPerformance?.playerMp.id) {
          ball.setNonStrikeBatter(player)
        }

        // construct S3P messages for the edited ball
        S3PHelpers.resendBallS3pMessages(
          appSettings.appMode,
          activeInning,
          currentInning.inning,
          game,
          ball,
          batterPerformance
        )
      })

      const mistakenPlayerWasOnStrike: boolean = batterPerformance?.onStrike ? true : false
      activeInning?.swapBatterMistake(
        batterPerformance,
        player,
        appSettings.timeMachine.baseline && appSettings.timeMachine.activated
          ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
          : undefined
      )
      if (mistakenPlayerWasOnStrike) {
        currentBall?.setStrikeBatter(player, true)
      } else {
        currentBall?.setNonStrikeBatter(player)
      }

      if (currentInning && appSettings.appMode === 'core' && replaceBalls?.length) {
        S3PHelpers.resendInningsStateS3pMessages(appSettings.appMode, game, currentInning.inning, balls)
      }

      setMistake(false)
    }

    const completeChangeBowler = (player: IMatchPlayerModel) => {
      if (appSettings.appMode === 'fielding') {
        // swap fielding placements between old bowler & new bowler
        currentBall?.fieldingAnalysis?.swapPlayerPlacement(currentBall?.bowlerMp, player)
      }
      if (currentBall && currentBall.bowlerMp) {
        const bowlingPerformance = activeInning?.getBowlingPerformance(currentBall.bowlerMp.id)
        if (bowlingPerformance && activeInning) {
          closeSpellForPreviousBallForBowler(
            bowlingPerformance.playerMp.id,
            currentBall.overNumber,
            currentBall.ballNumber,
            balls.getBallsForBowler(activeInning?.id, bowlingPerformance.playerMp.id),
            activeInning
          )
        }
      }
      if (activeInning) {
        const latestBall = balls.getNewestBall(activeInning?.id, true)
        const isNewestOver = latestBall?.overNumber === currentBall?.overNumber
        const isNewestBall =
          `${latestBall?.overNumber}.${latestBall?.ballNumber}` ===
          `${currentBall?.overNumber}.${currentBall?.ballNumber}`
        activeInning?.newBowler(player, isNewestOver, isNewestBall)
      }
      if (currentBall && currentBall.bowlerMp && appSettings.appMode === 'core') {
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.appMode, game),
          S3PHelpers.bowlerChange(player, currentBall.bowlerMp, game)
        )
      }
      currentBall?.setBowler(player)
      if (appSettings.appMode === 'fielding' && currentBall && currentBall.bowlerMp) {
        // if in fielding mode, update the current field preset name with new bowler name
        fieldingPlacements
          .getFieldById(matchSettings.activeFieldId)
          ?.updateLabel(currentBall.bowlerMp, currentBall.overNumber)
      }
      if (activeInning) {
        timelineEvents?.addNewBowler(
          player,
          activeInning.inningsMatchOrder,
          appSettings.timeMachine.baseline,
          appSettings.timeMachine.activated
        )
      }
      setChangeBowler(false)
    }

    const completeMistakeBowler = (player: IMatchPlayerModel) => {
      // swap the ball details for any applicable balls in the current over
      if (!currentInning || !activeInning || !bowlerPerformance) return
      const isCurrentOverInActiveInning =
        currentInning.id === activeInning.id && changeBowlerBallOver?.overNumber === currentBall?.overNumber
      const wasPerformance = bowlerPerformance
      if (isCurrentOverInActiveInning || (mistakeBowler && !changeBowlerBallOver)) {
        // set old perf to off strike & not bowling if changing bowler in current over of the current innings
        wasPerformance.setIsBowling(false)
        wasPerformance.setIsOnStrike(false)
      }

      // get list of the balls that need the bowler replaced for
      const replaceBalls = balls.getBallsFromPerf(
        currentInning.id,
        'bowl',
        wasPerformance,
        changeBowlerBallOver ? changeBowlerBallOver.overNumber : currentBall?.overNumber
      )
      if (!replaceBalls) return

      // create a bowling performance for the new bowler
      const newBowlerPerformance: IBowlingPerformanceModel = currentInning.inning.newBowler(
        player,
        !changeBowlerBallOver || isCurrentOverInActiveInning,
        (replaceBalls.length === 0 && !changeBowlerBallOver) ||
          (replaceBalls.length > 0 &&
            replaceBalls[replaceBalls.length - 1].isNewestBallInOver &&
            isCurrentOverInActiveInning)
      )

      each(replaceBalls, (ball: IBallModel) => {
        if (ball.bowlerMp?.id === wasPerformance?.playerMp.id) {
          const wasBall = getSnapshot(ball)

          // update ball & ball.dismissal's bowler ref
          ball.setBowler(player)
          if (ball.dismissal && ball.dismissal.batterMp) {
            // if ball had a dismissal, we need to update the batting perf dismissal object as well
            currentInning.inning
              .getBatterPerformance(ball.dismissal.batterMp.id)
              ?.updateDismissal({ ...wasBall.dismissal, bowler: player })
          }

          // Get Edit Diff for ball
          const diff: BallEditContainerModel = MatchHelpers.differenceBetweenBalls({
            ball: wasBall,
            currentBall: ball,
            wasInMaiden: ball.overIsMaiden(),
            noBallValue: game.matchConfigs.noBallRuns,
            overStatus: ball.getOverStatus(game.matchConfigs.ballsPerOver || 6),
          })

          // Remove ball from was performance
          removeBallFromPerformance(
            wasPerformance,
            game.matchConfigs.ballsPerOver || 6,
            diff,
            ball.isNewestBallInOver && (isCurrentOverInActiveInning || (mistakeBowler && !changeBowlerBallOver)),
            balls.getBallsForBowler(currentInning.inning.id, wasPerformance.playerMp.id, true),
            balls.getLastBallForBowler(currentInning.inning.id, wasPerformance.playerMp.id),
            currentInning.id,
            appSettings.timeMachine.baseline && appSettings.timeMachine.activated
              ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
              : undefined
          )
          bowlingPerformanceRemoveBallCleanup(
            currentInning.inning,
            diff,
            balls.getBallsForBowler(currentInning.inning.id, wasPerformance.playerMp.id),
            game.matchConfigs.ballsPerOver || 6,
            game.matchConfigs.noBallRuns || 1
          )

          // set meta data
          ball.setBowlerHand(
            player?.player.bowlingHandedId !== null && player?.player.bowlingHandedId !== undefined
              ? Reference.HandedTypeOptions[player.player.bowlingHandedId]
              : null
          )
          ball.setBowlerType(
            player?.player.bowlingTypeId !== null && player?.player.bowlingTypeId !== undefined
              ? Reference.BowlerTypeOptions[player.player.bowlingTypeId]
              : null
          )

          // Add ball to new performance
          bowlingPerformanceAddBallPrepare(
            currentInning.inning,
            ball,
            balls.getBallsForBowler(currentInning.inning.id, newBowlerPerformance.playerMp.id),
            game.matchConfigs.ballsPerOver || 6,
            ball.isNewestBallInOver && (!changeBowlerBallOver || isCurrentOverInActiveInning),
            !changeBowlerBallOver || isCurrentOverInActiveInning
          )
          addBallToPerformance(
            newBowlerPerformance,
            ball,
            game.matchConfigs.ballsPerOver || 6,
            game.matchConfigs.noBallRuns || 1,
            ball.isNewestBallInOver && (!changeBowlerBallOver || isCurrentOverInActiveInning),
            currentInning.inning.getCurrentOver,
            diff
          )

          // replace bowler names in ball description & update lastUpdated timestamp
          let ballDesc = ball.textDescription
          if (wasPerformance.playerMp.cardNameF && newBowlerPerformance.playerMp.cardNameF) {
            ballDesc = ballDesc.replace(wasPerformance.playerMp.cardNameF, newBowlerPerformance.playerMp.cardNameF)
          }
          if (wasPerformance.playerMp.cardNameS && newBowlerPerformance.playerMp.cardNameS) {
            ballDesc = ballDesc.replace(wasPerformance.playerMp.cardNameS, newBowlerPerformance.playerMp.cardNameS)
          }
          ball.setTextDescription(ballDesc)
          ball.setLastUpdated(
            appSettings.timeMachine.baseline && appSettings.timeMachine.activated
              ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
              : undefined
          )
        }

        // construct S3P messages for the edited ball
        S3PHelpers.resendBallS3pMessages(
          appSettings.appMode,
          activeInning,
          currentInning.inning,
          game,
          ball,
          batterPerformance
        )
      })

      setBowlerPerformanceToChange(newBowlerPerformance)

      // set meta data on current ball (if current ball in the match's active innings)
      if (isCurrentOverInActiveInning || (mistakeBowler && !changeBowlerBallOver)) {
        currentBall?.setBowler(player)
        currentBall?.setBowlerHand(
          player?.player.bowlingHandedId !== null && player?.player.bowlingHandedId !== undefined
            ? Reference.HandedTypeOptions[player.player.bowlingHandedId]
            : null
        )
        currentBall?.setBowlerType(
          player?.player.bowlingTypeId !== null && player?.player.bowlingTypeId !== undefined
            ? Reference.BowlerTypeOptions[player.player.bowlingTypeId]
            : null
        )
      }

      if (currentInning && appSettings.appMode === 'core' && replaceBalls.length) {
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.appMode, game),
          S3PHelpers.overState(currentInning.inning, replaceBalls[0])
        )

        S3PHelpers.resendInningsStateS3pMessages(appSettings.appMode, game, currentInning.inning, balls)
      }

      // finish up
      currentInning.inning.cleanupBowlingPerformancesAndSpells(balls)
      setMistakeBowler(false)
      setChangeBowlerBallOver(null)
    }

    const completeChangeFielder = (
      newPlayer: IMatchPlayerModel,
      type?: string,
      oldPlayer?: IMatchPlayerModel | null
    ) => {
      if (currentBall && currentBall.fieldingAnalysis && oldPlayer) {
        const oldPlayerPlacement: IFieldingPositionModel | null | undefined =
          currentBall.fieldingAnalysis.fieldingPlacementForPlayer(oldPlayer.id)
        const newPlayerPlacement: IFieldingPositionModel | null | undefined =
          currentBall.fieldingAnalysis.fieldingPlacementForPlayer(newPlayer.id)
        if (newPlayerPlacement) {
          // swap positions (both players currently on the field)
          currentBall.fieldingAnalysis.swapPlayerPlacement(oldPlayer, newPlayer)
          if (
            oldPlayerPlacement &&
            !isNil(oldPlayerPlacement.fieldingPositionId) &&
            Reference.FieldingPositions[oldPlayerPlacement.fieldingPositionId] === 'WICKET_KEEPER'
          ) {
            // if swapping wicketkeeper position, also exchange the role
            game.getBowlingTeam(activeInning?.id)?.setWicketKeeper(newPlayer, true)
            game.getBowlingTeam(activeInning?.id)?.setWicketKeeper(oldPlayer, false)
          }
        } else if (oldPlayerPlacement) {
          // replace at position (new player is currently OFF the field)
          oldPlayerPlacement.updatePlayer(newPlayer)
          newPlayer.setActiveStatus(true)
          oldPlayer.setActiveStatus(false)
        }
      }
      setChangeFielder(null)
    }

    const triggerEditBall = ({ ball, isNewestOver, isNewestBallInOver }: TriggerEditBallArgs) => {
      if (
        !currentInning ||
        (editBall && editBall.overNumber === ball.overNumber && editBall.ballNumber === ball.ballNumber)
      ) {
        return
      }
      if (editBall) {
        // if we clicked on a ball to edit while already editing a ball...
        cancelEditBall()
      }
      const ballToEdit = balls.getBall(currentInning.id, ball.overNumber, ball.ballNumber)
      if (ballToEdit) {
        setEditBall(getSnapshot(ballToEdit))
        setEditBallProps({
          isNewestOver: isNewestOver || false,
          isNewestBall: (isNewestOver && isNewestBallInOver) || false,
          isNewestBallInOver: isNewestBallInOver,
          wasInMaiden: ball.overIsMaiden(),
          overStatus: ball.getOverStatus(game.matchConfigs.ballsPerOver || 6),
        })
        setBallsSnapshot(getSnapshot(balls))
        setCurrentBall(ball)
        setBetweenOvers(false)
      }
    }

    const triggerInsertBall = (overNumber: number, ballNumber: number) => {
      setInsertingBall(true)
      createNewBall(false, undefined, undefined, { overNumber, ballNumber })
      setInBall(true)
    }

    const triggerUndoReopenInnings = () => {
      if (!activeInning) return

      // Set the active innings meta flags back to false depending on the close reason
      if (activeInning.getCloseReason === 'TARGET_REACHED') {
        activeInningMetaData?.updatePassedScore(false)
      } else if (activeInning.getCloseReason === 'ALL_OUT') {
        activeInningMetaData?.updatePassedWickets(false)
      } else if (activeInning.getCloseReason === 'COMPULSORY_CLOSE') {
        activeInningMetaData?.updatePassedOvers(false)
      }

      // if we are undo-ing the latest ball of the previous innings whilst between innings
      activeInning.setStatus('IN_PROGRESS')
      activeInning.setCloseReason('IN_PROGRESS')
      activeInning.setEndTime(null)
      game.updateMatchBreak(null, true)
      setCurrentInning(rehydrateCurrentInning())
      setClosedInning(false)
      setDismissal(false)
      setEndInning(false)

      // re-open the partnership & any "not out" batting performances if ball being undo-ne is not a wicket
      activeInning.getLastPartnership()?.setEnd(null)
      activeInning.getCurrentBatterPerformances.forEach(b => b.updateBattingMins(null))

      // send innings re-open S3P message
      db.createS3PMessage(S3PHelpers.metadata(appSettings.appMode, game), S3PHelpers.innings('REOPEN', game))
    }

    const triggerUndoEvent = (event: ITimelineEventModel, relatedEvent?: ITimelineEventModel) => {
      if (isNil(event.matchEventTypeId)) return
      if (Reference.TimelineTypes[event.matchEventTypeId] === 'PENALTY_RUNS') {
        // remove the latest penalty runs added to the match
        const removedPenaltyId = game.removeLatestPenaltyRuns()

        if (appSettings.appMode === 'core') {
          const { team, inningsOrder } = MatchHelpers.getTeamAndInningsForPenaltyRuns(
            game,
            activeInning,
            event.textDescription === 'Penalty Runs - Batting team' ? 'bat' : 'field'
          )
          if (team && removedPenaltyId) {
            db.createS3PMessage(
              S3PHelpers.metadata(appSettings.appMode, game, undefined, removedPenaltyId),
              S3PHelpers.penaltyRuns(5, game, team, inningsOrder, true),
              true
            )
          }
        }

        if (timelineEvents) {
          timelineEvents.removeEvent(event.id)
        }
      } else if (event.playerMp && Reference.TimelineTypes[event.matchEventTypeId] === 'NON_BALL_DISMISSAL') {
        // remove the dismissal object from the player perf
        let restoreAsOnStrike = false
        if (relatedEvent?.playerMp) {
          const newBatterPerf = activeInning?.getBatterPerformance(relatedEvent.playerMp.id)
          if (newBatterPerf) {
            restoreAsOnStrike = newBatterPerf.onStrike
            activeInning?.removePerformance(newBatterPerf)
          }
        }
        const nonBallDismissalBatterPerf = activeInning?.getBatterPerformance(event.playerMp.id)
        if (nonBallDismissalBatterPerf) {
          if (
            activeInning &&
            nonBallDismissalBatterPerf.dismissal &&
            !isNil(nonBallDismissalBatterPerf.dismissal.dismissalTypeId)
          ) {
            if (
              !includes(
                ['RETIRED_HURT', 'RETIRED_NO'],
                Reference.DismissalMethods[nonBallDismissalBatterPerf.dismissal.dismissalTypeId]
              )
            ) {
              // ensure any non-ball dismissal that isn't Retired Hurt/Not Out reverts the wickets count...
              activeInning.setWickets((activeInning.progressiveScores.wickets ?? 1) - 1)
              // ... and removes the newly created partnership
              activeInning.removeCurrentPartnership(nonBallDismissalBatterPerf.dismissal)
            } else {
              // If it is Retired Hurt/Not Out then just remove the latest batter from the partnership
              activeInning.removeLatestBatterFromPartnership()
            }

            // send dismissal deleted s3p message
            if (activeInning && appSettings.appMode === 'core') {
              db.createS3PMessage(
                S3PHelpers.metadata(appSettings.appMode, game),
                S3PHelpers.dismissal(
                  game,
                  nonBallDismissalBatterPerf.dismissal.getDismissalType,
                  nonBallDismissalBatterPerf,
                  undefined,
                  undefined,
                  true
                )
              )
            }
          }
          nonBallDismissalBatterPerf.updateDismissal(null, true)
          nonBallDismissalBatterPerf.updateStrike(restoreAsOnStrike)
        }
        // Remove timeline event
        if (timelineEvents) {
          timelineEvents.removeEvent(event.id)
          if (relatedEvent) timelineEvents.removeEvent(relatedEvent.id)
        }
        // Remove ball
        if (currentBall) {
          balls.removeBall({
            inningsId: activeInning?.id,
            matchId: game.id,
            ballId: currentBall.id,
            deadBall: false,
            ballComplete: !!currentBall.timestamps.confirmed,
            overId: currentBall.overId,
          })
          createNewBall(awaitingFirstBallOfOver)
        }
        if (activeInning && appSettings.appMode === 'core') {
          db.createS3PMessage(
            S3PHelpers.metadata(appSettings.appMode, game),
            S3PHelpers.inningsState(appSettings.appMode, activeInning, game, balls)
          )
          db.createS3PMessage(
            S3PHelpers.metadata(appSettings.appMode, game),
            S3PHelpers.inningsStatePartnerships(activeInning, game.matchConfigs.ballsPerOver)
          )
          db.createS3PMessage(
            S3PHelpers.metadata(appSettings.appMode, game),
            S3PHelpers.inningsStateSpells(appSettings.appMode, activeInning, game.matchConfigs.ballsPerOver)
          )
        }
      }
    }

    const triggerUndo = (ball: IBallModel, undoOnly?: boolean) => {
      let reopenedInnings = false
      if (closedInning && activeInning?.inningsStatusId === 2) {
        triggerUndoReopenInnings()
        reopenedInnings = true
      }
      const bowlerFromUndoBall = ball.bowlerMp?.id
      const ballRemovedWasFirstBallOver = ball.ballNumber === 1
      const ballRemovedWasLastBallOver = ball.endOfOver
      if (activeInning && !undoOnly) {
        timelineEvents?.addBallRemove(
          activeInning.inningsMatchOrder,
          appSettings.timeMachine.baseline,
          appSettings.timeMachine.activated
        )
        setAwaitingFirstBallOfOver(ballRemovedWasFirstBallOver)
      }
      if (currentBall && ball.overNumber !== currentBall.overNumber) {
        cancelEndOfOverFinalise()
      }
      if (appSettings.appMode !== 'fielding') {
        const undoDiff = MatchHelpers.differenceBetweenBalls({
          ball: ball,
          wasInMaiden: ball.overIsMaiden(),
          noBallValue: game.matchConfigs.noBallRuns,
          overStatus: ball.getOverStatus(game.matchConfigs.ballsPerOver || 6),
        })
        ball.setBallConfirm(false, appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
        activeInning?.undoFromBall(
          ball,
          undoDiff,
          game.matchConfigs.ballsPerOver || 6,
          balls.getNewestBall(activeInning.id, true),
          undoOnly || reopenedInnings,
          lastBall,
          appSettings.timeMachine.baseline && appSettings.timeMachine.activated
            ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
            : undefined
        )
        if (activeInning) {
          // S3P: send deleted ball & commentary messages, and update innings and match state
          if (appSettings.appMode === 'core') {
            // send dismissal deleted s3p message
            if (ball?.dismissal) {
              db.createS3PMessage(
                S3PHelpers.metadata(appSettings.appMode, game),
                S3PHelpers.dismissal(game, ball.dismissal.getDismissalType, undefined, ball, undefined, true)
              )
            }
            db.createS3PMessage(
              S3PHelpers.metadata(appSettings.appMode, game),
              S3PHelpers.ball('COMPLETE', game, ball, true)
            )
          }
          if (
            currentBall &&
            ((appSettings.appMode === 'core' && game.matchConfigs.coverageLevelId === 1) ||
              (appSettings.appMode === 'advanced' && (game.matchConfigs.coverageLevelId || 3 >= 2)))
          ) {
            db.createS3PMessage(S3PHelpers.metadata(appSettings.appMode, game), S3PHelpers.commentary(game, ball, true))
          }
          S3PHelpers.resendInningsStateS3pMessages(appSettings.appMode, game, activeInning, balls)
        }
      }

      // Checks if the ball being undone was the first of a spell, and deletes the spell if it is
      BallHelpers.deleteSpellOnUndo(ball, activeInning)

      // remove the currentBall first, if it exists
      const currentBallIsNotLastConfirmed = currentBall && currentBall.id !== ball.id
      if (currentBallIsNotLastConfirmed) {
        balls.removeBall({
          inningsId: activeInning?.id,
          matchId: game.id,
          ballId: currentBall.id,
          deadBall: false,
          ballComplete: !!currentBall.timestamps.confirmed,
          overId: currentBall.overId,
        })
      }
      balls.removeBall({
        inningsId: activeInning?.id,
        matchId: game.id,
        ballId: ball.id,
        deadBall: false,
        ballComplete: !!ball.timestamps.confirmed,
        overId: ball.overId,
      })
      const newestBall = activeInning && balls.getNewestBall(activeInning.id)
      if (newestBall && game) {
        // set this ball id as the latestBallId on the match object - for Feed Reconciliation purposes
        game.setLatestBallId(newestBall.id)
      }
      game.setDescription(MatchHelpers.gameDescriptionString(game))
      const currentBowlers = activeInning?.getCurrentBowlerPerformances
      if (
        currentBowlers?.length === 2 &&
        !ballRemovedWasFirstBallOver &&
        bowlerFromUndoBall !==
          find(currentBowlers, (bowler: IBowlingPerformanceModel) => bowler.isBowling && bowler.onStrike)?.playerMp.id
      ) {
        // if undo means the bowler has changed
        activeInning?.switchStrikeBowlers(undefined, ballRemovedWasLastBallOver)
      }

      // Check for and remove any empty bowling performances
      activeInning?.bowlingPerformances.forEach(bowlingPerf => {
        if (bowlingPerf.isEmpty && bowlingPerf.playerMp.id !== bowlerFromUndoBall) {
          activeInning.removePerformance(bowlingPerf)
        }
      })

      if (appSettings.appMode !== 'fielding') {
        if (
          activeInning?.getActivePowerPlay?.start === activeInning?.progressiveScores.oversBowled &&
          ball.ballNumber === 1
        ) {
          // clear the active power play if we have just undo-ne the ball when it was activated
          activeInning?.updateActivePowerPlay(null, true)
        } else if (newestBall && !isNil(newestBall.powerPlayId)) {
          // if the now-newest ball was in a power play, we need to reactivate that
          if (activeInning?.getActivePowerPlay?.getDescription !== newestBall.getPowerPlayType) {
            activeInning?.updateActivePowerPlay(null, true)
          }
          activeInning?.updateActivePowerPlay(newestBall?.getPowerPlayType)
        }
      }
      if (!undoOnly) {
        createNewBall(ballRemovedWasFirstBallOver)
      }
    }

    const triggerMissedBall = () => {
      // set bowler running in timestamp (used for sorting on Activity Log)
      currentBall?.setBRI(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
      // confirm the current ball and then create a new current ball
      completeBall(false, true)
      createNewBall(false)
    }

    const handleEndInning = (type: string, clearNotOutRetirements?: boolean, dismissalType?: string) => {
      if (!activeInning) return
      setCurrentBall(undefined)
      closeEditBallMode()
      game.setLatestBallId(null)
      setOversPassedIsOpen(false)
      setScorePassedIsOpen(false)
      setWicketsPassedIsOpen(false)
      setEndOver(false)
      if (clearNotOutRetirements) {
        if (dismissalType) {
          // before we end the innings, we need to handle the final wicket of the innings
          triggerNewBatter(dismissalType)
          if (startsWith(dismissalType, 'RETIRED')) {
            // if we are retiring (any type), use "Retired Out" as this is the last wicket for the innings...
            // ...and we want to have the active partnership closed as a result of this
            activeInning.retireBattingPerformance(
              'RETIRED',
              batterPerformance,
              currentBall,
              undefined,
              appSettings.timeMachine.baseline && appSettings.timeMachine.activated
                ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
                : undefined
            )
            timelineEvents?.addBatterRetirement(
              dismissalType.replace('_', ' '),
              activeInning.inningsMatchOrder,
              batterPerformance?.getPlayerProfile,
              appSettings.timeMachine.baseline,
              appSettings.timeMachine.activated
            )
          } else {
            // for any other dismissal type, just make sure we process it normally
            activeInning?.dismissBattingPerformance(
              dismissalType,
              batterPerformance,
              currentBall,
              appSettings.timeMachine.baseline && appSettings.timeMachine.activated
                ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
                : undefined
            )
          }
        }
        // process any retired n.o./hurt => retired out
        activeInning?.setNotOutRetirementsAsOut()
        // move to the end of innings state
        setDismissal(false)
        setNonBallDismissal(undefined)
        setRetired(undefined)
      }
      if (activeInning && appSettings.appMode === 'core') {
        S3PHelpers.resendInningsStateS3pMessages(appSettings.appMode, game, activeInning, balls)
        db.createS3PMessage(S3PHelpers.metadata(appSettings.appMode, game), S3PHelpers.innings('FINISH', game, type))
      }
      game?.endInning(
        type,
        undefined,
        appSettings.timeMachine.baseline && appSettings.timeMachine.activated
          ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
          : undefined
      )
      if (appSettings.appMode === 'core' && MatchHelpers.checkIfInningsBreakStatusRequired(game, activeInning)) {
        // if we are now in an innings break, compose an S3P message
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.appMode, game),
          S3PHelpers.matchBreak('START', game.getActiveMatchBreak?.getMatchBreakType || null, game)
        )
      }
      setClosedInning(true)
    }

    const handleStartPowerPlay = (value?: string, endOnly?: boolean) => {
      if (!activeInning) return
      let activePowerPlay = game.getActivePowerPlay
      setPowerPlayStartIsOpen(false)
      if (activePowerPlay?.getDescription === value) return
      if (activePowerPlay) {
        timelineEvents?.addPowerPlayEnd(
          `${activePowerPlay.getDescription.replace(/_/g, ' ')} complete`,
          activePowerPlay,
          activeInning.inningsMatchOrder,
          appSettings.timeMachine.baseline,
          appSettings.timeMachine.activated
        )
      }
      activeInning.updateActivePowerPlay(null)

      if (!endOnly) {
        activeInning.updateActivePowerPlay(value)
        activePowerPlay = game.getActivePowerPlay
        if (activePowerPlay) {
          timelineEvents?.addPowerPlayStart(
            `${activePowerPlay.getDescription.replace(/_/g, ' ')} starting`,
            activePowerPlay,
            activeInning.inningsMatchOrder,
            appSettings.timeMachine.baseline,
            appSettings.timeMachine.activated
          )
        }
      }
    }

    const handleVenueEnds = (value: IVenueEndModel | null) => {
      setVenueEndsIsOpen(false)
      if (currentBall && game.venue && value) {
        currentBall.setVenueEnd(value)
        // show the umpire ends prompt now that we have chosen a starting end
        const onFieldUmpires = take(
          game.matchOfficials?.filter((official: IMatchOfficialModel) => official.getType === 'UMPIRE'),
          2
        )
        if (onFieldUmpires.length > 0) {
          setUmpireEnds({
            startingEnd: value.name,
            ends: game.venue.venueEnds,
            umpires: onFieldUmpires,
          })
          setUmpireEndsIsOpen(true)
        }
      }
    }

    const handleUmpireEnds = (
      umpireControl: IMatchOfficialModel | null,
      umpireNonControl: IMatchOfficialModel | null
    ) => {
      setUmpireEndsIsOpen(false)
      if (umpireControl) currentBall?.setUmpireControl(umpireControl)
      if (umpireNonControl) currentBall?.setUmpireNonControl(umpireNonControl)
    }

    const handlePenaltyRuns = ({ value }: { value: string }) => {
      if (!activeInning) return

      const { team, inningsOrder, inningsToAdjust } = MatchHelpers.getTeamAndInningsForPenaltyRuns(
        game,
        activeInning,
        value as 'bat' | 'field'
      )

      // add penalty runs to the innings
      if (inningsToAdjust) {
        inningsToAdjust.updatePenaltyRuns(5)
      }

      // add penalty runs history to match object
      const addedPenaltyId = game.updatePenaltyRuns(team?.id, 5, inningsOrder)

      if (appSettings.appMode === 'core' && team && addedPenaltyId) {
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.appMode, game, undefined, addedPenaltyId),
          S3PHelpers.penaltyRuns(5, game, team, inningsOrder),
          true
        )
      }

      // notify scorer that penalty runs have been applied
      timelineEvents?.addPenaltyRuns(
        `Penalty Runs - ${value === 'bat' ? 'Batting' : 'Fielding'} team`,
        activeInning.inningsMatchOrder,
        appSettings.timeMachine.baseline,
        appSettings.timeMachine.activated
      )
      toast({
        title: 'Penalty Runs',
        description: `+5 penalty runs to the ${value === 'bat' ? 'batting' : 'fielding'} team${
          value !== 'bat' && !inningsOrder
            ? ". Runs will be added to the fielding team's score once they begin their batting innings."
            : ''
        }`,
        status: 'success',
        duration: value !== 'bat' && !inningsOrder ? 10000 : 5000,
        isClosable: true,
      })
    }

    const createNewBall = useCallback(
      (
        endOfOver: boolean = false,
        currentBall: IBallModel | undefined = undefined,
        fieldingPositions: SnapshotOrInstance<IFieldingPlacementModel>[] | null | undefined = undefined,
        insertBall: { overNumber: number; ballNumber: number } | null = null,
        undoneOrCancelledDismissal: boolean = false
      ) => {
        if (game && activeInning) {
          // Bowler
          let bowlerOnStrikePerf = activeInning?.bowlingPerformances.find(perf => perf.onStrike)

          // Batter & Non Strike Batter ( & re-setting the Bowler when inserting ball )
          let previousBallAtPosition: IBallModel | null | undefined = null
          let previousBallAtPositionStrikeChange = false
          let previousBallAtPositionTimestamp = null
          if (insertBall && activeInning) {
            previousBallAtPosition =
              insertBall && activeInning
                ? balls.getBall(activeInning?.id, insertBall.overNumber, insertBall.ballNumber)
                : null
            if (!previousBallAtPosition) {
              // inserting as last ball in an over
              previousBallAtPosition = balls.getBall(activeInning?.id, insertBall.overNumber, insertBall.ballNumber - 1)
              previousBallAtPositionTimestamp = previousBallAtPosition?.timestamps.bowlerRunningIn
              if (previousBallAtPosition) {
                endOfOver = true
                previousBallAtPosition.setEndOfOver(false)
                bowlerOnStrikePerf = activeInning?.bowlingPerformances.find(
                  perf => perf.playerMp.id === previousBallAtPosition?.bowlerMp?.id
                )
                previousBallAtPositionStrikeChange = previousBallAtPosition
                  ? MatchHelpers.strikeShouldChange(previousBallAtPosition, game.matchConfigs.noBallRuns)
                  : false
                if (previousBallAtPosition.dismissal) {
                  // if previous last ball in the over was a wicket, we will need to get the batters from the first ball of next over
                  previousBallAtPosition = balls.getBall(activeInning?.id, insertBall.overNumber + 1, 1, true)
                  if (!previousBallAtPositionStrikeChange) previousBallAtPositionStrikeChange = true
                }
              }
            } else {
              // inserting before the end of an over
              previousBallAtPositionTimestamp = previousBallAtPosition?.timestamps.bowlerRunningIn
              bowlerOnStrikePerf = activeInning?.bowlingPerformances.find(
                perf => perf.playerMp.id === previousBallAtPosition?.bowlerMp?.id
              )
            }
          }
          const batterOnStrikePerf = previousBallAtPosition
            ? activeInning?.battingPerformances.find(
                perf =>
                  (previousBallAtPositionStrikeChange &&
                    perf.playerMp.id === previousBallAtPosition?.batterNonStrikeMp?.id) ||
                  (!previousBallAtPositionStrikeChange && perf.playerMp.id === previousBallAtPosition?.batterMp?.id)
              )
            : activeInning?.battingPerformances.find(perf => perf.notOut && perf.onStrike)
          const batterNonStrikePerf = previousBallAtPosition
            ? activeInning?.battingPerformances.find(
                perf =>
                  (previousBallAtPositionStrikeChange && perf.playerMp.id === previousBallAtPosition?.batterMp?.id) ||
                  (!previousBallAtPositionStrikeChange &&
                    perf.playerMp.id === previousBallAtPosition?.batterNonStrikeMp?.id)
              )
            : activeInning?.battingPerformances.find(perf => perf.notOut && !perf.onStrike)

          // Field (first ball of an innings)
          if (appSettings.appMode !== 'core' && matchSettings.activeFieldId === '') {
            matchSettings.setActiveFieldID(
              appSettings.appMode === 'fielding'
                ? 'default'
                : batterOnStrikePerf?.getPlayerProfile?.player.battingHandedId ===
                  indexOf(Reference.HandedTypeOptions, 'LEFT')
                ? 'defaultLhb'
                : 'defaultRhb'
            )
          }

          const updatedLastBall = balls.lastBall(activeInning.id)

          // Create ball
          const ball = balls.createBall({
            mode: appSettings.appMode,
            inningId: activeInning?.id,
            matchId: game.id,
            onStrikeBatter: batterOnStrikePerf,
            nonStrikeBatter: batterNonStrikePerf,
            bowler: bowlerOnStrikePerf,
            fieldingTeam: appSettings.appMode === 'fielding' ? game.getBowlingTeam() : undefined,
            endOfOver: endOfOver,
            powerPlayId: activeInning.getActivePowerPlay?.powerPlayTypeId,
            venueEnds: game.venue?.venueEnds,
            matchSettings: matchSettings,
            umpireControl: updatedLastBall?.umpireControl,
            umpireNonControl: updatedLastBall?.umpireNonControl,
            currentBall: currentBall,
            ballsPerOver: game.matchConfigs.ballsPerOver || 6,
            bowlerConsecutiveOvers: game.matchConfigs.bowlerConsecutiveOvers || false,
            freeHitAfterNoBall: game.matchConfigs.freeHitAfterNoBall || false,
            fieldingPositions: fieldingPositions || undefined,
            insertBall: insertBall || undefined,
            bowlerRunningInTimestamp: previousBallAtPositionTimestamp || undefined,
            awaitingFirstBallOfNewOver: awaitingFirstBallOfOver && insertingBall,
          })
          setCurrentBall(ball)
          setBetweenOvers(false)

          // check if we are potentially triggering the start/end of a new powerplay with this ball
          const isPowerPlayStartTrigger =
            !editBall &&
            !activeInning?.superOver &&
            powerPlayTriggers?.find(
              ppt => Number(ppt.startOver) === Number(activeInning?.progressiveScores.oversBowled)
            )
          const isPowerPlayEndTrigger =
            !editBall &&
            !activeInning?.superOver &&
            powerPlayTriggers?.find(ppt => Number(ppt.endOver) === Number(activeInning?.progressiveScores.oversBowled))
          if (
            powerPlayTriggers &&
            isPowerPlayStartTrigger &&
            ball?.ballNumber === 1 &&
            !undoneOrCancelledDismissal &&
            (!game.matchDls?.active || (game.matchDls?.active && isPowerPlayStartTrigger.powerPlay === 'POWER_PLAY_1'))
          ) {
            // check if power play is starting (will end any active power play first)
            setPowerPlayStartIsOpen(true)
          }
          if (
            powerPlayTriggers &&
            !isPowerPlayStartTrigger &&
            isPowerPlayEndTrigger &&
            ball?.ballNumber === 1 &&
            (!game.matchDls?.active || (game.matchDls?.active && isPowerPlayEndTrigger.powerPlay === 'POWER_PLAY_1'))
          ) {
            // check if power play is ending (and we don't have another one starting)
            handleStartPowerPlay(undefined, true)
          }
          // check if we need to show the venue ends prompt (on the first ball of each innings)
          if (
            appSettings.appMode !== 'fielding' &&
            ball?.overNumber === 0 &&
            ball?.ballNumber === 1 &&
            !insertBall &&
            !undoneOrCancelledDismissal &&
            game.venue
          ) {
            setVenueEndsIsOpen(true)
          }
          if (appSettings.appMode === 'core') {
            if (ball?.freeHit) {
              db.createS3PMessage(S3PHelpers.metadata(appSettings.appMode, game), S3PHelpers.freeHit(game))
            }
            if (insertBall && ball?.batterMp && ball?.runsBat) {
              db.createS3PMessage(
                S3PHelpers.metadata(appSettings.appMode, game),
                S3PHelpers.manualScoreChange(ball.runsBat, game, ball.batterMp.id, activeInning)
              )
            }
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        game,
        activeInning,
        appSettings.appMode,
        matchSettings,
        balls,
        setCurrentBall,
        setBetweenOvers,
        powerPlayTriggers,
        setPowerPlayStartIsOpen,
        setVenueEndsIsOpen,
        lastBall,
        editBall,
        insertingBall,
      ]
    )

    useEffect(() => {
      if (appSettings.appMode === 'fielding' && !matchSettings.fielderEvents) matchSettings.setFielderEvents(true)
      if (appSettings.appMode === 'fielding' && !matchSettings.fielderPlacement) matchSettings.setFielderPlacement(true)
      const eligibleBatters = game.getEligibleBattingPlayersInBattingOrder()
      if (!currentBall && activeInning && !closedInning) {
        const lastConfirmedBall = balls.lastBall(activeInning.id || '')
        // change logic here to not use the last ball endOfOver flag - not sure that actually makes sense
        // because we changed the way we add endOfOver on a ball
        // also don't think we should create a ball if the last ball was end of over - instead show the choose bowler screen
        let ballSet = false
        if (lastConfirmedBall) {
          if (
            lastConfirmedBall.endOfOver ||
            (lastConfirmedBall &&
              !lastConfirmedBall.endOfOver &&
              lastConfirmedBall.isNewestBallInCompleteOverValid(game.matchConfigs.ballsPerOver || 6))
          ) {
            // if the game was last scored between overs on the New Bowler screen, we need to return there upon resuming...
            // ... as long as we didn't already select a bowler for the upcoming over
            if (
              lastConfirmedBall.bowlerMp?.id ===
              activeInning.getCurrentBowlerPerformances.find((p: IBowlingPerformanceModel) => p.onStrike && p.isBowling)
                ?.playerMp.id
            ) {
              setEndOver(true)
              setCurrentBall(lastConfirmedBall)
            } else {
              // ... and if we have already selected a new bowler, we just need to create the first ball for the new over
              createNewBall(true)
            }
            ballSet = true
          }
          // if last ball was a dismissal OR retired, and there has not been another batter selected, and there are eligible batters
          // set appropriate state
          if (
            lastConfirmedBall.dismissal &&
            activeInning.battingPerformances.filter(p => p.notOut).length === 1 &&
            eligibleBatters &&
            eligibleBatters.length > 0
          ) {
            setDismissal(true)
            setCurrentBall(lastConfirmedBall)
            if (!lastConfirmedBall.endOfOver) setAwaitingFirstBallOfOver(false)
            ballSet = true
          }
          // if last ball was a dismissal OR retired, and there are no eligible batters remaining
          // set appropriate state
          if (
            lastConfirmedBall.dismissal &&
            (!eligibleBatters || eligibleBatters.length === 0) &&
            (activeInning.getCurrentBatterPerformances.length ?? 0) < 2
          ) {
            setCurrentBall(lastConfirmedBall)
            setWicketsPassedIsOpen(true)
            ballSet = true
          }
        }
        if (!ballSet) {
          createNewBall(false)
          setAwaitingFirstBallOfOver(false)
        }
      }
      if (activeInning?.inningsStatusId === 2 && !closedInning) {
        // if returning to Scoring screen but match has already been ended
        setClosedInning(true)
      }
      if (closedInning && lastBall && !editBall) {
        setDismissal(false)
        setCurrentBall(lastBall)
      }
      if (!currentInning || !activeInning) {
        setCurrentInning(rehydrateCurrentInning())
      }
      if (!activeInning && game) {
        setDismissal(false)
        setRetired(undefined)
        setMistake(false)
        setChangeBowler(false)
        setMistakeBowler(false)
        setEndOver(false)
        setEndInning(false)
      }
    }, [
      createNewBall,
      currentBall,
      activeInning,
      currentInning,
      setCurrentInning,
      rehydrateCurrentInning,
      balls,
      game?.id,
      game,
      setDismissal,
      setRetired,
      setMistake,
      setChangeBowler,
      setMistakeBowler,
      setEndOver,
      setEndInning,
      setAwaitingFirstBallOfOver,
      closedInning,
      setClosedInning,
      lastBall,
      setCurrentBall,
      setWicketsPassedIsOpen,
      editBall,
      appSettings.appMode,
      matchSettings,
    ])

    /* Between Innings */
    if (!activeInning && game && !closedInning && appSettings.appMode !== 'fielding') {
      return (
        <Flex flex={1}>
          <Flex flex="8" direction="column">
            {appSettings.appMode === 'advanced' && <ShortScorecard game={game} mode="advanced" />}
            <Flex flex={1}>
              <BetweenInning game={game} timelineEvents={timelineEvents} mode={appSettings.appMode} />
            </Flex>
          </Flex>
        </Flex>
      )
    } else if (
      (!activeInning || activeInning.getInningStatus === 'COMPLETED') &&
      game &&
      !closedInning &&
      appSettings.appMode === 'fielding'
    ) {
      return (
        <Flex flex={1} width="100%">
          <Flex flex="8" direction="column" width="100%">
            <Flex flex={1}>
              <BetweenInning game={game} timelineEvents={timelineEvents} mode={appSettings.appMode} />
            </Flex>
          </Flex>
        </Flex>
      )
    }

    /* Innings - Missing data */
    if (game && activeInning && appSettings.appMode !== 'fielding') {
      if (activeInning.battingPerformances.length < 2) {
        // if innings is missing either one or both batters, then go to the batting setup screen
        navigate(`/game/${game.id}/${appSettings.appMode}/inning-batting-setup`)
      } else if (
        activeInning.bowlingPerformances.length === 0 ||
        !activeInning.bowlingPerformances.find(perf => perf.onStrike)
      ) {
        // if innings is missing a starting bowler, then go to the bowling setup screen
        navigate(`/game/${game.id}/${appSettings.appMode}/inning-bowling-setup`)
      } else if (
        !dismissal &&
        !retired &&
        !mistake &&
        !nonBallDismissal &&
        !endInning &&
        !closedInning &&
        activeInning.battingPerformances.filter(p => p.notOut).length === 2 &&
        (!activeInning.battingPerformances.find(p => p.notOut && p.onStrike) ||
          !activeInning.battingPerformances.find(p => p.notOut && !p.onStrike))
      ) {
        // if strike/non-strike batters can't be determined from the two not out batters
        const activeBatters = activeInning.battingPerformances.filter(p => p.notOut)
        return (
          <Flex flex={1} width="100%" h="100%" alignItems="center" justifyContent="center" direction="column">
            <Box paddingY="7px">
              Strike batter could not be automatically determined. Please select the current strike batter:
            </Box>
            <Box paddingY="7px">
              <Button
                colorScheme="green"
                data-testid="selectFirstAsStrikeBatterButton"
                onClick={() => {
                  activeInning.switchStrike({
                    onStrike: activeBatters[0].playerMp,
                    offStrike: activeBatters[1].playerMp,
                    changeStrike: false,
                  })
                  createNewBall(awaitingFirstBallOfOver)
                }}
              >
                {activeBatters[0].playerMp.getDisplayName()}
              </Button>
            </Box>
            <Box paddingY="7px">
              <Button
                data-testid="selectSecondAsStrikeBatterButton"
                colorScheme="green"
                onClick={() => {
                  activeInning.switchStrike({
                    onStrike: activeBatters[1].playerMp,
                    offStrike: activeBatters[0].playerMp,
                    changeStrike: false,
                  })
                  createNewBall(awaitingFirstBallOfOver)
                }}
              >
                {activeBatters[1].playerMp.getDisplayName()}
              </Button>
            </Box>
          </Flex>
        )
      }
    }

    if (!currentBall || !activeInning || !game) return null

    const containerStyling =
      editBall || insertingBall
        ? {
            borderTop: '3px solid',
            borderRight: '3px solid',
            borderColor: insertingBall ? 'cls.blue.400' : 'cls.yellow.400',
          }
        : {
            borderTop: '3px solid',
            borderRight: '3px solid',
            borderColor: 'white',
          }

    if (appSettings.appMode === 'advanced') {
      return (
        <Flex flex={1}>
          <Flex flex="8" direction="column">
            <ShortScorecard
              inning={activeInning}
              game={game}
              mode="advanced"
              ball={currentBall}
              batterPerformance={batterPerformance}
              setBatterPerformance={setBatterPerformanceToChange}
              setBowlerPerformance={setBowlerPerformanceToChange}
              triggerNewBatter={triggerNewBatter}
              triggerNewBowler={triggerNewBowler}
              handlePenaltyRuns={handlePenaltyRuns}
              isDisabled={disableInterfaceEndInning || cancelledDismissal}
              inBall={inBall}
              editBall={editBall ? true : false}
              insertingBall={insertingBall}
              closedInning={closedInning}
              endOver={endOver}
            />
            <Flex flex={1} bg="white" borderTopRightRadius="7px" {...containerStyling}>
              {(!endOver ||
                (endOver && (closedInning || (endInning && eligibleBatters && eligibleBatters.length === 0)))) &&
                (!dismissal || ((dismissal || endInning) && eligibleBatters && eligibleBatters.length === 0)) &&
                !retired &&
                (!nonBallDismissal ||
                  ((nonBallDismissal || endInning) && eligibleBatters && eligibleBatters.length === 0)) &&
                !mistake &&
                !changeBowler &&
                !mistakeBowler && (
                  <Box padding="14px 7px 7px 14px" width="100%">
                    <DescriptionSection
                      game={game}
                      ball={currentBall}
                      lastBall={lastBall}
                      editBall={editBall}
                      editBallProps={editBallProps}
                      deadBall={deadBall}
                      cancelEditBall={cancelEditBall}
                      endOver={endOver}
                      preBall={!inBall && !editBall}
                      bowlerRunningIn={bowlerRunningIn}
                      triggerEndOver={triggerEndOverManual}
                      commentaryChanged={commentaryChanged}
                      setCommentaryChanged={setCommentaryChanged}
                      timelineEvents={timelineEvents}
                      isDisabled={disableInterfaceEndInning}
                      closedInning={closedInning}
                      cancelledDismissal={cancelledDismissal}
                      insertingBall={insertingBall}
                    />
                    {!inBall && !editBall && matchSettings.preBallScreen && matchSettings.fielderPlacement && (
                      <PreBallAdvanced game={game} ball={currentBall} />
                    )}
                    {(inBall || editBall || !matchSettings.preBallScreen || !matchSettings.fielderPlacement) && (
                      <>
                        <InBallAdvanced
                          game={game}
                          ball={currentBall}
                          completeBall={completeBall}
                          resyncBall={resyncBall}
                          inBall={inBall}
                          editBall={editBall}
                          ballRunsVal={ballRunsVal}
                          setBallRunsVal={setBallRunsVal}
                          ballExtrasVal={ballExtrasVal}
                          setBallExtrasVal={setBallExtrasVal}
                          isPrimaryEditCheck={isPrimaryEditCheck}
                          insertingBall={insertingBall}
                        />
                      </>
                    )}
                  </Box>
                )}
              {dismissal && eligibleBatters && eligibleBatters.length > 0 && (
                <Box w="40%">
                  <SelectPlayer
                    mode="advanced"
                    title="Select New Batter"
                    players={eligibleBatters}
                    playersNotRetiredNotOut={game.getEligibleBattingPlayersInBattingOrder(true)}
                    onChange={completeNewBatsmen}
                    onCancel={() => cancelDismissalOrLastBall(true)}
                    handleEndInning={handleEndInning}
                  />
                </Box>
              )}
              {nonBallDismissal && eligibleBatters && eligibleBatters.length > 0 && (
                <Box w="40%">
                  <SelectPlayer
                    mode="advanced"
                    title="Select New Batter"
                    type={nonBallDismissal}
                    players={game.getEligibleBattingPlayersInBattingOrder()}
                    playersNotRetiredNotOut={game.getEligibleBattingPlayersInBattingOrder(true)}
                    onChange={(player, type) => {
                      if (type) completeNonBallDismissal(player, type)
                    }}
                    handleEndInning={handleEndInning}
                  />
                </Box>
              )}
              {retired && (
                <Box w="40%">
                  <SelectPlayer
                    mode="advanced"
                    title="Select New Batter"
                    type={retired}
                    players={game.getEligibleBattingPlayersInBattingOrder()}
                    playersNotRetiredNotOut={game.getEligibleBattingPlayersInBattingOrder(true)}
                    onChange={(player, type) => {
                      if (type) completeRetired(player, type)
                    }}
                    handleEndInning={handleEndInning}
                  />
                </Box>
              )}
              {mistake && (
                <Box w="40%">
                  <SelectPlayer
                    mode="advanced"
                    title="Select New Batter"
                    players={game.getEligibleBattingPlayersInBattingOrder()}
                    onChange={completeMistake}
                    onCancel={cancelChangePerson}
                  />
                </Box>
              )}
              {changeBowler && (
                <Box w="40%">
                  <SelectPlayer
                    mode="advanced"
                    title="Select New Bowler"
                    players={game.getBowlingPlayersInBattingOrder(true)}
                    playersDisabled={currentBall.getOverBowlers(game.matchConfigs.bowlerConsecutiveOvers || false)}
                    onChange={completeChangeBowler}
                    onCancel={cancelChangePerson}
                  />
                </Box>
              )}
              {mistakeBowler && (
                <Box w="40%">
                  <SelectPlayer
                    mode="advanced"
                    title={
                      changeBowlerBallOver
                        ? `Select New Bowler for Over ${changeBowlerBallOver.overNumber + 1}`
                        : `Select New Bowler`
                    }
                    players={game.getBowlingPlayersInBattingOrder(true, false, currentInning.id)}
                    playersDisabled={currentBall.getOverBowlers(game.matchConfigs.bowlerConsecutiveOvers || false)}
                    onChange={completeMistakeBowler}
                    onCancel={cancelChangePerson}
                  />
                </Box>
              )}
              {!dismissal && !closedInning && endOver && (
                <Box w="40%">
                  <SelectPlayer
                    mode="advanced"
                    title="Select New Bowler"
                    subtitle={`Start of Over ${currentBall.overNumber + 2}`}
                    primaryButton="Start New Over"
                    players={game.getBowlingPlayersInBattingOrder(true)}
                    playersDisabled={currentBall.getOverBowlers(game.matchConfigs.bowlerConsecutiveOvers || false)}
                    playerPreselected={currentBall.getPreviousOverBowler(
                      game.matchConfigs.bowlerConsecutiveOvers || false
                    )}
                    onChange={completeEndOfOver}
                    onCancel={cancelEndOfOver}
                  />
                </Box>
              )}
            </Flex>
          </Flex>
          <Flex flex={game.isLimitedOversMatch ? '1' : '1.75'} direction="column">
            <ControlsPanel
              game={game}
              inning={activeInning}
              currentBall={currentBall}
              setCurrentBall={setCurrentBall}
              timelineEvents={timelineEvents}
              inningsInOrder={inningsInOrder}
              mode="advanced"
              isDisabled={disableInterfaceEndInning || cancelledDismissal}
              closedInning={closedInning}
              setClosedInning={setClosedInning}
              closeEditBallMode={closeEditBallMode}
              setEndOver={setEndOver}
              setInBall={setInBall}
            />
            <Flex flex={1} direction="column" paddingX="14px">
              <Box w="100%" h="2px" marginBottom="14px" borderBottom="2px solid" borderBottomColor="gray.200" />
              <ActivityLog
                game={game}
                balls={confirmedBalls}
                relevantTimelineEvents={relevantTimelineEvents}
                mode="advanced"
                manualEndOver={triggerEndOverManual}
                insertBall={triggerInsertBall}
                betweenOvers={betweenOvers}
                triggerEditBall={triggerEditBall}
                editBall={editBall ? currentBall : undefined}
                cancelEditBall={cancelEditBall}
                triggerUndo={triggerUndo}
                triggerUndoEvent={triggerUndoEvent}
                triggerNewBowler={triggerNewBowler}
                setBowlerPerformance={setBowlerPerformanceToChange}
                inningsInOrder={inningsInOrder}
                currentInning={currentInning}
                setCurrentInning={setCurrentInning}
                closedInning={closedInning}
                editingDisabled={
                  changeBowler ||
                  mistakeBowler ||
                  (endOver && !endInning) ||
                  mistake ||
                  !!retired ||
                  !!nonBallDismissal ||
                  (dismissal && eligibleBatters && eligibleBatters.length > 0) ||
                  cancelledDismissal ||
                  insertingBall
                }
                actionsDisabled={
                  disableInterfaceEndInning ||
                  changeBowler ||
                  mistakeBowler ||
                  mistake ||
                  !!retired ||
                  !!nonBallDismissal ||
                  inBall ||
                  closedInning ||
                  (dismissal && !disableInterfaceEndInning) ||
                  cancelledDismissal ||
                  insertingBall
                }
              />
            </Flex>
          </Flex>
          <ScoringAlerts
            mode={appSettings.appMode}
            activeInningMetaData={activeInningMetaData}
            scorePassedIsOpen={scorePassedIsOpen}
            setScorePassedIsOpen={setScorePassedIsOpen}
            oversPassedIsOpen={oversPassedIsOpen}
            setOversPassedIsOpen={setOversPassedIsOpen}
            handleEndInning={handleEndInning}
            wicketsPassedIsOpen={wicketsPassedIsOpen}
            setWicketsPassedIsOpen={setWicketsPassedIsOpen}
            powerPlayStartIsOpen={powerPlayStartIsOpen}
            setPowerPlayStartIsOpen={setPowerPlayStartIsOpen}
            handleStartPowerPlay={handleStartPowerPlay}
            powerPlayProps={{
              triggers: powerPlayTriggers,
              oversBowled: activeInning.progressiveScores.oversBowled,
              dlsActive: game.matchDls?.active,
            }}
            cascadeEditIsOpen={cascadeEditIsOpen}
            setCascadeEditIsOpen={setCascadeEditIsOpen}
            handleCascadeEdit={handleCascadeEdit}
            cascadeEditProps={cascadeEditProps}
            venueEndsIsOpen={venueEndsIsOpen}
            setVenueEndsIsOpen={setVenueEndsIsOpen}
            handleVenueEnds={handleVenueEnds}
            venueEndsProps={game.venue?.venueEnds}
            umpireEndsIsOpen={umpireEndsIsOpen}
            setUmpireEndsIsOpen={setUmpireEndsIsOpen}
            handleUmpireEnds={handleUmpireEnds}
            umpireEnds={umpireEnds}
            insertingBall={insertingBall}
            cancelDismissalOrLastBall={cancelDismissalOrLastBall}
          />
        </Flex>
      )
    }

    if (appSettings.appMode === 'core') {
      return (
        <Flex flex={1} width="100%" height="100%" justifyContent="center">
          <Flex
            flex={8}
            direction="column"
            width="100%"
            paddingX={['0px', '14px', '14px', '14px']}
            maxW={['100%', '100%', 1280, 1280]}
            alignItems="center"
          >
            {(!nonBallDismissal &&
              !retired &&
              !mistake &&
              !changeBowler &&
              !mistakeBowler &&
              (!dismissal || (dismissal && endInning && eligibleBatters && eligibleBatters.length === 0)) &&
              (!endOver || (endOver && endInning && eligibleBatters && eligibleBatters.length === 0))) ||
            (closedInning && endOver) ? (
              <Flex flex={1} width="100%">
                <Flex flex={2} width="100%">
                  {(!endOver ||
                    (endOver && (closedInning || (endInning && eligibleBatters && eligibleBatters.length === 0)))) &&
                    (!dismissal || ((dismissal || endInning) && eligibleBatters && eligibleBatters.length === 0)) &&
                    !inBall &&
                    !editBall && (
                      <PreBallCore
                        game={game}
                        ball={currentBall}
                        timelineEvents={timelineEvents}
                        relevantTimelineEvents={relevantTimelineEvents}
                        setCurrentBall={setCurrentBall}
                        confirmedBalls={confirmedBalls}
                        bowlerRunningIn={bowlerRunningIn}
                        betweenOvers={betweenOvers}
                        editBall={editBall}
                        triggerEditBall={triggerEditBall}
                        triggerUndo={triggerUndo}
                        triggerInsertBall={triggerInsertBall}
                        insertingBall={insertingBall}
                        currentBall={currentBall}
                        inningsInOrder={inningsInOrder}
                        currentInning={currentInning}
                        setCurrentInning={setCurrentInning}
                        editingDisabled={
                          changeBowler ||
                          mistakeBowler ||
                          (endOver && !endInning) ||
                          mistake ||
                          !!retired ||
                          !!nonBallDismissal ||
                          (dismissal && eligibleBatters && eligibleBatters.length > 0) ||
                          cancelledDismissal ||
                          insertingBall
                        }
                        activeInning={activeInning}
                        closedInning={closedInning}
                        setClosedInning={setClosedInning}
                        triggerNewBatter={triggerNewBatter}
                        triggerNewBowler={triggerNewBowler}
                        batterPerformance={batterPerformance}
                        setBatterPerformanceToChange={setBatterPerformanceToChange}
                        setBowlerPerformanceToChange={setBowlerPerformanceToChange}
                        handlePenaltyRuns={handlePenaltyRuns}
                        isDisabled={disableInterfaceEndInning}
                        setEndOver={setEndOver}
                      />
                    )}
                  {!endOver &&
                    (inBall || editBall) &&
                    (!dismissal || (dismissal && eligibleBatters && eligibleBatters.length === 0)) && (
                      <InBallCore
                        game={game}
                        ball={currentBall}
                        lastBall={lastBall}
                        ballStore={balls}
                        timelineEvents={timelineEvents}
                        completeBall={completeBall}
                        resyncBall={resyncBall}
                        deadBall={deadBall}
                        editBall={editBall}
                        editBallProps={editBall && editBallProps ? editBallProps : undefined}
                        cancelEditBall={cancelEditBall}
                        ballRunsVal={ballRunsVal}
                        setBallRunsVal={setBallRunsVal}
                        ballExtrasVal={ballExtrasVal}
                        setBallExtrasVal={setBallExtrasVal}
                        commentaryChanged={commentaryChanged}
                        setCommentaryChanged={setCommentaryChanged}
                        activeInning={activeInning}
                        handlePenaltyRuns={handlePenaltyRuns}
                        confirmedBalls={confirmedBalls}
                        insertingBall={insertingBall}
                        isPrimaryEditCheck={isPrimaryEditCheck}
                        isDisabled={disableInterfaceEndInning}
                      />
                    )}
                </Flex>
                <Flex
                  flex={1}
                  padding="7px 14px 14px"
                  display={['none', 'none', 'block', 'block']}
                  backgroundColor="cls.white.400"
                  borderTop="solid 2px"
                  borderTopColor="cls.backgroundGray"
                  marginLeft={['0', '0', '21px', '21px']}
                  borderRadius={['0', '0', '7px', '7px']}
                  w="100%"
                  h="100%"
                >
                  <ActivityLog
                    game={game}
                    balls={confirmedBalls}
                    relevantTimelineEvents={relevantTimelineEvents}
                    mode="core"
                    manualEndOver={triggerEndOverManual}
                    betweenOvers={betweenOvers}
                    triggerEditBall={triggerEditBall}
                    editBall={editBall ? currentBall : undefined}
                    cancelEditBall={cancelEditBall}
                    triggerUndo={triggerUndo}
                    triggerUndoEvent={triggerUndoEvent}
                    triggerNewBowler={triggerNewBowler}
                    setBowlerPerformance={setBowlerPerformanceToChange}
                    inningsInOrder={inningsInOrder}
                    currentInning={currentInning}
                    setCurrentInning={setCurrentInning}
                    closedInning={closedInning}
                    editingDisabled={
                      changeBowler ||
                      mistakeBowler ||
                      (endOver && !endInning) ||
                      mistake ||
                      !!retired ||
                      !!nonBallDismissal ||
                      (dismissal && eligibleBatters && eligibleBatters.length > 0) ||
                      cancelledDismissal ||
                      insertingBall
                    }
                    actionsDisabled={
                      disableInterfaceEndInning ||
                      changeBowler ||
                      mistakeBowler ||
                      mistake ||
                      !!retired ||
                      !!nonBallDismissal ||
                      inBall ||
                      (dismissal && !disableInterfaceEndInning) ||
                      cancelledDismissal ||
                      insertingBall ||
                      closedInning
                    }
                  />
                </Flex>
              </Flex>
            ) : (
              <>
                {dismissal && eligibleBatters && eligibleBatters.length > 0 && (
                  <SelectPlayer
                    mode="core"
                    title="Select New Batter"
                    cancelButton="Cancel & Reset Ball"
                    players={eligibleBatters}
                    playersNotRetiredNotOut={game.getEligibleBattingPlayersInBattingOrder(true)}
                    onChange={completeNewBatsmen}
                    onCancel={coreModeEditingDisabled ? undefined : () => cancelDismissalOrLastBall(true)}
                    handleEndInning={handleEndInning}
                    controlsPanelProps={{
                      game: game,
                      inning: activeInning,
                      currentBall: currentBall,
                      setCurrentBall: setCurrentBall,
                      timelineEvents: timelineEvents,
                      inningsInOrder: inningsInOrder,
                      mode: 'core',
                      isDisabled: disableInterfaceEndInning,
                    }}
                  />
                )}
                {nonBallDismissal && eligibleBatters && eligibleBatters.length > 0 && (
                  <Box w="40%">
                    <SelectPlayer
                      mode="core"
                      title="Select New Batter"
                      type={nonBallDismissal}
                      players={game.getEligibleBattingPlayersInBattingOrder()}
                      playersNotRetiredNotOut={game.getEligibleBattingPlayersInBattingOrder(true)}
                      onChange={(player, type) => {
                        if (type) completeNonBallDismissal(player, type)
                      }}
                      handleEndInning={handleEndInning}
                    />
                  </Box>
                )}
                {retired && (
                  <SelectPlayer
                    mode="core"
                    title="Select New Batter"
                    type={retired}
                    players={game.getEligibleBattingPlayersInBattingOrder()}
                    playersNotRetiredNotOut={game.getEligibleBattingPlayersInBattingOrder(true)}
                    onChange={(player, type) => {
                      if (type) completeRetired(player, type)
                    }}
                    handleEndInning={handleEndInning}
                  />
                )}
                {mistake && (
                  <SelectPlayer
                    mode="core"
                    title="Select New Batter"
                    players={game.getEligibleBattingPlayersInBattingOrder()}
                    onChange={completeMistake}
                    onCancel={cancelChangePerson}
                  />
                )}
                {changeBowler && (
                  <SelectPlayer
                    mode="core"
                    title="Select New Bowler"
                    players={game.getBowlingPlayersInBattingOrder(true)}
                    playersDisabled={currentBall.getOverBowlers(game.matchConfigs.bowlerConsecutiveOvers || false)}
                    onChange={completeChangeBowler}
                    onCancel={cancelChangePerson}
                  />
                )}
                {mistakeBowler && (
                  <SelectPlayer
                    mode="core"
                    title="Select New Bowler"
                    players={game.getBowlingPlayersInBattingOrder(true)}
                    playersDisabled={currentBall.getOverBowlers(game.matchConfigs.bowlerConsecutiveOvers || false)}
                    onChange={completeMistakeBowler}
                    onCancel={cancelChangePerson}
                  />
                )}
                {!dismissal && !closedInning && endOver && (
                  <SelectPlayer
                    mode="core"
                    title="Select New Bowler"
                    subtitle={`Start of Over ${currentBall.overNumber + 2}`}
                    primaryButton="Start New Over"
                    players={game.getBowlingPlayersInBattingOrder(true)}
                    playersDisabled={currentBall.getOverBowlers(game.matchConfigs.bowlerConsecutiveOvers || false)}
                    onChange={completeEndOfOver}
                    onCancel={cancelEndOfOver}
                    controlsPanelProps={{
                      game: game,
                      inning: activeInning,
                      currentBall: currentBall,
                      setCurrentBall: setCurrentBall,
                      timelineEvents: timelineEvents,
                      inningsInOrder: inningsInOrder,
                      mode: 'core',
                      isDisabled: disableInterfaceEndInning,
                      setEndOver: setEndOver,
                      setClosedInning: setClosedInning,
                    }}
                  />
                )}
              </>
            )}
          </Flex>
          <ScoringAlerts
            mode={appSettings.appMode}
            activeInningMetaData={activeInningMetaData}
            scorePassedIsOpen={scorePassedIsOpen}
            setScorePassedIsOpen={setScorePassedIsOpen}
            oversPassedIsOpen={oversPassedIsOpen}
            setOversPassedIsOpen={setOversPassedIsOpen}
            handleEndInning={handleEndInning}
            wicketsPassedIsOpen={wicketsPassedIsOpen}
            setWicketsPassedIsOpen={setWicketsPassedIsOpen}
            powerPlayStartIsOpen={powerPlayStartIsOpen}
            setPowerPlayStartIsOpen={setPowerPlayStartIsOpen}
            cascadeEditIsOpen={cascadeEditIsOpen}
            setCascadeEditIsOpen={setCascadeEditIsOpen}
            handleCascadeEdit={handleCascadeEdit}
            cascadeEditProps={cascadeEditProps}
            handleStartPowerPlay={handleStartPowerPlay}
            powerPlayProps={{
              triggers: powerPlayTriggers,
              oversBowled: activeInning.progressiveScores.oversBowled,
              dlsActive: game.matchDls?.active,
            }}
            venueEndsIsOpen={venueEndsIsOpen}
            setVenueEndsIsOpen={setVenueEndsIsOpen}
            handleVenueEnds={handleVenueEnds}
            venueEndsProps={game.venue?.venueEnds}
            umpireEndsIsOpen={umpireEndsIsOpen}
            setUmpireEndsIsOpen={setUmpireEndsIsOpen}
            handleUmpireEnds={handleUmpireEnds}
            umpireEnds={umpireEnds}
            cancelDismissalOrLastBall={cancelDismissalOrLastBall}
          />
        </Flex>
      )
    }

    if (appSettings.appMode === 'fielding') {
      return (
        <Flex flex={1} width="100%" height="100%" justifyContent="center">
          <Flex
            flex="8"
            direction="column"
            width="100%"
            paddingX={['0px', '14px', '14px', '14px']}
            maxW={['100%', '100%', 1280, 1280]}
            alignItems="center"
          >
            {!endOver && !inBall && !changeBowler && !changeFielder && !editBall && (
              <PreBallFielding
                game={game}
                ball={currentBall}
                lastBall={lastBall}
                timelineEvents={timelineEvents}
                relevantTimelineEvents={relevantTimelineEvents}
                setCurrentBall={setCurrentBall}
                confirmedBalls={confirmedBalls}
                bowlerRunningIn={bowlerRunningIn}
                triggerEndOver={triggerEndOverManual}
                betweenOvers={betweenOvers}
                fieldHasChanged={fieldHasChanged}
                setFieldHasChanged={setFieldHasChanged}
                editBall={editBall}
                triggerEditBall={triggerEditBall}
                triggerUndo={triggerUndo}
                triggerUndoEvent={triggerUndoEvent}
                triggerMissedBall={triggerMissedBall}
                currentBall={currentBall}
                inningsInOrder={inningsInOrder}
                currentInning={currentInning}
                setCurrentInning={setCurrentInning}
                setClosedInning={setClosedInning}
                closedInning={closedInning}
                editingDisabled={changeBowler || endOver}
                activeInning={activeInning}
                triggerNewBowler={triggerNewBowler}
                triggerNewFielder={triggerNewFielder}
                completeChangeBowler={completeChangeBowler}
                isDisabled={disableInterfaceEndInning}
                activityLogActionsDisabled={
                  disableInterfaceEndInning ||
                  changeBowler ||
                  inBall ||
                  (dismissal && !disableInterfaceEndInning) ||
                  closedInning
                }
              />
            )}
            {(inBall || editBall) && !changeFielder && (
              <InBallFielding
                game={game}
                ball={currentBall}
                ballStore={balls}
                mode="fielding"
                activeInning={activeInning}
                confirmedBalls={confirmedBalls}
                deadBall={deadBall}
                completeBall={completeBall}
                resyncBall={resyncBall}
                triggerEndOver={triggerEndOverManual}
                fieldHasChanged={fieldHasChanged}
                setFieldHasChanged={setFieldHasChanged}
                editBall={editBall}
                cancelEditBall={cancelEditBall}
                ballRunsVal={ballRunsVal}
                setBallRunsVal={setBallRunsVal}
                ballExtrasVal={ballExtrasVal}
                setBallExtrasVal={setBallExtrasVal}
                isPrimaryEditCheck={isPrimaryEditCheck}
                triggerNewFielder={triggerNewFielder}
              />
            )}
            {changeFielder && (
              <SelectPlayer
                mode="fielding"
                title="Select New Fielder"
                players={game.getBowlingPlayersInBattingOrder(true, true)}
                playersDisabled={currentBall.fieldingAnalysis?.fieldersDisallowedFromChange(changeFielder)}
                playerToSwap={game.getBowlingTeam()?.getPlayerById(changeFielder)}
                onChange={completeChangeFielder}
                onCancel={cancelChangePerson}
              />
            )}
            {changeBowler && (
              <SelectPlayer
                mode="fielding"
                title="Select New Bowler"
                players={game.getFieldingPlayersInBattingOrder(currentBall, true)}
                playersDisabled={[
                  game.getBowlingTeam(activeInning.id)?.getWicketkeeper,
                  ...currentBall.getOverBowlers(game.matchConfigs.bowlerConsecutiveOvers || false),
                ].filter((player): player is IMatchPlayerModel => !!player)}
                onChange={completeChangeBowler}
                onCancel={cancelChangePerson}
              />
            )}
            {!dismissal && endOver && (
              <SelectPlayer
                mode="fielding"
                title="Select New Bowler"
                subtitle={`Start of Over ${currentBall.overNumber + 2}`}
                primaryButton="Start New Over"
                players={game.getFieldingPlayersInBattingOrder(currentBall, true)}
                playersDisabled={[
                  game.getBowlingTeam(activeInning.id)?.getWicketkeeper,
                  ...currentBall.getOverBowlers(game.matchConfigs.bowlerConsecutiveOvers || false),
                ].filter((player): player is IMatchPlayerModel => !!player)}
                onChange={completeEndOfOver}
                onCancel={cancelEndOfOver}
                controlsPanelProps={{
                  game: game,
                  inning: activeInning,
                  currentBall: currentBall,
                  setCurrentBall: setCurrentBall,
                  timelineEvents: timelineEvents,
                  mode: 'fielding',
                  inningsInOrder: inningsInOrder,
                  isDisabled: disableInterfaceEndInning,
                }}
              />
            )}
          </Flex>
        </Flex>
      )
    }
    return null
  }
)
