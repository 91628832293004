import { Flex, Text } from '@chakra-ui/react'
import type { IBallModel, IInningModel } from '@clsplus/cls-plus-data-models'
import {
  convertBallsTotalToOvers,
  convertOversToBallsTotal,
  getOversRemaining,
  getOversValue,
  getProjectedTotal,
  getRunRate,
  getRunRateRequired,
} from '@clsplus/cricket-logic'
import { observer } from 'mobx-react-lite'

import { useMst } from '../../data/stores/rootStore'
import MatchHelpers from '../../helpers/matchHelpers'
import type { IBallStore, ISettingsModel } from '../../types/models'
import type { MetricProps } from '../../types/props'

const Metric = observer(({ id, game }: MetricProps) => {
  const activeInning: IInningModel | undefined = game?.getActiveInning()
  const { balls, appSettings }: { balls: IBallStore; appSettings: ISettingsModel } = useMst()
  if (!activeInning || !game) return null
  const matchSettings = appSettings.getMatchSettings(game.id)

  const metricTitle = () => {
    let val = ''
    switch (id) {
      case 'run_rate':
        val = 'RUN RATE'
        break
      case 'req_run_rate':
        val = 'RR REQ'
        break
      case 'last_5':
        val = 'LAST 5'
        break
      case 'run_rate_last_5':
        val = 'RR (L5)'
        break
      case 'runs_req':
        val = 'RUNS REQ'
        break
      case 'overs_rem':
        val = 'OVR. REM'
        break
      case 'projected':
        val = 'PROJECTED'
        break
      case 'new_ball_in':
        val = 'NEW BALL'
        break
      default:
        val = id.replace(/_/g, ' ').toUpperCase()
        break
    }
    return val
  }

  const getLast5Overs = (balls: IBallModel[]) => {
    const totalBalls = (game.matchConfigs.ballsPerOver || 6) * 5
    const total = { currentBall: 0, balls: 0, runs: 0, wickets: 0 }
    balls.map((ball: IBallModel) => {
      if (total.balls < totalBalls) {
        total.runs += (ball.runsBat || 0) + (ball.runsExtra || 0)
        if (ball.dismissal) total.wickets++
        if (ball.ballDisplayNumber && ball.ballDisplayNumber !== total.currentBall) {
          total.balls++
          total.currentBall = ball.ballDisplayNumber
        }
      }
      return true
    })
    return { wickets: total.wickets, runs: total.runs, balls: total.balls }
  }

  const metricValue = (id: string) => {
    let val: number | string = 0
    switch (id) {
      case 'run_rate':
        val = getRunRate({
          oversBowled: activeInning.progressiveScores.oversBowled || 0,
          runs: activeInning.progressiveScores.runs || 0,
          ballsPerOver: game.matchConfigs.ballsPerOver || 6,
        })
        break
      case 'req_run_rate': {
        const rrq = getRunRateRequired({
          runsRequired: MatchHelpers.getRunsRequired(
            game.getBattingTeam(),
            game.getBowlingTeam(),
            activeInning,
            game.matchDls?.targetScore
          ),
          oversBowled: activeInning.progressiveScores.oversBowled || 0,
          oversPerInnings: MatchHelpers.getOversPerInnings(
            activeInning,
            game.matchConfigs.maxOvers || 0,
            game.matchDls?.targetOvers
          ),
          ballsPerOver: game.matchConfigs.ballsPerOver || 6,
        })
        val = rrq === Infinity ? '-' : rrq
        break
      }
      case 'last_5':
      case 'run_rate_last_5': {
        const last5 = getLast5Overs(balls.confirmedBallsInOrder(activeInning.id))
        if (id === 'last_5') {
          val =
            matchSettings.scoreFormat === 'WICKETS-RUNS'
              ? `${last5.wickets}/${last5.runs}`
              : `${last5.runs}/${last5.wickets}`
        } else if (id === 'run_rate_last_5') {
          val = getRunRate({
            oversBowled: convertBallsTotalToOvers({ balls: last5.balls || 0 }),
            runs: last5.runs || 0,
            ballsPerOver: game.matchConfigs.ballsPerOver || 6,
          })
        }
        break
      }
      case 'runs_req':
        val = MatchHelpers.getRunsRequired(
          game.getBattingTeam(),
          game.getBowlingTeam(),
          activeInning,
          game.matchDls?.targetScore
        )
        break
      case 'overs_rem':
        val = getOversRemaining({
          oversBowled: activeInning.progressiveScores.oversBowled || 0,
          oversPerInnings: MatchHelpers.getOversPerInnings(
            activeInning,
            game.matchConfigs.maxOvers || 0,
            game.matchDls?.targetOvers
          ),
          ballsPerOver: game.matchConfigs.ballsPerOver || 6,
        })
        break
      case 'projected':
        val = getProjectedTotal({
          runs: activeInning.progressiveScores.runs || 0,
          oversBowled: activeInning.progressiveScores.oversBowled || 0,
          oversPerInnings: MatchHelpers.getOversPerInnings(
            activeInning,
            game.matchConfigs.maxOvers || 0,
            game.matchDls?.targetOvers
          ),
          ballsPerOver: game.matchConfigs.ballsPerOver || 6,
        })
        break
      case 'new_ball_in': {
        const lastNewBallOvers: number = balls.getLastNewBallTaken(activeInning?.id) || 0
        val = convertBallsTotalToOvers({
          balls:
            convertOversToBallsTotal({
              overs:
                lastNewBallOvers === 0
                  ? 0
                  : getOversValue({
                      currentValue: lastNewBallOvers,
                      undo: true,
                      ballsPerOver: game.matchConfigs.ballsPerOver || 6,
                    }),
            }) +
            convertOversToBallsTotal({ overs: 80, ballsPerOver: game.matchConfigs.ballsPerOver || 6 }) -
            convertOversToBallsTotal({
              overs: activeInning?.progressiveScores.oversBowled || 0,
              ballsPerOver: game.matchConfigs.ballsPerOver || 6,
            }),
        })
        break
      }
      case 'target':
        val = game.matchDls?.targetScore || '-'
        break
    }
    return val
  }
  return (
    <Flex key={`metric_${id}`} flex={1} flexDirection="column" marginX="7px" justify="flex-end">
      <Text fontSize="sm" color="cls.textGray" lineHeight="18px" letterSpacing="-0.5px">
        {metricTitle()}
      </Text>
      <Text fontSize="xl" fontWeight="600" lineHeight="22px">
        {metricValue(id)}
      </Text>
    </Flex>
  )
})

export default Metric
