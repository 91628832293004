import { Box, Flex, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Bowling from '../../assets/bowling.svg'
import BallHelpers from '../../helpers/ballHelpers'
import theme from '../../theme/theme'
import type { BallItemProps } from '../../types/props'
import BallSummary from './BallSummary'
import Undo from './Undo'

const BallItem = ({
  ball,
  editBall,
  triggerEditBall,
  mode,
  triggerUndo,
  handleBallClick,
  hasUndo,
  undoDisabled,
  noBallValue,
}: BallItemProps) => {
  const coreModeEditingDisabled = mode === 'core' && import.meta.env.VITE_ENV_BETTING_EDITING_DISABLED === 'true'
  return (
    <Box key={`ball-${ball.overNumber}_${ball.ballNumber}`} data-testid={`ball-${ball.overNumber}_${ball.ballNumber}`}>
      <Flex w="100%" h="60px" flexDirection="row">
        <Flex
          w={hasUndo && !undoDisabled ? '85%' : '100%'}
          h="100%"
          direction="column"
          backgroundColor={
            editBall && editBall.overNumber === ball.overNumber && editBall.ballNumber === ball.ballNumber
              ? 'cls.yellow.400'
              : mode !== 'core'
              ? 'cls.white.400'
              : 'cls.backgroundGray'
          }
          boxShadow="cls.light"
          borderRadius="4px"
          align="center"
          cursor={coreModeEditingDisabled ? 'not-allowed' : triggerEditBall ? 'pointer' : 'auto'}
          onClick={() => {
            handleBallClick(ball, ball.isCurrentOver(false), ball.isNewestBallInOver)
          }}
        >
          <Flex w="100%" h="100%" paddingLeft="10px" direction="column" alignItems="center" justifyContent="center">
            <Flex w="100%" h="100%" paddingTop="0px" direction="row" alignItems="center" justifyContent="center">
              <Box textAlign="center">
                <Text fontSize={16} fontWeight={600} data-testid={`ball-${ball.overNumber}_${ball.ballNumber}-number`}>
                  {mode !== 'fielding' ? `${ball.overNumber}.${ball.ballDisplayNumber}` : ball.ballNumber}
                </Text>
              </Box>
              {mode !== 'fielding' && (
                <Flex marginX="14px" align="center" textAlign="center">
                  <Flex
                    borderRadius="16px"
                    height="32px"
                    width="32px"
                    border="2px solid"
                    align="center"
                    justify="center"
                  >
                    <Text
                      fontSize={BallSummary(ball, noBallValue || 1).length > 2 ? 'sm' : 'md'}
                      lineHeight={BallSummary(ball, noBallValue || 1).length > 2 ? '10px' : 'normal'}
                      data-testid={`ball-${ball.overNumber}_${ball.ballNumber}-summary`}
                    >
                      {BallSummary(ball, noBallValue || 1)}
                    </Text>
                  </Flex>
                </Flex>
              )}
              <Flex
                flex={1}
                direction="column"
                marginLeft={mode === 'fielding' ? '14px' : '0px'}
                overflow={mode === 'fielding' ? 'hidden' : 'unset'}
              >
                <Flex align="center" overflow={mode === 'fielding' ? 'hidden' : 'unset'}>
                  {mode !== 'fielding' && (
                    <>
                      <FontAwesomeIcon
                        icon={['fal', 'cricket']}
                        size="sm"
                        style={{ marginRight: '6px', fontSize: '10px' }}
                      />
                      <Text
                        fontSize="sm"
                        style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                        data-testid={`ball-${ball.overNumber}_${ball.ballNumber}-batterName`}
                      >
                        {ball.batterMp?.getDisplayName()}
                      </Text>
                    </>
                  )}
                  {mode === 'fielding' && (
                    <>
                      <FontAwesomeIcon
                        icon={['fal', 'hand-paper']}
                        size="sm"
                        style={{ marginRight: '8px', fontSize: '10px' }}
                      />
                      <Text
                        fontSize="sm"
                        style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                        data-testid={`ball-${ball.overNumber}_${ball.ballNumber}-fielderName`}
                      >
                        {ball.fieldingAnalysis?.fieldersInvolvedOnBall || '-'}
                      </Text>
                    </>
                  )}
                </Flex>
                <Flex align="center">
                  <Box width="17px">
                    <img src={Bowling} style={{ height: 12, width: 12 }} alt="Bowling" />
                  </Box>
                  <Text
                    fontSize="sm"
                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                    data-testid={`ball-${ball.overNumber}_${ball.ballNumber}-bowlerName`}
                  >
                    {ball.bowlerMp?.getDisplayName()}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            w="100%"
            h="16px"
            paddingY="7px"
            paddingX="4px"
            alignItems="center"
            justify="space-between"
            style={{
              backgroundColor:
                editBall && editBall.overNumber === ball.overNumber && editBall.ballNumber === ball.ballNumber
                  ? 'transparent'
                  : mode !== 'core'
                  ? theme.colors.cls.backgroundGray
                  : '#FFFFFF',
              border:
                editBall && editBall.overNumber === ball.overNumber && editBall.ballNumber === ball.ballNumber
                  ? `solid 2px ${theme.colors.cls.yellow['400']}`
                  : mode !== 'core'
                  ? 'solid 2px #FFFFFF'
                  : `solid 2px ${theme.colors.cls.backgroundGray}`,
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
            }}
          >
            {mode !== 'core' && (
              <>
                <Flex flex={1} alignItems="center" justifyContent="flex-start">
                  {ball.incomplete && (
                    <>
                      <FontAwesomeIcon
                        icon={['fas', 'exclamation-circle']}
                        color={theme.colors.cls.negativeRed}
                        size="sm"
                        style={{ fontSize: '10px' }}
                      />
                      <Text
                        h="16px"
                        marginLeft="4px"
                        lineHeight="16px"
                        fontSize="sm"
                        color="cls.negativeRed"
                        style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                        data-testid={`ball-${ball.overNumber}_${ball.ballNumber}-incompleteData`}
                      >
                        {mode === 'advanced' ? BallHelpers.incompleteBallData(ball)?.length || '' : ''}
                      </Text>
                    </>
                  )}
                </Flex>
              </>
            )}
            <Flex
              flex={1}
              alignItems="center"
              justifyContent={mode !== 'core' ? 'flex-end' : 'flex-start'}
              textAlign={mode !== 'core' ? 'right' : 'left'}
            >
              <FontAwesomeIcon icon={['far', 'clock']} size="sm" style={{ fontSize: '10px' }} />
              <Text
                h="16px"
                marginLeft="4px"
                lineHeight="16px"
                fontSize="sm"
                color="cls.black"
                style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                data-testid={`ball-${ball.overNumber}_${ball.ballNumber}-timeTakenToComplete`}
              >
                {ball.timeTakenToComplete}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {hasUndo && !undoDisabled && (
          <Flex w={mode === 'core' ? '15%' : 'unset'} h="100%" justify="flex-end">
            <Undo
              mode={mode}
              editBall={editBall ? true : false}
              onClick={() => {
                if (triggerUndo && !editBall) triggerUndo(ball)
              }}
            />
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

export default BallItem
