import {
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react'
import type { IInningModel } from '@clsplus/cls-plus-data-models'
import { observer } from 'mobx-react-lite'

import { useMst } from '../../data/stores/rootStore'
import type { ISettingsModel } from '../../types/models'
import type { TeamSummaryProps } from '../../types/props'

export const TeamSummary = observer(({ game, team, isBatting, gamesScreen }: TeamSummaryProps) => {
  const { appSettings }: { appSettings: ISettingsModel } = useMst()
  const matchSettings = game ? appSettings.getMatchSettings(game.id) : null
  const inningCount = team?.innings.length || 0
  const shortName = team?.shortName?.substring(0, 3).toUpperCase()
  const currentInning = game?.getActiveInning()

  const handleBattingReviewCountChange = (_valueAsString: string, valueAsNumber: number) => {
    currentInning?.setBattingReviewsRemaining(valueAsNumber)
  }

  const handleBowlingReviewCountChange = (_valueAsString: string, valueAsNumber: number) => {
    currentInning?.setBowlingReviewsRemaining(valueAsNumber)
  }

  return (
    <Flex h="44px">
      <Flex align="center" justify="center">
        <Flex
          align="center"
          justify="center"
          color="white"
          bg={isBatting ? 'red.400' : 'blue.400'}
          borderRadius="15px"
          h="30px"
          w="30px"
          fontSize="md"
        >
          <Text>{shortName}</Text>
        </Flex>
      </Flex>
      <Flex align="center" justify="flex-start" marginLeft="14px" flex={!gamesScreen ? 3 : undefined}>
        <Text
          textTransform="uppercase"
          fontWeight={isBatting ? 900 : 400}
          fontSize="xl"
          fontStyle="italic"
          letterSpacing="-0.5px"
          lineHeight="22px"
          paddingRight="10px"
        >
          {team?.name}
        </Text>
        {appSettings.appMode === 'advanced' && currentInning && isBatting && (
          <>
            <NumberInput
              width="60px"
              borderColor="gray.200"
              min={0}
              max={game?.matchConfigs.maxBattingReviewsPerInnings || 0}
              value={currentInning.battingReviewsRemaining === null ? 0 : currentInning.battingReviewsRemaining}
              onChange={handleBattingReviewCountChange}
              clampValueOnBlur
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper borderColor="gray.200" />
                <NumberDecrementStepper borderColor="gray.200" />
              </NumberInputStepper>
            </NumberInput>
            <Text marginLeft="7px" fontWeight={400} fontSize="md" fontStyle="italic" lineHeight="22px">
              of {game?.matchConfigs.maxBattingReviewsPerInnings} reviews remaining
            </Text>
          </>
        )}
        {appSettings.appMode === 'advanced' && currentInning && !isBatting && (
          <>
            <NumberInput
              width="60px"
              borderColor="gray.200"
              min={0}
              max={game?.matchConfigs.maxBowlingReviewsPerInnings || 0}
              value={currentInning.bowlingReviewsRemaining === null ? 0 : currentInning.bowlingReviewsRemaining}
              onChange={handleBowlingReviewCountChange}
              clampValueOnBlur
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper borderColor="gray.200" />
                <NumberDecrementStepper borderColor="gray.200" />
              </NumberInputStepper>
            </NumberInput>
            <Text marginLeft="7px" fontWeight={400} fontSize="md" fontStyle="italic" lineHeight="22px">
              of {game?.matchConfigs.maxBowlingReviewsPerInnings} reviews remaining
            </Text>
          </>
        )}
      </Flex>
      {team && (
        <Flex align="center" justify="flex-end" flex={1}>
          {team.innings.map((inning: IInningModel, idx: number) => (
            <Flex key={inning.inningsMatchOrder} justify="flex-end" align="center">
              <Text
                textTransform="uppercase"
                fontWeight={isBatting && inningCount - 1 === idx ? 900 : 400}
                fontSize={inningCount - 1 === idx ? '3xl' : '2xl'}
                fontStyle="italic"
                letterSpacing="-0.5px"
                lineHeight="22px"
                data-testid={`shortScorecard${team.isHome ? 1 : 2}Inning${idx + 1}Score`}
              >
                {game &&
                  inning &&
                  matchSettings?.scoreFormat === 'WICKETS-RUNS' &&
                  `${inning.progressiveScores.wickets}/${inning.progressiveScores.runs}`}
                {game &&
                  inning &&
                  matchSettings?.scoreFormat === 'RUNS-WICKETS' &&
                  `${inning.progressiveScores.runs}/${inning.progressiveScores.wickets}`}
              </Text>
              {inningCount - 1 === idx && (
                <Text
                  marginLeft="14px"
                  textTransform="uppercase"
                  fontWeight={400}
                  fontSize="lg"
                  fontStyle="italic"
                  letterSpacing="-0.5px"
                  data-testid={`shortScorecard${team.isHome ? 1 : 2}Inning${idx + 1}OversBowled`}
                >
                  {inning && `(${inning.progressiveScores.oversBowled})`}
                </Text>
              )}
              {inningCount - 1 !== idx && (
                <Text
                  textTransform="uppercase"
                  fontWeight={400}
                  fontSize="2xl"
                  fontStyle="italic"
                  letterSpacing="-0.5px"
                  lineHeight="22px"
                  paddingX="14px"
                >
                  &#38;
                </Text>
              )}
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  )
})
